import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';


const TextEditor = React.forwardRef(({className, style, value, onChange}, ref) => {
    return (
        <ReactQuill ref={ref} className={className} style={{...style}} value={value} theme= 'snow'
                    formats={['bold', 'italic', 'underline', 'link', 'list','header']}
                    onChange={onChange}>
        </ReactQuill>
    );
});

export default TextEditor;
