import {jwtDecode} from "jwt-decode";

const versions = [
    'starter',
    'extended',
    'pro'
    ];
 export const hasVersion = (version) => {
    const token = localStorage.getItem('id_token');
    const decoded = jwtDecode(token);

    return versions.indexOf(version) <= versions.indexOf(decoded.systemVersion);
}

export const getVersion = () => {
    const token = localStorage.getItem('id_token');
    const decoded = jwtDecode(token);

    return decoded.systemVersion;
}

