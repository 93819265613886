import React from 'react'
import {Grid, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {Info, LocalOffer, Settings, TextFields} from "@mui/icons-material";
import {Route, Routes} from "react-router";
import {Link, Navigate} from "react-router-dom";
import TagList from './Tags/List'
import PropertyList from './Properties/List'
import {useSelector} from "react-redux";
import BasicSettings from "./Basics/Settings";
import SystemInfo from "./Info/Info";
import {useMatchPaths} from "../../Utils/useMatchPaths";

const Router = () => {
    const {t} = useTranslation();
    const paths = ['/settings/general/system-info', '/settings/general/basics', '/settings/general/tags', '/settings/general/properties'];
    let currentTab = useMatchPaths(paths);
    let redirect = null;
    const user = useSelector((state) => state.user)
    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

    const routes = []
    const items = [];

    if (user.user.roles.includes('administrator')) {
        redirect = '/settings/general/system-info'
        items.push(<ListItemButton key='system-info' component={Link} to={'/settings/general/system-info'}
                                   selected={currentTab === '/settings/general/system-info'}>
            <ListItemIcon><Info/></ListItemIcon>
            <ListItemText primary={t('settings.systemInfo.label')}/>
        </ListItemButton>)
        routes.push(<Route key='basics' path={'system-info'} element={<SystemInfo/>}/>)
    }

    if (hasPermission(['basics.read'])) {
        redirect = redirect || '/settings/general/basics'
        items.push(<ListItemButton key='basics' component={Link} to={'/settings/general/basics'}
                                   selected={currentTab === '/settings/general/basics'}>
            <ListItemIcon><Settings/></ListItemIcon>
            <ListItemText primary={t('settings.basics.label')}/>
        </ListItemButton>)
        routes.push(<Route key='basics' path={'basics'} element={<BasicSettings/>}/>)
    }

    if (hasPermission(['tags.read'])) {
        redirect = redirect || '/settings/general/tags'
        items.push(<ListItemButton key='tags' component={Link} to={'/settings/general/tags'}
                                   selected={currentTab === '/settings/general/tags'}>
            <ListItemIcon><LocalOffer/></ListItemIcon>
            <ListItemText primary={t('settings.tags.label')}/>
        </ListItemButton>)
        routes.push(<Route key='tags' path={'tags'} element={<TagList/>}/>)
    }
    if (hasPermission(['properties.read'])) {
        redirect = redirect || '/settings/general/properties'
        items.push(<ListItemButton key='properties' component={Link} to={'/settings/general/properties'}
                                   selected={currentTab === '/settings/general/properties'}>
            <ListItemIcon><TextFields/></ListItemIcon>
            <ListItemText primary={t('settings.properties.label')}/>
        </ListItemButton>)
        routes.push(<Route key='properties' path={'properties'} element={<PropertyList/>}/>)
    }

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'}
                           element={<Navigate to={redirect}/>}/>)
    }


    return <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <List component={Paper}>
                {items}
            </List>
        </Grid>
        <Grid item xs={12} sm={9}>
            <Routes>
                {routes}
            </Routes>
        </Grid>
    </Grid>;

}

export default Router
