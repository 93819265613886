import { useCallback, useState } from "react";
import { debounce } from "lodash";

export const useDebounce = (obj = null, wait = 1000) => {
    const [state, setState] = useState(obj);

    const setDebouncedState = (_val) => {
        debounced(_val);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(
        debounce((_prop) =>  {
            setState(_prop)
        }, wait),
        []
    );

    return [state, setDebouncedState];
};
