import React from 'react';
import {
    Box,
    Button,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar, Tooltip
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {Add, ChevronLeft, ChevronRight, InfoOutlined} from "@mui/icons-material";
import Row from "../Settings/Tickets/Categories/Row";
import {v1 as uuid} from "uuid";

const Categories = ({categories, setCategories, setActiveStep}) => {
    const {t} = useTranslation();

    const onAdd = () => {
        categories.push({id: uuid(), name: '', description: ''})
        setCategories(categories.slice())
    }

    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Button size='small' startIcon={<ChevronLeft/>} variant='contained'
                    onClick={() => setActiveStep(2)}>{t('back')}</Button>
            <Box flexGrow={1}/>
            <Button size='small' startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(4)}>{t('next')}</Button>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.categories.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.categories.description')}</TableCell>
                        <Tooltip title={t('settings.categories.preSelectedInfo')}>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Box>{t('settings.categories.preSelected')}</Box>
                                    <InfoOutlined sx={{color: (theme) => theme.palette.grey[600]}} fontSize='small'/>
                                </Stack>
                            </TableCell>
                        </Tooltip>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map((cat) => (
                        <Row key={cat.id} category={cat} disableDelete={categories.length <= 1}
                             onDelete={() => {
                                 setCategories((cats) => {
                                     const i = cats.findIndex((c) => c.id === cat.id);
                                     cats.splice(i, 1)
                                     return cats.slice();
                                 })
                             }}
                             onChange={(changedCategory) => {
                                 setCategories((categories) => {
                                     const i = categories.findIndex((c) => c.id === changedCategory.id);
                                     categories[i] = changedCategory;

                                     if(changedCategory.preSelected) {
                                         categories.forEach((c, i) => {
                                             if(c.id !== changedCategory.id && c.preSelected) {
                                                 c.preSelected = false;
                                                 c.hasChanges = true;
                                                 categories[i] = c;
                                             }
                                         })
                                     }

                                     return categories.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Toolbar variant='dense' disableGutters={true}>
            <Button size='small' onClick={onAdd} startIcon={<Add/>}
                    variant='outlined'
                    color='primary'>{t('settings.categories.addCategory')}</Button>
        </Toolbar>
    </React.Fragment>
}

export default Categories;
