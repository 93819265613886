import { REQUEST_LIST_FAILURE, REQUEST_LIST_REQUEST, REQUEST_LIST_SUCCESS } from "../actions/requestActions";

export function requestReducer(state = {
    isFetching: false,
    error: null,
    list: [],
    total: 0,
    page: 1,
    size: 10
}, action) {
    switch (action.type) {
        case REQUEST_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case REQUEST_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case REQUEST_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.response,
                total: parseInt(action.headers['x-total-count'] || "0", 10),
                page: parseInt(action.headers['x-page'] || "1", 10),
                size: parseInt(action.headers['x-size'] || "10", 10)
            });
        default:
            return state
    }
}

