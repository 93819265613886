import { useSelector } from "react-redux";

const CheckPermissions = ({children, list, prefix = false}) => {
    const user = useSelector((state) => state.user)

    if (prefix) {
        const show = user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

        if (show) {
            return children
        } else {
            return null
        }
    } else {
        const show = user.user.permissions.includes('*') || user.user.permissions.some((key) => list.includes(key))
        if (show) {
            return children
        } else {
            return null
        }
    }
}

export default CheckPermissions
