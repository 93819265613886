import { Box, Button, IconButton, Paper, Toolbar } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, Delete, Edit, Replay } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import DeleteDialog from "../../../Utils/DeleteDialog";
import CheckPermissions from "../../../Utils/CheckPermissions";
import { useSelector } from "react-redux";

const columns = (theme, t, onDelete, permissions) => ([
    {
        field: 'name',
        editable: false,
        minWidth: 100,
        headerName: t('settings.connections.name'),
        flex: 1,
        disableColumnMenu: true
    },
    {
        disableColumnMenu: true,
        minWidth: 200,
        field: 'description',
        editable: false,
        headerName: t('settings.connections.description'),
        flex: 1,
        renderCell: ({value}) => (
            <Box sx={{color: theme.palette.text.secondary}}>
                {value}
            </Box>
        )
    },
    {
        disableColumnMenu: true,
        minWidth: 200,
        field: 'type',
        editable: false,
        headerName: t('settings.connections.type'),
        flex: 1,
        renderCell: ({value}) => (
            <Box sx={{color: theme.palette.text.secondary}}>
                {t('settings.connections.types.' + value)}
            </Box>
        )
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: t('actions'),
        getActions: (params) => {
            const actions = [];

            if (permissions.indexOf('connections.delete') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem disabled={!params.row.editable} icon={<Delete/>}
                                                  onClick={() => onDelete(params.id)}
                                                  label={t('settings.connections.deleteConnection')} showInMenu/>);
            }

            if (permissions.indexOf('connections.write') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem disabled={!params.row.editable} icon={<Edit/>} component={Link}
                                                  to={'/settings/integrations/connections/edit/' + params.row.id}
                                                  label={t('settings.connections.editConnection')}/>);
            }

            return actions;
        }
    }
]);


const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [connections, setConnections] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [deleting, setDeleting] = React.useState(false);

    const user = useSelector((state) => state.user)

    useEffect(() => {
        fetchConnections()
    }, []);

    const fetchConnections = () => {
        setLoading(true);
        Api.fetch({
            endpoint: 'connections'
        })
            .then((res) => {
                setConnections(res.response || []);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onDelete = () => {
        setDeleting(true);

        Api.fetch({
            endpoint: 'connections/' + deleteId,
            method: "DELETE",
        }).then((res) => {
            setDeleteId(null)
            fetchConnections();
        }, () => {
        }).then(() => {
            setDeleting(false);
        });
    }

    let deleteConnectionName = '';
    if (deleteId) {
        const connection = connections.find(r => r.id === deleteId);
        if (connection) {
            deleteConnectionName = connection.name;
        }
    }


    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['connections.write']}>
                <Button size='small' component={Link} to={'/settings/integrations/connections/new'} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.connections.addConnection')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchConnections}><Replay/></IconButton>
        </Toolbar>
        <Paper>
            <DataGrid
                disableSelectionOnClick={true}
                loading={loading}
                autoHeight
                rows={connections}
                columns={columns(theme, t, setDeleteId, user.user.permissions)}
                pageSize={50}
                rowsPerPageOptions={[50]}
            />
        </Paper>
        <DeleteDialog onDelete={() => onDelete()} isDeleting={deleting} title={t('settings.connections.deleteConnection')}
                      handleClose={() => setDeleteId(null)}
                      description={t('settings.connections.deleteConnectionDescription', {name: deleteConnectionName})}
                      open={Boolean(deleteId)}/>
    </React.Fragment>;
}

export default List
