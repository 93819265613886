import {STATUS_LIST_FAILURE, STATUS_LIST_REQUEST, STATUS_LIST_SUCCESS} from "../actions/statusActions";

export function statusReducer(state = {
    isFetching: false,
    error: null,
    list: []
}, action) {
    switch (action.type) {
        case STATUS_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case STATUS_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case STATUS_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.response.sort((a, b) => a.priority - b.priority)
            });
        default:
            return state
    }
}

