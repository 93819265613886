import {
    Badge,
    Checkbox,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import React, { useEffect } from "react";
import { formatAddress } from "../../../helper/formatAddress";
import TextEditor from "../../Utils/TextEditor";
import { useTranslation } from "react-i18next";
import { Calculate, Timer } from "@mui/icons-material";
import CalculateDistanceDialog from "./CalculateDistanceDialog";
import { usePrevious } from "../../Utils/usePrevious";
import { Box } from "@mui/system";
import moment from "moment/moment";
import TimersDialog from "./TimersDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EditAction = ({action, handleChange, errorList, addresses, tasks, devices, deviceTypeList, customer}) => {
    const {t} = useTranslation();

    const [calculateDistanceDialogOpen, setCalculateDistanceDialogOpen] = React.useState(false);
    const [timerDialogOpen, setTimerDialogOpen] = React.useState(false);
    const previousTaskIds = usePrevious(action.taskIds);

    useEffect(() => {
        const previousMinutes = previousTaskIds?.map(taskId => {
            const task = tasks.find(task => task.id === taskId);
            return task.averageDuration;
        }).reduce((acc, curr) => acc + curr, 0);
        const newMinutes = action.taskIds.map(taskId => {
            const task = tasks.find(task => task.id === taskId);
            return task.averageDuration;
        }).reduce((acc, curr) => acc + curr, 0);
        if ((action.duration <= 0 || previousMinutes === parseInt(action.duration)) && newMinutes !== parseInt(action.duration)) {
            handleChange('duration', newMinutes);
        }

    }, [action.taskIds, previousTaskIds, action.duration, handleChange, tasks]);

    const calculatedDuration = action.taskIds.map(taskId => {
        const task = tasks.find(task => task.id === taskId);
        return task.averageDuration;
    }).reduce((acc, curr) => acc + curr, 0) === parseInt(action.duration) && action.taskIds.length > 0;

    return <React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth margin='normal'>
                    <InputLabel>{t('tickets.fields.addresses')}</InputLabel>
                    <Select
                        multiple
                        value={action.addressIds || []}
                        onChange={(e) => handleChange('addressIds', e.target.value)}
                        input={<OutlinedInput label={t('tickets.fields.addresses')}/>}
                        renderValue={
                            (selected) => selected.map((v) => addresses.find(r => r.id === v)?.name).join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {addresses.map((a) => (<MenuItem key={a.id} value={a.id}>
                                <Checkbox checked={action.addressIds?.includes(a.id)}/>
                                <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                                    <Chip size='small' sx={{ml: 2}}
                                          label={t('customers.address.main')}/> : null}</React.Fragment>}
                                              secondary={formatAddress(a)}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth margin='normal'>
                    <InputLabel>{t('tickets.fields.devices')}</InputLabel>
                    <Select
                        multiple
                        disabled={devices.length === 0}
                        value={action.deviceIds || []}
                        onChange={(e) => handleChange('deviceIds', e.target.value)}
                        input={<OutlinedInput label={t('tickets.fields.devices')}/>}
                        renderValue={
                            (selected) => selected.map((v) => {
                                const d = devices?.find(r => r.id === v)
                                return `${d?.number} ${d?.brand} ${d?.model}`
                            }).join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {devices.map((d) => {
                            const location = customer.addresses?.find((a) => a.id === d.addressId)?.locations?.find((l) => l.id === d.locationId)?.name || t('notFound');
                            const type = deviceTypeList?.find((dt) => dt.id === d.typeId)?.name || 'type not found';

                            return <MenuItem key={d.id} value={d.id}>
                                <Checkbox checked={action.deviceIds?.includes(d.id)}/>
                                <ListItemText
                                    primary={<React.Fragment>{d.number}: {d.brand} {d.model}</React.Fragment>}
                                    secondary={`${type} / ${location}`}/>
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth margin='normal'>
                    <InputLabel>{t('tickets.fields.tasks')}</InputLabel>
                    <Select
                        multiple
                        value={action.taskIds || []}
                        onChange={(e) => handleChange('taskIds', e.target.value)}
                        input={<OutlinedInput label={t('tickets.fields.tasks')}/>}
                        renderValue={
                            (selected) => selected.map((v) => tasks.find(r => r.id === v).name).join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {tasks.map((t) => (<MenuItem value={t.id} key={t.id}>
                                <Checkbox checked={action.taskIds?.includes(t.id)}/>
                                <ListItemText primary={t.name} secondary={t.description}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <TextEditor
            style={{}}
            value={action.message}
            onChange={(v) => handleChange('message', v)}
        />
        {errorList.hasOwnProperty('message') &&
            <FormHelperText error>{errorList.message}</FormHelperText>}
        <Box display='flex' alignItems='center'>
            <Grid container spacing={2} sx={{mt: 0}}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        type='number'
                        onChange={(e) => handleChange('duration', e.target.value)}
                        value={action.duration}
                        label={t('tickets.fields.duration')}
                        helperText={calculatedDuration ? t('tickets.actions.calculatedDuration') : ''}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('tickets.fields.minutes')}</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        type='number'
                        onChange={(e) => handleChange('distance', e.target.value)}
                        value={action.distance}
                        label={t('tickets.fields.distance')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">km
                                <IconButton edge="end" sx={{ml: 1}}
                                            onClick={() => setCalculateDistanceDialogOpen(true)}>
                                    <Calculate/>
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <Box sx={{ml: 1, mt: 2}}>
                <IconButton size='large' onClick={() => setTimerDialogOpen(true)}  >
                    <Badge color='secondary'
                           badgeContent={action.timerIds?.length || 0}>
                        <Timer/>
                    </Badge>
                </IconButton>
            </Box>
        </Box>
        <TimersDialog
            open={timerDialogOpen}
            onClose={() => setTimerDialogOpen(false)}
            timerIds={action.timerIds || []}
            setTimers={(timers) => {
                handleChange('distance', timers.reduce((a, b) => a + b.distance, 0));

                let durationInSeconds = 0
                timers.forEach((t) => {
                    const d = moment(moment(t.stoppedAt).diff(moment(t.startedAt))).unix();
                    durationInSeconds += d
                })

                handleChange('duration', Math.round(durationInSeconds / 60));
                handleChange('distance', timers.reduce((a, b) => a + b.distance, 0));
                handleChange('timerIds', timers.map(t => t.id))
            }}
        />
        <CalculateDistanceDialog
            open={calculateDistanceDialogOpen}
            addresses={addresses}
            onClose={() => setCalculateDistanceDialogOpen(false)}
            onSubmit={(distance) => {
                setCalculateDistanceDialogOpen(false)
                handleChange('distance', distance);
            }}
        />
    </React.Fragment>
}

export default EditAction;
