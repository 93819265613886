import { cloneDeep } from "lodash";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { fetchCategoryList } from "../../actions/categoryActions";
import { fetchStatusList } from "../../actions/statusActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const StatisticSettings = ({settings, onChange, statusList, categoryList}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleFilterChange = (key, value) => {
        const filter = settings.filter || {};
        filter[key] = value;
        settings.filter = filter;
        onChange(cloneDeep(settings));
    }

    const handleChange = (key, value) => {
        settings[key] = value;
        onChange(cloneDeep(settings));
    }

    useEffect(() => {
        dispatch(fetchCategoryList())
        dispatch(fetchStatusList())
    }, [dispatch]);

    return <>
        <Typography variant='h5' sx={{mt: 1, mb: 1}}
                    component='h1'>{t('dashboard.tickets.settings')}</Typography>
        <TextField
            fullWidth
            margin='dense'
            required
            label={t('dashboard.panelLabel')}
            placeholder={t('dashboard.panelLabel')}
            value={settings?.label}
            onChange={(e) => handleChange('label', e.target.value)}
        />
        <FormControl fullWidth margin='dense' sx={{mb: 1}}>
            <InputLabel>{t('dashboard.statistics.type')}</InputLabel>
            <Select
                value={settings?.type || 'tickets-by-status'}
                onChange={(e) => handleChange('type', e.target.value)}
                input={<OutlinedInput label={t('dashboard.statistics.type')}/>}
            >
                {['tickets-by-status', 'tickets-by-category'].map((key) => (
                    <MenuItem key={key} value={key}>
                        {t('dashboard.statistics.types.' + key)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <Typography variant='h5' sx={{mt: 1, mb: 1}}
                    component='h1'>{t('dashboard.tickets.filters')}</Typography>
        {settings.type === 'tickets-by-category' && <FormControl fullWidth margin='dense'>
            <InputLabel>{t('tickets.fields.categories')}</InputLabel>
            <Select
                value={settings?.filter?.categories || []}
                onChange={(e) => handleFilterChange('categories', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                multiple
                renderValue={
                    (selected) => selected.map((c) => categoryList.find(r => r.id === c)?.name || 'deleted').join(', ')
                }
                input={<OutlinedInput label={t('tickets.fields.categories')}/>}
            >
                {categoryList.map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                        <Checkbox checked={(settings?.filter?.categories || []).includes(r.id)}/>
                        <ListItemText primary={r.name} secondary={r.description}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>}
        <FormControl fullWidth margin='dense'>
            <InputLabel>{t('tickets.fields.status')}</InputLabel>
            <Select
                value={settings?.filter?.status || []}
                onChange={(e) => handleFilterChange('status', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                multiple
                renderValue={
                    (selected) => selected.map((c) => statusList.find(r => r.id === c)?.name || 'deleted').join(', ')
                }
                input={<OutlinedInput label={t('tickets.fields.status')}/>}
            >
                {statusList.map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                        <Checkbox checked={(settings?.filter?.status || []).includes(r.id)}/>
                        <ListItemText primary={r.name} secondary={r.description}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </>
}

export default StatisticSettings;
