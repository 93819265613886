import React from 'react';
import {Route, Routes} from 'react-router';
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import {hasPermission} from "../../helper/hasPermission";
import List from './List/List';
import Create from "./Create/Create";
import Show from "./Show/Show";
import Data from "./Recurring/Data";

const Router = () => {
    const user = useSelector((state) => state.user)
    return <Box component='div' sx={{my: 2, height: '100%', boxSizing: 'border-box', pb: 2}}>
        <Routes>
            <Route exact path='/' element={<List />}/>
            {hasPermission(user, ['tickets.write']) && <Route exact path='/new' element={<Create />}/>}
            {hasPermission(user, ['recurring.write']) && <Route exact path='/new-recurring' element={<Data />}/>}
            {hasPermission(user, ['recurring.write']) &&
                <Route exact path='/edit-recurring/:id' element={<Data />}/>}
            <Route path='/show/:id/*' element={<Show />}/>
        </Routes>
    </Box>
};

export default Router;
