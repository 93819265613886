import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import {
    Alert,
    Box,
    Collapse,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Switch,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Download, Replay, RestartAlt, Save, Send } from "@mui/icons-material";
import CheckPermissions from "../../../Utils/CheckPermissions";


const Settings = () => {
    const {t} = useTranslation()
    const [settings, setSettings] = useState({
        active: false,
        chatId: null,
        validationCode: null
    })

    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [testing, setTesting] = useState(false)

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const fetchChatId = () => {
        setFetching(true);
        Api.fetch({
            endpoint: 'integrations/telegram/chat-id',
        }).then((res) => {
            handleChange('chatId', res.response.chatId)
        }, () => {
        }).then(() => {
            setFetching(false)
        })
    }

    const testChat = () => {
        setTesting(true);
        Api.fetch({
            endpoint: 'integrations/telegram/test',
            method: 'POST',
            body: {
                chatId: settings.chatId,
            }
        }).then(() => {
        }, () => {
        }).then(() => {
            setTesting(false)
        })
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/telegram'
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    useEffect(() => {
        fetchSettings()
    }, [fetchSettings])

    const onSave = useCallback(() => {
        setSaving(true)
        Api.fetch({
            endpoint: 'settings/telegram',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving])

    return <Grid container>
        <Grid item xs={12} md={6}>
            <Toolbar variant='dense' disableGutters={true}>
                <CheckPermissions list={['telegram.write']}>
                    <LoadingButton
                        onClick={onSave}
                        size='small'
                        loadingPosition="start"
                        disabled={loading}
                        loading={saving}
                        startIcon={<Save/>}
                        variant='contained'
                        color='primary'>{t('save')}</LoadingButton>
                </CheckPermissions>
                <Box flexGrow={1}/>
                <IconButton onClick={() => {
                    fetchSettings();
                }}><Replay/></IconButton>
            </Toolbar>
            <Paper sx={{p: 2}}>
                <Alert sx={{mb: 2}} severity={'info'}>{t('settings.telegram.description')}</Alert>
                <FormControlLabel control={<Switch onChange={(e) => handleChange('active', e.target.checked)}
                                                   checked={settings.active}/>}
                                  label={t('settings.reminder.active')}/>
                <Collapse in={settings.active}>
                    <Typography>
                        <TextField
                            fullWidth
                            value={settings.chatId || ''}
                            label={t('settings.telegram.chatId')}
                            placeholder={t('settings.telegram.chatId')}
                            margin='normal'
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" color="primary"
                                                    disabled={!settings.chatId}
                                                    onClick={() => handleChange('chatId', null)}>
                                            <RestartAlt/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{__html: t('settings.telegram.instruction', {validationCode: settings.validationCode})}}/>
                    </Typography>
                    <CheckPermissions list={['telegram.write']}>
                        <LoadingButton loadingPosition="start" startIcon={<Download/>} loading={fetching}
                                       onClick={fetchChatId} sx={{mt: 2}} fullWidth
                                       variant='contained'>{t('settings.telegram.request')}</LoadingButton>
                        <LoadingButton disabled={!settings.chatId} loadingPosition="start" startIcon={<Send/>}
                                       loading={testing}
                                       onClick={testChat} sx={{mt: 2}} fullWidth
                                       variant='outlined'>{t('settings.telegram.test')}</LoadingButton>
                    </CheckPermissions>
                </Collapse>
            </Paper>
        </Grid>
    </Grid>;
}

export default Settings;
