import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";

const Ftp = ({setData, data}) => {
    const {t} = useTranslation()
    const setField = (field, value) => {
        setData({
            ...data,
            [field]: value
        })
    }

    return <Box sx={{p: 2}}>
        <FormControl fullWidth margin='dense'>
            <InputLabel>{t('settings.connections.ftp.protocol')}</InputLabel>
            <Select
                label={t('settings.connections.ftp.protocol')}
                value={data.protocol}
                fullWidth
                onChange={(e) => setField('protocol', e.target.value)}
            >
                {['ftp', 'sftp', 'ftps'].map((v) => (<MenuItem key={v} value={v}>
                    {v.toUpperCase()}
                </MenuItem>))}
            </Select>
        </FormControl>
        <TextField
            margin='dense'
            fullWidth
            value={data.host}
            onChange={(event) => setField('host', event.target.value)}
            label={t('settings.connections.ftp.host')}
            variant="outlined"
        />
        <TextField
            margin='dense'
            fullWidth
            value={data.port}
            onChange={(event) => setField('port', event.target.value)}
            label={t('settings.connections.ftp.port')}
            variant="outlined"
        />
        <TextField
            margin='dense'
            fullWidth
            value={data.username}
            onChange={(event) => setField('username', event.target.value)}
            label={t('settings.connections.ftp.username')}
            variant="outlined"
        />
        <TextField
            margin='dense'
            fullWidth
            value={data.password}
            type="password"
            onChange={(event) => setField('password', event.target.value)}
            label={t('settings.connections.ftp.password')}
            variant="outlined"
        />
    </Box>
}

export default Ftp;
