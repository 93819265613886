import {CALL_API} from '../middleware/api'

export const CONNECTION_LIST_REQUEST = 'CONNECTION_LIST_REQUEST';
export const CONNECTION_LIST_SUCCESS = 'CONNECTION_LIST_SUCCESS';
export const CONNECTION_LIST_FAILURE = 'CONNECTION_LIST_FAILURE';


export function fetchConnectionList() {

    return {
        [CALL_API]: {
            endpoint: 'connections',
            method: 'GET',
            types: [CONNECTION_LIST_SUCCESS, CONNECTION_LIST_FAILURE, CONNECTION_LIST_REQUEST]
        }
    }
}

