import React, {useCallback, useEffect} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar
} from "@mui/material";
import {Add, ChevronLeft, Replay, Save} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {useDispatch, useSelector} from "react-redux";
import {fetchTagList} from "../../../actions/tagActions";
import TagSelect from "../../Utils/TagSelect";
import {v1 as uuid} from "uuid";
import {fetchPropertyList} from "../../../actions/propertyActions";
import Api from "../../../core/Api";
import {push} from "@lagunovsky/redux-react-router";
import Schema from "validate";
import * as EmailValidator from "email-validator";
import {filterErrorList} from "../../Utils/FilterErrorList";
import Address from "../Utils/Address";
import PropertyInput from "../../Utils/PropertyInput";

const email = (val) => val ? EmailValidator.validate(val) : true;

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String,
            message: t('customers.validation.name'),
        },
        number: {
            required: true,
            type: String,
            message: t('customers.validation.number'),
        },
        email: {
            required: false,
            type: String,
            use: {email},
            message: t('customers.validation.email'),
        },
        telephoneNumber: {
            required: false,
            type: String,
            message: t('customers.validation.telephoneNumber'),
        },
        addresses: {
            type: Array,
            required: true,
            length: {min: 1},
            each: {
                id: {
                    type: String,
                    required: true
                },
                name: {
                    type: String,
                    required: true,
                    length: {min: 1},
                    message: t('customers.validation.addressName'),
                },
                address: {
                    type: String,
                    required: true,
                    length: {min: 1},
                    message: t('customers.validation.address'),
                },
                zip: {
                    type: String,
                    required: true,
                    message: t('customers.validation.zip'),
                },
                city: {
                    type: String,
                    required: true,
                    message: t('customers.validation.city'),
                },
                contacts: {
                    type: Array,
                    each: {
                        id: {
                            type: String,
                            required: true
                        },
                        name: {
                            type: String,
                            required: true,
                            length: {min: 1},
                            message: t('customers.validation.name'),
                        },
                    }
                },
                locations: {
                    type: Array,
                    required: true,
                    length: {min: 1},
                    each: {
                        id: {
                            type: String,
                            required: true
                        },
                        name: {
                            type: String,
                            required: true,
                            length: {min: 1},
                            message: t('customers.validation.locationName'),
                        },
                    }
                }
            }
        }
    })
}


const Create = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const tagList = useSelector((state) => state.tags.list)
    const propertyList = useSelector((state) => state.properties.list)
    const loading = useSelector((state) => state.tags.isFetching || state.properties.isFetching)

    const [errorList, setErrorList] = React.useState({})

    const [customer, setCustomer] = React.useState({
        name: '',
        number: '',
        email: '',
        telephoneNumber: '',
        tags: [],
        properties: [],
        addresses: [
            {
                id: uuid(),
                name: 'Hauptstandort',
                main: true,
                address: '',
                zip: '',
                city: '',
                additional: '',
                contacts: [],
                locations: [
                    {
                        id: uuid(),
                        name: 'Standplatz',
                    }
                ],
            }
        ]
    })

    const addAddress = () => {
        setCustomer({
            ...customer,
            addresses: [
                ...customer.addresses,
                {
                    id: uuid(),
                    name: 'Adresse ' + (customer.addresses.length + 1),
                    main: false,
                    address: '',
                    zip: '',
                    city: '',
                    additional: '',
                    contacts: [],
                    locations: [
                        {
                            id: uuid(),
                            name: '',
                        }
                    ],
                }
            ]
        })
    }

    const handleChange = (key, value) => {
        setCustomer({
            ...customer,
            [key]: value
        })
    }

    const handlePropertyChange = (id, value) => {
        let internalValue = value ? value.toString() : value;
        if (value === '') {
            internalValue = null;
        }

        const properties = customer.properties
        const index = properties.findIndex((property) => property.id === id)
        if (index === -1) {
            properties.push({
                id: id,
                value: internalValue
            })
        } else {
            properties[index]['value'] = internalValue
        }

        setCustomer({
            ...customer,
            properties: properties.slice()
        })
    }
    const [saving, setSaving] = React.useState(false);

    const fetchData = useCallback(() => {
        dispatch(fetchTagList())
        dispatch(fetchPropertyList())
    }, [dispatch])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const onSave = () => {
        setSaving(true)
        setErrorList({})

        const v = getValidationSchema(t)
        const errors = v.validate(JSON.parse(JSON.stringify(customer)))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'customers',
            body: customer,
            method: 'POST'
        }).then((res) => {
            dispatch(push('/customers/show/' + res.response.id))
        }, () => {
        }).then(() => setSaving(false))
    }

    const addressErrors = filterErrorList(errorList, 'addresses')

    const properties = propertyList.filter((property) => property.areas.includes('customer')).map((property) => {
        const value = customer.properties.find((propertyValue) => propertyValue.id === property.id)?.value
        return <TableRow key={property.id}>
            <TableCell>{property.name}</TableCell>
            <TableCell>{property.description}</TableCell>
            <TableCell>
                <PropertyInput property={property} value={value} onChange={handlePropertyChange}/>
            </TableCell>
        </TableRow>
    })

    const addressList = customer.addresses.map((address, i) => {
        return <Paper key={address.id} variant='outlined' sx={{mb: i < customer.addresses.length - 1 ? 2 : 0, p: 2}}>
            <Address address={address}
                     errors={addressErrors[i] || {}}
                     deleteDisabled={customer.addresses.length === 1}
                     onDelete={() => {
                         setCustomer((internalCustomer) => {
                             const addresses = internalCustomer.addresses

                             return Object.assign({}, internalCustomer, {
                                 addresses: addresses.filter((a) => a.id !== address.id)
                             })
                         })
                     }}
                     onChange={(updatedAddress) => {
                         setCustomer((internalCustomer) => {
                             const addresses = internalCustomer.addresses
                             addresses[i] = updatedAddress

                             if (updatedAddress.main) {
                                 addresses.forEach((a) => {
                                     if (a.id !== updatedAddress.id) {
                                         a.main = false
                                     }
                                 })
                             }

                             return Object.assign({}, internalCustomer, {
                                 addresses: addresses
                             })
                         })
                     }}/>
        </Paper>
    })

    return <React.Fragment>
        <Box sx={{overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box', pb: 2}}>
            <Toolbar variant='dense' disableGutters={true}>
                <LoadingButton
                    loadingPosition="start"
                    disabled={loading}
                    loading={saving}
                    onClick={onSave}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('customers.saveNew')}</LoadingButton>
                <Button sx={{ml: 2}} component={RouterLink} to={'/customers'}
                        startIcon={<ChevronLeft/>}
                        color='primary'>{t('back')}</Button>
                <Box flexGrow={1}/>
                <IconButton onClick={fetchData}><Replay/></IconButton>
            </Toolbar>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card sx={{mt: 1}}>
                        <CardHeader title={t('customers.information')}/>
                        <CardContent sx={{pt: 0}}>
                            <TextField
                                autoFocus
                                fullWidth
                                required
                                error={errorList.hasOwnProperty('name')}
                                helperText={errorList.name}
                                margin='dense'
                                label={t('customers.fields.name')}
                                placeholder={t('customers.fields.name')}
                                value={customer.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                            />
                            <TextField
                                fullWidth
                                margin='dense'
                                required
                                error={errorList.hasOwnProperty('number')}
                                helperText={errorList.number}
                                label={t('customers.fields.number')}
                                placeholder={t('customers.fields.number')}
                                value={customer.number}
                                onChange={(e) => handleChange('number', e.target.value)}
                            />
                            <TextField
                                fullWidth
                                margin='dense'
                                label={t('customers.fields.email')}
                                placeholder={t('customers.fields.email')}
                                value={customer.email}
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                            <TextField
                                fullWidth
                                margin='dense'
                                label={t('customers.fields.telephone')}
                                placeholder={t('customers.fields.telephone')}
                                value={customer.telephoneNumber}
                                onChange={(e) => handleChange('telephoneNumber', e.target.value)}
                            />
                            <TagSelect
                                multiple
                                margin='dense'
                                sx={{mt: 1}}
                                tags={tagList}
                                label={t('customers.fields.tags')} value={customer.tags}
                                onChange={(v) => handleChange('tags', v || null)}/>
                        </CardContent>
                    </Card>
                    {properties.length > 0 && <Card sx={{mt: 2}}>
                        <CardHeader title={t('customers.properties')}/>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('customers.fields.property')}
                                        </TableCell>
                                        <TableCell>
                                            {t('customers.fields.description')}
                                        </TableCell>
                                        <TableCell>
                                            {t('customers.fields.value')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {properties}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{mt: 1}}>
                        <CardHeader title={t('customers.addresses')} action={
                            <IconButton onClick={addAddress}>
                                <Add/>
                            </IconButton>
                        }/>
                        <CardContent sx={{pt: 0}}>
                            {addressList}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    </React.Fragment>;
}

export default Create;
