import {
    Box,
    Button,
    Chip,
    CircularProgress,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import CheckPermissions from "../../Utils/CheckPermissions";
import { Add, Delete, Replay } from "@mui/icons-material";
import React, { useCallback, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Api from "../../../core/Api";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { fetchTagList } from "../../../actions/tagActions";
import { fetchCategoryList } from "../../../actions/categoryActions";
import { fetchStatusList } from "../../../actions/statusActions";
import { uniq } from "lodash";
import DeleteDialog from "../../Utils/DeleteDialog";

const StyledLink = styled(RouterLink)`
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const RecurringTicketList = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [recurringTickets, setRecurringTickets] = React.useState([]);
    const [fetching, setFetching] = React.useState(false);
    const [customers, setCustomers] = React.useState({});
    const [deleteId, setDeleteId] = React.useState(null);
    const [deleting, setDeleting] = React.useState(false);

    const fetchRecurringTickets = useCallback(() => {
        setFetching(true);
        Api.fetch({
            endpoint: `recurring-tickets`,
        }).then((res) => {
            setRecurringTickets(res.response)
        }, () => {
        }).then(() => setFetching(false));
    }, []);

    const onDeleteClick = useCallback(() => {
        setDeleting(true)
        Api.fetch({
            endpoint: "recurring-tickets/" + deleteId,
            method: "DELETE"
        }).then(() => {
            fetchRecurringTickets();
            setDeleteId(null);
        }).then(() => setDeleting(false));
    }, [deleteId, fetchRecurringTickets]);

    const fetchData = useCallback(() => {
        fetchRecurringTickets();
        dispatch(fetchTagList())
        dispatch(fetchCategoryList())
        dispatch(fetchStatusList())
    }, [fetchRecurringTickets, dispatch]);

    useEffect(() => {
        const customerIds = uniq(recurringTickets.map(ticket => ticket.customerId)).sort();
        if (customerIds.filter(c => !Object.keys(customers).includes(c)) <= 0) {
            return;
        }

        const loadedCustomers = {};
        Api.fetch({
            endpoint: "customers",
            parameter: {
                filter: {
                    ids: 'in<-->' + customerIds.join(",")
                }
            }
        }).then(response => {
            response.response.forEach(customer => {
                loadedCustomers[customer.id] = customer;
            });
            customerIds.forEach((customerId) => {
                if (!loadedCustomers[customerId]) {
                    loadedCustomers[customerId] = null
                }
            })
            setCustomers(loadedCustomers);
        });

    }, [customers, recurringTickets])


    useEffect(() => {
        fetchData();
    }, [fetchData])

    const tagList = useSelector((state) => state.tags.list)
    const statusList = useSelector((state) => state.statusList.list)
    const categories = useSelector((state) => state.categories.list)

    const globalIsFetching = useSelector((state) => state.tags.isFetching || state.statusList.isFetching || state.categories.isFetching || state.userList.isFetching)
    const loading = fetching || globalIsFetching

    const ticketList = recurringTickets.map((recTicket) => {
        const customer = customers[recTicket.customerId];
        const category = categories.find(c => c.id === recTicket.categoryId);
        const status = statusList.find(c => c.id === recTicket.statusId);
        const filteredTags = recTicket.tags?.filter((tag) => tagList.find((t) => t.id === tag)) || [];
        return (
            <TableRow component={StyledLink} key={recTicket.id} hover
                      to={'/tickets/edit-recurring/' + recTicket.id}>
                <TableCell>{t('tickets.recurring.intervals.' + recTicket.interval)}</TableCell>
                <TableCell>{moment(recTicket.startTimestamp * 1000).utc().format('DD.MM.YYYY')}</TableCell>
                <TableCell>{recTicket.endTimestamp ? moment(recTicket.endTimestamp * 1000).utc().format('DD.MM.YYYY') : '-'}</TableCell>
                <TableCell>{customer?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>{category?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>{recTicket.title}</TableCell>
                <TableCell>{status?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>{recTicket.importanceId}</TableCell>
                <TableCell>
                    {filteredTags.length ? filteredTags.map((tag) => {
                        const tagDefinition = tagList.find((t) => t.id === tag);
                        return <Chip key={tag} label={tagDefinition.name}
                                     sx={{mr: 1, backgroundColor: tagDefinition.color, color: 'white'}}/>
                    }) : '-'}
                </TableCell>
                <TableCell>{moment(recTicket.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>{moment(recTicket.updatedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell width={50}>
                    <CheckPermissions list={['recurring.delete']}>
                        <IconButton disabled={deleting} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDeleteId(recTicket.id)
                        }}>
                            <Delete/>
                        </IconButton>
                    </CheckPermissions>
                </TableCell>
            </TableRow>
        )
    });

    const deleteTicket = deleteId ? recurringTickets.find((r) => r.id === deleteId) : null;

    const notFoundMessage = <Box sx={{textAlign: 'center', color: (theme) => theme.palette.grey[400]}}>
        <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        <Box sx={{color: (theme) => theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('tickets.recurring.notFound')}</Typography>
            <CheckPermissions list={['recurring.write']}>
                <Link variant='body2' component={RouterLink}
                      to={'/tickets/new-recurring'}>{t('tickets.recurring.add')}</Link>
            </CheckPermissions>
        </Box>
    </Box>

    return <>
        <Toolbar variant='dense' disableGutters={true} sx={{mx: 1}}>
            <CheckPermissions list={['recurring.write']}>
                <Button sx={{marginLeft: (th) => th.spacing(1)}} size='small' startIcon={<Add/>}
                        component={RouterLink}
                        to={'/tickets/new-recurring'}
                        variant='outlined'
                        color='primary'>{t('tickets.recurring.add')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton disabled={loading} onClick={fetchData}><Replay/></IconButton>
        </Toolbar>
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.interval')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.startDate')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.endDate')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.customer')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.category')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.title')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.status')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.importance')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.tags')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.createdAt')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.updatedAt')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                {!loading && <TableBody>
                    {ticketList}
                </TableBody>}
            </Table>
            {
                loading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>
            }
            {
                recurringTickets.length <= 0 && !loading && notFoundMessage
            }
        </TableContainer>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('tickets.recurring.delete')}
                      isDeleting={deleting}
                      handleClose={() => setDeleteId(null)}
                      description={t('tickets.recurring.deleteDescription', {title: deleteTicket?.title})}
                      open={Boolean(deleteTicket)}/>
    </>
}

export default RecurringTicketList
