import { styled } from "@mui/material/styles";
import { Box, FormControl, Slider, Typography } from "@mui/material";
import React from "react";

const MySlider = styled(Slider)({
    height: 8,
    '& .MuiSlider-rail': {
        opacity: 1,
        background: ' linear-gradient(to right, green, yellow, red) !important',
    },
    '& .MuiSlider-mark': {
        background: '#fff',
    },
    '& .MuiSlider-track': {
        border: 'none',
        background: 'transparent',
    }
})

const ImportanceSlider = ({value, onChange, label, ...props}) => {
    return <FormControl fullWidth margin='normal' {...props}>
        <Typography gutterBottom>{label}</Typography>
        <Box sx={{pl: 2, pr: 2}}>
            <MySlider
                step={1}
                marks={[
                    {value: 0, label: '0'},
                    {value: 1, label: '1'},
                    {value: 2, label: '2'},
                    {value: 3, label: '3'},
                    {value: 4, label: '4'},
                    {value: 5, label: '5'},
                    {value: 6, label: '6'},
                    {value: 7, label: '7'},
                    {value: 8, label: '8'},
                    {value: 9, label: '9'},
                    {value: 10, label: '10'}
                ]}
                min={0}
                max={10}
                valueLabelDisplay='auto'
                value={value}
                onChange={onChange}
            />
        </Box>
    </FormControl>
}

export default ImportanceSlider
