export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';

export function connectWebsocket() {
    return {
        type: WEBSOCKET_CONNECT
    }
}

export function disconnectWebsocket() {
    return {
        type: WEBSOCKET_DISCONNECT
    }
}

