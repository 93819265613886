import { IconButton, TableCell, TableRow, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Schema from "validate";
import DeleteDialog from "../../../Utils/DeleteDialog";
import { Delete } from "@mui/icons-material";
import CheckPermissions from "../../../Utils/CheckPermissions";

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String
        },
    })
}

const Row = ({type, onChange, onDelete, disableDelete}) => {
    const {t} = useTranslation();
    const [errorList, setErrorList] = React.useState({});
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const onDeleteClick = () => {
        setDeleteOpen(false);
        onDelete();
    }

    useEffect(() => {
        const v = getValidationSchema(t);
        const errors = v.validate({name: type.name})
        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setErrorList(errorObject)
            type.hasErrors = true;
            onChange(type)
        } else {
            type.hasErrors = false;
            setErrorList({})
            onChange(type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type.name, t]);

    return <React.Fragment>
        <TableRow>
            <TableCell width={300}>
                <TextField
                    fullWidth
                    variant='standard'
                    placeholder={t('settings.deviceType.name')}
                    value={type.name}
                    onChange={(e) => {
                        type.name = e.target.value
                        type.hasChanges = true
                        onChange(type)
                    }}
                    error={errorList.hasOwnProperty('name')}
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    variant='standard'
                    placeholder={t('settings.deviceType.description')}
                    value={type.description}
                    onChange={(e) => {
                        type.description = e.target.value
                        type.hasChanges = true
                        onChange(type)
                    }}
                />
            </TableCell>
            <TableCell width={50}>
                <CheckPermissions list={['devicesType.delete']}>
                    <IconButton disabled={disableDelete} onClick={() => setDeleteOpen(true)} align="right"><Delete/></IconButton>
                </CheckPermissions>
            </TableCell>
        </TableRow>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('settings.deviceType.deleteType')}
                      handleClose={() => setDeleteOpen(false)}
                      description={t('settings.deviceType.deleteTypeDescription', {name: type.name || ''})}
                      open={deleteOpen}/>
    </React.Fragment>
}

export default Row
