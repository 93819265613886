import {CALL_API} from '../middleware/api'

export const SYSTEM_DATA_REQUEST = 'SYSTEM_DATA_REQUEST';
export const SYSTEM_DATA_SUCCESS = 'SYSTEM_DATA_SUCCESS';
export const SYSTEM_DATA_FAILURE = 'SYSTEM_DATA_FAILURE';
export const SYSTEM_DATA_CLEAR = 'SYSTEM_DATA_CLEAR';

export function fetchSystemData() {

    return {
        [CALL_API]: {
            endpoint: 'settings/system',
            method: 'GET',
            types: [SYSTEM_DATA_SUCCESS, SYSTEM_DATA_FAILURE, SYSTEM_DATA_REQUEST]
        }
    }
}

export function clearSystemData() {
    return {
        type: SYSTEM_DATA_CLEAR
    }
}
