import React, {Suspense, useEffect, useState} from 'react';
import {createTheme, CssBaseline, IconButton, ThemeProvider} from '@mui/material';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import {history, store} from '../../core';
import Snackbars from '../Snackbar/Snackbars';
import i18n from '../../i18n/i18n';
import LoadingScreen from '../Utils/LoadingScreen';
import moment from 'moment';
import 'moment/locale/de';
import Main from "../Main/Main";
import SupportRouter from "../Support/Router";
import {ReduxRouter} from "@lagunovsky/redux-react-router";
import {useCookies} from 'react-cookie'
import {StyledEngineProvider} from '@mui/material/styles';
import {Route, Routes} from "react-router";
import Login from "../Login/Login";
import {deDE, enUS} from '@mui/x-data-grid';
import {deDE as coreDeDE, enUS as coreEnUS} from '@mui/material/locale';
import {Close} from "@mui/icons-material";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ErrorBoundary from "./ErrorBoundary";
import LoginCallback from "../Utils/LoginCallback";
import LiveTicketList from "../Live/LiveTicketList";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const createCustomTheme = (locale, theme) => {
    return createTheme(
        {
            palette: {
                mode: theme === 'dark' ? 'dark' : 'light',
                background: {
                    default: theme === 'dark' ? '#121212' : '#f3f3f3'
                    //default: "#f5f5f5"
                },
                primary: {
                    main: '#3b7ea9',
                },
                secondary: {
                    main: '#EE8434',
                },
                action: {
                    selected: 'rgba(0,0,0,0.12)',
                    selectedOpacity: 0.12
                }
            }
        },
        locale === 'de' ? deDE : enUS,
        locale === 'de' ? coreDeDE : coreEnUS,
    )
};

const defaultTheme = createCustomTheme('de', 'light');

const App = (props) => {
    // add action to all snackbars
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    };

    const [stateTheme, setStateTheme] = useState('light')
    const [customTheme, setCustomTheme] = useState(defaultTheme)
    const [cookies, setCookie] = useCookies(['locale', 'theme']);
    const {locale, theme} = cookies
    useEffect(() => {
        let myLocale = locale
        if (!locale) {
            myLocale = 'de'
            setCookie('locale', myLocale)
        }
        i18n.changeLanguage(myLocale);
        moment.locale(myLocale);
    }, [locale, setCookie])
    useEffect(() => {
        let myTheme = theme
        if (!theme) {
            myTheme = 'light'
            setCookie('theme', myTheme)
        }
        setStateTheme(myTheme);
    }, [theme, setCookie, setStateTheme])

    useEffect(() => {
        setCustomTheme(createCustomTheme(locale, stateTheme));
    }, [locale, stateTheme])

    // customized
    const action = key => (<IconButton color="inherit" size='small' onClick={onClickDismiss(key)}>
        <Close fontSize='small'/>
    </IconButton>);
    return <ThemeProvider theme={customTheme}>
        <StyledEngineProvider injectFirst>
            <CssBaseline/>
            <ErrorBoundary>
                <Suspense fallback={<LoadingScreen loading={true}/>}>
                    <I18nextProvider i18n={i18n}>
                        <Provider store={store}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <ReduxRouter history={history}>
                                    <SnackbarProvider ref={notistackRef} maxSnack={3} action={action}
                                                      anchorOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'right',
                                                      }}
                                                      autoHideDuration={6000}
                                                      preventDuplicate={true}>
                                        <Snackbars/>
                                        <Routes>
                                            <Route path={'/support/*'} element={<SupportRouter/>}/>
                                            <Route path={'/live/:linkId/:hash'} element={<LiveTicketList/>}/>
                                            <Route path={'/login'} element={<Login/>}/>
                                            <Route path={'/login-callback'} element={<LoginCallback/>}/>
                                            <Route path={'*'} element={<Main/>}/>
                                        </Routes>
                                    </SnackbarProvider>
                                </ReduxRouter>
                            </LocalizationProvider>
                        </Provider>
                    </I18nextProvider>
                </Suspense>
            </ErrorBoundary>
        </StyledEngineProvider>
    </ThemeProvider>
};

export default App;
