import {
    Box,
    Divider,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import React, {useCallback, useImperativeHandle, useRef} from "react";
import {useTranslation} from "react-i18next";
import Api from "../../core/Api";
import {Delete, UploadFile} from "@mui/icons-material";
import {useTheme} from "@mui/system";
import {useDropzone} from 'react-dropzone'
import {LoadingButton} from "@mui/lab";
import moment from "moment";

const TemporaryFileBrowser = ({type, accept, displayType}, ref) => {
    const uploadRef = useRef();

    const {t} = useTranslation();
    const theme = useTheme();
    const [files, setFiles] = React.useState([]);

    const deleteFile = useCallback((file) => {
        setFiles((files) => files.filter((f) => f.name !== file));
    }, []);


    useImperativeHandle(ref, () => ({
        upload: async (type) => {
            const promises = files.map((file) => {
                return new Promise((resolve, reject) => {
                    Api.fetch({
                        endpoint: 'storage/' + type,
                        method: 'POST',
                        body: {
                            fileName: file.name
                        }
                    }).then((response) => {
                        fetch(response.response.url, {method: 'PUT', body: file}).then(() => resolve(), () => reject());
                    }, () => {
                        reject();
                    });
                })
            })

            await Promise.all(promises);
        }
    }));

    const onDrop = useCallback(acceptedFiles => {
        setFiles((files) => {
            const newFiles = [...files, ...acceptedFiles];
            const uniqueFiles = new Map(newFiles.map(file => [file.name, file]));
            return Array.from(uniqueFiles.values())
        })
    }, [setFiles])

    const onFileSelect = useCallback(({target}) => {
        setFiles((files) => {
            const newFiles = [...files, ...Array.from(target.files)];
            const uniqueFiles = new Map(newFiles.map(file => [file.name, file]));
            return Array.from(uniqueFiles.values())
        })
    }, [setFiles])

    const {getRootProps} = useDropzone({onDrop, accept, multiple: true, noClick: true})

    let list = <List>
        {files.map((file, i) => {
            return <React.Fragment key={file.name}>
                <ListItem secondaryAction={<React.Fragment>
                    <IconButton onClick={() => deleteFile(file.name)}><Delete/></IconButton>
                </React.Fragment>}>
                    {file.type.indexOf('image/') === 0 && <ListItemAvatar sx={{mr: 2}}>
                        <Box sx={{width: '100px'}} textAlign='center'>
                            <Box component='img' sx={{maxWidth: '100px', maxHeight: '40px'}} alt={file.name}
                                 src={URL.createObjectURL(file)}/>
                        </Box>
                    </ListItemAvatar>}
                    <ListItemText primary={file.name} secondary={moment(file.lastModified).format('DD.MM.YY, HH:mm')}/>
                </ListItem>
                {i < files.length - 1 && <Divider variant={'fullWidth'} component='li'/>}
            </React.Fragment>
        })}
    </List>


    if (displayType === 'grid') {
        list = <ImageList sx={{px: 2}} variant='masonry' cols={3} gap={8}>
            {files.map((file) => {
                return <ImageListItem key={file.name}>
                    <img src={URL.createObjectURL(file)} alt={file.name}/>
                    <ImageListItemBar
                        title={file.name}
                        subtitle={moment(file.lastModified).format('DD.MM.YY, HH:mm')}
                        actionIcon={
                            <React.Fragment>
                                <IconButton onClick={() => deleteFile(file.name)}
                                            sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                ><Delete/></IconButton>
                            </React.Fragment>
                        }
                    />
                </ImageListItem>
            })}
        </ImageList>
    }

    let content = <React.Fragment>
        <Toolbar variant='dense'>
            <LoadingButton
                onClick={() => uploadRef.current.click()}
                size='small'
                loadingPosition="start"
                startIcon={<UploadFile/>}
                variant='contained'
                color='primary'>{t('upload')}</LoadingButton>
            <Box flexGrow={1}/>
        </Toolbar>
        {list}
    </React.Fragment>

    if (files.length <= 0) {
        content = <Box sx={{textAlign: 'center', color: theme.palette.grey[400], pb: 1, pt: 1}}>
            <UploadFile sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                <Typography variant='body2'>{t('filePicker.noFiles')}</Typography>
                <Link onClick={() => uploadRef.current.click()} variant='body2'>{t('filePicker.uploadFile')}</Link>
            </Box>
        </Box>
    }

    return <Box component='div' sx={{pt: 1}}>
        <input
            multiple={true}
            onChange={onFileSelect}
            accept={accept}
            ref={uploadRef}
            style={{display: 'none'}}
            id="file-upload-input"
            type="file"
        />
        <div {...getRootProps()}>
            {content}
        </div>
    </Box>;
}
export default React.forwardRef(TemporaryFileBrowser);
