import React from 'react';
import {Route, Routes} from 'react-router';
import {Box} from "@mui/material";
import Create from "./Create/Create";
import {useSelector} from "react-redux";
import {hasPermission} from "../../helper/hasPermission";
import List from "./List/List";
import Show from "./Show/Show";

const Router = () => {
    const user = useSelector((state) => state.user)
    return <Box component='div' sx={{my: 2, px: 2, overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box'}}>
        <Routes>
            <Route exact path='/' element={<List />}/>
            <Route path='show/:id/*' element={<Show />}/>
            {hasPermission(user, ['devices.write']) && <Route exact path='new' element={<Create />}/>}
        </Routes>
    </Box>
};

export default Router;
