import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import { Box, Grid, IconButton, Paper, TextField, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Replay, Save } from "@mui/icons-material";
import CheckPermissions from "../../../Utils/CheckPermissions";
import Schema from "validate";
import { cloneDeep } from "lodash";


const getValidationSchema = (t) => {
    return new Schema({
        companyName: {
            required: true,
            type: String,
            message: t('settings.basics.companyNameRequired'),
        },
        address: {
            required: true,
            type: String,
            message: t('settings.basics.addressRequired'),
        },
        zip: {
            required: true,
            type: String,
            message: t('settings.basics.zipRequired'),
        },
        city: {
            required: true,
            type: String,
            message: t('settings.basics.cityRequired'),
        }
    });
}

const Settings = () => {
    const {t} = useTranslation()
    const [settings, setSettings] = useState({
        companyName: '',
        address: '',
        zip: '',
        city: '',
    })

    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [errorList, setErrorList] = useState({})

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/basics'
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    useEffect(() => {
        fetchSettings()
    }, [fetchSettings])

    const onSave = useCallback(() => {
        setSaving(true)

        const v = getValidationSchema(t)
        const errors = v.validate(cloneDeep(settings))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'settings/basics',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
            setErrorList({})
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving, t])

    return <Grid container>
        <Grid item xs={12} md={6}>
            <form noValidate onSubmit={(e) => {
                e.preventDefault()
                onSave();
            }}>
                <Toolbar variant='dense' disableGutters={true}>
                    <CheckPermissions list={['basics.write']}>
                        <LoadingButton
                            size='small'
                            type='submit'
                            loadingPosition="start"
                            disabled={loading}
                            loading={saving}
                            startIcon={<Save/>}
                            variant='contained'
                            color='primary'>{t('save')}</LoadingButton>
                    </CheckPermissions>
                    <Box flexGrow={1}/>
                    <IconButton onClick={() => {
                        fetchSettings();
                    }}><Replay/></IconButton>
                </Toolbar>
                <Paper sx={{p: 2}}>
                    <TextField
                        autoFocus
                        fullWidth
                        required
                        error={errorList.hasOwnProperty('companyName')}
                        helperText={errorList.companyName}
                        margin='dense'
                        label={t('settings.basics.companyName')}
                        placeholder={t('settings.basics.companyName')}
                        value={settings.companyName}
                        onChange={(e) => handleChange('companyName', e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin='dense'
                        required
                        error={errorList.hasOwnProperty('address')}
                        helperText={errorList.address}
                        label={t('settings.basics.address')}
                        placeholder={t('settings.basics.address')}
                        value={settings.address}
                        onChange={(e) => handleChange('address', e.target.value)}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                margin='dense'
                                required
                                error={errorList.hasOwnProperty('zip')}
                                helperText={errorList['zip']}
                                label={t('settings.basics.zip')}
                                placeholder={t('settings.basics.zip')}
                                value={settings.zip}
                                onChange={(e) => handleChange('zip', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                margin='dense'
                                required
                                error={errorList.hasOwnProperty('city')}
                                helperText={errorList.city}
                                label={t('settings.basics.city')}
                                placeholder={t('settings.basics.city')}
                                value={settings.city}
                                onChange={(e) => handleChange('city', e.target.value)}

                            />
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    </Grid>;
}

export default Settings;
