import React from 'react';
import {Route, Routes} from 'react-router';
import New from "./New";
import {Navigate} from "react-router-dom";

const Router = () => {
    return <Routes>
        <Route exact path='new-ticket' element={<New />}/>
        <Route path={'*'} element={<Navigate to='/support/new-ticket'/>}/>
    </Routes>
};

export default Router;
