import {Card, CardHeader, IconButton, List} from "@mui/material";
import {Delete, DragHandle} from "@mui/icons-material";
import {Container, Draggable} from "react-smooth-dnd";
import React from "react";
import {useTranslation} from "react-i18next";
import DeleteDialog from "../../../Utils/DeleteDialog";
import {arrayMoveImmutable} from "array-move";
import Device from "./Device";
import Paper from "@mui/material/Paper";

const Address = ({location, locationData, onChange, devices}) => {
    const {t} = useTranslation()
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    return <>
        <Draggable key={location.position}>
            <Card variant='outlined' sx={{mb: 1}}>
                <CardHeader
                    avatar={<IconButton><DragHandle className='drag-handle-2'/></IconButton>}
                    title={locationData ? locationData.name : <i>{t('deleted')}</i>}
                    action={<IconButton onClick={() => setDeleteDialogOpen(true)}><Delete/></IconButton>}
                />
                <Paper variant='outlined' sx={{mx: 2, mb: 2}}>
                    <List>
                        <Container dragHandleSelector=".drag-handle-3" lockAxis="y"
                                   onDrop={({removedIndex, addedIndex}) => {
                                       location.devices = arrayMoveImmutable(location.devices, removedIndex, addedIndex).map((value, index) => {
                                           value.position = index;
                                           return value
                                       });
                                       onChange(location)
                                   }}>
                            {location.devices.filter((d) => devices.find(dd => dd.id === d.deviceId)).map((device) => {
                                return <Device device={device}
                                               deviceData={devices.find(dd => dd.id === device.deviceId)}
                                               onChange={(updatedDevice) => {
                                                   const index = location.devices.findIndex(dd => dd.deviceId === device.deviceId)
                                                   if (updatedDevice) {
                                                       location.devices[index] = updatedDevice
                                                   } else {
                                                       location.devices.splice(index, 1)
                                                   }

                                                   if (location.devices.length === 0) {
                                                       onChange(null)
                                                       return
                                                   }

                                                   onChange(location)
                                               }}/>
                            })}
                        </Container>
                    </List>
                </Paper>
            </Card>
        </Draggable>
        <DeleteDialog
            onDelete={() => {
                onChange(null)
                setDeleteDialogOpen(false)
            }} isDeleting={false}
            title={t('settings.tours.deleteTourLocation')}
            handleClose={() => setDeleteDialogOpen(false)}
            description={t('settings.tours.deleteTourLocationDescription', {name: locationData.name})}
            open={deleteDialogOpen}/>
    </>
}

export default Address