import React from 'react';
import {Route, Routes} from 'react-router';
import {AppBar, Box, Paper, Tab, Tabs} from "@mui/material";
import {useSelector} from "react-redux";
import {hasPermission} from "../../helper/hasPermission";
import {Link, Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ImportRouter from './Import/Router';
import ExportRouter from './Export/Router';
import FileBrowser from "../Utils/FileBrowser";
import {useMatchPaths} from "../Utils/useMatchPaths";

const Router = () => {
    const {t} = useTranslation();
    const user = useSelector((state) => state.user)

    const paths = ['/data/import', '/data/export', '/data/webspace'];
    let currentTab = useMatchPaths(paths);

    let redirect = null;
    const tabs = [];
    const routes = [];

    if (hasPermission(user, ['import'])) {
        redirect = '/data/import'
        tabs.push(<Tab key='import' label={t('data.import.label')} value='/data/import'
                       to='/data/import'
                       component={Link}/>)
        routes.push(<Route key='import' path='import/*' element={<ImportRouter/>}/>)
    }
    if (hasPermission(user, ['export'])) {
        redirect = redirect || '/data/export'
        tabs.push(<Tab key='export' label={t('data.export.label')} value='/data/export'
                       to='/data/export'
                       component={Link}/>)
        routes.push(<Route key='export' path='export/*' element={<ExportRouter/>}/>)
    }

    redirect = redirect || '/data/webspace'
    tabs.push(<Tab key='webspace' label={t('data.webspace.label')} value='/data/webspace'
                   to='/data/webspace'
                   component={Link}/>)

    const Webspace = () => <Paper>
        <FileBrowser type={'webspace'}/>
    </Paper>

    routes.push(<Route exact path='webspace'
                       element={<Webspace />}/>)

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'} element={<Navigate to={redirect}/>}/>)
    }

    return  <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
        <AppBar position='relative' color='transparent'
                sx={{display: 'grid', backgroundColor: (t) => t.palette.background.paper}}>
            {currentTab && <Tabs value={currentTab} variant="scrollable"
                                 scrollButtons="auto">
                {tabs}
            </Tabs>}
        </AppBar>
        <Box component='div' sx={{flexGrow: 1, overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box'}}>
            <Box component='div' sx={{margin: 2}}>
                <Routes>
                    {routes}
                </Routes>
            </Box>
        </Box>
    </Box>;
};

export default Router;
