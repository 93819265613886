import React from 'react';
import { Paper } from "@mui/material";
import DeviceList from '../Utils/DeviceList';

const List = () => {
    return <React.Fragment>
        <Paper sx={{p: 2}}>
            <DeviceList/>
        </Paper>
    </React.Fragment>;
}

export default List;
