import {Route, Routes} from "react-router";
import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchUser} from "../../actions/userActions";
import Navigation from "../Navigation/Navigation";
import {Navigate, useLocation} from 'react-router-dom'
import {styled} from "@mui/material/styles";
import {Box} from "@mui/system";
import LoadingScreen from "../Utils/LoadingScreen";
import SettingsRouter from "../Settings/Router";
import {
    Alert,
    Badge,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, MenuItem,
    Select,
    SpeedDial,
    SpeedDialAction,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {Android, Apple, Cancel, Close, Notes, OpenInNew, Send, Support} from "@mui/icons-material";
import Api from "../../core/Api";
import TextField from "@mui/material/TextField";
import Schema from "validate";
import Profile from "../Profile/Profile";
import {fetchSystemData} from "../../actions/systemDataActions";
import Setup from "../Setup/Setup";
import CustomerRouter from '../Customers/Router';
import DevicesRouter from '../Devices/Router';
import TicketsRouter from '../Tickets/Router';
import DataRouter from '../Data/Router';
import Dashboard from "../Dashboard/Dashboard";
import TimerIcon from '@mui/icons-material/Timer';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import {fetchRequestList} from "../../actions/requestActions";
import {fetchNotificationCount} from "../../actions/notificationActions";
import {fetchUserNotifications} from "../../actions/userNotificationsActions";
import logo from "../../images/small-logo.png";
import html2canvas from "html2canvas";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Paper from "@mui/material/Paper";
import {browserNotificationsSupported} from "../../helper/browserNotificationsSupported";
import MobileDetect from "mobile-detect";
import MaintenanceScreen from "../Utils/MaintenanceScreen";
import {connectWebsocket, disconnectWebsocket} from "../../actions/websocketActions";
import AwsRumSingleton from "../../helper/awsRum";

const MainElement = styled('main')(({theme}) => ({
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column'
}));

const Toolbar = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const getValidationSchema = (t) => {
    return new Schema({
        code: {
            required: true,
            type: String,
            length: {min: 6, max: 6},
            message: t('navigation.codeRequired'),
        },
    })
}

const checked = (val) => val === true;

const getSupportValidationSchema = (t) => {
    return new Schema({
        subject: {
            required: true,
            type: String,
            message: t('navigation.support.subjectRequired'),
        },
        message: {
            required: true,
            type: String,
            message: t('navigation.support.messageRequired'),
        },
        privacyChecked: {
            required: true,
            type: Boolean,
            use: {checked},
            message: t('navigation.support.privacyCheckedRequired'),
        },
        consentChecked: {
            required: false,
            type: Boolean,
            use: {checked},
            message: t('navigation.support.consentCheckedRequired'),
        },
    })
}

const Main = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const navigationRef = useRef();

    useEffect(() => {
        dispatch(fetchUser())
        dispatch(fetchUserNotifications())
        dispatch(fetchSystemData())
    }, [dispatch])

    useEffect(() => {
        dispatch(connectWebsocket())

        return () => {
            dispatch(disconnectWebsocket())
        }
    }, [dispatch])

    const [verifyOpen, setVerifyOpen] = React.useState(false);
    const [verifying, setVerifying] = React.useState(false);
    const [resending, setResending] = React.useState(false);
    const [code, setCode] = React.useState('');
    const [errorList, setErrorList] = useState({});

    const [latestNotificationID, setLatestNotificationID] = useState('');

    const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);

    const [timerListLoading, setTimerListLoading] = useState(false);
    const [latestTimerLoading, setLatestTimerLoading] = useState(false);
    const [positionLoading, setPositionLoading] = useState(false);
    const [timerList, setTimerList] = useState([]);
    const [latestTimer, setLatestTimer] = useState({
        startedAt: null,
        stoppedAt: null,
        startPoint: null,
        stopPoint: null,
    });
    const [currentLocation, setCurrentLocation] = useState(null);
    const [timerDuration, setTimerDuration] = useState(0);
    const [timerDialogOpen, setTimerDialogOpen] = useState(false);


    const [screenCapture, setScreenCapture] = useState(null);
    const [capturing, setCapturing] = useState(false);
    const [creatingSupportCase, setCreatingSupportCase] = useState(false);
    const [supportMessage, setSupportMessage] = useState('');
    const [supportTopic, setSupportTopic] = useState('question');
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const [supportErrorList, setSupportErrorList] = useState({});

    const location = useLocation();

    useEffect(() => {
        const md = new MobileDetect(window.navigator.userAgent);
        if (['iOS', 'iPadOS', 'AndroidOS'].includes(md.os())) {
            setIsMobileDialogOpen(true);
        }
    }, []);

    const fetchTimerList = useCallback(() => {
        setTimerListLoading(true);
        Api.fetch({
            endpoint: 'timer'
        }).then((res) => setTimerList(res.response), () => {
        }).then(() => setTimerListLoading(false))
    }, [])

    useEffect(() => {
        if (!timerDialogOpen) {
            return
        }
        fetchTimerList()
    }, [timerDialogOpen, fetchTimerList])

    useEffect(() => {
        AwsRumSingleton.getInstance()?.recordPageView(location.pathname);
    }, [location]);

    useEffect(() => {
        setLatestTimerLoading(true);
        Api.fetch({
            endpoint: 'timer/latest',
            method: 'GET',
            ignoreErrorCodes: [404]
        }).then((res) => {
            setLatestTimer(res.response);
        }, () => {
        }).then(() => setLatestTimerLoading(false))
    }, [])

    useEffect(() => {
        if (timerDialogOpen) {
            if (navigator.geolocation) {
                setPositionLoading(true);
                navigator.geolocation.getCurrentPosition((c) => {
                    setCurrentLocation({
                        lat: c.coords.latitude,
                        lon: c.coords.longitude
                    })
                    setPositionLoading(false);
                }, e => {
                    setCurrentLocation(null);
                    setPositionLoading(false);
                });
            } else {
                setCurrentLocation(null);
                console.log("This browser does not support geolocation");
            }
        }
    }, [timerDialogOpen]);

    const user = useSelector((state) => state.user)
    const systemData = useSelector((state) => state.systemData)
    const notificationSettings = useSelector((state) => state.notificationSettings)
    const latestNotification = useSelector((state) => state.latestNotification);

    const sendNotification = useCallback(() => {
        if (Notification.permission === 'denied') {
            return;
        }

        try {
            const notify = new Notification(t('notifications.browser.title'), {
                body: t('notifications.browser.body'),
                icon: logo
            });
            notify.onclick = () => {
                window.focus();
                notify.close();
                navigationRef.current.showNotificationDialog();
            }
        } catch (e) {
            console.log(e)
        }
    }, [t]);

    const notificationId = latestNotification?.latest?.id || null;
    const notificationType = latestNotification?.latest?.type || null;
    useEffect(() => {
        const notificationSettingsData = notificationSettings.data || {};
        if (browserNotificationsSupported() && notificationId !== latestNotificationID) {
            if (latestNotificationID !== '' && notificationSettingsData[notificationType]?.browser) {
                if (Notification.permission === 'granted') {
                    sendNotification();
                } else {
                    // request permission from user
                    Notification.requestPermission().then(function (p) {
                        if (p === 'granted') {
                            sendNotification();
                        }
                    }).catch(function (err) {
                        console.error(err);
                    });
                }
            }
            setLatestNotificationID(notificationId)
        }
    }, [latestNotificationID, setLatestNotificationID, notificationSettings.data, notificationId, notificationType, sendNotification])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!latestTimer.startedAt || latestTimer.stoppedAt) {
                setTimerDuration(0);
                return;
            }

            const startedAt = moment(latestTimer.startedAt);
            const diff = moment().diff(startedAt);
            setTimerDuration(diff);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [latestTimer.startedAt, latestTimer.stoppedAt, setTimerDuration]);

    const startTimer = () => {
        setLatestTimerLoading(true);
        Api.fetch({
            endpoint: 'timer',
            method: 'POST',
            body: {
                location: currentLocation
            }
        }).then((res) => {
            setLatestTimer(res.response);
        }, () => {
        }).then(() => setLatestTimerLoading(false))
    }

    const stopTimer = () => {
        setLatestTimerLoading(true);
        Api.fetch({
            endpoint: 'timer/latest',
            method: 'PUT',
            body: {
                location: currentLocation
            }
        }).then((res) => {
            setLatestTimer(res.response);
            setTimerList((currentTimerList) => [res.response, ...currentTimerList]);
        }, () => {
        }).then(() => setLatestTimerLoading(false))
    }

    const waypoint = async () => {
        setLatestTimerLoading(true);
        try {
            const stopRes = await Api.fetch({
                endpoint: 'timer/latest',
                method: 'PUT',
                body: {
                    location: currentLocation
                }
            });
            setLatestTimer(stopRes.response);
            setTimerList((currentTimerList) => [stopRes.response, ...currentTimerList]);

            const startRes = await Api.fetch({
                endpoint: 'timer',
                method: 'POST',
                body: {
                    location: currentLocation
                }
            })
            setLatestTimer(startRes.response);
        } catch (e) {
        }

        setLatestTimerLoading(false);
    }


    useEffect(() => {
        if (!user.user?.id) {
            return
        }
        dispatch(fetchRequestList(user, 1, 10))
    }, [user, dispatch])

    useEffect(() => {
        dispatch(fetchNotificationCount())
    }, [dispatch])

    if (!user.user || !systemData.data || !notificationSettings.data) {
        const errorList = [];
        if (user.error) {
            console.log('user error', user)
            errorList.push(user.error)
        } else if (systemData.error) {
            console.log('systemData error', systemData)
            errorList.push(systemData.error)
        } else if (notificationSettings.error) {
            console.log('notificationSettings error', notificationSettings)
            errorList.push(notificationSettings.error)
        }

        //contains error: maintenance display MaintenanceScreen
        if (errorList.includes('maintenance')) {
            return <MaintenanceScreen/>
        }

        return <LoadingScreen errorList={errorList}
                              loading={user.isFetching || systemData.isFetching || notificationSettings.isFetching}/>
    }

    if (!systemData.data.setupDone) {
        return <Setup/>
    }

    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))


    const onVerify = () => {
        setVerifying(true);

        const verifyData = {
            code: code,
        }

        const v = getValidationSchema(t)
        const errors = v.validate(Object.assign({}, verifyData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setVerifying(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'user/verify-email',
            method: "POST",
            body: verifyData,
            tokenType: 'access_token',
        }).then((res) => {
            setVerifyOpen(false)
            dispatch(fetchUser())
        }, (err) => {
        }).then(() => {
            setVerifying(false);
        });
    }

    const onResend = () => {
        setResending(true);

        Api.fetch({
            endpoint: 'user/resend-verification-email',
            method: "POST",
            tokenType: 'access_token',
        }).then((res) => {
        }, (err) => {
        }).then(() => {
            setResending(false);
        });
    }

    const createSupportCase = () => {
        setCreatingSupportCase(true);
        setSupportErrorList({})

        const v = getSupportValidationSchema(t)
        const errors = v.validate({
            subject: supportTopic,
            message: supportMessage,
            privacyChecked: privacyChecked,
            consentChecked: consentChecked
        })

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setCreatingSupportCase(false);
            setSupportErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'internal-support/case',
            method: "POST",
            body: {
                message: supportMessage,
                subject: supportTopic,
                screenCapture: screenCapture,
                path: location.pathname,
            }
        }).then(() => {
            setSupportMessage('');
            setScreenCapture(null);
            dispatch({
                type: 'ADD_ALERT',
                message: t('navigation.support.caseCreated'),
                style: 'success'
            })
        }, () => {
        }).then(() => setCreatingSupportCase(false));
    }

    const neededSettingsPermissions = [
        'tags',
        'properties',
        'user',
        'groups',
        'roles',
        'categories',
        'tasks',
        'status',
        'pdf',
        'supportTicket',
        'reminder',
        'deviceTypes',
        'deviceComponentTypes',
        'numberRange',
        'qrCode',
        'customerMap',
        'fourVending',
        'telegram'
    ]

    const captureScreen = async () => {
        setCapturing(true);
        html2canvas(document.body).then((canvas) => {
            setSupportMessage('');
            setScreenCapture(canvas.toDataURL());
        }).then(() => {
        }, () => {
        }).then(() => setCapturing(false));
    }

    let appLink = null;
    let os = null;
    const md = new MobileDetect(window.navigator.userAgent);
    if (['iOS', 'iPadOS'].includes(md.os())) {
        os = 'ios'
        appLink = 'https://apps.apple.com/de/app/vecodesk/id1659551306'
    } else if (['AndroidOS'].includes(md.os())) {
        os = 'android'
        appLink = 'https://play.google.com/store/apps/details?id=de.vecodesk.app'
    }

    const formattedDuration = moment.utc(timerDuration).format('HH:mm:ss');

    return <React.Fragment>
        <Box sx={{display: 'flex', height: '100%', width: '100%'}}>
            <Navigation path={location.pathname} ref={navigationRef}/>
            <MainElement>
                <Toolbar/>
                {!user.user.emailVerified && <Alert severity='warning'>
                    <Typography>
                        {t('navigation.emailNotVerifiedWarning')}&nbsp;
                        <Link onClick={() => setVerifyOpen(true)}>{t('navigation.verifyEmailNow')}</Link>
                    </Typography>
                </Alert>}
                <Box sx={{flexGrow: 1, overflowY: 'hidden', boxSizing: 'border-box'}}>
                    <Routes>
                        <Route path='/dashboard' element={<Dashboard/>}/>
                        <Route path='/xxx' element={<Fragment />}/>
                        {hasPermission(neededSettingsPermissions) &&
                            <Route path='/settings/*' element={<SettingsRouter/>}/>}
                        <Route path='/profile' element={<Profile/>}/>my fallback redirect is not working
                        {hasPermission(['customers.read']) && <Route path='/customers/*' element={<CustomerRouter/>}/>}
                        {hasPermission(['devices.read']) && <Route path='/devices/*' element={<DevicesRouter/>}/>}
                        {hasPermission(['tickets.read']) && <Route path='/tickets/*' element={<TicketsRouter/>}/>}
                        {hasPermission(['import', 'export']) && <Route path='/data/*' element={<DataRouter/>}/>}
                        <Route path='*' element={<Navigate to='/dashboard'/>}/>;
                    </Routes>
                </Box>
            </MainElement>
        </Box>
        <Dialog
            open={verifyOpen}
            onClose={() => setVerifyOpen(false)}
        >
            <DialogTitle>
                {t('navigation.verifyEmail')}
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    disabled={verifying}
                    name="code"
                    label={t('navigation.code')}
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    error={errorList.hasOwnProperty('code')} helperText={errorList.code}
                />
                <LoadingButton loading={resending} loadingPosition="start" startIcon={<Send/>}
                               onClick={onResend}>{t('navigation.resendEmail')}</LoadingButton>
            </DialogContent>
            <DialogActions>
                <Button disabled={verifying} onClick={() => setVerifyOpen(false)}>{t('cancel')}</Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={verifying}
                    onClick={onVerify}
                    startIcon={<Send/>}
                    type="submit"
                    autoFocus variant='contained' color='primary'>
                    {t('navigation.verifyEmailNow')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            maxWidth='md' fullWidth
            open={Boolean(screenCapture)}
            onClose={() => setScreenCapture(null)}
        >
            <DialogTitle>
                {t('navigation.support.title')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            {t('navigation.support.informations')}
                        </Typography>
                        <List disablePadding>
                            <ListItem>
                                <ListItemText
                                    primary={'help@vecodesk.com'}
                                    secondary={t('navigation.support.mail')}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={'0561/47395107'}
                                    secondary={t('navigation.support.telephone')}
                                />
                            </ListItem>
                        </List>
                        <Typography variant="h6">
                            {t('navigation.support.links')}
                        </Typography>
                        <List disablePadding>
                            <ListItemButton target='_blank' component={Link}
                                            href="https://knowledge.vecodesk.com">
                                <ListItemText
                                    primary={t('navigation.support.knowledge')}
                                />
                                <ListItemIcon>
                                    <OpenInNew/>
                                </ListItemIcon>
                            </ListItemButton>
                        </List>

                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            {t('navigation.support.getInTouch')}
                        </Typography>
                        <FormControl fullWidth size='small' margin='dense'
                                     error={supportErrorList.hasOwnProperty('subject')}>
                            <InputLabel shrink>{t('navigation.support.subject')}</InputLabel>
                            <Select
                                notched
                                label={t('navigation.support.subject')}
                                value={supportTopic}
                                fullWidth
                                onChange={(e) => setSupportTopic(e.target.value)}
                            >
                                <MenuItem value={'question'}>
                                    {t('navigation.support.subjects.question')}
                                </MenuItem>
                                <MenuItem value={'feature-request'}>
                                    {t('navigation.support.subjects.featureRequest')}
                                </MenuItem>
                                <MenuItem value={'bug-report'}>
                                    {t('navigation.support.subjects.bugReport')}
                                </MenuItem>
                                <MenuItem value={'other'}>
                                    {t('navigation.support.subjects.other')}
                                </MenuItem>
                            </Select>
                            {supportErrorList.hasOwnProperty('subject') &&
                                <FormHelperText>{supportErrorList.subject}</FormHelperText>}
                        </FormControl>
                        <TextField
                            fullWidth
                            margin='dense'
                            label={t('navigation.support.message')}
                            multiline
                            error={supportErrorList.hasOwnProperty('message')}
                            helperText={supportErrorList.message}
                            value={supportMessage}
                            onChange={(e) => setSupportMessage(e.target.value)}
                            rows={4}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={privacyChecked}
                                    onChange={(e) => setPrivacyChecked(e.target.checked)}
                                    name="privacyChecked"
                                />
                            }
                            label={<Trans i18nKey='navigation.support.privacy' components={{externalLink: <Link/>}}/>}
                        />
                        {supportErrorList.privacyChecked &&
                            <FormHelperText error>{supportErrorList.privacyChecked}</FormHelperText>}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={consentChecked}
                                    onChange={(e) => setConsentChecked(e.target.checked)}
                                    name="consentChecked"
                                />
                            }
                            label={t('navigation.support.communicationConsent')}
                        />
                        {supportErrorList.consentChecked &&
                            <FormHelperText error>{supportErrorList.consentChecked}</FormHelperText>}
                        <LoadingButton
                            disabled={!user.user.emailVerified}
                            sx={{mt: 1}}
                            loadingPosition="start"
                            loading={creatingSupportCase}
                            onClick={createSupportCase}
                            startIcon={<Send/>}
                            type="submit"
                            autoFocus variant='contained' color='primary'>
                            {t('navigation.support.create')}
                        </LoadingButton>
                        {!user.user.emailVerified &&  <FormHelperText error>{t('navigation.support.emailMustBeVerifiedToCreateSupportCase')}</FormHelperText>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={creatingSupportCase} onClick={() => setScreenCapture(null)}>{t('close')}</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            maxWidth='md' fullWidth
            open={timerDialogOpen}
            onClose={() => setTimerDialogOpen(false)}
        >
            <DialogTitle>
                {t('timer.title')}
            </DialogTitle>
            <DialogContent>
                <Box sx={{textAlign: 'center', mb: 2}}>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                        {t('timer.runningSince')}
                    </Typography>
                    <Typography variant="h5" component="div" gutterBottom>
                        {formattedDuration}
                    </Typography>
                    {(latestTimer?.startedAt && !latestTimer.stoppedAt) && <LoadingButton
                        sx={{mb: 1}}
                        loadingPosition="start"
                        loading={latestTimerLoading || positionLoading}
                        startIcon={<Send/>}
                        color='primary' variant='outlined' fullWidth
                        onClick={waypoint}>
                        {t('timer.waypoint')}
                    </LoadingButton>}
                    <LoadingButton
                        loadingPosition="start"
                        loading={latestTimerLoading || positionLoading}
                        startIcon={(latestTimer?.startedAt && !latestTimer.stoppedAt) ? <Cancel/> : <Send/>}
                        color='primary' variant='contained' fullWidth
                        onClick={(latestTimer?.startedAt && !latestTimer.stoppedAt) ? stopTimer : startTimer}>
                        {(latestTimer?.startedAt && !latestTimer.stoppedAt) ? t('timer.stop') : t('timer.start')}
                    </LoadingButton>
                </Box>
                <Typography variant="h6" gutterBottom>
                    {t('timer.history')}
                </Typography>
                <Paper variant='outlined'>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 'bold'}}>{t('timer.startedAt')}</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>{t('timer.stoppedAt')}</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>{t('timer.startPoint')}</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>{t('timer.stopPoint')}</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>{t('timer.distance')}</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>{t('timer.duration')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!timerListLoading && timerList.filter(entry => entry.stoppedAt).map((entry) => {
                                    const duration = moment(moment(entry.stoppedAt).diff(moment(entry.startedAt)));
                                    const entryFormattedDuration = moment.utc(duration).format('HH:mm:ss');

                                    return <TableRow key={entry.id}>
                                        <TableCell>{moment(entry.startedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                        <TableCell>{moment(entry.stoppedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                        <TableCell>{entry.startPointLabel || '-'}</TableCell>
                                        <TableCell>{entry.stopPointLabel || '-'}</TableCell>
                                        <TableCell>{entry.distance >= 0 ? `${entry.distance} km` : '-'}</TableCell>
                                        <TableCell>{entryFormattedDuration}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        {timerListLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                            <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                        </Box>}
                        {!timerListLoading && timerList.length <= 0 && (
                            <Box sx={{textAlign: 'center', color: (t) => t.palette.grey[400]}}>
                                <Notes sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                                <Box sx={{color: (t) => t.palette.text.secondary, mb: 2}} textAlign='center'>
                                    <Typography variant='body2'>{t('timer.noHistoryFound')}</Typography>
                                </Box>
                            </Box>
                        )}
                    </TableContainer>
                </Paper>
            </DialogContent>
        </Dialog>
        <SpeedDial
            ariaLabel={'speedDial.label'}
            sx={{position: 'fixed', bottom: 16, left: 16, zIndex: 2000}}
            icon={<Badge variant='dot' color='secondary'
                         invisible={Boolean(!latestTimer?.startedAt || latestTimer.stoppedAt)}><HomeRepairServiceIcon/></Badge>}
        >
            <SpeedDialAction
                icon={latestTimerLoading ? <CircularProgress size={20} color='inherit'/> :
                    <Badge variant='dot' color='secondary'
                           invisible={Boolean(!latestTimer?.startedAt || latestTimer.stoppedAt)}><TimerIcon/></Badge>}
                onClick={() => setTimerDialogOpen(true)}
                tooltipTitle={t('speedDial.timer')}
            />
            <SpeedDialAction
                icon={capturing ? <CircularProgress size={20} color='inherit'/> : <Support/>}
                onClick={captureScreen}
                tooltipTitle={t('speedDial.support')}
            />
        </SpeedDial>
        <Drawer open={isMobileDialogOpen} anchor={'bottom'} onClose={() => setIsMobileDialogOpen(false)}
                sx={{zIndex: 3000}}
        >
            <ListItemButton
                component={'a'} href={appLink}
                target={'_blank'}
                onClick={() => setIsMobileDialogOpen(false)}
            >
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="close"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsMobileDialogOpen(false)
                                }}
                    >
                        <Close/>
                    </IconButton>
                </ListItemSecondaryAction>
                <ListItemIcon>{os === 'ios' ? <Apple/> : <Android/>}</ListItemIcon>
                <ListItemText
                    primary={t('appNotification.available')}
                    secondary={t('appNotification.clickToDownload')}
                />
            </ListItemButton>
        </Drawer>
    </React.Fragment>
}

export default Main
