import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import {
    Alert,
    Box,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Switch,
    TextField,
    Toolbar
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Replay, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatusList } from "../../../../actions/statusActions";
import CheckPermissions from "../../../Utils/CheckPermissions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Settings = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [settings, setSettings] = useState({
        active: false,
        statusList: [],
        interval: 1
    })
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/reminder'
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    const fetchEntities = useCallback(() => {
        dispatch(fetchStatusList())
    }, [dispatch])

    useEffect(() => {
        fetchSettings()
        fetchEntities()
    }, [fetchSettings, fetchEntities])


    const statusList = useSelector((state) => state.statusList.list)
    const isFetching = useSelector((state) => state.statusList.isFetching)

    const onSave = useCallback(() => {
        setSaving(true)
        Api.fetch({
            endpoint: 'settings/reminder',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving])

    return <Grid container>
        <Grid item xs={12} md={6}>
            <Toolbar variant='dense' disableGutters={true}>
                <CheckPermissions list={['reminder.write']}>
                    <LoadingButton
                        onClick={onSave}
                        size='small'
                        loadingPosition="start"
                        disabled={loading || isFetching}
                        loading={saving}
                        startIcon={<Save/>}
                        variant='contained'
                        color='primary'>{t('save')}</LoadingButton>
                </CheckPermissions>
                <Box flexGrow={1}/>
                <IconButton onClick={() => {
                    fetchSettings();
                    fetchEntities();
                }}><Replay/></IconButton>
            </Toolbar>
            <Paper sx={{p: 2}}>
                <Alert sx={{mb: 2}} severity={'info'}>{t('settings.reminder.description')}</Alert>

                <FormControlLabel control={<Switch onChange={(e) => handleChange('active', e.target.checked)}
                                                   checked={settings.active}/>}
                                  label={t('settings.reminder.active')}/>
                <Collapse in={settings.active}>
                    <FormControl fullWidth margin='normal'>
                        <InputLabel>{t('settings.reminder.status')}</InputLabel>
                        <Select
                            multiple
                            value={settings.statusList.filter(g => statusList.map(g => g.id).indexOf(g) > -1)}
                            onChange={(e) => {
                                handleChange('statusList', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                            }}
                            input={<OutlinedInput label={t('settings.reminder.status')}/>}
                            renderValue={
                                (selected) => selected.map((v) => statusList.find(r => r.id === v).name).join(', ')
                            }
                            MenuProps={MenuProps}
                        >
                            {statusList.map((r) => (
                                <MenuItem key={r.id} value={r.id}>
                                    <Checkbox checked={settings.statusList.includes(r.id)}/>
                                    <ListItemText primary={r.name} secondary={r.description}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        placeholder={t('settings.reminder.interval')}
                        margin='normal'
                        type='number'
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('settings.reminder.days')}</InputAdornment>
                        }}
                        fullWidth
                        variant='outlined'
                        label={t('settings.reminder.interval')}
                        value={settings.interval}
                        onChange={(e) => handleChange('interval', parseInt(e.target.value, 10))}
                    />
                </Collapse>
            </Paper>
        </Grid>
    </Grid>;
}

export default Settings;
