import React from 'react'
import {Grid, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {LocalOffer, QrCode2, Settings, TextFields} from "@mui/icons-material";
import {Route, Routes} from "react-router";
import {Link, Navigate} from "react-router-dom";
import TypeList from './Types/List'
import ComponentTypeList from './ComponentTypes/List'
import NumberRangeSettings from './NumberRange/Settings'
import QrSettings from './Qr/Settings'
import {useSelector} from "react-redux";
import {useMatchPaths} from "../../Utils/useMatchPaths";

const Router = () => {
    const {t} = useTranslation();
    const paths = ['/settings/devices/types', '/settings/devices/component-types', '/settings/devices/number-range', '/settings/devices/qr'];
    let currentTab = useMatchPaths(paths);
    let redirect = null;
    const user = useSelector((state) => state.user)
    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

    const routes = []
    const items = [];
    if (hasPermission(['devicesType.read'])) {
        redirect = '/settings/devices/types'
        items.push(<ListItemButton key='types' component={Link} to={'/settings/devices/types'}
                                   selected={currentTab === '/settings/devices/types'}>
            <ListItemIcon><Settings/></ListItemIcon>
            <ListItemText primary={t('settings.deviceType.label')}/>
        </ListItemButton>)
        routes.push(<Route key='types' path='types' element={<TypeList/>}/>)
    }
    if (hasPermission(['deviceComponentTypes.read'])) {
        redirect = redirect || '/settings/devices/component-types'
        items.push(<ListItemButton key='component-types' component={Link} to={'/settings/devices/component-types'}
                                   selected={currentTab === '/settings/devices/component-types'}>
            <ListItemIcon><LocalOffer/></ListItemIcon>
            <ListItemText primary={t('settings.deviceComponentType.label')}/>
        </ListItemButton>)
        routes.push(<Route key='component-types' path='component-types'
                           element={<ComponentTypeList/>}/>)
    }
    if (hasPermission(['numberRange.read'])) {
        redirect = redirect || '/settings/devices/number-range'
        items.push(<ListItemButton key='number-range' component={Link} to={'/settings/devices/number-range'}
                                   selected={currentTab === '/settings/devices/number-range'}>
            <ListItemIcon><TextFields/></ListItemIcon>
            <ListItemText primary={t('settings.numberRange.label')}/>
        </ListItemButton>)
        routes.push(<Route key='number-range' path='number-range' element={<NumberRangeSettings/>}/>)
    }
    if (hasPermission(['qrCode.read'])) {
        redirect = redirect || '/settings/devices/qr'
        items.push(<ListItemButton key='qr' component={Link} to={'/settings/devices/qr'}
                                   selected={currentTab === '/settings/devices/qr'}>
            <ListItemIcon><QrCode2/></ListItemIcon>
            <ListItemText primary={t('settings.qr.label')}/>
        </ListItemButton>)
        routes.push(<Route key='qr' path='qr' element={<QrSettings/>}/>)
    }

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'}
                           element={<Navigate to={redirect}/>}/>)
    }


    return <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <List component={Paper}>
                {items}
            </List>
        </Grid>
        <Grid item xs={12} sm={9}>
            <Routes>
                {routes}
            </Routes>
        </Grid>
    </Grid>;

}

export default Router