import React from 'react';
import {
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    List,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Delete } from "@mui/icons-material";
import { Box, useTheme } from "@mui/system";
import Location from "./Location";
import {v1 as uuid} from "uuid";
import { filterErrorList } from "../../Utils/FilterErrorList";
import Contact from "./Contact";

const Address = ({address, onChange, onDelete, deleteDisabled, standalone, errors = {}, ...otherProps}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const locationErrors = filterErrorList(errors, 'locations')
    const contactErrors = filterErrorList(errors, 'contacts')


    const locationList = address.locations?.map((location, i) => {
        return <Location location={location} key={location.id}
                         deleteDisabled={address.locations.length === 1}
                         errors={locationErrors[i] || {}}
                         onDelete={() => {
                             address.locations = address.locations.filter((l) => l.id !== location.id);
                             onChange(address)
                         }}
                         onChange={(updateLocation) => {
                             const locations = address.locations
                             locations[i] = updateLocation
                             onChange({...address, locations})
                         }}/>
    })

    const contactList = address.contacts?.map((contact, i) => {
        return <Contact contact={contact} key={contact.id}
                        errors={contactErrors[i] || {}}
                        onDelete={() => {
                            address.contacts = address.contacts.filter((l) => l.id !== contact.id);
                            onChange(address)
                        }}
                        onChange={(updatedContact) => {
                            const contacts = address.contacts
                            contacts[i] = updatedContact
                            onChange({...address, contacts})
                        }}
        />
    })

    const addLocation = () => {
        if(!Array.isArray(address.locations)) {
            address.locations = []
        }
        address.locations.push({
            id: uuid(),
            name: '',
        })
        onChange(address)
    }

    const addContact = () => {
        if(!Array.isArray(address.contacts)) {
            address.contacts = []
        }

        address.contacts.push({
            id: uuid(),
            name: '',
            email: '',
            phone: '',
        })
        onChange(address)
    }


    return <React.Fragment>
        <Toolbar disableGutters={true} variant='dense' sx={{mb: 1}}>
            <TextField
                sx={{mr: 2}}
                fullWidth
                margin='none'
                variant='standard'
                required
                label={t('customers.address.name')}
                placeholder={t('customers.address.name')}
                error={errors.hasOwnProperty('name')}
                helperText={errors.name}
                value={address.name}
                onChange={(e) => {
                    address.name = e.target.value
                    onChange(address)
                }}
            />
            <Box flexGrow={1}/>
            {!standalone && <IconButton disabled={deleteDisabled} onClick={onDelete}><Delete/></IconButton>}
        </Toolbar>
        <FormControlLabel control={<Switch checked={address.main} onChange={(e) => {
            if (!standalone && !e.target.checked) {
                return;
            }
            address.main = e.target.checked
            onChange(address)
        }
        }/>} label={t('customers.address.main')}/>
        <TextField
            fullWidth
            margin='dense'
            required
            error={errors.hasOwnProperty('address')}
            helperText={errors.address}
            label={t('customers.address.address')}
            placeholder={t('customers.address.address')}
            value={address.address}
            onChange={(e) => {
                address.address = e.target.value
                onChange(address)
            }}
        />
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    margin='dense'
                    required
                    error={errors.hasOwnProperty('zip')}
                    helperText={errors['zip']}
                    label={t('customers.address.zip')}
                    placeholder={t('customers.address.zip')}
                    value={address.zip}
                    onChange={(e) => {
                        address.zip = e.target.value
                        onChange(address)
                    }}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    margin='dense'
                    required
                    error={errors.hasOwnProperty('city')}
                    helperText={errors.city}
                    label={t('customers.address.city')}
                    placeholder={t('customers.address.city')}
                    value={address.city}
                    onChange={(e) => {
                        address.city = e.target.value
                        onChange(address)
                    }}
                />
            </Grid>
        </Grid>
        <TextField
            fullWidth
            margin='dense'
            label={t('customers.address.additional')}
            placeholder={t('customers.address.additional')}
            value={address.additional}
            onChange={(e) => {
                address.additional = e.target.value
                onChange(address)
            }}
        />
        <Toolbar disableGutters={true} variant='dense'>
            <Typography variant='h6'>{t('customers.address.locations')}</Typography>
            <Box flexGrow={1}/>
            <IconButton onClick={addLocation}><Add/></IconButton>
        </Toolbar>
        <List disablePadding={true} dense>
            {locationList}
        </List>
        <Toolbar disableGutters={true} variant='dense'>
            <Typography variant='h6'>{t('customers.contacts')}</Typography>
            <Box flexGrow={1}/>
            <IconButton onClick={addContact}><Add/></IconButton>
        </Toolbar>
        {(address.contacts?.length || 0) <= 0 && <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
            <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                <Typography variant='body2'>{t('customers.noContactCreated')}</Typography>
                <Link variant='body2' onClick={addContact}>{t('customers.createContact')}</Link>
            </Box>
        </Box>}
        {address.contacts?.length > 0 && <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('customers.fields.name')}
                        </TableCell>
                        <TableCell>
                            {t('customers.fields.email')}
                        </TableCell>
                        <TableCell>
                            {t('customers.fields.telephone')}
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contactList}
                </TableBody>
            </Table>
        </TableContainer>}
    </React.Fragment>
};

export default Address;
