import {useEffect, useState} from "react";
import Api from "../../core/Api";
import moment from "moment";
import {Card, CardContent, CardHeader} from "@mui/material";
import ReactMarkdown from "react-markdown";
import {useTranslation} from "react-i18next";

const Changelog = () => {
    const {t} = useTranslation();
    const [changelogDetails, setChangelogDetails] = useState([])

    useEffect(() => {
        Api.fetch({
            endpoint: 'public/changelog/details/ui',
            auth: false
        }).then((res) => {
            setChangelogDetails(res.response.sort((a, b) => {
                //sort with moment descending .createdAt
                return moment(b.createdAt).diff(moment(a.createdAt))
            }));
        }, () => {})
    },[])

    const changelogs = changelogDetails.map((changelog, i) => {
        // add margin to all but last card
        return <Card variant='outlined' sx={{marginBottom: i < changelogDetails.length - 1 ? 2 : 0}}>
            <CardHeader
                title={changelog.version}
                subheader={moment(changelog.createdAt).fromNow()}
                />
            <CardContent>
                {changelog.changelog?.trim().length > 0 ? <ReactMarkdown>{changelog.changelog}</ReactMarkdown> : t('navigation.changelog.noFurtherInformationProvides')}
            </CardContent>
        </Card>
    })

    return <>{changelogs}</>
}

export default Changelog