import {
    Box,
    Button, FormControl, IconButton, InputLabel, List, ListItem, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Toolbar
} from "@mui/material";
import { Add, Delete, RemoveCircle } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

const Fields = ({ exportType, setExportData, exportData, somethingIsLoading}) => {
    const {t} = useTranslation();
    const rows = exportData.fields?.map((field, i) => {
        const sources = field.source?.map((source, i2) => {
            return <ListItem disableGutters={true}
                             secondaryAction={<IconButton disabled={field.source?.length <= 1 || somethingIsLoading}
                                                          onClick={() => {
                                                              const newFields = [...exportData.fields];
                                                              newFields[i].source.splice(i2, 1);
                                                              setExportData((currentExportData) => ({
                                                                  ...currentExportData,
                                                                  fields: newFields
                                                              }))
                                                          }}>
                                 <RemoveCircle/>
                             </IconButton>}>
                <Box sx={{width: '100%', display: 'flex'}}>
                    <FormControl fullWidth size='small'>
                        <InputLabel shrink>{t('data.export.source')}</InputLabel>
                        <Select
                            disabled={somethingIsLoading}
                            notched
                            label={t('data.export.source')}
                            value={source.key || ''}
                            fullWidth
                            onChange={(e) => {
                                setExportData((currentImportData) => {
                                    const fields = currentImportData.fields.slice();
                                    const sources = fields[i].source.slice();
                                    sources[i2].key = e.target.value;
                                    fields[i].source = sources;
                                    return {
                                        ...currentImportData,
                                        fields: fields
                                    }
                                })
                            }}
                        >
                            {exportType.fields?.map((field) => {
                                return <MenuItem key={field.identifier} value={field.identifier}>
                                    {t(`data.export.fields.${exportData?.type}.${field.identifier}`)}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ml: 1}}
                        disabled={somethingIsLoading}
                        required
                        size='small'
                        label={t('data.export.separator')}
                        placeholder={t('data.export.separator')}
                        value={source.separator}
                        onChange={(e) => {
                            setExportData((currentExportData) => {
                                const fields = currentExportData.fields.slice();
                                const sources = fields[i].source.slice();
                                sources[i2].separator = e.target.value;
                                fields[i].source = sources;
                                return {
                                    ...currentExportData,
                                    fields: fields
                                }
                            })
                        }}
                    />
                </Box>
            </ListItem>
        })
        return <TableRow key={i}>
            <TableCell>
                <TextField
                    fullWidth
                    required
                    size='small'
                    margin='none'
                    value={field.columnName}
                    disabled={somethingIsLoading}
                    onChange={(e) => {
                        setExportData((currentExportData) => {
                            const fields = currentExportData.fields.slice();
                            fields[i].columnName = e.target.value;
                            return {
                                ...currentExportData,
                                fields: fields
                            }
                        })
                    }}
                />
            </TableCell>
            <TableCell>
                <List disablePadding={true} dense>
                    {sources}
                </List>
                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                    {field.source?.length > 1 && <TextField
                        disabled={somethingIsLoading}
                        sx={{mr: 1}}
                        required
                        size='small'
                        margin='dense'
                        label={t('data.export.separator')}
                        placeholder={t('data.export.separator')}
                        value={field.separator}
                        onChange={(e) => {
                            setExportData((currentExportData) => {
                                const fields = currentExportData.fields.slice();
                                fields[i].separator = e.target.value;
                                return {
                                    ...currentExportData,
                                    fields: fields
                                }
                            })
                        }}
                    />}
                    <Button disabled={somethingIsLoading} size='small'
                            onClick={() => setExportData((currentExportData) => {
                                const fields = currentExportData.fields.slice();
                                fields[i].source.push({key: exportType.fields[0].identifier, separator: ','})
                                return {
                                    ...currentExportData,
                                    fields: fields
                                }
                            })} startIcon={<Add/>}>{t('data.import.addSource')}</Button>
                </Box>
            </TableCell>
            <TableCell>
                <IconButton disabled={somethingIsLoading} onClick={() => setExportData({
                    ...exportData,
                    fields: exportData.fields.filter((f, j) => i !== j)
                })}><Delete/></IconButton>
            </TableCell>
        </TableRow>
    });

    return <Paper>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('data.export.columnName')}</TableCell>
                        <TableCell>{t('data.export.sources')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
        <Toolbar sx={{px: 2}} variant='regular' disableGutters={true}>
            <Button size='small' onClick={() => {
                setExportData({
                    ...exportData,
                    fields: [...exportData.fields, {
                        source: [{key: exportType.fields[0].identifier, separator: ','}],
                        separator: ';',
                        columnName: t(`data.export.fields.${exportData?.type}.${exportType.fields[0].identifier}`)
                    }]
                });
            }}
                    startIcon={<Add/>}
                    variant='outlined'
                    color='primary'>{t('data.import.addField')}</Button>
        </Toolbar>
    </Paper>
}

export default Fields;
