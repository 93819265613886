import React from 'react';
import { Box, Button, FormControlLabel, FormGroup, Paper, Switch, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronRight } from "@mui/icons-material";

const Introduction = ({setAdditionalFeatures, additionalFeatures, setActiveStep}) => {
    const {t} = useTranslation();

    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Box flexGrow={1}/>
            <Button size='small' startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(1)}>{t('next')}</Button>
        </Toolbar>
        <Paper sx={{p: 2}}>
            <Typography variant='h5'>{t('setup.introduction')}</Typography>
            <Typography variant='body'>
                {t('setup.introductionText')}
            </Typography>
        </Paper>
        <Paper sx={{mt: 2, p: 2}}>
            <Typography variant='h5'>{t('setup.additionalFeatures')}</Typography>
            <FormGroup>
                <FormControlLabel checked={additionalFeatures.includes('numberRange')}
                                  control={<Switch/>}
                                  onChange={(e) => setAdditionalFeatures(additionalFeatures.includes('numberRange') ?
                                      additionalFeatures.filter(f => f !== 'numberRange') :
                                      [...additionalFeatures, 'numberRange'])}
                                  label={t('setup.numberRange')}/>
                <FormControlLabel checked={additionalFeatures.includes('telegram')}
                                  onChange={(e, v) => setAdditionalFeatures(additionalFeatures.includes('telegram') ?
                                      additionalFeatures.filter(f => f !== 'telegram') :
                                      [...additionalFeatures, 'telegram'])}
                                  control={<Switch/>}
                                  label={t('setup.telegram')}/>
            </FormGroup>
        </Paper>
    </React.Fragment>
}

export default Introduction;
