import React from 'react';
import { Box, Button, Collapse, FormControlLabel, Paper, Switch, TextField, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const MapSettings = ({numberRangeSettings, setNumberRangeSettings, setActiveStep}) => {
    const {t} = useTranslation();

    const handleChange = (key, value) => {
        setNumberRangeSettings((s) => ({...s, [key]: value}))
    }

    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Button size='small' startIcon={<ChevronLeft/>} variant='contained'
                    onClick={() => setActiveStep(5)}>{t('back')}</Button>
            <Box flexGrow={1}/>
            <Button size='small' startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(7)}>{t('next')}</Button>
        </Toolbar>
        <Paper sx={{p: 2, mb: 2}}>
            <TextField
                placeholder={t('settings.numberRange.places')}
                margin='normal'
                type='number'
                fullWidth
                variant='outlined'
                label={t('settings.numberRange.places')}
                value={numberRangeSettings.places}
                onChange={(e) => handleChange('places', parseInt(e.target.value, 10))}
            />
            <TextField
                placeholder={t('settings.numberRange.start')}
                margin='normal'
                type='number'
                fullWidth
                variant='outlined'
                label={t('settings.numberRange.start')}
                value={numberRangeSettings.start}
                onChange={(e) => handleChange('start', parseInt(e.target.value, 10))}
            />
            <FormControlLabel control={<Switch
                checked={numberRangeSettings.endEnabled}
                onChange={(e) => handleChange('endEnabled', e.target.checked)}
            />} label={t('settings.numberRange.endEnabled')}/>
            <Collapse in={numberRangeSettings.endEnabled}>
                <TextField
                    placeholder={t('settings.numberRange.end')}
                    margin='normal'
                    type='number'
                    fullWidth
                    variant='outlined'
                    label={t('settings.numberRange.end')}
                    value={numberRangeSettings.end}
                    onChange={(e) => handleChange('end', parseInt(e.target.value, 10))}
                />
            </Collapse>
        </Paper>
    </React.Fragment>
}

export default MapSettings;
