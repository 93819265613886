import React, {useCallback, useEffect, useState} from "react";
import Api from "../../core/Api";
import qs from "query-string";
import {
    Chip,
    LinearProgress,
    SpeedDial,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
import {Box, darken, lighten, styled, useTheme} from "@mui/system";
import {ConfirmationNumber, Refresh} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {Link as RouterLink, useLocation, useParams} from "react-router-dom";
import {formatAddress} from "../../helper/formatAddress";

const StyledLink = styled(RouterLink)`
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const LiveTicketList = () => {
    const {t} = useTranslation('frontend');
    const {linkId, hash} = useParams();
    const location = useLocation();
    const theme = useTheme();
    const [tickets, setTickets] = useState([])
    const [ticketsLoading, setTicketsLoading] = useState(false)
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(20);
    const [order, setOrder] = React.useState('asc');
    const [orderColumn, setOrderColumn] = React.useState('statusPriority');
    const [columns, setColumns] = React.useState(['number', 'customer', 'category', 'title', 'status', 'importance', 'tags', 'createdAt', 'updatedAt', 'completedAt']);

    const fetchTickets = useCallback((linkId, hash) => {
        setTicketsLoading(true)
        Api.fetch({
            endpoint: 'public/live/tickets',
            parameter: {
                page: page,
                linkId: linkId,
                hash: hash,
            },
            auth: false,
        }).then((res) => {
            setTotal(parseInt(res.headers['x-total-count'] || "0", 10));
            setPage(parseInt(res.headers['x-page'] || "1", 10));
            setPerPage(parseInt(res.headers['x-size'] || "20", 10));
            const sort = res.headers['x-sort'] || "statusPriority_asc";
            const sortSplit = sort.split('_');
            setOrderColumn(sortSplit[0]);
            setOrder(sortSplit[1]);
            setColumns((res.headers['x-preset-columns'] || '').split(','));
            setTickets(res.response.filter((t) => !t.ticket.deletedAt));
        }, () => {
        }).then(() => setTicketsLoading(false))
    }, [page])

    const query = qs.parse(location.search);
    const refreshInterval = query.refreshInterval || 300;

    useEffect(() => {
        fetchTickets(linkId, hash)
        const intervalId = setInterval(() => {
            fetchTickets(linkId, hash)
        }, 1000 * refreshInterval)
        return () => {
            clearInterval(intervalId);
        }
    }, [linkId, refreshInterval, hash, fetchTickets])

    const changeSort = (column) => {
        if (orderColumn === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrder('asc');
            setOrderColumn(column);
        }
    };

    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;

    const ticketList = tickets.map((ticketData) => {
        const ticket = ticketData.ticket
        let color = 'inherit';
        let hoverColor = 'inherit';
        if (ticketData.status?.type === 'open') {
            if (ticket.importanceId >= 8) {
                color = getBackgroundColor(theme.palette.error.light, 0.5)
                hoverColor = getBackgroundColor(theme.palette.error.light, 0.3)
            } else if (ticket.importanceId >= 5) {
                color = getBackgroundColor(theme.palette.warning.light, 0.5)
                hoverColor = getBackgroundColor(theme.palette.warning.light, 0.3)
            }
        }

        const ticketLocations = ticketData.locations || []
        let locations = '-'
        if(ticketLocations.length === 1) {
            locations = ticketLocations[0].name
        }
        else if (ticketLocations.length > 1) {
            locations = <Box component='ul' sx={{margin: 0, padding: 'inherit'}}>{ticketLocations.map((l) => {
                return <li>{l.name}</li>
            })}</Box>
        }

        const addressesArray = (ticketData.customerAddresses ||[]).map((address) => {
            return formatAddress(address)
        })

        let addresses = '-'
        if(addressesArray.length === 1) {
            addresses = addressesArray[0]
        }
        else if (addressesArray.length > 1) {
            addresses = <Box component='ul' sx={{margin: 0, padding: 'inherit'}}>{addressesArray.map((a) => {
                return <li>{a}</li>
            })}</Box>
        }

        const ticketDevices = ticketData.devices || []
        let devicesWithTypes = '-'
        if(ticketDevices.length === 1) {
            devicesWithTypes = ticketDevices[0].number;
            if(ticketDevices[0].typeName) {
                devicesWithTypes += ' (' + ticketDevices[0].typeName + ')'
            }
        } else if (ticketDevices.length > 1) {
            devicesWithTypes = <Box component='ul' sx={{margin: 0, padding: 'inherit'}}>{ticketDevices.map((d) => {
                let deviceWithTypes = d.number;
                if(d.typeName) {
                    deviceWithTypes += ' (' + d.typeName + ')'
                }
                return <li>{deviceWithTypes}</li>
            })}</Box>
        }

        const tableColumns = columns.map((column) => {
            switch (column) {
                case 'number':
                    return <TableCell>#{ticket.number}</TableCell>
                case 'customer':
                    return <TableCell>{ticketData.customerNumber ? ticketData.customerNumber + ' - ' + ticketData.customerName :
                        <i>{t('deleted')}</i>}</TableCell>
                case 'addresses':
                    return <TableCell>{addresses}</TableCell>
                case 'devices':
                    return <TableCell>{devicesWithTypes}</TableCell>
                case 'locations':
                    return <TableCell>{locations}</TableCell>
                case 'categories':
                    return <TableCell>{ticketData.category?.name || <i>{t('deleted')}</i>}</TableCell>
                case 'title':
                    return <TableCell>{ticket.title}</TableCell>
                case 'status':
                    return <TableCell>{ticketData.status?.name || <i>{t('deleted')}</i>}</TableCell>
                case 'importance':
                    return <TableCell>{ticket.importanceId}</TableCell>
                case 'tags':
                    return <TableCell>
                        {ticketData.tags?.length ? ticketData.tags?.map((tag) => {
                            return <Chip key={tag.id} label={tag.name}
                                         sx={{mr: 1, backgroundColor: tag.color, color: 'white'}}/>
                        }) : '-'}
                    </TableCell>
                case 'createdAt':
                    return <TableCell>{moment(ticket.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                case 'updatedAt':
                    return <TableCell>{moment(ticket.updatedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                case 'completedAt':
                    return <TableCell>{ticket.completedAt ? moment(ticket.completedAt).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>
                default:
                    return <TableCell/>
            }
        })

        return (
            <TableRow component={StyledLink} key={ticket.id} hover
                      sx={{backgroundColor: color, "&:hover": {backgroundColor: hoverColor + ' !important'}}}
                      target={'_blank'}
                      to={'/tickets/show/' + ticket.id}>
                {tableColumns}
            </TableRow>
        )
    });

    const tableHeader = columns.map((column) => {
        switch (column) {
            case 'number':
                return <TableCell sortDirection={orderColumn === 'number' ? order : false}
                                  sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'number'}
                        direction={order}
                        onClick={() => changeSort('number')}
                    >
                        {t('tickets.number')}
                    </TableSortLabel>
                </TableCell>
            case 'customer':
                return <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.customer')}</TableCell>
            case 'addresses':
                return <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.addresses')}</TableCell>
            case 'devices':
                return <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.devices')}</TableCell>
            case 'locations':
                return <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.locations')}</TableCell>
            case 'categories':
                return <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.category')}</TableCell>
            case 'title':
                return <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.title')}</TableCell>
            case 'status':
                return <TableCell
                    sortDirection={orderColumn === 'statusPriority' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'statusPriority'}
                        direction={order}
                        onClick={() => changeSort('statusPriority')}
                    >
                        {t('tickets.status')}
                    </TableSortLabel>
                </TableCell>
            case 'importance':
                return <TableCell
                    sortDirection={orderColumn === 'importanceId' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'importanceId'}
                        direction={order}
                        onClick={() => changeSort('importanceId')}
                    >
                        {t('tickets.importance')}
                    </TableSortLabel>
                </TableCell>
            case 'tags':
                return <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.tags')}</TableCell>
            case 'createdAt':
                return <TableCell
                    sortDirection={orderColumn === 'createdAt' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'createdAt'}
                        direction={order}
                        onClick={() => changeSort('createdAt')}
                    >
                        {t('tickets.createdAt')}
                    </TableSortLabel>
                </TableCell>
            case 'updatedAt':
                return <TableCell
                    sortDirection={orderColumn === 'updatedAt' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'updatedAt'}
                        direction={order}
                        onClick={() => changeSort('updatedAt')}
                    >
                        {t('tickets.updatedAt')}
                    </TableSortLabel>
                </TableCell>
            case 'completedAt':
                return <TableCell
                    sortDirection={orderColumn === 'completedAt' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'completedAt'}
                        direction={order}
                        onClick={() => changeSort('completedAt')}
                    >
                        {t('tickets.completedAt')}
                    </TableSortLabel>
                </TableCell>
            default:
                return <TableCell/>
        }
    })

    return <>
        <Box sx={{width: '100%', height: '100%'}}>
            {ticketsLoading && <LinearProgress sx={{height: '3px', position: 'absolute', zIndex: '100', width: '100%'}}
                                               color="secondary"/>}
            <TableContainer sx={{height: 'calc(100% - 55px)'}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {tableHeader}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ticketList}
                    </TableBody>
                </Table>
                {total <= 0 &&
                    <Box display='flex' alignItems='center' justifyContent='center' sx={{height: 'calc(100% - 55px)'}}>
                        <Box sx={{textAlign: 'center', color: (t) => t.palette.grey[400], mb: 16}}>
                            <ConfirmationNumber sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                            <Box sx={{color: (t) => t.palette.text.secondary, mb: 2}} textAlign='center'>
                                <Typography variant='body2'>{t('tickets.noTicketFound')}</Typography>
                            </Box>
                        </Box>
                    </Box>}
            </TableContainer>
            {total >= 0 && <TablePagination
                rowsPerPageOptions={[perPage]}
                onPageChange={(e, p) => {
                    setPage(p + 1)
                }}
                component="div"
                count={total}
                rowsPerPage={perPage}
                page={page - 1}
            />}
        </Box>
        <SpeedDial
            ariaLabel='refresh'
            onClick={() => fetchTickets(linkId, hash)}
            sx={{position: 'absolute', bottom: 16, left: 16}}
            icon={<Refresh/>}
        />
    </>
}

export default LiveTicketList
