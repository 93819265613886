import React from 'react';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";
import Row from "../Settings/Devices/Types/Row";
import {v1 as uuid} from "uuid";

const DeviceTypes = ({deviceTypes, setDeviceTypes, setActiveStep}) => {
    const {t} = useTranslation();

    const onAdd = () => {
        deviceTypes.push({id: uuid(), name: '', description: ''})
        setDeviceTypes(deviceTypes.slice())
    }

    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Button size='small' startIcon={<ChevronLeft/>} variant='contained'
                    onClick={() => setActiveStep(1)}>{t('back')}</Button>
            <Box flexGrow={1}/>
            <Button size='small' startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(3)}>{t('next')}</Button>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.deviceType.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.deviceType.description')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deviceTypes.map((type) => (
                        <Row key={type.id} type={type} disableDelete={deviceTypes.length <= 1}
                             onDelete={() => {
                                 setDeviceTypes((types) => {
                                     const i = types.findIndex((c) => c.id === type.id);
                                     types.splice(i, 1)
                                     return types.slice();
                                 })
                             }}
                             onChange={(changedType) => {
                                 setDeviceTypes((types) => {
                                     const i = types.findIndex((c) => c.id === changedType.id);
                                     types[i] = changedType;
                                     return types.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Toolbar variant='dense' disableGutters={true}>
            <Button size='small' onClick={onAdd} startIcon={<Add/>}
                    variant='outlined'
                    color='primary'>{t('settings.deviceType.addType')}</Button>
        </Toolbar>
    </React.Fragment>
}

export default DeviceTypes;
