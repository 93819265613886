import {CALL_API} from '../middleware/api'

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAILURE = 'CATEGORY_LIST_FAILURE';


export function fetchCategoryList() {

    return {
        [CALL_API]: {
            endpoint: 'categories',
            method: 'GET',
            types: [CATEGORY_LIST_SUCCESS, CATEGORY_LIST_FAILURE, CATEGORY_LIST_REQUEST]
        }
    }
}

