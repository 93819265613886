import React from 'react';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";
import Row from "../Settings/Tickets/Status/Row";
import {v1 as uuid} from "uuid";

const DeviceTypes = ({statusList, setStatusList, setActiveStep}) => {
    const {t} = useTranslation();

    const onAdd = () => {
        statusList.push({id: uuid(), name: '', description: '', priority: 0})
        setStatusList(statusList.slice())
    }

    const displayList = statusList.sort((a, b) => a.priority - b.priority)

    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Button size='small' startIcon={<ChevronLeft/>} variant='contained'
                    onClick={() => setActiveStep(3)}>{t('back')}</Button>
            <Box flexGrow={1}/>
            <Button size='small' startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(5)}>{t('next')}</Button>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.description')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.priority')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.type')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayList.filter((g) => !g.isDeleted).map((status) => (
                        <Row key={status.id} status={status} disableDelete={statusList.length <= 1}
                             onDelete={() => {
                                 setStatusList((statusList) => {
                                     const i = statusList.findIndex((c) => c.id === status.id);
                                     statusList.splice(i, 1)
                                     return statusList.slice();
                                 })
                             }}
                             onChange={(changedStatus) => {
                                 setStatusList((statusList) => {
                                     const i = statusList.findIndex((c) => c.id === changedStatus.id);
                                     statusList[i] = changedStatus;
                                     return statusList.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Toolbar variant='dense' disableGutters={true}>
            <Button size='small' onClick={onAdd} startIcon={<Add/>}
                    variant='outlined'
                    color='primary'>{t('settings.status.addStatus')}</Button>
        </Toolbar>
    </React.Fragment>
}

export default DeviceTypes;
