import {applyMiddleware, compose, legacy_createStore as createStore} from 'redux';
import {createRouterMiddleware} from "@lagunovsky/redux-react-router";
import history from './history';
import createRootReducer from './reducers'
import {thunk} from 'redux-thunk'
import {api} from "../middleware/api";
import {websocketMiddleware} from "../middleware/websocket";
import {ApplicationWebsocket} from "./Websocket";

const initialState = {};

const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
        applyMiddleware(
            createRouterMiddleware(history),
            thunk,
            api,
            websocketMiddleware(new ApplicationWebsocket())
        ),
    )
);

export default store;
