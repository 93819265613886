import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import {Add, Close, Devices as DevicesIcon, LinkOff, OpenInNew, Save} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import DeviceList from "../../Devices/Utils/DeviceList";
import {Link} from "react-router-dom";
import DeleteDialog from "../../Utils/DeleteDialog";

const Devices = ({devices, fetching, deviceTypeList, customer, saveDevices}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [deviceSelectOpen, setDeviceSelectOpen] = React.useState(false);
    const [temporaryDevices, setTemporaryDevices] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    useEffect(() => {
        setTemporaryDevices([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceSelectOpen]);

    const [adding, setAdding] = React.useState(false);
    const [removing, setRemoving] = React.useState(false);

    const addDevices = () => {
        setAdding(true);
        const newDevices = temporaryDevices.filter(d => !devices.find(e => e.id === d.id));
        saveDevices([...devices, ...newDevices]).then(() => {
            setDeviceSelectOpen(false)
        }, () => {
        }).then(() => setAdding(false));
    };

    const removeDevices = () => {
        setRemoving(true);
        const newDevices = devices.filter(d => !selected.includes(d.id));
        saveDevices(newDevices).then(() => {
            setSelected([])
            setDeleteDialogOpen(false)
        }, () => {
        }).then(() => setRemoving(false));
    }

    const deviceList = devices.map((d, i) => {
        const location = customer.addresses?.find((a) => a.id === d.addressId)?.locations?.find((l) => l.id === d.locationId)?.name || t('notFound');
        const type = deviceTypeList.find((dt) => dt.id === d.typeId)?.name || 'type not found';
        const move = customer.id !== d.customerId
        const link = `/customers/show/${d.customerId}/addresses/${d.addressId}/devices/${d.id}`

        return <>
            <ListItem
                key={d.id} sx={{whiteSpace: 'nowrap'}}
                secondaryAction={
                    <IconButton component={Link} to={link} target={'_blank'} edge="end" aria-label="delete">
                        <OpenInNew/>
                    </IconButton>}
            >
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={selected.includes(d.id)}
                        onClick={() => setSelected((selected) => {
                            if (selected.includes(d.id)) {
                                return selected.filter(s => s !== d.id)
                            }
                            return [...selected, d.id]
                        })}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText primary={<React.Fragment>{d.number}: {d.brand} {d.model} {move &&
                    <Chip size='small' label={t('devices.list.isMoving')}/>}</React.Fragment>}
                              secondary={`${type} / ${location}`}/>
            </ListItem>
        </>
    })

    return <>
        <Toolbar variant='dense' disableGutters sx={{mx: 1}}>
            <Button size='small' startIcon={<Add/>}
                    disabled={fetching}
                    onClick={() => setDeviceSelectOpen(true)}
                    variant='contained'
                    color='primary'>{t('tickets.deviceList.addDevices')}</Button>
            {Boolean(selected.length > 0) && <Button size='small' startIcon={<LinkOff/>} sx={{ml: 1}}
                                                     onClick={() => setDeleteDialogOpen(true)}
                                                     variant='outlined'>{t('tickets.deviceList.removeSelectedLinks')}</Button>}
        </Toolbar>
        <List disablePadding>
            {(!fetching && deviceList.length > 0) && <Box sx={{overflow: 'auto'}}>{deviceList}</Box>}
            {fetching && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            </Box>}
            {(!fetching && deviceList.length <= 0) &&
                <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
                    <DevicesIcon sx={{fontSize: 60}}/>
                    <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                        <Typography variant='body2'>{t('tickets.deviceList.noDevices')}</Typography>
                    </Box>
                </Box>}
        </List>
        <Dialog maxWidth='md' fullWidth open={deviceSelectOpen}>
            <DialogTitle>
                {t('tickets.deviceList.addDevices')}
                <IconButton
                    aria-label="close"
                    disabled={adding}
                    onClick={() => setDeviceSelectOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DeviceList hiddenColumns={['customer']} fixedFilters={{customerIds: customer.id}}
                            displayCreate={false} linkToDevice={false} containerStyle={{pt: 1}}
                            tableStyle={{maxHeight: '30vh'}} selectable
                            selected={temporaryDevices?.map(d => d.id) || []}
                            onSelectionChange={(d) => {
                                setTemporaryDevices((devices) => {
                                    const found = devices.find(r => r.id === d.id)
                                    if (!found) {
                                        return [...devices, d];
                                    }
                                    return devices.filter(r => r.id !== d.id).slice()
                                })
                            }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={adding}
                    onClick={() => setDeviceSelectOpen(false)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={adding}
                    onClick={addDevices}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <DeleteDialog onDelete={() => removeDevices()} isDeleting={removing}
                      title={t('tickets.deviceList.removeSelectedLinks')}
                      handleClose={() => setDeleteDialogOpen(false)}
                      description={t('tickets.deviceList.removeSelectedLinksDescription')}
                      buttonIcon={<LinkOff/>}
                      buttonText={t('tickets.deviceList.removeSelectedLinks')}
                      open={deleteDialogOpen}/>
    </>;
}

export default Devices;
