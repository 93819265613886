import {
    Alert,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container, FormControl,
    FormHelperText,
    Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select,
    TextField,
    Typography
} from "@mui/material";
import logo from '../../images/small-logo.png';
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/system";
import TextEditor from "../Utils/TextEditor";
import React, { useCallback, useEffect, useRef } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Autocomplete, LoadingButton } from "@mui/lab";
import { AddAlert } from "@mui/icons-material";
import * as EmailValidator from "email-validator";
import Schema from "validate";
import getTextColor from "../../helper/getTextColor";
import Api from "../../core/Api";
import Paper from "@mui/material/Paper";
import { useDebounce } from "../Utils/useDebounce";
import qs from "query-string";
import { useLocation } from "react-router-dom";

const email = (val) => val ? EmailValidator.validate(val) : true;

const getValidationSchema = (t, settings) => {
    return new Schema({
        deviceNumber: {
            required: true,
            type: String,
            message: t('validation.deviceNumber'),
        },
        givenName: {
            required: true,
            type: String,
            message: t('validation.givenName'),
        },
        familyName: {
            required: true,
            type: String,
            message: t('validation.familyName'),
        },
        email: {
            required: false,
            type: String,
            use: {email},
        },
        telephone: {
            required: false,
            type: String,
        },
        subject: {
            required: settings.subjectType === 'freeText',
            type: String,
            message: t('validation.subject'),
        },
        selectedSubject: {
            required: settings.subjectType === 'subjectSelection',
            type: Number,
            message: t('validation.subject'),
        },
        description: {
            required: true,
            type: String,
            message: t('validation.description'),
        },
        token: {
            required: true,
            type: String,
            message: t('validation.token'),
        }
    })
}

const New = () => {
    const captchaRef = useRef(null);

    const {t} = useTranslation('frontend');
    const theme = useTheme();

    const [supportSettings, setSupportSettings] = React.useState({});


    const [deviceNumber, setDeviceNumber] = React.useState('');

    const [numberInputOpen, setNumberInputOpen] = React.useState(false);
    const [numbers, setNumbers] = React.useState([]);
    const [numbersLoading, setNumbersLoading] = React.useState(false);

    const [subject, setSubject] = React.useState('');
    const [selectedSubject, setSelectedSubject] = React.useState(0);
    const [givenName, setGivenName] = React.useState('');
    const [familyName, setFamilyName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [telephone, setTelephone] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [token, setToken] = React.useState('');
    const [errorList, setErrorList] = React.useState({});
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [debouncedDeviceNumber, setDebouncedDeviceNumber] = useDebounce('', 300);
    const [success, setSuccess] = React.useState(false);

    const location = useLocation();
    useEffect(() => {
        const parsedQuery = qs.parse(location.search);
        if (parsedQuery?.device) {
           setDeviceNumber(parsedQuery.device);
        }
    }, [location.search])

    useEffect(() => {
        setDebouncedDeviceNumber(deviceNumber);
    }, [deviceNumber, setDebouncedDeviceNumber]);

    const loadNumbers = useCallback((prefix) => {
        setNumbersLoading(true);
        Api.fetch({
            endpoint: 'public/support/devices?prefix=' + prefix,
            auth: false,
        }).then((res) => {
            setNumbers(res.response.map((item) => item.number));
        }, () => {
        }).then(() => setNumbersLoading(false))
    }, []);

    useEffect(() => {
        if (numberInputOpen) {
            loadNumbers(debouncedDeviceNumber);
        }
    }, [debouncedDeviceNumber, numberInputOpen, loadNumbers])

    useEffect(() => {
        setLoading(true);
        Api.fetch({
            endpoint: 'public/support/settings',
            auth: false,
        }).then((res) => {
            setSupportSettings(res.response);
        }, () => {
        }).then(() => setLoading(false))
    }, [])

    const onLoad = () => {
        captchaRef.current?.execute();
    };

    const backgroundColor = supportSettings.color || theme.palette.primary.main
    const logoUrl = supportSettings.logoUrl || logo

    const onSave = () => {
        setErrorList({})
        const schema = getValidationSchema(t, supportSettings);
        const errors = schema.validate({
            deviceNumber: deviceNumber,
            givenName: givenName,
            familyName: familyName,
            email: email,
            telephone: telephone,
            subject: subject,
            selectedSubject: selectedSubject,
            description: description,
            token: token,
        });
        if (errors.length) {
            const errorList = {};
            errors.forEach((error) => {
                errorList[error.path] = error.message;
            });
            setErrorList(errorList);
            return;
        }


        setSaving(true);
        Api.fetch({
            endpoint: 'public/support/ticket',
            auth: false,
            method: 'POST',
            body: {
                subject: subject,
                selectedSubject: selectedSubject,
                deviceNumber: deviceNumber,
                givenName: givenName,
                familyName: familyName,
                email: email,
                telephone: telephone,
                description: description,
                token: token,
            }
        }).then((res) => {
            setSuccess(true);
        }, () => {
        }).then(() => setSaving(false))
    }

    if (!loading && !supportSettings.active) {
        return <Container>
            <Alert sx={{my: 4}} severity='warning'>{t('supportTicketsNotActive')}</Alert>
        </Container>
    }

    return <Container>
        {loading && <Paper sx={{textAlign: 'center', my: 4, py: 2}}>
            <CircularProgress/>
        </Paper>}
        {(!loading && success) && <Alert sx={{my: 4}} severity='success'>{t('successFeedback')}</Alert>}
        {(!loading && !success) && <Card sx={{my: 4}}>
            <CardHeader
                avatar={<Avatar variant="square" sx={{height: 100, width: 'auto'}} src={logoUrl}/>}
                sx={{backgroundColor: backgroundColor}}
                titleTypographyProps={{sx: {fontSize: '250%', color: getTextColor(backgroundColor)}}}
                title={t('createTicket')}
            />
            <CardContent>
                <Box>
                    <Typography variant={'h5'} component={'div'} sx={{mb: 2}}>{t('deviceInformation')}</Typography>
                    <Autocomplete
                        margin='dense'
                        options={numbers}
                        isOptionEqualToValue={(option, value) => option === value}
                        onInputChange={(e, value) => setDeviceNumber(value)}
                        onOpen={() => setNumberInputOpen(true)}
                        onClose={() => setNumberInputOpen(false)}
                        filterOptions={(o) => o}
                        onChange={(e, value) => {
                            setDeviceNumber(value);
                        }}
                        value={deviceNumber}
                        filterSelectedOptions
                        loading={numbersLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('deviceNumber')}
                                placeholder={t('deviceNumber')}
                                error={errorList.hasOwnProperty('deviceNumber')}
                                helperText={errorList.deviceNumber}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {numbersLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
                <Box sx={{mt: 2}}>
                    <Typography variant={'h5'} component={'div'} sx={{mb: 2}}>{t('personalInformation')}</Typography>
                    <Grid spacing={2} container>
                        <Grid item xs={12} md={6}>
                            <TextField required placeholder={t('Max')} label={t('givenName')} variant={'outlined'}
                                       error={errorList.hasOwnProperty('givenName')} helperText={errorList.givenName}
                                       value={givenName} onChange={(e) => setGivenName(e.target.value)}
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField required placeholder={t('Mustermann')} label={t('familyName')}
                                       error={errorList.hasOwnProperty('familyName')} helperText={errorList.familyName}
                                       value={familyName} onChange={(e) => setFamilyName(e.target.value)}
                                       variant={'outlined'} fullWidth/>
                        </Grid>
                    </Grid>
                    <Typography variant='h6' component={'div'} sx={{my: 1}}>{t('optionalInformation')}</Typography>
                    <Grid spacing={2} container>
                        <Grid item xs={12} md={6}>
                            <TextField placeholder={t('max.mustermann@gmail.com')} label={t('email')}
                                       error={errorList.hasOwnProperty('email')} helperText={errorList.email}
                                       value={email} onChange={(e) => setEmail(e.target.value)}
                                       variant={'outlined'}
                                       type={'email'}
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField placeholder={t('+49 123 45678901')} label={t('telephone')} type={'tel'}
                                       error={errorList.hasOwnProperty('telephone')} helperText={errorList.telephone}
                                       value={telephone} onChange={(e) => setTelephone(e.target.value)}
                                       variant={'outlined'} fullWidth/>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{mt: 2}}>
                    <Typography variant={'h5'} component={'div'} sx={{mb: 2}}>{t('problemDescription')}</Typography>
                    {supportSettings.subjectType === 'freeText' && <TextField required placeholder={t('Kein Rückgeld')} label={t('subject')} variant={'outlined'}
                               error={errorList.hasOwnProperty('subject')} helperText={errorList.subject}
                               value={subject} onChange={(e) => setSubject(e.target.value)}
                               fullWidth/>}
                    {supportSettings.subjectType === 'subjectSelection' && <FormControl fullWidth margin='normal'>
                        <InputLabel>{t('subject')}</InputLabel>
                        <Select
                            value={selectedSubject}
                            onChange={(e) => setSelectedSubject(parseInt(e.target.value, 10))}
                            input={<OutlinedInput label={t('subject')}/>}
                        >
                            {supportSettings.subjectOptions.sort((a, b) => {
                                //sort by position
                                if (a.position < b.position) {
                                return -1;
                            }
                                if (a.position > b.position) {
                                return 1;
                            }
                                return 0;
                            }).map((subjectSelectionItem) => (
                                <MenuItem key={subjectSelectionItem.position} value={subjectSelectionItem.position}>
                                    <ListItemText primary={subjectSelectionItem.content} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                    <TextEditor
                        style={{marginTop: '8px', marginBottom: '8px'}}
                        value={description}
                        onChange={(v) => setDescription(v)}
                    />
                    {errorList.description && <FormHelperText error>{errorList.description}</FormHelperText>}
                </Box>
                <Box sx={{mt: 2}}>
                    <HCaptcha
                        sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                        onLoad={onLoad}
                        onVerify={setToken}
                        ref={captchaRef}
                    />
                    {errorList.token && <FormHelperText error>{errorList.token}</FormHelperText>}
                </Box>
                <LoadingButton onClick={onSave} loading={saving} loadingPosition="start" startIcon={<AddAlert/>}
                               variant={'contained'} size='large'
                               sx={{mt: 2}}>{t('createTicket')}</LoadingButton>
            </CardContent>
        </Card>}
    </Container>
}

export default New;
