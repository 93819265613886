import { push } from "@lagunovsky/redux-react-router";
import store from './store';
import axios from 'axios';

const BASE_URL = '/api/';

const axiosInstance = axios.create()


// Response interceptor for API calls
axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const access_token = await refreshAccessToken(originalRequest.tokenType);
            originalRequest.headers.Authorization = 'Bearer ' + access_token;
            return axiosInstance(originalRequest);
        } catch (e) {
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
});


export default class Api {

    static fetch({
                     endpoint,
                     method = 'GET',
                     parameter = {},
                     header = {},
                     body,
                     auth = true,
                     ignoreErrorCodes = [],
                     tokenType = "id_token",
                     publicApi = false,
                 }) {
        store.dispatch({
            type: 'ADD_REQUEST'
        });

        return new Promise((resolve, reject) => {
            const token = localStorage.getItem(tokenType) || null;

            let config = {
                method: method,
                tokenType: tokenType
            };

            if (auth && token) {
                header.Authorization = `Bearer ${token}`
            } else if (auth) {
                store.dispatch(push('/login'));
                return reject('No Bearer Token')
            }

            header['accept'] = 'application/json';
            header['x-origin'] = window.location.origin
            header['Content-Type'] = 'application/json';

            config.data = body;

            config.headers = header;

            let params = '';
            if (Object.keys(parameter).length) {
                for (const key in parameter) {
                    if (typeof parameter[key] !== 'object') {
                        continue;
                    }

                    for (const objectKey in parameter[key]) {
                        if (typeof parameter[key][objectKey] === 'undefined') {
                            continue;
                        }

                        parameter[key + '[' + objectKey + ']'] = parameter[key][objectKey];
                    }

                    delete parameter[key];
                }

                params = '?' + (new URLSearchParams(parameter));
            }

            config.url = endpoint


            let baseUrl = BASE_URL
            if (process.env.REACT_APP_API_BASE_URL) {
                baseUrl = process.env.REACT_APP_API_BASE_URL
            }

            config.url = baseUrl + endpoint + params;


            axiosInstance.request(config).then((res) => {
                store.dispatch({
                    type: 'REMOVE_REQUEST'
                });

                resolve({
                    response: res.data,
                    status: res.status,
                    headers: res.headers
                });
            }).catch((err) => {
                store.dispatch({
                    type: 'REMOVE_REQUEST'
                });

                if(typeof err.response === 'undefined') {
                    return reject({
                        message: 'Network Error',
                        response: null
                    })
                }
                const response = err.response
                let showAlert = true
                let message = response.statusText
                if (response.data?.message) {
                    message = response.data?.message
                }

                if (response.data?.Message) {
                    message = response.data?.Message
                }

                if (response.status === 401 && auth) {
                    showAlert = false;
                    store.dispatch(push('/login'));
                }

                if (showAlert && ignoreErrorCodes.indexOf(response.status) === -1) {
                    store.dispatch({
                        type: 'ADD_ALERT',
                        message: message,
                        style: 'error'
                    });
                }

                reject({
                    message: message,
                    response: response
                })
            })
        })
    }
};

const refreshAccessToken = async (tokenType) => {
    const res = await axios.post(BASE_URL + 'user/refresh', {
        refreshToken: localStorage.getItem('refresh_token'),
        username: localStorage.getItem('user_name')
    }, {
        headers: {
            'x-origin': window.location.origin
        }
    })

    localStorage.setItem('access_token', res.data.accessToken)
    localStorage.setItem('refresh_token', res.data.refreshToken)
    localStorage.setItem('id_token', res.data.idToken)

    return tokenType === 'id_token' ? res.data.idToken : res.data.accessToken
}
