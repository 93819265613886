import {USER_CLEAR, USER_FAILURE, USER_REQUEST, USER_SUCCESS} from "../actions/userActions";

export function userReducer(state = {
    isFetching: false,
    error: null,
    initialized: false,
    user: null
}, action) {
    switch (action.type) {
        case USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: null
            });
        case USER_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
                isFetching: false,
                initialized: true
            });
        case USER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                user: action.response,
                error: null,
                initialized: true
            });
        case USER_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                user: null,
                error: null,
                initialized: false
            });
        default:
            return state
    }
}

