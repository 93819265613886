import {AwsRum} from 'aws-rum-web';

class AwsRumSingleton {
    static instance = null;

    static initialize() {
        if (!this.instance) {
            try {
                const config = {
                    sessionSampleRate: 1,
                    guestRoleArn: 'arn:aws:iam::145335933802:role/RUM-Monitor-eu-central-1-145335933802-7104650763461-Unauth',
                    identityPoolId: 'eu-central-1:a917d674-2fdf-489f-8909-6d115c0a2035',
                    endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
                    //telemetries: ['performance', 'errors', ['http', {addXRayTraceIdHeader: true}]],
                    telemetries: ['performance', 'errors'],
                    allowCookies: true,
                    enableXRay: true
                };

                const APPLICATION_ID = '6944ec52-99eb-46ba-938b-4867623a46ed';
                const APPLICATION_VERSION = '1.0.0';
                const APPLICATION_REGION = 'eu-central-1';

                this.instance = new AwsRum(
                    APPLICATION_ID,
                    APPLICATION_VERSION,
                    APPLICATION_REGION,
                    config
                );
            } catch (error) {
                // Ignore errors thrown during CloudWatch RUM web client initialization
            }
        }
    }

    /**
     * @returns {AwsRum|null}
     */
    static getInstance() {
        if (!this.instance) {
            this.initialize();
        }
        return this.instance;
    }
}

export default AwsRumSingleton;