import React from 'react'
import {hasVersion, getVersion} from "../../helper/hasVersion";
import { Alert, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const CheckVersion = ({children, minimumVersion, fallback = null, disableFallback}) => {
    const {t} = useTranslation();
    const hasMinimumVersion = hasVersion(minimumVersion)
    if (hasMinimumVersion) {
        return children
    } else if (!disableFallback) {
        return fallback || <Alert severity='info' ><Box dangerouslySetInnerHTML={{__html: t('payingPlanToLow', {
                systemPlan: t('versions.' + getVersion()),
                neededPlan: t('versions.' + minimumVersion)
            })}} /></Alert>
    } else {
        return <></>
    }
}

export default CheckVersion
