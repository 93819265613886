import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button, Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl,
    IconButton, InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem, OutlinedInput, Select,
    Toolbar,
    Typography
} from "@mui/material";
import { Add, Cancel, CheckCircle, Close, Delete, Devices as DevicesIcon, Save } from "@mui/icons-material";
import React from "react";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DeleteDialog from "../../Utils/DeleteDialog";
import { LoadingButton } from "@mui/lab";
import { uniq } from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const LinkedUsers = ({userList, ticket, groupList, loading, saving, deleteUserLink, addUserLinks}) => {

    const [addOpen, setAddOpen] = React.useState(false);

    const [deleteUserId, setDeleteUserId] = React.useState(null);

    const [userIds, setUserIds] = React.useState([]);
    const [groupIds, setGroupIds] = React.useState([]);

    const theme = useTheme();
    const {t} = useTranslation();
    const userLinks = ticket.userLinks || []
    const userLinkList = userLinks.slice().sort((a, b) => {
        const map = {
            'accepted': -1,
            'pending': 0,
            'rejected': 1,
        }

        return map[a.status] - map[b.status]
    }).map((link, i) => {
        let userName = t('deleted');
        const user = userList.find((user) => user.id === link.userId);
        if (user) {
            userName = user.givenName + ' ' + user.familyName;
        }
        return <React.Fragment key={link.userId}>
            <ListItem
                secondaryAction={
                    <IconButton onClick={() => setDeleteUserId(link.userId)} edge="end" aria-label="delete">
                        <Delete/>
                    </IconButton>}
            >
                {link.status === 'pending' && <ListItemIcon>
                    <WatchLaterIcon/>
                </ListItemIcon>}
                {link.status === 'accepted' && <ListItemIcon>
                    <CheckCircle color='success'/>
                </ListItemIcon>}
                {link.status === 'rejected' && <ListItemIcon>
                    <Cancel color='error'/>
                </ListItemIcon>}
                <ListItemText
                    primary={userName}
                />

            </ListItem>
            {i < userLinks.length - 1 && <Divider variant='middle'/>}
        </React.Fragment>
    })

    let deleteUserName = t('deleted');
    const user = userList.find((user) => user.id === deleteUserId);
    if (user) {
        deleteUserName = user.givenName + ' ' + user.familyName;
    }

    return <>
        <Toolbar variant='dense' disableGutters sx={{mx: 1}}>
            <Button size='small' startIcon={<Add/>}
                    disabled={loading}
                    onClick={() => setAddOpen(true)}
                    variant='outlined'
                    color='primary'>{t('tickets.userLinks.linkUsers')}</Button>
        </Toolbar>
        <List disablePadding>
            {(!loading && userLinks.length > 0) && <Box sx={{overflow: 'auto'}}>{userLinkList}</Box>}
            {loading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            </Box>}
            {(!loading && userLinks.length <= 0) &&
                <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
                    <DevicesIcon sx={{fontSize: 60}}/>
                    <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                        <Typography variant='body2'>{t('tickets.userLinks.noLinks')}</Typography>
                    </Box>
                </Box>}
        </List>
        <Dialog maxWidth='md' fullWidth open={addOpen}>
            <DialogTitle>
                {t('tickets.userLinks.linkUsers')}
                <IconButton
                    aria-label="close"
                    disabled={saving || loading}
                    onClick={() => setAddOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('tickets.fields.groups')}</InputLabel>
                    <Select
                        multiple
                        value={groupIds}
                        onChange={(e) => setGroupIds(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                        input={<OutlinedInput label={t('tickets.fields.groups')}/>}
                        renderValue={
                            (selected) => selected.map((v) => groupList.find(r => r.id === v).name).join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {groupList.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <Checkbox checked={groupIds.includes(r.id)}/>
                                <ListItemText primary={r.name} secondary={r.description}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('tickets.fields.users')}</InputLabel>
                    <Select
                        multiple
                        value={userIds}
                        onChange={(e) => {
                            setUserIds(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                        }}
                        input={<OutlinedInput label={t('tickets.fields.users')}/>}
                        renderValue={
                            (selected) => selected.map((v) => {
                                const user = userList.find(r => r.id === v)
                                if (!user.givenName.length && !user.familyName.length) {
                                    return user.email
                                }
                                return user.givenName + ' ' + user.familyName
                            }).join(', ')
                        }
                        MenuProps={MenuProps}
                    >
                        {userList.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <Checkbox checked={userIds.includes(r.id)}/>
                                <ListItemText primary={r.givenName + ' ' + r.familyName} secondary={r.email}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setAddOpen(false)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={() => {
                        const newUserLinks = uniq([...userIds, ...groupIds.map((g) => {
                            return userList.filter(u => u.groups.includes(g)).map(u => u.id)
                        }).flat()])

                        addUserLinks(newUserLinks).then(() => setAddOpen(false), () => {})
                    }
                    }
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <DeleteDialog onDelete={() => {
            deleteUserLink(deleteUserId).then(() => setDeleteUserId(null), () => null);
        }} title={t('tickets.userLinks.deleteLink')}
                      isDeleting={saving}
                      handleClose={() => setDeleteUserId(null)}
                      description={t('tickets.userLinks.deleteLinkDescription', {userName: deleteUserName})}
                      open={Boolean(deleteUserId)}/>
    </>
}

export default LinkedUsers
