import React, { useCallback, useEffect } from "react";
import Api from "../../../core/Api";
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const Preview = ({ importId, fields }) => {
    const [previewLoading, setPreviewLoading] = React.useState(false);
    const [preview, setPreview] = React.useState({fields: []});

    const fetchPreview = useCallback(() => {
        setPreviewLoading(true);
        Api.fetch({
            endpoint: 'exports/' + importId + '/preview',
        }).then((res) => {
            setPreview(res.response);
        }, () => {
        }).then(() => setPreviewLoading(false));
    }, [importId])

    useEffect(() => {
        fetchPreview();
    }, [fetchPreview])

    const rawRows = [];

    const headers = fields.map((field, i) => {
        preview?.fields[field.columnName]?.forEach((value, j) => {
            if (!rawRows[j]) {
                rawRows[j] = [];
            }
            rawRows[j][i] = value;
        });
        return <TableCell key={field.columnName}>{field.columnName}</TableCell>
    });

    const rows = rawRows.map((row, i) => {
        return <TableRow key={i}>
            {row.map((value, j) => {
                return <TableCell key={j}>{value}</TableCell>
            })}
        </TableRow>
    });

    return <Paper>
        {previewLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
            <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        </Box>}
        {!previewLoading && <Table>
            <TableHead>
                <TableRow>
                    {headers}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>}
    </Paper>
}

export default Preview
