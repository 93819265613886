import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel, FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Calculate } from "@mui/icons-material";
import { formatAddress } from "../../../helper/formatAddress";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Api from "../../../core/Api";
import CheckVersion from "../../Utils/CheckVersion";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CalculateDistanceDialog = ({open, addresses, onClose, onSubmit}) => {
    const {t} = useTranslation();

    const [permission, setPermission] = React.useState(false);

    const [routeLoading, setRouteLoading] = React.useState(false);

    const [errorList, setErrorList] = React.useState({});


    const [coordsLoading, setCoordsLoading] = React.useState(false);
    const [startType, setStartType] = React.useState('own')
    const [startCoordinates, setStartCoordinates] = React.useState(null)
    const [startAddressId, setStartAddressId] = React.useState(addresses[0]?.id || '')

    const [endType, setEndType] = React.useState('address')
    const [endAddressId, setEndAddressId] = React.useState(addresses[0]?.id || '')
    const [endCoordinates, setEndCoordinates] = React.useState(null)

    useEffect(() => {
        if (open) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(() => setPermission(true), e => {
                    if (e.code === 1) {
                        setPermission(false)
                    }
                });
            } else {
                setPermission(false)
                console.log("This browser does not support geolocation");
            }
        }
    }, [open]);

    const determineCoordinates = (type) => {
        setCoordsLoading(true)
        navigator.geolocation.getCurrentPosition((c) => {
            Api.fetch({
                endpoint: 'integrations/location/by-coordinates',
                parameter: {
                    lat: c.coords.latitude,
                    lng: c.coords.longitude
                }
            }).then((res) => {
                type === 'start' ? setStartCoordinates(res.response) : setEndCoordinates(res.response)
            }, () => {
            }).then(() => {
                setCoordsLoading(false)
            })
        });
    }

    const calculate = async () => {
        setRouteLoading(true)
        setErrorList({})
        const start = {
            lat: 0,
            lng: 0
        }

        const errorObject = {}
        if (startType === 'own') {
            if (startCoordinates?.lat != null && startCoordinates?.lng != null) {
                start.lat = startCoordinates.lat
                start.lng = startCoordinates.lng
            } else if(startCoordinates?.label) {
                const res = await Api.fetch({
                    endpoint: 'integrations/location/by-address',
                    parameter: {
                        address: startCoordinates?.label
                    }
                })
                setStartCoordinates(res.response)
                start.lat = res.response.lat
                start.lng = res.response.lng
            } else {
                errorObject['start'] = t('tickets.distanceCalculation.error.start')
            }
        } else {
            const address = addresses.find(a => a.id === startAddressId)
            if(!address?.latitude || !address?.longitude) {
                errorObject['start'] = t('tickets.distanceCalculation.error.start')
            } else {
                start.lat = address.latitude
                start.lng = address.longitude
            }
        }

        const end = {
            lat: 0,
            lng: 0
        }

        if (endType === 'own') {
            if (endCoordinates?.lat != null && endCoordinates?.lng != null) {
                end.lat = endCoordinates.lat
                end.lng = endCoordinates.lng
            } else if(endCoordinates?.label) {
                const res = await Api.fetch({
                    endpoint: 'integrations/location/by-address',
                    parameter: {
                        address: endCoordinates?.label
                    }
                })
                setEndCoordinates(res.response)
                end.lat = res.response.lat
                end.lng = res.response.lng
            } else {
                errorObject['end'] = t('tickets.distanceCalculation.error.end')
            }
        } else {
            const address = addresses.find(a => a.id === endAddressId)

            if(!address?.latitude || !address?.longitude) {
                errorObject['end'] = t('tickets.distanceCalculation.error.end')
            } else {
                end.lat = address.latitude
                end.lng = address.longitude
            }
        }

        if (Object.keys(errorObject).length > 0) {
            setErrorList(errorObject)
            setRouteLoading(false)
            return;
        }

        Api.fetch({
            method: 'POST',
            endpoint: 'integrations/location/route',
            body: {
                from: start,
                to: end
            }
        }).then((res) => {
            onSubmit(res.response.distance.toFixed(3))
        }, () => {
        }).then(() => {
            setRouteLoading(false)
        })
    }

    return <Dialog
        fullWidth maxWidth='sm'
        open={open || false}
        onClose={() => {
            if (routeLoading) {
                return;
            }
            onClose()
        }}
    >
        <DialogTitle>{t('tickets.distanceCalculation.title')}</DialogTitle>
        <DialogContent>
            <CheckVersion minimumVersion='extended'>
            <Typography variant='h6'>{t('tickets.distanceCalculation.start')}</Typography>
            <FormControl>
                <RadioGroup
                    row
                    value={startType}
                    onChange={e => setStartType(e.target.value)}
                >
                    <FormControlLabel value={'address'} control={<Radio/>}
                                      label={t('tickets.distanceCalculation.customerAddress')}/>
                    <FormControlLabel value={'own'} control={<Radio/>}
                                      label={t('tickets.distanceCalculation.customEntry')}/>
                </RadioGroup>
            </FormControl>
            {startType === 'address' && <FormControl fullWidth margin='none' sx={{mb: 2, mt: 1}} error={errorList.hasOwnProperty('start')}>
                <InputLabel>{t('tickets.fields.address')}</InputLabel>
                <Select
                    value={startAddressId}
                    onChange={(e) => setStartAddressId(e.target.value)}
                    input={<OutlinedInput label={t('tickets.fields.address')}/>}
                    MenuProps={MenuProps}
                >
                    {addresses.map((a) => (<MenuItem key={a.id} value={a.id}>
                            <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                                <Chip size='small' sx={{ml: 2}}
                                      label={t('customers.address.main')}/> : null}</React.Fragment>}
                                          secondary={formatAddress(a)}/>
                        </MenuItem>
                    ))}
                </Select>
                {errorList.hasOwnProperty('start') && <FormHelperText>{errorList.start}</FormHelperText>}
            </FormControl>}
            {startType === 'own' && <TextField
                fullWidth
                error={errorList.hasOwnProperty('start')}
                helperText={errorList.start}
                sx={{mb: 2, mt: 1}}
                label={t('tickets.fields.address')}
                value={startCoordinates?.label || ''}
                onChange={(e) => setStartCoordinates((c) => ({
                    lat: null,
                    lng: null,
                    label: e.target.value
                }))}
                InputProps={{
                    endAdornment: <InputAdornment position="end" sx={{mr: 1}}>
                        {!coordsLoading &&
                            <IconButton edge="end" disabled={!permission} onClick={() => determineCoordinates('start')}>
                                <LocationSearchingIcon/>
                            </IconButton>}
                        {coordsLoading && <CircularProgress size={20}/>}
                    </InputAdornment>,
                }}
            />}
            <Typography variant='h6'>{t('tickets.distanceCalculation.end')}</Typography>
            <FormControl>
                <RadioGroup
                    row
                    value={endType}
                    onChange={e => setEndType(e.target.value)}
                >
                    <FormControlLabel value={'address'} control={<Radio/>}
                                      label={t('tickets.distanceCalculation.customerAddress')}/>
                    <FormControlLabel value={'own'} control={<Radio/>}
                                      label={t('tickets.distanceCalculation.customEntry')}/>
                </RadioGroup>
            </FormControl>
            {endType === 'address' && <FormControl fullWidth margin='none' sx={{mt: 1}} error={errorList.hasOwnProperty('end')}>
                <InputLabel>{t('tickets.fields.address')}</InputLabel>
                <Select
                    value={endAddressId}
                    onChange={(e) => setEndAddressId(e.target.value)}
                    input={<OutlinedInput label={t('tickets.fields.address')}/>}
                    MenuProps={MenuProps}
                >
                    {addresses.map((a) => (<MenuItem key={a.id} value={a.id}>
                            <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                                <Chip size='small' sx={{ml: 2}}
                                      label={t('customers.address.main')}/> : null}</React.Fragment>}
                                          secondary={formatAddress(a)}/>
                        </MenuItem>
                    ))}
                </Select>
                {errorList.hasOwnProperty('end') && <FormHelperText>{errorList.end}</FormHelperText>}
            </FormControl>}
            {endType === 'own' && <TextField
                error={errorList.hasOwnProperty('end')}
                helperText={errorList.end}
                fullWidth
                sx={{mb: 2, mt: 1}}
                label={t('tickets.fields.address')}
                value={endCoordinates?.label || ''}
                onChange={(e) => setEndCoordinates((c) => ({
                    lat: null,
                    lng: null,
                    label: e.target.value
                }))}
                InputProps={{
                    endAdornment: <InputAdornment position="end" sx={{mr: 1}}>
                        {!coordsLoading &&
                            <IconButton edge="end" disabled={!permission} onClick={() => determineCoordinates('end')}>
                                <LocationSearchingIcon/>
                            </IconButton>}
                        {coordsLoading && <CircularProgress size={20}/>}
                    </InputAdornment>,
                }}
            />}
            </CheckVersion>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose()} autoFocus disabled={routeLoading}>
                {t('cancel')}
            </Button>
            <CheckVersion disableFallback minimumVersion='extended'>
            <LoadingButton
                onClick={() => calculate()}
                loading={routeLoading}
                loadingPosition="start"
                startIcon={<Calculate/>}
                variant="contained"
                color='primary'
            >
                {t('tickets.distanceCalculation.calculate')}
            </LoadingButton>
            </CheckVersion>
        </DialogActions>
    </Dialog>;
}

export default CalculateDistanceDialog;
