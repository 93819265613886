import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";
import Api from "../../core/Api";
import moment from "moment";
import { OpenInNew, Replay, Visibility, VisibilityOff } from "@mui/icons-material";
import { push } from "react-router-redux";
import { useDispatch } from "react-redux";
import { fetchNotificationCount } from "../../actions/notificationActions";

const NotificationsList = ({onClick}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [notificationList, setNotificationList] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [fetching, setFetching] = useState(false);

    const fetchNotifications = useCallback(() => {
        setFetching(true)
        Api.fetch({
            endpoint: 'notifications',
            method: 'GET',
            parameter: {
                page: page,
                size: 20,
                sort: `createdAt_desc`,
            },
        }).then((res) => {
            setTotal(parseInt(res.headers['x-total-count'] || "0", 10));
            setNotificationList(res.response)
            dispatch(fetchNotificationCount())
        }, () => {
        }).then(() => setFetching(false));
    }, [page, setFetching, setTotal, setNotificationList, dispatch])
    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    const open = (id, link) => {
        dispatch(push(link));
        if (typeof onClick === 'function') {
            onClick();
        }
    }

    const read = (id) => {
        setFetching(true);
        Api.fetch({
            endpoint: `notifications/${id}/read`,
            method: 'POST',
        }).then((res) => {
            setNotificationList(notificationList.map(item => item.id === id ? {...item, read: true} : item))
            setTimeout(() => {
                dispatch(fetchNotificationCount())
            }, 11000);
        }, () => {
        }).then(() => setFetching(false));
    }

    const unread = (id) => {
        setFetching(true);
        Api.fetch({
            endpoint: `notifications/${id}/unread`,
            method: 'POST',
        }).then((res) => {
            setNotificationList(notificationList.map(item => item.id === id ? {...item, read: false} : item))
            setTimeout(() => {
                dispatch(fetchNotificationCount())
            }, 11000);
        }, () => {
        }).then(() => setFetching(false));
    }

    const readAll = () => {
        setFetching(true);
        Api.fetch({
            endpoint: `notifications/read-all`,
            method: 'POST',
        }).then((res) => {
            setNotificationList(notificationList.map(item => ({...item, read: true})))
            setTimeout(() => {
                dispatch(fetchNotificationCount())
            }, 11000);
        }, () => {
        }).then(() => setFetching(false));
    }
    const notifications = notificationList.map((n) => {
        let reference;
        if (n.referenceValue) {
            if (n.referenceType === 'ticket') {
                reference = `/tickets/show/${n.referenceValue}`
            }
            if (n.referenceType === 'ticket-files') {
                reference = `/tickets/show/${n.referenceValue}/files`
            }
            if (n.referenceType === 'device-files') {
                reference = `/devices/show/${n.referenceValue}/files`
            }
            if (n.referenceType === 'import') {
                reference = `/data/import/show/${n.referenceValue}?openHistory`
            }
            if (n.referenceType === 'export') {
                reference = `data/webspace`
            }
        }

        return <TableRow key={n.id} sx={{
            borderLeft: n.read ? 'inherit' : '5px solid ' + theme.palette.info.light,
        }}>
            <TableCell>{n.message}</TableCell>
            <TableCell>{moment(n['@timestamp']).fromNow()}</TableCell>
            <TableCell>
                {reference && <IconButton onClick={() => open(n.id, reference)}><OpenInNew/></IconButton>}
                <IconButton onClick={() => n.read ? unread(n.id) : read(n.id)}>{n.read ? <VisibilityOff/> :
                    <Visibility/>}</IconButton>
            </TableCell>
        </TableRow>
    });

    return <>
        {!fetching && <>
            <Toolbar variant='dense'>
                <Button onClick={readAll} size='small'
                        startIcon={<Visibility/>}>{t('notifications.markAllAsRead')}</Button>
                <Box sx={{flexGrow: 1}}/>
                <IconButton onClick={fetchNotifications}><Replay/></IconButton>
            </Toolbar>
            <TableContainer>
                <Table>
                    <TableBody>
                        {notifications}
                    </TableBody>
                </Table>
                {total <= 0 &&
                    <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                        <ConfirmationNumberIcon sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                            <Typography variant='body2'>{t('notifications.noNotificationFound')}</Typography>
                        </Box>
                    </Box>}
                {total > 0 && <TablePagination
                    sx={{position: 'sticky', bottom: 0, backgroundColor: theme.palette.background.paper}}
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={total}
                    rowsPerPage={20}
                    page={page - 1}
                    onPageChange={(e, p) => {
                        setPage(p + 1);
                    }}
                />}
            </TableContainer>
        </>}
        {fetching && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
            <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        </Box>}
    </>;
}

export default NotificationsList;
