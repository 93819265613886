import {CALL_API} from '../middleware/api'

export const NOTIFICATION_COUNT_REQUEST = 'NOTIFICATION_COUNT_REQUEST';
export const NOTIFICATION_COUNT_SUCCESS = 'NOTIFICATION_COUNT_SUCCESS';
export const NOTIFICATION_COUNT_FAILURE = 'NOTIFICATION_COUNT_FAILURE';
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';

export function fetchNotificationCount() {
    return {
        [CALL_API]: {
            endpoint: 'notifications',
            method: 'GET',
            parameter: {
                page: 1,
                size: 20,
                sort: `createdAt_desc`,
                filter: {
                    unread: 'true'
                }
            },
            types: [NOTIFICATION_COUNT_SUCCESS, NOTIFICATION_COUNT_FAILURE, NOTIFICATION_COUNT_REQUEST]
        }
    }
}

export function notificationReceived(data) {
    return {
        type: NOTIFICATION_RECEIVED,
        payload: data
    }
}

