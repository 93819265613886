import {Box, Checkbox, IconButton, TableCell, TableRow, TextField} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Schema from "validate";
import DeleteDialog from "../../../Utils/DeleteDialog";
import {Delete} from "@mui/icons-material";
import CheckPermissions from "../../../Utils/CheckPermissions";
import {ColorPicker} from "mui-color";
const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String
        },
        color: {
            type: String,
            required: true,
            use: {hexColor},
        },
    })
}
const hexColor = val => /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(val)

const Row = ({tag, onChange, onDelete}) => {
    const {t} = useTranslation();
    const [errorList, setErrorList] = React.useState({});
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const onDeleteClick = () => {
        setDeleteOpen(false);
        onDelete();
    }

    useEffect(() => {
        const v = getValidationSchema(t);
        const errors = v.validate({name: tag.name, color: tag.color})
        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setErrorList(errorObject)
            tag.hasErrors = true;
            onChange(tag)
        } else {
            tag.hasErrors = false;
            setErrorList({})
            onChange(tag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag.name, tag.color, t]);

    return <React.Fragment>
        <TableRow>
            <TableCell width={300}>
                <TextField
                    fullWidth
                    variant='standard'
                    placeholder={t('settings.tags.name')}
                    value={tag.name}
                    onChange={(e) => {
                        tag.name = e.target.value
                        tag.hasChanges = true
                        onChange(tag)
                    }}
                    error={errorList.hasOwnProperty('name')}
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    variant='standard'
                    placeholder={t('settings.tags.description')}
                    value={tag.description}
                    onChange={(e) => {
                        tag.description = e.target.value
                        tag.hasChanges = true
                        onChange(tag)
                    }}
                />
            </TableCell>
            <TableCell>
                    <Box display='flex' flexDirection='row' sx={{width: 'max-content'}}>
                        <ColorPicker value={tag.color} hideTextfield={true} onChange={({hex}) => {
                            tag.color = '#' + hex
                            tag.hasChanges = true
                            onChange(tag)
                        }}/>
                        <TextField value={tag.color} variant="standard"/>
                    </Box>
            </TableCell>
            <TableCell>
                <Checkbox checked={tag.displayInTickets || false} onChange={(e) => {
                    tag.displayInTickets = e.target.checked
                    tag.hasChanges = true
                    onChange(tag)
                }}/>
            </TableCell>
            <TableCell width={50}>
                <CheckPermissions list={['tags.delete']}>
                    <IconButton onClick={() => setDeleteOpen(true)} align="right"><Delete/></IconButton>
                </CheckPermissions>
            </TableCell>
        </TableRow>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('settings.user.deleteTag')}
                      handleClose={() => setDeleteOpen(false)}
                      description={t('settings.tags.deleteTagDescription', {name: tag.name || ''})}
                      open={deleteOpen}/>
    </React.Fragment>
}

export default Row
