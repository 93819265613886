class ApplicationWebsocket {

    reconnectHandler = null

    callbacks = {}

    constructor() {
        this.socket = null
    }

    connect() {
        console.log('Connecting to websocket')
        if (!this.socket) {
            const url = new URL(process.env.REACT_APP_WEBSOCKET_URL)
            url.searchParams.append('id_token', localStorage.getItem('id_token'))
            this.socket = new WebSocket(url.toString());
            this.reconnectHandler = () => {
                setTimeout(() => {
                    //get existing handlers
                    this.socket = null
                    this.connect()
                    for (const eventName in this.callbacks) {
                        this.socket.addEventListener(eventName, this.callbacks[eventName])
                    }
                }, 10000)
            }
            this.socket.addEventListener('close', this.reconnectHandler);
        }
    }

    disconnect() {
        console.log('Disconnecting from websocket')
        if (this.socket) {
            this.socket.removeEventListener('close', this.reconnectHandler)
            this.socket.close()
            this.socket = null
        }
    }

    send(message) {
        console.log('Sending message to websocket')
        if (this.socket) {
            this.socket.send(JSON.stringify(message))
        }
    }

    on(eventName, callback) {
        this.callbacks[eventName] = callback
        if (this.socket) {
            this.socket.addEventListener(eventName, callback)
        }
    }
}

export {ApplicationWebsocket}