import { Button, Dialog, DialogActions, DialogTitle, IconButton, InputAdornment, TextField } from "@mui/material";
import { Close, FileOpen } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import FileBrowser from "./FileBrowser";

const FilePicker = ({path, onSelect, type, accept, placeholder = null, removable = false}) => {
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();

    const onInternalSelect = (path) => {
        if (path) {
            setOpen(false);
        }
        onSelect(path);
    }

    return <React.Fragment>
        <TextField
            onClick={() => setOpen(true)}
            fullWidth
            value={path || ''}
            label={placeholder}
            placeholder={placeholder || t('filePicker.selectFile')}
            margin='normal'
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                            <FileOpen/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
        {removable && path && <Button onClick={() => onSelect(null)}>{t('filePicker.deleteSelection')}</Button>}
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
            <DialogTitle>
                {t('filePicker.selectFile')}
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>

            </DialogTitle>
            <FileBrowser accept={accept} onSelect={onInternalSelect} type={type} selected={path}/>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}
export default FilePicker
