import {Card, CardContent, CardHeader, IconButton} from "@mui/material";
import {Delete, DragHandle} from "@mui/icons-material";
import {Container, Draggable} from "react-smooth-dnd";
import React from "react";
import {useTranslation} from "react-i18next";
import DeleteDialog from "../../../Utils/DeleteDialog";
import {formatAddressOnly} from "../../../../helper/formatAddress";
import {arrayMoveImmutable} from "array-move";
import Location from "./Location";

const Address = ({address, customerData, onChange, devices}) => {
    const {t} = useTranslation()
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const addressData = customerData?.addresses.find((a) => a.id === address.addressId)

    return <>
        <Draggable key={address.position}>
            <Card variant='outlined' sx={{mb: 1}}>
                <CardHeader
                    avatar={<IconButton><DragHandle className='drag-handle-1'/></IconButton>}
                    title={customerData ? `${customerData.number}: ${customerData.name} - ${addressData?.name}` :
                        <i>{t('deleted')}</i>}
                    subheader={formatAddressOnly(addressData)}
                    action={<IconButton onClick={() => setDeleteDialogOpen(true)}><Delete/></IconButton>}
                />
                <CardContent>
                    <Container dragHandleSelector=".drag-handle-2" lockAxis="y"
                               onDrop={({removedIndex, addedIndex}) => {
                                   address.locations = arrayMoveImmutable(address.locations, removedIndex, addedIndex).map((value, index) => {
                                       value.position = index;
                                       return value
                                   });
                                   onChange(address)
                               }}>
                        {address.locations.filter((l) => addressData?.locations.find(al => al.id === l.locationId)).map((location) => {
                            return <Location location={location}
                                             devices={devices}
                                             locationData={addressData?.locations.find(al => al.id === location.locationId)}
                                             onChange={(updatedLocation) => {
                                                 const index = address.locations.findIndex(a => a.locationId === location.locationId)
                                                 if (updatedLocation) {
                                                     address.locations[index] = updatedLocation
                                                 } else {
                                                     address.locations.splice(index, 1)
                                                 }

                                                 if (address.locations.length === 0) {
                                                     onChange(null)
                                                     return
                                                 }

                                                 onChange(address)
                                             }}/>
                        })}
                    </Container>
                </CardContent>
            </Card>
        </Draggable>
        <DeleteDialog
            onDelete={() => {
                onChange(null)
                setDeleteDialogOpen(false)
            }} isDeleting={false}
            title={t('settings.tours.deleteTourAddress')}
            handleClose={() => setDeleteDialogOpen(false)}
            description={t('settings.tours.deleteTourAddressDescription', {name: customerData ? `${customerData.number}: ${customerData.name} - ${addressData?.name}` : t('deleted')})}
            open={deleteDialogOpen}/>
    </>
}

export default Address