import React from 'react';
import { IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";

const Contact = ({contact, onChange, onDelete, errors}) => {
    const {t} = useTranslation();

    return <TableRow>
        <TableCell>
            <TextField
                fullWidth
                margin='none'
                size='small'
                required
                error={errors.hasOwnProperty('name')}
                label={t('customers.fields.name')}
                placeholder={t('customers.fields.name')}
                value={contact.name}
                onChange={(e) => {
                    contact.name = e.target.value
                    onChange(contact)
                }}
            />
        </TableCell>
        <TableCell>
            <TextField
                fullWidth
                margin='none'
                size='small'
                label={t('customers.fields.email')}
                placeholder={t('customers.fields.email')}
                value={contact.email}
                onChange={(e) => {
                    contact.email = e.target.value
                    onChange(contact)
                }}
            />
        </TableCell>
        <TableCell>
            <TextField
                fullWidth
                margin='none'
                size='small'
                label={t('customers.fields.telephone')}
                placeholder={t('customers.fields.telephone')}
                value={contact.telephone}
                onChange={(e) => {
                    contact.telephone = e.target.value
                    onChange(contact)
                }}
            />
        </TableCell>
        <TableCell>
            <IconButton onClick={onDelete}>
                <Delete/>
            </IconButton>
        </TableCell>
    </TableRow>;
};

export default Contact;
