import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {Link, useLocation} from "react-router-dom";
import {history} from '../../core';
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import i18n from "../../i18n/i18n";
import {useSelector} from "react-redux";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import SearchIcon from '@mui/icons-material/Search';
import {
    alpha,
    Avatar,
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    InputBase,
    LinearProgress,
    Link as BrowserLink,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import {
    Android,
    Apple,
    ChevronLeft as ChevronLeftIcon,
    Close,
    Dashboard,
    Devices,
    ExitToApp,
    Folder,
    Language,
    Menu as MenuIcon,
    People,
    Person,
    QuestionAnswer,
    Settings
} from "@mui/icons-material";
import {styled} from '@mui/material/styles';
import logo from '../../images/small-logo.png';
import {useTheme} from "@mui/system";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckPermissions from "../Utils/CheckPermissions";
import moment from "moment";
import Api from "../../core/Api";
import qs from "query-string";
import Notifications from "./Notifications";
import {useDebounce} from "../Utils/useDebounce";
import md5 from 'md5'
import Changelog from "./Changelog";
import TicketRequests from "./TicketRequests";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'none',
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        display: 'block',
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const ListItemLink = (props) => {
    const {to} = props;
    const pathname = history.location.pathname;

    let selected = pathname.indexOf(to) === 0;
    if (props.exact) {
        selected = history.location.pathname === to;
    }

    const listItemProps = Object.assign({}, props);
    delete listItemProps.exact;

    return (
        <ListItem {...listItemProps} selected={selected}>
            {props.children}
        </ListItem>
    );
};

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Navigation = forwardRef((props, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [menuOpen, setMenuOpen] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [languageSelectOpen, setLanguageSelectOpen] = React.useState(false);
    const [ticketsRequestDialogOpen, setTicketsRequestDialogOpen] = React.useState(false);
    const [notificationDialogOpen, setNotificationDialogOpen] = React.useState(false);

    const [searchOpen, setSearchOpen] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [searching, setSearching] = React.useState(false);
    const [debouncedSearchString, setDebouncedSearchString] = useDebounce('', 300);
    const [results, setResults] = React.useState([]);
    const [totalResults, setTotalResults] = React.useState(0);
    const {t} = useTranslation()

    useEffect(() => {
        setDebouncedSearchString(searchString);
    }, [searchString, setDebouncedSearchString]);

    const setCookie = useCookies(['cookies'])[1];

    const setLanguage = (lang) => {
        setCookie('locale', lang)
        i18n.changeLanguage(lang);
    }

    const neededSettingsPermissions = [
        'tags',
        'properties',
        'user',
        'groups',
        'roles',
        'categories',
        'tasks',
        'status',
        'pdf',
        'supportTicket',
        'reminder',
        'devicesType',
        'deviceComponentTypes',
        'numberRange',
        'qrCode',
        'customerMap',
        'fourVending',
        'telegram'
    ]

    const ticketRequestData = useSelector((state) => state.requests)
    const requestCount = useSelector(state => state.requestCount)
    const notificationCount = useSelector((state) => state.notifications)?.total || 0;
    const user = useSelector((state) => state.user)
    const [currentVersions, setCurrentVersions] = React.useState({});
    const [changelogOpen, setChangelogOpen] = React.useState(false);

    const [queryHasPanelOpen, setQueryHasPanelOpen] = React.useState(false);

    let gravatarUrl = null;
    if (user.user.email) {
        gravatarUrl = 'https://www.gravatar.com/avatar/' + md5(user.user.email.trim().toLowerCase()) + '?d=404'
    }

    useEffect(() => {
        Api.fetch({
            endpoint: 'public/changelog/current',
            auth: false
        }).then((res) => {
            setCurrentVersions(res.response)
        }, () => {
        })
    }, [])

    useEffect(() => {
        if (!searchOpen) {
            return;
        }
        setSearching(true);
        Api.fetch({
            endpoint: 'search',
            parameter: {
                filter: {
                    query: debouncedSearchString
                }
            }
        }).then((res) => {
            setResults(res.response)
            setTotalResults(parseInt(res.headers['x-total-count'] || "0", 10));
        }, () => {
        }).then(() => setSearching(false));
    }, [debouncedSearchString, searchOpen])

    const location = useLocation();
    useEffect(() => {
        const parsedQuery = qs.parse(location.search);
        if (parsedQuery?.openNotificationPanel === null) {
            setQueryHasPanelOpen(true);
        } else {
            setQueryHasPanelOpen(false);
        }
    }, [location.search]);

    useEffect(() => {
        if (queryHasPanelOpen) {
            setNotificationDialogOpen(true);
        }
    }, [queryHasPanelOpen]);

    useImperativeHandle(ref, () => ({
        showNotificationDialog() {
            setNotificationDialogOpen(true);
        }

    }));

    const renderResult = (result) => {
        let link = ''
        if (result.type === 'ticket') {
            link = '/tickets/show/' + result.id
        } else if (result.type === 'customer') {
            link = '/customers/show/' + result.id
        } else if (result.type === 'device') {
            link = '/devices/show/' + result.id
        }

        const type = t('navigation.resultTypes.' + result.type)

        return <ListItemButton to={link} component={Link} onClick={() => setSearchOpen(false)}>
            <ListItemText primary={<><Chip label={type} size='small'/> {`#${result.number}: ${result.title}`}</>}
                          secondary={link}/>
        </ListItemButton>
    }


    return (<React.Fragment>
            <Box sx={{display: 'flex'}}>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(true)}
                            edge="start"
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Avatar sx={{marginRight: theme.spacing(2)}} src={logo} alt="logo" variant='square'/>
                        <Typography variant="h6" noWrap component="div">
                            VeCoDesk
                        </Typography>
                        <Box flexGrow={1}/>
                        <Search onClick={() => setSearchOpen(true)}>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                readOnly
                                placeholder={t('navigation.search')}
                            />
                        </Search>
                        <IconButton
                            onClick={(e) => setMenuOpen(e.currentTarget)}
                            color="inherit"
                        >
                            <Badge
                                overlap="circular"
                                max={99}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={ticketRequestData.total + notificationCount} color="secondary">
                                <Avatar src={gravatarUrl}>{user.user.givenName[0]}{user.user.familyName[0]}</Avatar>
                            </Badge>
                        </IconButton>
                    </Toolbar>
                    {Boolean(requestCount.count) && <LinearProgress sx={{height: '3px'}} color="secondary"/>}
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={() => setOpen(false)}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </DrawerHeader>
                    <List>
                        <ListItemLink button component={Link} key='dashboard'
                                      onClick={() => isMobile ? setOpen(false) : null} to={'/dashboard'} exact>
                            <ListItemIcon><Dashboard/></ListItemIcon>
                            <ListItemText primary={t('navigation.dashboard')}/>
                        </ListItemLink>
                        <CheckPermissions prefix={false} list={['tickets.read']}>
                            <ListItemLink button component={Link} key='tickets'
                                          onClick={() => isMobile ? setOpen(false) : null} to={'/tickets'}>
                                <ListItemIcon><ConfirmationNumberIcon/></ListItemIcon>
                                <ListItemText primary={t('navigation.tickets')}/>
                            </ListItemLink>
                        </CheckPermissions>
                        <CheckPermissions prefix={false} list={['customers.read']}>
                            <ListItemLink button component={Link} key='customers'
                                          onClick={() => isMobile ? setOpen(false) : null} to={'/customers'}>
                                <ListItemIcon><People/></ListItemIcon>
                                <ListItemText primary={t('navigation.customers')}/>
                            </ListItemLink>
                        </CheckPermissions>
                        <CheckPermissions prefix={false} list={['devices.read']}>
                            <ListItemLink button component={Link} key='devices'
                                          onClick={() => isMobile ? setOpen(false) : null} to={'/devices'}>
                                <ListItemIcon><Devices/></ListItemIcon>
                                <ListItemText primary={t('navigation.devices')}/>
                            </ListItemLink>
                        </CheckPermissions>
                        <CheckPermissions prefix={true} list={['import', 'export']}>
                            <ListItemLink button component={Link} key='data'
                                          onClick={() => isMobile ? setOpen(false) : null} to={'/data'}>
                                <ListItemIcon><Folder/></ListItemIcon>
                                <ListItemText primary={t('navigation.data')}/>
                            </ListItemLink>
                        </CheckPermissions>
                        <CheckPermissions prefix={true} list={neededSettingsPermissions}>
                            <ListItemLink button component={Link} key='settings'
                                          onClick={() => isMobile ? setOpen(false) : null} to={'/settings'}>
                                <ListItemIcon><Settings/></ListItemIcon>
                                <ListItemText primary={t('navigation.settings')}/>
                            </ListItemLink>
                        </CheckPermissions>
                    </List>
                    <Divider/>
                    {currentVersions.ui &&
                        <Box textAlign='center' sx={{mt: 1}}><BrowserLink onClick={() => setChangelogOpen(true)}
                                                                          sx={{color: (t) => t.palette.text.secondary}}>
                            <Typography variant='body2'>{currentVersions.ui.version}</Typography>
                            <Typography sx={!open ? {display: 'none'} : null}
                                        variant='caption'>({moment(currentVersions.ui.createdAt).fromNow()})</Typography>
                        </BrowserLink></Box>}
                    <Box sx={{display: !open ? 'none' : 'block', textAlign: 'center', mt: 1}}>
                        <Button
                            component={BrowserLink}
                            target='_blank'
                            href='https://play.google.com/store/apps/details?id=de.vecodesk.app'
                            startIcon={<Android/>}>Android</Button>
                        <Button
                            component={BrowserLink}
                            target='_blank'
                            href='https://apps.apple.com/de/app/vecodesk/id1659551306'
                            startIcon={<Apple/>}>iOS</Button>
                    </Box>
                    <Box
                        sx={{
                            display: open ? 'none' : 'flex',
                            'flexDirection': 'column',
                            textAlign: 'center',
                            mt: 1
                        }}>
                        <Box sx={{textAlign: 'center'}}>
                            <IconButton
                                component={BrowserLink}
                                target='_blank'
                                href='https://play.google.com/store/apps/details?id=de.vecodesk.app'
                            ><Android/></IconButton>
                        </Box>
                        <Box sx={{textAlign: 'center'}}>
                            <IconButton
                                component={BrowserLink}
                                target='_blank'
                                href='https://apps.apple.com/de/app/vecodesk/id1659551306'
                            ><Apple/></IconButton>
                        </Box>
                    </Box>
                </Drawer>
            </Box>
            <Menu PaperProps={{style: {width: 250}}} open={Boolean(menuOpen)} anchorEl={menuOpen}
                  onClose={() => setMenuOpen(null)}>
                <MenuItem>
                    <ListItemText
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}
                        primary={`${user.user.givenName} ${user.user.familyName}`}
                        secondary={user.user.email}
                    />
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => {
                    setTicketsRequestDialogOpen(true);
                    setMenuOpen(null)
                }}>
                    <ListItemIcon>
                        <QuestionAnswer fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>{t('navigation.requests')}</ListItemText>
                    <Chip label={ticketRequestData.total} size='small'/>
                </MenuItem>
                <MenuItem onClick={() => {
                    setNotificationDialogOpen(true);
                    setMenuOpen(null)
                }}>
                    <ListItemIcon>
                        <NotificationsIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>{t('notifications.title')}</ListItemText>
                    <Chip label={notificationCount} size='small'/>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => {
                    setLanguageSelectOpen(true);
                    setMenuOpen(null);
                }}>
                    <ListItemIcon>
                        <Language fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>{t('navigation.language')}</ListItemText>
                </MenuItem>
                <MenuItem component={Link} to='/profile' onClick={() => setMenuOpen(null)}>
                    <ListItemIcon>
                        <Person fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>{t('navigation.profile')}</ListItemText>
                </MenuItem>
                <MenuItem component={Link} to='/login'>
                    <ListItemIcon>
                        <ExitToApp fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>{t('navigation.logout')}</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={searchOpen}
                onClose={() => setSearchOpen(false)}
            >
                <DialogContent sx={{marginTop: '20px', paddingTop: 0}}>
                    <TextField
                        sx={{
                            position: 'sticky',
                            zIndex: 1,
                            top: 0,
                            backgroundColor: theme.palette.background.paper
                        }}
                        autoFocus
                        fullWidth
                        placeholder={t('navigation.searchExample')}
                        margin='none'
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary"/>
                                </InputAdornment>
                            ),
                            endAdornment: searching ? <CircularProgress color="primary" size={20}/> : null,
                        }}
                    />
                    {(!searching && !results.length) &&
                        <Box sx={{mt: 2, textAlign: 'center', color: theme.palette.grey[400]}}>
                            <SearchIcon sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                            <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                                <Typography variant='body2'>{t('navigation.noResults')}</Typography>
                            </Box>
                        </Box>}
                    {searching && <Box sx={{textAlign: 'center', py: 2}}>
                        <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                    </Box>}
                    {(!searching && !!results.length) && <List
                        subheader={
                            <ListSubheader sx={{top: '56px'}}>
                                {t('navigation.searchResults', {total: totalResults})}
                            </ListSubheader>
                        }
                    >
                        {results.map((result) => renderResult(result))}
                    </List>}
                </DialogContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={languageSelectOpen}
                onClose={() => setLanguageSelectOpen(false)}
            >
                <DialogTitle>{t('navigation.selectLanguage')}</DialogTitle>
                <DialogContent>
                    <Select
                        variant='outlined'
                        fullWidth
                        value={i18n.language}
                        onChange={(e) => setLanguage(e.target.value)}
                    >
                        <MenuItem value={'en'}>{t('languages.english')}</MenuItem>
                        <MenuItem value={'de'}>{t('languages.german')}</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLanguageSelectOpen(false)} color="primary" autoFocus>
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={ticketsRequestDialogOpen}
                onClose={() => setTicketsRequestDialogOpen(false)}
            >
                <DialogTitle>{t('navigation.requests')}</DialogTitle>
                {ticketsRequestDialogOpen && <TicketRequests onClose={() => setTicketsRequestDialogOpen(false)}/>}
                <DialogActions>
                    <Button onClick={() => setTicketsRequestDialogOpen(false)} color="primary" autoFocus>
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={notificationDialogOpen}
                onClose={() => setNotificationDialogOpen(false)}
            >
                <DialogTitle>
                    {t('notifications.title')}
                    <IconButton
                        aria-label="close"
                        onClick={() => setNotificationDialogOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close/>
                    </IconButton>
                </DialogTitle>
                {notificationDialogOpen && <Notifications onClose={() => setNotificationDialogOpen(false)}/>}
                <DialogActions>
                    <Button onClick={() => setNotificationDialogOpen(false)} color="primary" autoFocus>
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={changelogOpen}
                onClose={() => setChangelogOpen(false)}
            >
                <DialogTitle>
                    {t('navigation.changelog.title')}
                    <IconButton
                        aria-label="close"
                        onClick={() => setChangelogOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close/>
                    </IconButton>
                </DialogTitle>
                {changelogOpen && <Box sx={{mx: 2}}><Changelog/></Box>}
                <DialogActions>
                    <Button onClick={() => setChangelogOpen(false)} color="primary" autoFocus>
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
        ;
});

export default Navigation
