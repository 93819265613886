import {
    CUSTOMER_MAP_SETTING_FAILURE,
    CUSTOMER_MAP_SETTING_REQUEST,
    CUSTOMER_MAP_SETTING_SUCCESS
} from "../actions/settingsActions";

export function customerMapReducer(state = {
    isFetching: false,
    error: null,
    settings: {}
}, action) {
    switch (action.type) {
        case CUSTOMER_MAP_SETTING_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case CUSTOMER_MAP_SETTING_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case CUSTOMER_MAP_SETTING_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                settings: action.response
            });
        default:
            return state
    }
}

