import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Select,
    Switch as MuiSwitch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {
    Cancel,
    Check,
    Close,
    Delete,
    Edit,
    LocalShipping,
    MoreVert,
    QrCode2,
    Replay,
    Save,
    Settings,
    TextFields
} from "@mui/icons-material";
import {Link as RouterLink, Link as BrowserLink} from "react-router-dom";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../../core/Api";
import {fetchTagList} from "../../../../actions/tagActions";
import {fetchPropertyList} from "../../../../actions/propertyActions";
import {push} from "@lagunovsky/redux-react-router";
import {fetchDeviceTypes} from "../../../../actions/deviceTypeActions";
import FileBrowser from "../../../Utils/FileBrowser";
import {useTheme} from "@mui/system";
import moment from "moment";
import CheckPermissions from "../../../Utils/CheckPermissions";
import {LoadingButton} from "@mui/lab";
import {cloneDeep} from "lodash";
import Schema from "validate";
import TagSelect from "../../../Utils/TagSelect";
import PropertyInput from "../../../Utils/PropertyInput";
import CustomerList from "../../Utils/CustomerList";
import History from "./History";
import {formatAddress} from "../../../../helper/formatAddress";
import TicketList from "../../../Tickets/Utils/TicketList";
import DeleteDialog from "../../../Utils/DeleteDialog";
import {useMatch} from "react-router";

const geDeviceDataSchema = (t) => {
    return new Schema({
        number: {
            required: true,
            type: String,
            message: t('devices.validation.number')
        },
        brand: {
            required: true,
            type: String,
            message: t('devices.validation.brand'),
        },
        model: {
            required: true,
            type: String,
            message: t('devices.validation.brand'),
        }
    })
}

const Show = ({deviceId, customer, addressId}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loading, setLoading] = React.useState(false);
    const [device, setDevice] = React.useState({});
    const [moveCustomer, setMoveCustomer] = React.useState({});

    const [errors, setErrors] = React.useState({});
    const [saving, setSaving] = React.useState(false);
    const [menu, setMenu] = React.useState(null);
    const [editDeviceData, setEditDeviceData] = React.useState(null);
    const [editDeviceProperties, setEditDeviceProperties] = React.useState(null);

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const [moveData, setMoveData] = React.useState(null);

    const [createQrCodeSettings, setCreateQrCodeSettings] = React.useState(null);
    const [qrCodeIsCreating, setQrCodeIsCreating] = React.useState(false);

    const handleQrCodeSettingsChange = useCallback((key, value) => {
        setCreateQrCodeSettings((d) => ({
            ...d,
            [key]: value
        }))
    }, [setCreateQrCodeSettings]);

    const handleChange = (key, value) => {
        setEditDeviceData({
            ...editDeviceData,
            [key]: value
        })
    }

    const createQrCodes = () => {
        setQrCodeIsCreating(true);

        Api.fetch({
            endpoint: 'actions',
            method: 'POST',
            body: {
                action: 'device-qr-code',
                payload: {
                    deviceIds: [device.id],
                    type: createQrCodeSettings.type,
                    saveZipToFiles: createQrCodeSettings.saveZipToFiles
                }
            }
        }).then(() => {
            setCreateQrCodeSettings(null)
            dispatch({
                type: 'ADD_ALERT',
                message: t('devices.qr.qrCodeWillBeCreated'),
                style: 'success'
            })
        }, () => {
        }).then(() => setQrCodeIsCreating(false))
    }

    const onDeleteClick = () => {
        setIsDeleting(true);

        Api.fetch({
            endpoint: 'devices/' + device.id,
            method: "DELETE"
        }).then(() => {
            dispatch(push('/customers/show/' + customer.id + '/addresses/' + addressId + '/devices'));
        }, () => {
        }).then(() => setIsDeleting(false))
    }

    const handleMoveDataChange = useCallback((key, value) => {
        setMoveData({
            ...moveData,
            [key]: value
        })
    }, [setMoveData, moveData])

    const handlePropertyChange = (id, value) => {
        let internalValue = value ? value.toString() : value;
        if (value === '') {
            internalValue = null;
        }

        const properties = editDeviceProperties
        const index = properties.findIndex((property) => property.id === id)

        if (index === -1) {
            properties.push({
                id: id,
                value: internalValue
            })
        } else {
            properties[index]['value'] = internalValue
        }
        setEditDeviceProperties(properties.slice())
    }

    const routeMatch = useMatch('/customers/show/:id/addresses/:addressId/devices/:deviceId/:selectionId');
    const selectionId = routeMatch?.params.selectionId;

    useEffect(() => {
        if (!['tickets', 'properties', 'components', 'files', 'history'].includes(selectionId)) {
            dispatch(push('/customers/show/' + customer.id + '/addresses/' + addressId + '/devices/' + deviceId + '/tickets'))
        }
    }, [selectionId, dispatch, customer.id, addressId, deviceId]);

    const tagList = useSelector((state) => state.tags.list)
    const deviceTypeList = useSelector((state) => state.deviceTypes.list)
    const propertyList = useSelector((state) => state.properties.list)

    const fetchDevice = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: `devices/${deviceId}`,
        }).then((res) => {
            setDevice(res.response)
        }, () => {
        }).then(() => setLoading(false));
    }, [setLoading, deviceId])
    const fetchData = useCallback(() => {
        fetchDevice();
        dispatch(fetchTagList())
        dispatch(fetchPropertyList())
        dispatch(fetchDeviceTypes())
    }, [fetchDevice, dispatch])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {
        if (moveData?.customerId && moveCustomer.id !== moveData?.customerId) {
            Api.fetch({
                endpoint: 'customers/' + moveData.customerId,
            }).then((response) => {
                setMoveCustomer(response.response);
                const addressIds = response.response.addresses.map((address) => address.id);
                if (!addressIds.includes(moveData?.addressId)) {
                    handleMoveDataChange('addressId', addressIds[0]);
                }
            });
        }
    }, [handleMoveDataChange, moveData?.customerId, moveData?.addressId, moveCustomer.id])

    useEffect(() => {
        if (moveData?.addressId) {
            const address = moveCustomer.addresses?.find((address) => address.id === moveData?.addressId);
            if (!address) {
                return;
            }

            const locationIds = address.locations.map((location) => location.id);
            if (moveData?.locationId === null || !locationIds.includes(moveData?.locationId)) {
                handleMoveDataChange('locationId', locationIds[0]);
            }
        }
    }, [handleMoveDataChange, moveData?.locationId, moveData?.addressId, moveCustomer?.addresses])

    const editData = () => {
        setErrors({});
        setSaving(true);

        const v = geDeviceDataSchema(t)
        const errors = v.validate(cloneDeep(editDeviceData))
        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })
            setSaving(false);
            setErrors(errorObject)
            return;
        }

        const saveDevice = cloneDeep(device);
        saveDevice.number = editDeviceData.number;
        saveDevice.brand = editDeviceData.brand;
        saveDevice.model = editDeviceData.model;
        saveDevice.typeId = editDeviceData.typeId;
        saveDevice.tags = editDeviceData.tags;

        Api.fetch({
            endpoint: 'devices/' + saveDevice.id,
            method: "PUT",
            body: saveDevice
        })
            .then(response => {
                setDevice(response.response);
                setEditDeviceData(null);
            }, () => {
            }).then(() => setSaving(false))
    }

    const saveProperties = () => {
        setSaving(true);

        const saveDevice = cloneDeep(device);
        saveDevice.properties = editDeviceProperties.slice();

        Api.fetch({
            endpoint: 'devices/' + saveDevice.id,
            method: "PUT",
            body: saveDevice
        })
            .then(response => {
                setDevice(response.response);
                setEditDeviceProperties(null);
            }, () => {
            }).then(() => setSaving(false))
    }

    const moveDevice = () => {
        setSaving(true);
        const origData = {
            customerId: device?.customerId,
            addressId: device?.addressId,
            locationId: device?.locationId,
        }
        const saveDevice = cloneDeep(device);
        saveDevice.customerId = moveData.customerId;
        saveDevice.locationId = moveData.locationId;
        saveDevice.addressId = moveData.addressId;
        Api.fetch({
            endpoint: 'devices/' + saveDevice.id,
            method: "PUT",
            body: saveDevice
        })
            .then(response => {
                const savedDevice = response.response;
                setDevice(savedDevice);
                setMoveData(null);
                setMoveCustomer({});
                if (origData.customerId !== savedDevice.customerId) {
                    dispatch(push(`/customers/show/${origData.customerId}/addresses/${origData.addressId}/devices`))
                    return;
                }
                if (addressId !== 'all') {
                    dispatch(push(`/customers/show/${savedDevice.customerId}/addresses/${savedDevice.addressId}/devices/${deviceId}/${selectionId}`))
                }
            }, () => {
            }).then(() => setSaving(false))
    }

    const address = customer.addresses.find((a) => a.id === device.addressId);
    const location = address?.locations.find((l) => l.id === device.locationId);
    const type = deviceTypeList.find((t) => t.id === device.typeId);

    const properties = propertyList.filter(property => property.areas.includes('device')).map((p) => {
        let value = '';
        const property = device?.properties?.find(c => c.id === p.id);
        if (property) {
            value = property.value || '';
            if (p.type === 'date' && value.length) {
                value = moment(value).format('DD.MM.YYYY HH:mm')
            } else if (p.type === 'boolean') {
                value = value?.toString() === '1' ? <Check/> : <Cancel/>
            }
        }
        return <TableRow key={p.id}>
            <TableCell>{p.name}</TableCell>
            <TableCell>{p.description}</TableCell>
            <TableCell>{value}</TableCell>
        </TableRow>
    })

    const locations = moveCustomer?.addresses?.find((address) => address.id === moveData?.addressId)?.locations || [];
    return <React.Fragment>
        <Toolbar disableGutters variant='dense'>
            <Button startIcon={<Close/>} to={`/customers/show/${customer.id}/addresses/${addressId}/devices`}
                    component={BrowserLink}>{t('devices.close')}</Button>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchData}><Replay/></IconButton>
        </Toolbar>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
                <Paper variant='outlined'>
                    <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                        <Typography variant='h5'
                                    component='h1'>{device.number}</Typography>
                        <Box flexGrow={1}/>
                        <CheckPermissions list={['devices.write']}>
                            <IconButton sx={{ml: 1}} disabled={loading}
                                        onClick={(e) => setMenu(e.currentTarget)}><MoreVert/></IconButton>
                        </CheckPermissions>
                    </Toolbar>
                    <List disablePadding={true}>
                        <ListItem>
                            <ListItemText primary={t('devices.fields.brand')}
                                          secondary={device.brand || '-'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={t('devices.fields.model')}
                                          secondary={device.model || '-'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={t('devices.fields.type')}
                                          secondary={type ? type.name : '-'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={t('devices.fields.location')}
                                          secondary={location ? location.name : '-'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={t('devices.fields.tags')}
                                          secondaryTypographyProps={{
                                              component: 'div'
                                          }}
                                          secondary={device.tags?.filter((tag) => tagList.find((t) => t.id === tag))?.length ? device.tags?.filter((tag) => tagList.find((t) => t.id === tag)).map((tag) => {
                                              const tagDefinition = tagList.find((t) => t.id === tag);
                                              return <Chip key={tag} label={tagDefinition.name} sx={{
                                                  mr: 1,
                                                  backgroundColor: tagDefinition.color,
                                                  color: 'white'
                                              }}/>
                                          }) : '-'}/>
                        </ListItem>
                    </List>
                </Paper>
                <Menu
                    anchorEl={menu}
                    open={Boolean(menu)}
                    onClose={() => setMenu(null)}
                >
                    <MenuItem onClick={() => {
                        setMenu(null);
                        setEditDeviceData({
                            number: device.number,
                            brand: device.brand,
                            model: device.model,
                            typeId: device.typeId,
                            tags: device.tags
                        });
                    }}>
                        <ListItemIcon>
                            <Edit fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>{t('devices.editDevice')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setMenu(null);
                        setMoveData({
                            customerId: device.customerId,
                            locationId: device.locationId,
                            addressId: device.addressId
                        });
                    }}>
                        <ListItemIcon>
                            <LocalShipping fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>{t('devices.moveDevice')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setMenu(null);
                        setDeleteOpen(true);
                    }}>
                        <ListItemIcon>
                            <Delete fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>{t('devices.delete.label')}</ListItemText>
                    </MenuItem>
                </Menu>
                <Toolbar disableGutters={true} variant='dense' sx={{pt: 1}}>
                    <Tooltip title={t('devices.qr.create')}>
                        <IconButton onClick={() => setCreateQrCodeSettings({
                            type: 'internal',
                            saveZipToFiles: false,
                        })}>
                            <QrCode2/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <Paper sx={{mt: 1}} variant='outlined'>
                    <List disablePadding={true}>
                        <ListItem button component={RouterLink} selected={selectionId === 'tickets'}
                                  to={'/customers/show/' + customer.id + '/addresses/' + addressId + '/devices/' + deviceId + '/tickets'}>
                            <ListItemText primary={t('devices.tickets')}/>
                        </ListItem>
                        <ListItem button component={RouterLink} selected={selectionId === 'properties'}
                                  to={'/customers/show/' + customer.id + '/addresses/' + addressId + '/devices/' + deviceId + '/properties'}>
                            <ListItemText primary={t('devices.properties')}/>
                        </ListItem>
                        <ListItem disabled button component={RouterLink} selected={selectionId === 'components'}
                                  to={'/customers/show/' + customer.id + '/addresses/' + addressId + '/devices/' + deviceId + '/components'}>
                            <ListItemText primary={t('devices.components')} secondary='coming soon'/>
                        </ListItem>
                        <ListItem button component={RouterLink} selected={selectionId === 'files'}
                                  to={'/customers/show/' + customer.id + '/addresses/' + addressId + '/devices/' + deviceId + '/files'}>
                            <ListItemText primary={t('devices.files')}/>
                        </ListItem>
                        <ListItem button component={RouterLink} selected={selectionId === 'history'}
                                  to={'/customers/show/' + customer.id + '/addresses/' + addressId + '/devices/' + deviceId + '/history'}>
                            <ListItemText primary={t('devices.history.label')}/>
                        </ListItem>
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9}>
                {selectionId === 'tickets' && <Box sx={{mb: 1}}>
                    <TicketList paperView tablePaperStyle={{flexGrow: 1}} variant='outlined' actionBar={false}
                                horizontalDisplay={true}
                                addressId={addressId !== 'all' ? addressId : null}
                                customerId={customer.id} deviceId={deviceId}/>
                </Box>}
                {selectionId === 'properties' && <Paper variant='outlined'>
                    <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                        <Typography variant='h5'
                                    component='h1'>{t('devices.properties')}</Typography>
                        <Box flexGrow={1}/>
                        <CheckPermissions list={['devices.write']}>
                            <Button disabled={loading || properties.length <= 0} startIcon={<Edit/>}
                                    onClick={() => setEditDeviceProperties(cloneDeep(device.properties || []))}>{t('customers.editProperties')}</Button>
                        </CheckPermissions>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 'bold'}}>
                                        {t('devices.fields.property')}
                                    </TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>
                                        {t('devices.fields.description')}
                                    </TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>
                                        {t('devices.fields.value')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {properties}
                            </TableBody>
                        </Table>
                        {properties.length <= 0 &&
                            <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
                                <TextFields sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                                    <Typography
                                        variant='body2'>{t('settings.properties.noPropertiesCreated')}</Typography>
                                    <Link component={RouterLink} variant='body2'
                                          to='/settings/general/properties'>{t('settings.properties.editProperties')}</Link>
                                </Box>
                            </Box>}
                    </TableContainer>
                </Paper>}
                {selectionId === 'components' && '#TODO'}
                {selectionId === 'files' && <Paper variant='outlined'>
                    <FileBrowser type={'device-' + deviceId}/>
                </Paper>}
                {selectionId === 'history' && <History history={device.locationHistory || []} customer={customer}/>}
            </Grid>
        </Grid>
        <Dialog maxWidth='md' fullWidth open={Boolean(editDeviceData)}>
            <DialogTitle>
                {t('devices.editDevice')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setEditDeviceData(null) && setErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {editDeviceData && <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    error={errors.hasOwnProperty('number')}
                    helperText={errors.number}
                    margin='dense'
                    label={t('devices.fields.number')}
                    placeholder={t('devices.fields.number')}
                    value={editDeviceData.number}
                    onChange={(e) => handleChange('number', e.target.value)}
                />
                <TextField
                    fullWidth
                    margin='dense'
                    required
                    error={errors.hasOwnProperty('brand')}
                    helperText={errors.brand}
                    label={t('devices.fields.brand')}
                    placeholder={t('devices.fields.brand')}
                    value={editDeviceData.brand}
                    onChange={(e) => handleChange('brand', e.target.value)}
                />
                <TextField
                    fullWidth
                    margin='dense'
                    required
                    error={errors.hasOwnProperty('model')}
                    helperText={errors.model}
                    label={t('devices.fields.model')}
                    placeholder={t('devices.fields.model')}
                    value={editDeviceData.model}
                    onChange={(e) => handleChange('model', e.target.value)}
                />
                <FormControl fullWidth margin='dense'>
                    <InputLabel shrink>{t('devices.fields.type')}</InputLabel>
                    <Select
                        notched
                        label={('devices.fields.type')}
                        value={editDeviceData.typeId}
                        fullWidth
                        onChange={(e) => handleChange('typeId', e.target.value)}
                    >
                        {deviceTypeList.map((t) => (<MenuItem key={t.id} value={t.id}>
                            {t.name}
                        </MenuItem>))}
                    </Select>
                </FormControl>
                <TagSelect
                    multiple
                    margin='dense'
                    sx={{mt: 1}}
                    tags={tagList}
                    label={t('customers.fields.tags')} value={editDeviceData.tags}
                    onChange={(v) => handleChange('tags', v || null)}/>
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditDeviceData(null) && setErrors({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={editData}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth='md' fullWidth open={Boolean(editDeviceProperties)}>
            <DialogTitle>
                {t('devices.editProperties')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setEditDeviceProperties(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('customers.fields.property')}
                                </TableCell>
                                <TableCell>
                                    {t('customers.fields.description')}
                                </TableCell>
                                <TableCell>
                                    {t('customers.fields.value')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Boolean(editDeviceProperties) && propertyList.filter((property) => property.areas.includes('device')).map((property) => {
                                const value = editDeviceProperties.find((propertyValue) => propertyValue.id === property.id)?.value
                                return <TableRow key={property.id}>
                                    <TableCell>{property.name}</TableCell>
                                    <TableCell>{property.description}</TableCell>
                                    <TableCell>
                                        <PropertyInput property={property} value={value}
                                                       onChange={handlePropertyChange}/>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditDeviceProperties(null)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={saveProperties}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('customers.saveProperties')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth='xl' fullWidth open={Boolean(moveData)}>
            <DialogTitle>
                {t('devices.moveDevice')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setMoveData(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {Boolean(moveData) && <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant='h5' sx={{mb: 2}}
                                    component='h1'>{t('devices.create.selectCustomer')}</Typography>
                        <Paper variant='outlined' sx={{p: 2, mb: 2}}>
                            {t('devices.create.selectedCustomer')}: {moveCustomer.id &&
                            <React.Fragment>{moveCustomer.number} - {moveCustomer.name}</React.Fragment>}
                        </Paper>
                        <CustomerList selectable selected={moveData.customerId}
                                      containerStyle={{height: 'calc(100vh - 400px)'}}
                                      onSelectionChange={(cId) => handleMoveDataChange('customerId', cId)}
                                      linkToCustomer={false} displayCreate={false}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='h5' sx={{mb: 2}}
                                    component='h1'>{t('devices.create.information')}</Typography>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel shrink id='address-label'>{t('devices.fields.address')}</InputLabel>
                            <Select
                                notched
                                label={t('devices.fields.address')}
                                labelId='address-label'
                                value={moveData.addressId}
                                fullWidth
                                labelWidth={100}
                                onChange={(e) => handleMoveDataChange('addressId', e.target.value)}
                            >
                                {moveCustomer.addresses?.map((a) => (<MenuItem key={a.id} value={a.id}>
                                    <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                                        <Chip size='small' sx={{ml: 2}}
                                              label={t('customers.address.main')}/> : null}</React.Fragment>}
                                                  secondary={formatAddress(a)}/>
                                </MenuItem>))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel shrink>{t('devices.fields.location')}</InputLabel>
                            <Select
                                notched
                                label={('devices.fields.location')}
                                value={moveData.locationId}
                                fullWidth
                                onChange={(e) => handleMoveDataChange('locationId', e.target.value)}
                            >
                                {locations.map((t) => (<MenuItem key={t.id} value={t.id}>
                                    {t.name}
                                </MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setMoveData(null)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={moveDevice}
                    startIcon={<Settings/>}
                    variant='contained'
                    color='primary'
                >
                    {t('devices.moveDevice')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('devices.delete.label')}
                      isDeleting={isDeleting}
                      handleClose={() => setDeleteOpen(false)}
                      description={t('devices.delete.description', {number: device.number || ''})}
                      open={deleteOpen}/>
        <Dialog maxWidth='md' fullWidth open={Boolean(createQrCodeSettings)}>
            <DialogTitle>
                {t('devices.qr.create')}
                <IconButton
                    aria-label="close"
                    disabled={qrCodeIsCreating}
                    onClick={() => setCreateQrCodeSettings(null) && setErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {Boolean(createQrCodeSettings) && <DialogContent>
                <FormControl fullWidth margin='dense'>
                    <InputLabel shrink>{t('devices.qr.type')}</InputLabel>
                    <Select
                        shrink
                        label={t('devices.qr.type')}
                        value={createQrCodeSettings.type}
                        fullWidth
                        labelWidth={200}
                        onChange={(e) => handleQrCodeSettingsChange('type', e.target.value)}>
                        <MenuItem value='internal'>
                            <ListItemText primary={t('devices.qr.types.internal')}/>
                        </MenuItem>
                        <MenuItem value='external'>
                            <ListItemText primary={t('devices.qr.types.external')}/>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <FormControlLabel control={<MuiSwitch
                        onChange={(e) => handleQrCodeSettingsChange('saveZipToFiles', e.target.checked)}
                        checked={createQrCodeSettings.saveZipToFiles}/>}
                                      label={t('devices.qr.saveZipToFiles')}/>
                </FormControl>
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={qrCodeIsCreating}
                    onClick={() => setCreateQrCodeSettings(null)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={qrCodeIsCreating}
                    onClick={createQrCodes}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}

export default Show;
