import {
    Alert,
    AppBar,
    Box,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
    useTheme
} from "@mui/material";
import {Link as RouterLink, useParams} from "react-router-dom";
import { Person } from "@mui/icons-material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../core/Api";
import { useDispatch, useSelector } from "react-redux";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import LeafletChangeView from "../../Utils/LeafletChangeView";
import { fetchCustomerMapSettings } from "../../../actions/settingsActions";
import ListDevices from './Devices/List';
import { push } from "react-router-redux";
import { hasPermission } from "../../../helper/hasPermission";
import TicketList from "../../Tickets/Utils/TicketList";
import CheckVersion from "../../Utils/CheckVersion";

const ShowAddress = () => {
    const params = useParams();
    const subId = params.subId;
    const tab = params.tab;
    const addressId = params.addressId;
    const customerId = params.id;

    const user = useSelector((state) => state.user)
    const dispatch = useDispatch();
    const theme = useTheme();
    const {t} = useTranslation();
    const [customer, setCustomer] = React.useState({});
    const mapSettings = useSelector((state) => state.mapSettings.settings)


    useEffect(() => {
        if (subId && tab !== 'devices') {
            dispatch(push(`/customers/show/${customerId}/addresses/${addressId}/${tab}`))
        }
    }, [dispatch, customerId, addressId, tab, subId]);

    const fetchCustomer = useCallback(() => {
        Api.fetch({
            endpoint: `customers/${customerId}`,
        }).then((res) => {
            setCustomer(res.response)
        }, () => {
        });
    }, [customerId])
    const fetchData = useCallback(() => {
        fetchCustomer();
        dispatch(fetchCustomerMapSettings())
    }, [fetchCustomer, dispatch])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const contacts = customer.addresses?.filter((a) => addressId === 'all' || addressId === a.id)
        .map((a) => a.contacts).flat().filter((c) => !!c).map((contact) => {
            return <TableRow>
                <TableCell>{contact.name}</TableCell>
                <TableCell>{contact.email || '-'}</TableCell>
                <TableCell>{contact.telephone || '-'}</TableCell>
            </TableRow>
        }) || [];

    const markers = customer.addresses?.filter((a) => addressId === 'all' || addressId === a.id)
        .filter((a) => !!a.latitude && !!a.longitude)
        .map((address) => {
            return <Marker key={address.id} position={[address.latitude, address.longitude]}/>

        }) || [];

    let position = [51.36225805000004, 9.47249443100003];
    if (mapSettings?.lat && mapSettings?.lng) {
        position = [mapSettings.lat, mapSettings.lng]
    }

    if (addressId !== 'all') {
        const address = customer.addresses?.find((a) => a.id === addressId);
        if (address?.latitude && address?.longitude) {
            position = [address.latitude, address.longitude]
        }
    }

    return <Paper sx={{height: tab === 'tickets' ? 'calc(100% - 64px)' : 'unset'}}>
        <AppBar position="relative" color='transparent'>
            <Tabs value={tab} variant="scrollable"
                  scrollButtons="auto">
                {hasPermission(user, ['tickets.read']) &&
                    <Tab key='tickets' label={t('customers.tickets')} value='tickets'
                         to={`/customers/show/${customerId}/addresses/${addressId}/tickets`}
                         component={RouterLink}/>}
                {hasPermission(user, ['devices.read']) &&
                    <Tab key='devices' label={t('customers.devices')} value='devices'
                         to={`/customers/show/${customerId}/addresses/${addressId}/devices`}
                         component={RouterLink}/>}
                <Tab key='contacts' label={t('customers.contacts')} value='contacts'
                     to={`/customers/show/${customerId}/addresses/${addressId}/contacts`}
                     component={RouterLink}/>
                <Tab key={'map'} label={t('customers.map')} value='map'
                     to={`/customers/show/${customerId}/addresses/${addressId}/map`}
                     component={RouterLink}/>
            </Tabs>
        </AppBar>
        {(tab === 'contacts' && contacts.length <= 0) && <React.Fragment>
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
                <Person sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('customers.noContactsFound')}</Typography>
                </Box>
            </Box>
        </React.Fragment>}
        {(hasPermission(user, ['tickets.read']) && tab === 'tickets' && customer.id) &&
            <TicketList paperView
                        actionBar={false}
                        addressId={addressId !== 'all' ? addressId : null}
                        customerId={customer.id}/>}
        {(hasPermission(user, ['devices.read']) && tab === 'devices' && customer.id) &&
            <ListDevices addressId={addressId} customer={customer}
                         deviceId={subId}/>}
        {(tab === 'contacts' && contacts.length > 0) && <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('customers.fields.name')}
                        </TableCell>
                        <TableCell>
                            {t('customers.fields.email')}
                        </TableCell>
                        <TableCell>
                            {t('customers.fields.telephone')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contacts}
                </TableBody>
            </Table>
        </TableContainer>}
        {(tab === 'map') && <CheckVersion minimumVersion='extended'>
        {markers.length > 0 && <MapContainer style={{height: '600px', width: '100%'}} center={position}
                          zoom={mapSettings.zoom || 13}>
                scrollWheelZoom={false}>
                <LeafletChangeView zoom={mapSettings.zoom || 13} center={position}/>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers}
            </MapContainer>}
        {markers.length <= 0 && <Alert severity='warning'>
            {t('customers.noMarkersFound')}
        </Alert>}
            </CheckVersion>}

    </Paper>;
};

export default ShowAddress;
