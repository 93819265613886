import React from 'react';
import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Introduction = ({onSave, saving}) => {
    const {t} = useTranslation();

    return <React.Fragment>
        <Paper sx={{p: 2}}>
            <Typography variant='body'>
                {t('setup.completeText')}
            </Typography>
            <LoadingButton onClick={onSave}
                           sx={{mt: 2}}
                           fullWidth
                           size='large'
                           loadingPosition="start"
                           loading={saving}
                           startIcon={<Save/>}
                           variant='contained'
                           color='primary'>
                {t('setup.save')}
            </LoadingButton>
        </Paper>
    </React.Fragment>
}

export default Introduction;
