import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {Add, ChevronLeft, Close, LinkOff, Replay, Save} from "@mui/icons-material";
import {Link as RouterLink, useParams} from "react-router-dom";
import React, {useCallback, useEffect} from "react";
import {cloneDeep, uniq} from "lodash";
import {useTranslation} from "react-i18next";
import CustomerSelect from "../../Utils/CustomerSelect";
import {formatAddress} from "../../../helper/formatAddress";
import TextEditor from "../../Utils/TextEditor";
import {fetchGroupList} from "../../../actions/groupActions";
import {fetchUserList} from "../../../actions/userActions";
import {fetchCategoryList} from "../../../actions/categoryActions";
import {useDispatch, useSelector} from "react-redux";
import {fetchStatusList} from "../../../actions/statusActions";
import TagSelect from "../../Utils/TagSelect";
import {fetchTagList} from "../../../actions/tagActions";
import {useTheme} from "@mui/system";
import DeviceList from "../../Devices/Utils/DeviceList";
import {fetchDeviceTypes} from "../../../actions/deviceTypeActions";
import ImportanceSlider from "../../Utils/ImportanceSlider";
import Schema from "validate";
import Api from "../../../core/Api";
import {push} from "@lagunovsky/redux-react-router";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import {LoadingButton} from "@mui/lab";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getValidationSchema = (t) => {
    return new Schema({
        interval: {
            required: true,
            type: String,
            enum: ['day', 'week', 'month', 'quarter', 'half_year', 'year', 'two_years'],
            message: t('tickets.validation.interval')
        },
        startTimestamp: {
            required: true,
            type: Number,
            message: t('tickets.validation.startTimestamp'),
            use: (val) => val > 0
        },
        title: {
            required: true,
            type: String,
            message: t('tickets.validation.title'),
        },
        categoryId: {
            required: true,
            type: String,
            message: t('tickets.validation.categoryId'),
        },
        statusId: {
            required: true,
            type: String,
            message: t('tickets.validation.statusId'),
        },
        importanceId: {
            required: true,
            type: Number,
            message: t('tickets.validation.importanceId'),
        },
        customerId: {
            required: true,
            type: String,
            message: t('tickets.validation.customerId'),
        },
        userLinks: {
            type: Array,
            required: false,
            each: {
                userId: {
                    required: true,
                    type: String,
                    message: t('tickets.validation.userLinkUserId'),
                },
                status: {
                    required: true,
                    type: String,
                    message: t('tickets.validation.userLinkStatus'),
                },
            }
        },
        addressIds: {
            type: Array,
            required: false,
            length: {min: 1},
            each: {type: String},
            message: t('tickets.validation.addressIds'),
        }
    })
}

const Create = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const descriptionRef = React.createRef();

    const theme = useTheme();
    const {t} = useTranslation();
    const [saving, setSaving] = React.useState(false);
    const [errorList, setErrorList] = React.useState({});
    const [customer, setCustomer] = React.useState({});
    const [devices, setDevices] = React.useState([]);
    const [ticketLoading, setTicketLoading] = React.useState(false);

    const [deviceSelectOpen, setDeviceSelectOpen] = React.useState(false);

    const [ticket, setTicket] = React.useState({
        id: null,
        interval: "day",
        startDate: moment().utc().startOf('day'),
        endDate: null,
        title: '',
        description: '',
        categoryId: '',
        statusId: '',
        contact: null,
        contactId: '',
        userLinkIds: [],
        groupLinkIds: [],
        importanceId: 0,
        customerId: null,
        addressIds: [],
        tags: [],
    });

    const handleChange = useCallback((key, value) => {
        setTicket((d) => ({
            ...d,
            [key]: value
        }))
    }, [setTicket]);

    const deviceTypeList = useSelector((state) => state.deviceTypes.list)
    const tagList = useSelector((state) => state.tags.list)
    const groupList = useSelector((state) => state.groups.list)
    const userList = useSelector((state) => state.userList.list)
    const categoryList = useSelector((state) => state.categories.list)
    const statusList = useSelector((state) => state.statusList.list)


    useEffect(() => {
        if (!params.id) {
            return;
        }

        async function fetchData(ticketId) {
            setTicketLoading(true);

            try {
                const res = await Api.fetch({
                    endpoint: 'recurring-tickets/' + ticketId,
                });

                const t = res.response;
                setTicket({
                    id: t.id,
                    interval: t.interval || "day",
                    startDate: moment(t.startTimestamp * 1000).utc(),
                    endDate: t.endTimestamp ? moment(t.endTimestamp * 1000).utc() : null,
                    title: t.title,
                    description: t.description,
                    categoryId: t.categoryId,
                    statusId: t.statusId,
                    contact: t.contact,
                    contactId: t.contactId,
                    userLinkIds: t.userLinks,
                    groupLinkIds: [],
                    importanceId: t.importanceId,
                    customerId: t.customerId,
                    addressIds: t.addressIds,
                    tags: t.tags,
                });

                if (t.deviceIds && t.deviceIds.length > 0) {
                    const deviceRes = await Api.fetch({
                        endpoint: 'devices',
                        parameter: {
                            page: 1,
                            size: t.deviceIds.length,
                            filter: {
                                ids: 'in<-->' + uniq(t.deviceIds).join(','),
                            }
                        }
                    });

                    setDevices(deviceRes.response);
                }
            } catch (e) {
            }

            setTicketLoading(false);
        }

        fetchData(params.id);
    }, [params.id])

    const onChange = useCallback((newCustomer) => {
        if (newCustomer.id === customer.id) {
            return
        }

        setCustomer(newCustomer);
        setDevices([]);
        const addressIds = newCustomer.addresses.map((address) => address.id);
        setTicket((t) => {
            t.customerId = newCustomer.id;

            const newAddresses = ticket.addressIds.filter(aId => addressIds.includes(aId));
            if (!newAddresses.length) {
                newAddresses.push(addressIds[0]);
                t.addressIds = newAddresses;
            }
            return cloneDeep(t);
        })
    }, [customer.id, ticket.addressIds]);

    const onSave = () => {
        setSaving(true)
        setErrorList({})

        const saveTicket = cloneDeep(ticket);

        delete saveTicket.id;
        delete saveTicket.groupLinkIds;
        delete saveTicket.userLinkIds;
        delete saveTicket.startDate;
        delete saveTicket.endDate;

        saveTicket.startTimestamp = ticket.startDate?.unix() || null;
        saveTicket.endTimestamp = ticket.endDate ? ticket.endDate.unix() : null;

        saveTicket.deviceIds = devices.map(d => d.id);

        const userIds = uniq([...ticket.userLinkIds, ...ticket.groupLinkIds.map((g) => {
            return userList.filter(u => u.groups.includes(g)).map(u => u.id)
        }).flat()]).filter((u) => userList.find((user) => user.id === u));

        saveTicket.userLinks = userIds.map((userLinkId) => ({
            userId: userLinkId,
            status: "pending",
        }));

        const v = getValidationSchema(t)
        const errors = v.validate(cloneDeep(saveTicket))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            console.log(errorObject);
            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: ticket.id ? ('recurring-tickets/' + ticket.id) : 'recurring-tickets',
            body: saveTicket,
            method: ticket.id ? 'PUT' : 'POST'
        }).then((res) => {
            dispatch(push('/tickets/edit-recurring/' + res.response.id))
        }, () => {
        }).then(() => setSaving(false))
    }

    const fetchData = useCallback(() => {
        dispatch(fetchGroupList())
        dispatch(fetchUserList())
        dispatch(fetchCategoryList())
        dispatch(fetchStatusList())
        dispatch(fetchTagList())
        dispatch(fetchDeviceTypes())
    }, [dispatch])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {
        if (!ticket.categoryId && categoryList.length) {
            handleChange('categoryId', categoryList[0].id);
        }
    }, [categoryList, ticket, handleChange],)

    useEffect(() => {
        if (!ticket.statusId && statusList.length) {
            handleChange('statusId', statusList[0].id);
        }
    }, [statusList, ticket, handleChange])

    const stateLoading = useSelector((state) => state.groups.isFetching ||
        state.userList.isFetching || state.categories.isFetching || state.statusList.isFetching ||
        state.tags.isFetching || state.deviceTypes.isFetching);

    const loading = stateLoading || ticketLoading;

    const notFoundMessage = <Box sx={{textAlign: 'center', color: theme.palette.grey[400], pb: 1}}>
        <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('tickets.create.noDeviceFound')}</Typography>
            {!loading && ticket.customerId &&
                <Link disabled={loading || !ticket.customerId} onClick={() => setDeviceSelectOpen(true)}
                      variant='body2'>{t('tickets.create.addDevice')}</Link>}
        </Box>
    </Box>

    const contacts = (customer.addresses?.filter(a => ticket.addressIds.includes(a.id)).map((a) => {
        return a.contacts?.map((c) => (<MenuItem key={c.id} value={c.id}>
            <ListItemText primary={<React.Fragment>{c.name} ({a.name})</React.Fragment>}
                          secondary={[c.email, c.telephone].filter(k => Boolean(k)).join(', ')}/>
        </MenuItem>)) || []
    }) || []).flat();

    const deviceList = devices.map((device) => {
        const address = customer?.addresses?.find((a) => a.id === device.addressId);
        const location = address?.locations.find((l) => l.id === device.locationId);
        const type = deviceTypeList.find((t) => t.id === device.typeId);

        return (
            <TableRow key={device.id}>
                <TableCell>{device.number}</TableCell>
                <TableCell>{device.model}</TableCell>
                <TableCell>{device.brand}</TableCell>
                <TableCell>{type?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>{address ? formatAddress(address) : <i>{t('deleted')}</i>}</TableCell>
                <TableCell>{location?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>
                    {device.tags?.filter((tag) => tagList.find((t) => t.id === tag)).map((tag) => {
                        const tagDefinition = tagList.find((t) => t.id === tag);
                        return <Chip key={tag} label={tagDefinition.name}
                                     sx={{mr: 1, backgroundColor: tagDefinition.color, color: 'white'}}/>
                    })}
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => {
                        setDevices(devices.filter(d => d.id !== device.id).slice())
                    }}>
                        <LinkOff/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    });

    return <React.Fragment>
        <Box sx={{my: 2, px: 2, overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box'}}>
            <Toolbar variant='dense' disableGutters={true} sx={{mb: 1}}>
                <LoadingButton
                    loadingPosition="start"
                    disabled={loading}
                    loading={saving}
                    onClick={onSave}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('tickets.recurring.save')}</LoadingButton>
                <Button sx={{ml: 2}} component={RouterLink} to={'/tickets'}
                        startIcon={<ChevronLeft/>}
                        color='primary'>{t('back')}</Button>
                <Box flexGrow={1}/>
                <IconButton onClick={fetchData}><Replay/></IconButton>
            </Toolbar>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper sx={{p: 2, mb: 2}}>
                        <Typography variant='h5' sx={{mb: 2}}
                                    component='h1'>{t('tickets.create.customer')}</Typography>
                        <CustomerSelect onChange={onChange} externalCustomerId={ticket.customerId}
                                        error={errorList.customerId}/>
                        <FormControl fullWidth margin='dense' error={errorList.hasOwnProperty('addressIds')}>
                            <InputLabel shrink={Boolean(ticket.customerId)}
                                        id='address-label'>{t('tickets.fields.addresses')}</InputLabel>
                            <Select
                                notched={Boolean(ticket.customerId)}
                                label={t('tickets.fields.addresses')}
                                labelId='address-label'
                                multiple
                                error={errorList.hasOwnProperty('addressIds')}
                                renderValue={(v) => v.filter(aId => customer.addresses?.find(a => a.id === aId)).map(aId => customer.addresses.find(a => a.id === aId).name).join(', ')}
                                value={ticket.addressIds}
                                disabled={!ticket.customerId}
                                fullWidth
                                labelWidth={100}
                                onChange={(e) => setTicket((t) => {
                                    t.addressIds = e.target.value;
                                    return cloneDeep(t);
                                })}>
                                {customer.addresses?.map((a) => (<MenuItem key={a.id} value={a.id}>
                                    <Checkbox checked={ticket.addressIds.includes(a.id)}/>
                                    <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                                        <Chip size='small' sx={{ml: 2}}
                                              label={t('customers.address.main')}/> : null}</React.Fragment>}
                                                  secondary={formatAddress(a)}/>
                                </MenuItem>))}
                            </Select>
                            {errorList.hasOwnProperty('addressIds') &&
                                <FormHelperText>{errorList.addressIds}</FormHelperText>}
                        </FormControl>
                        {ticket.contact === null && <Box>
                            <FormControl fullWidth margin='dense' disabled={!contacts?.length}>
                                <InputLabel shrink={Boolean(ticket.contactId)}
                                            id='address-label'>{t('tickets.create.contact')}</InputLabel>
                                <Select
                                    shrink={Boolean(ticket.contactId)}
                                    label={t('tickets.create.contact')}
                                    labelId='address-label'
                                    value={ticket.contactId}
                                    disabled={!contacts?.length}
                                    fullWidth
                                    labelWidth={200}
                                    onChange={(e) => setTicket((t) => {
                                        t.contactId = e.target.value;
                                        return cloneDeep(t);
                                    })}>
                                    {contacts}
                                </Select>
                            </FormControl>
                            <Button edge="end" color="primary" onClick={() => setTicket((t) => ({
                                ...t,
                                contactId: null,
                                contact: '',
                            }))}>
                                {t('tickets.create.manually')}
                            </Button>
                        </Box>}
                        {ticket.contact !== null && <Box><TextField
                            fullWidth
                            margin='dense'
                            label={t('tickets.create.contact')}
                            placeholder={t('tickets.create.contact')}
                            value={ticket.contact}
                            onChange={(e) => {
                                handleChange('contact', e.target.value);
                            }}
                        />
                            <Button edge="end" color="primary" onClick={() => setTicket((t) => ({
                                ...t,
                                contactId: '',
                                contact: null,
                            }))}>
                                {t('tickets.create.selectContact')}
                            </Button>
                        </Box>}
                    </Paper>
                    <Paper sx={{mb: 2}}>
                        <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                            <Typography variant='h5'
                                        component='h1'>{t('tickets.create.devices')}</Typography>
                            <Box flexGrow={1}/>
                            <Button onClick={() => setDeviceSelectOpen(true)} disabled={loading || !ticket.customerId}
                                    startIcon={<Add/>}
                            >{t('tickets.create.addDevice')}</Button>
                        </Toolbar>
                        {devices.length <= 0 && notFoundMessage}
                        {devices.length > 0 && <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.number')}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.model')}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.brand')}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.type')}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.address')}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.location')}</TableCell>
                                        <TableCell sx={{fontWeight: 'bold'}}>{t('customers.fields.tags')}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deviceList}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper sx={{p: 2, mb: 2}}>
                        <Typography variant='h5' sx={{mb: 2}}
                                    component='h1'>{t('tickets.create.settings')}</Typography>
                        <FormControl fullWidth margin='dense' error={errorList.hasOwnProperty('interval')}>
                            <InputLabel shrink>{t('tickets.recurring.interval')}</InputLabel>
                            <Select
                                notched
                                label={t('tickets.recurring.interval')}
                                error={errorList.hasOwnProperty('interval')}
                                value={ticket.interval}
                                fullWidth
                                onChange={(e) => handleChange('interval', e.target.value)}>
                                {['day', 'week', 'month', 'quarter', 'half_year', 'year', 'two_years'].map((a) => (
                                    <MenuItem key={a} value={a}>
                                        {t('tickets.recurring.intervals.' + a)}
                                    </MenuItem>))}
                            </Select>
                            {errorList.hasOwnProperty('addressIds') &&
                                <FormHelperText>{errorList.interval}</FormHelperText>}
                        </FormControl>
                        <DatePicker label={t('tickets.recurring.startDate')}
                                    mask="__.__.____"
                                    value={ticket.startDate || null}
                                    minDate={moment().startOf('day')}
                                    onChange={(newValue) => handleChange('startDate', newValue?.unix() > 0 ? newValue.startOf('day') : null)}
                                    slotProps={{
                                        textField: {
                                            helperText: errorList.startTimestamp,
                                            error: errorList.hasOwnProperty('startTimestamp'),
                                            fullWidth: true,
                                            margin: 'dense'
                                        }
                                    }}
                        />
                        <DatePicker label={t('tickets.recurring.endDate')}
                                    mask="__.__.____"
                                    value={ticket.endDate || null}
                                    minDate={moment().endOf('day')}
                                    onChange={(newValue) => handleChange('endDate', newValue?.unix() > 0 ? newValue.endOf('day') : null)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            margin: 'dense'
                                        }
                                    }}
                        />
                    </Paper>
                    <Paper sx={{p: 2, mb: 2}}>
                        <Typography variant='h5' sx={{mb: 2}}
                                    component='h1'>{t('tickets.create.information')}</Typography>
                        <TextField
                            fullWidth
                            margin='dense'
                            required
                            error={errorList.hasOwnProperty('title')} y
                            helperText={errorList.title}
                            label={t('tickets.fields.title')}
                            placeholder={t('tickets.fields.title')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {Number(ticket.title?.length)}/50
                                    </InputAdornment>
                                ),
                            }}
                            value={ticket.title}
                            onChange={(e) => {
                                if (e.target.value.length >= 50 && Number(descriptionRef.current?.getEditor()?.getText()?.trim().length) <= 0) {
                                    descriptionRef.current.focus();
                                }
                                handleChange('title', e.target.value.slice(0, 50));
                            }}
                        />
                        <TextEditor
                            style={{marginTop: '8px', marginBottom: '8px'}}
                            ref={descriptionRef}
                            value={ticket.description}
                            onChange={(v) => handleChange('description', v)}
                        />
                        <FormControl fullWidth margin='dense'>
                            <InputLabel>{t('tickets.fields.status')}</InputLabel>
                            <Select
                                value={ticket.statusId}
                                onChange={(e) => handleChange('statusId', e.target.value)}
                                renderValue={
                                    (selected) => statusList.find(r => r.id === selected)?.name || ''
                                }
                                input={<OutlinedInput label={t('tickets.fields.status')}/>}
                            >
                                {statusList.map((r) => (
                                    <MenuItem key={r.id} value={r.id}>
                                        <ListItemText primary={r.name} secondary={r.description}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel>{t('tickets.fields.category')}</InputLabel>
                            <Select
                                value={ticket.categoryId}
                                onChange={(e) => handleChange('categoryId', e.target.value)}
                                renderValue={
                                    (selected) => categoryList.find(r => r.id === selected)?.name || ''
                                }
                                input={<OutlinedInput label={t('tickets.fields.category')}/>}
                            >
                                {categoryList.map((r) => (
                                    <MenuItem key={r.id} value={r.id}>
                                        <ListItemText primary={r.name} secondary={r.description}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <ImportanceSlider
                            label={t('tickets.fields.importance')}
                            value={ticket.importanceId}
                            onChange={(e, v) => handleChange('importanceId', v)}
                        />
                        <FormControl fullWidth margin='dense'>
                            <InputLabel>{t('tickets.fields.groups')}</InputLabel>
                            <Select
                                multiple
                                value={ticket.groupLinkIds}
                                onChange={(e) => handleChange('groupLinkIds', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                                input={<OutlinedInput label={t('tickets.fields.groups')}/>}
                                renderValue={
                                    (selected) => selected.map((v) => groupList.find(r => r.id === v).name).join(', ')
                                }
                                MenuProps={MenuProps}
                            >
                                {groupList.map((r) => (
                                    <MenuItem key={r.id} value={r.id}>
                                        <Checkbox checked={ticket.groupLinkIds.includes(r.id)}/>
                                        <ListItemText primary={r.name} secondary={r.description}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel>{t('tickets.fields.users')}</InputLabel>
                            <Select
                                multiple
                                value={ticket.userLinkIds.filter((v) => userList.find(r => r.id === v))}
                                onChange={(e) => {
                                    handleChange('userLinkIds', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                                }}
                                input={<OutlinedInput label={t('tickets.fields.users')}/>}
                                renderValue={
                                    (selected) => selected.filter((v) => userList.find(r => r.id === v)).map((v) => {
                                        const user = userList.find(r => r.id === v)
                                        if (!user.givenName.length && !user.familyName.length) {
                                            return user.email
                                        }
                                        return user.givenName + ' ' + user.familyName
                                    }).join(', ')
                                }
                                MenuProps={MenuProps}
                            >
                                {userList.map((r) => (
                                    <MenuItem key={r.id} value={r.id}>
                                        <Checkbox checked={ticket.userLinkIds.includes(r.id)}/>
                                        <ListItemText primary={r.givenName + ' ' + r.familyName} secondary={r.email}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TagSelect
                            multiple
                            margin='dense'
                            sx={{mt: 2}}
                            tags={tagList}
                            label={t('tickets.fields.tags')} value={ticket.tags}
                            onChange={(v) => handleChange('tags', v || null)}/>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Dialog open={deviceSelectOpen} fullWidth maxWidth='lg' onClose={() => setDeviceSelectOpen(false)}>
            <DialogTitle>
                {t('tickets.create.devices')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setDeviceSelectOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DeviceList hiddenColumns={['customer']} fixedFilters={{customerIds: ticket.customerId}}
                            displayCreate={false} linkToDevice={false} containerStyle={{pt: 1}}
                            tableStyle={{maxHeight: '30vh'}} selectable selected={devices.map(d => d.id)}
                            onSelectionChange={(d) => {
                                const found = devices.find(r => r.id === d.id)
                                if (!found) {
                                    setDevices([...devices, d])
                                } else {
                                    setDevices(devices.filter(r => r.id !== d.id).slice())
                                }
                            }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setDeviceSelectOpen(false)}
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}

export default Create;
