import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import {
    Alert,
    Box,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Switch,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Cancel, CheckCircle, Replay, Save, Tag, TextSnippet } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchTagList } from "../../../../actions/tagActions";
import CheckPermissions from "../../../Utils/CheckPermissions";
import TagSelect from "../../../Utils/TagSelect";

const CustomSelect = ({value, onChange, options, type, margin = 'normal', sx}) => {
    const {t} = useTranslation();
    return <FormControl margin={margin} fullWidth sx={sx}>
        <InputLabel>{t('settings.fourVending.types.' + type)}</InputLabel>
        <Select
            label={t('settings.fourVending.types.' + type)}
            value={value}
            fullWidth
            renderValue={(value) => {
                return <ListItem disableGutters disablePadding>
                    {value === 'active' && <ListItemIcon>
                        <CheckCircle color='success'/>
                    </ListItemIcon>}
                    {value === 'log' && <ListItemIcon>
                        <TextSnippet color='warning'/>
                    </ListItemIcon>}
                    {value === 'tag' && <ListItemIcon>
                        <Tag/>
                    </ListItemIcon>}
                    {value === 'ignore' && <ListItemIcon>
                        <Cancel color='error'/>
                    </ListItemIcon>}
                    <ListItemText primary={t('settings.fourVending.options.' + value)}/>
                </ListItem>
            }}
            onChange={(e) => onChange(e.target.value)}
        >
            {options.map((option) => (<MenuItem key={option} value={option}>
                <ListItemText primary={t('settings.fourVending.options.' + option)}
                              secondary={t('settings.fourVending.optionDescriptions.' + option)}/>
            </MenuItem>))}
        </Select>
    </FormControl>
}

const Settings = () => {
    const {t} = useTranslation()
    const [settings, setSettings] = useState({
        active: false,
        customers: {
            new: 'ignore',
            edit: 'ignore',
            delete: 'ignore',
            updateTagId: null,
            deleteTagId: null
        },
        devices: {
            new: 'ignore',
            edit: 'ignore',
            delete: 'ignore',
            updateTagId: null,
            deleteTagId: null
        }
    })
    const dispatch = useDispatch()

    const tagList = useSelector((state) => state.tags.list)
    const isFetching = useSelector((state) => state.tags.isFetching)

    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const handleDeviceChange = (key, value) => {
        setSettings((s) => {
            const devices = s.devices;
            devices[key] = value;
            return {...s, devices: devices}
        })
    }

    const handleCustomerChange = (key, value) => {
        setSettings((s) => {
            const customers = s.customers;
            customers[key] = value;
            return {...s, customers: customers}
        })
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/4vending',
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    const fetchEntities = useCallback(() => {
        dispatch(fetchTagList())
    }, [dispatch])

    useEffect(() => {
        fetchEntities();
        fetchSettings();
    }, [fetchSettings, fetchEntities])

    const onSave = useCallback(() => {
        setSaving(true)
        Api.fetch({
            endpoint: 'settings/4vending',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving])

    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['fourVending.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={loading || isFetching}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={() => {
                fetchSettings();
                fetchEntities();
            }}><Replay/></IconButton>
        </Toolbar>
        <Paper sx={{p: 2}}>
            <Alert sx={{mb: 2}} severity={'warning'}>{t('settings.fourVending.description')}</Alert>
            <FormControlLabel control={<Switch onChange={(e) => handleChange('active', e.target.checked)}
                                               checked={settings.active}/>}
                              label={t('settings.fourVending.active')}/>
            <Collapse in={settings.active}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{p: 2}} variant='outlined'>
                            <Typography variant='h6'>{t('settings.fourVending.customers')}</Typography>
                            <CustomSelect type='new' value={settings.customers.new}
                                          onChange={(v) => handleCustomerChange('new', v)}
                                          options={['active', 'log', 'ignore']}/>
                            <Box component='div' sx={{mt: 2, mb: 3}}>
                                <CustomSelect margin={'none'} type='edit' value={settings.customers.edit}
                                              onChange={(v) => handleCustomerChange('edit', v)}
                                              options={['active', 'ignore']}/>
                                {settings.customers.edit === 'tag' && (
                                    <TagSelect
                                        sx={{mt: 2}}
                                        tags={tagList}
                                        label={t('settings.fourVending.editTag')}
                                        value={settings.customers.updateTagId}
                                        onChange={(v) => handleCustomerChange('updateTagId', v || null)}/>)}
                            </Box>
                            <Box component='div' sx={{mt: 2, mb: 1}}>
                                <CustomSelect margin={'none'} type='delete' value={settings.customers.delete}
                                              onChange={(v) => handleCustomerChange('delete', v)}
                                              options={['active', 'log', 'tag', 'ignore']}/>
                                {settings.customers.delete === 'tag' && (
                                    <TagSelect
                                        sx={{mt: 2}}
                                        tags={tagList}
                                        label={t('settings.fourVending.deleteTag')}
                                        value={settings.customers.deleteTagId}
                                        onChange={(v) => handleCustomerChange('deleteTagId', v || null)}/>)}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{p: 2}} variant='outlined'>
                            <Typography variant='h6'>{t('settings.fourVending.devices')}</Typography>
                            <CustomSelect type='new' value={settings.devices.new}
                                          onChange={(v) => handleDeviceChange('new', v)}
                                          options={['active', 'log', 'ignore']}/>
                            <Box component='div' sx={{mt: 2, mb: 3}}>
                                <CustomSelect margin={'none'} type='edit' value={settings.devices.edit}
                                              onChange={(v) => handleDeviceChange('edit', v)}
                                              options={['active', 'ignore']}/>
                                {settings.devices.edit === 'tag' && (
                                    <TagSelect
                                        sx={{mt: 2}}
                                        tags={tagList}
                                        label={t('settings.fourVending.editTag')} value={settings.devices.updateTagId}
                                        onChange={(v) => handleDeviceChange('updateTagId', v || null)}/>)}
                            </Box>
                            <Box component='div' sx={{mt: 2, mb: 1}}>
                                <CustomSelect margin={'none'} type='delete' value={settings.devices.delete}
                                              onChange={(v) => handleDeviceChange('delete', v)}
                                              options={['active', 'log', 'tag', 'ignore']}/>
                                {settings.devices.delete === 'tag' && (
                                    <TagSelect
                                        sx={{mt: 2}}
                                        tags={tagList}
                                        label={t('settings.fourVending.deleteTag')} value={settings.devices.deleteTagId}
                                        onChange={(v) => handleDeviceChange('deleteTagId', v || null)}/>)}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Collapse>
        </Paper>
    </React.Fragment>;
}

export default Settings;
