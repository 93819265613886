import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../core/Api";
import { Delete, Replay, UploadFile } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { useDropzone } from 'react-dropzone'
import { LoadingButton } from "@mui/lab";
import DeleteDialog from "./DeleteDialog";

const FileBrowser = ({selected, onSelect, connectionId, accept, displayType}) => {
    const uploadRef = useRef();

    const {t} = useTranslation();
    const theme = useTheme();
    const [files, setFiles] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);

    const [deleting, setDeleting] = React.useState(false);
    const [deletePath, setDeletePath] = React.useState(null);

    const fetchFiles = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: 'connections/' + connectionId + '/list-files'
        }).then((response) => {
            setFiles(response.response);
        }, () => {
        }).then(() => {
            setLoading(false);
        });
    }, [connectionId])

    const deleteFile = useCallback(() => {
        setDeleting(true);
        if (selected === deletePath) {
            if (typeof onSelect === 'function') {
                onSelect(null);
            }
        }
        Api.fetch({
            endpoint: 'storage/webspace/' + deletePath,
            method: 'DELETE'
        }).then(() => {
            setDeletePath(null);
            fetchFiles();
        }, () => {
        }).then(() => {
            setDeleting(false)
        })
    }, [deletePath, fetchFiles, onSelect, selected]);


    const onUpload = useCallback((files) => {
        setUploading(true);

        const promises = files.map((file) => {
            return new Promise((resolve, reject) => {
                Api.fetch({
                    endpoint: 'storage/webspace',
                    method: 'POST',
                    body: {
                        fileName: file.name
                    }
                }).then((response) => {
                    fetch(response.response.url, {method: 'PUT', body: file}).then(() => resolve(), () => reject());
                }, () => {
                    reject();
                });
            })
        })

        Promise.all(promises).then(() => {
            fetchFiles();
        }, () => {
        }).then(() => {
            setUploading(false)
        })
    }, [fetchFiles, setUploading])

    const onDrop = useCallback(acceptedFiles => {
        onUpload(acceptedFiles);
    }, [onUpload])

    const onFileSelect = useCallback(({target}) => {
        onUpload(Array.from(target.files));
    }, [onUpload])

    const {getRootProps} = useDropzone({onDrop, accept, multiple: true, disabled: connectionId !== 'webspace', noClick: true})

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    if (loading || uploading) {
        return <Box sx={{textAlign: 'center', py: 2}}>
            <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        </Box>
    }

    let content = <React.Fragment>
        <Toolbar variant='dense'>
            {connectionId === 'webspace' && <LoadingButton
                onClick={() => uploadRef.current.click()}
                size='small'
                loadingPosition="start"
                startIcon={<UploadFile/>}
                variant='contained'
                color='primary'>{t('upload')}</LoadingButton>}
            <Box flexGrow={1}/>
            <IconButton onClick={() => {
                fetchFiles();
            }}><Replay/></IconButton>
        </Toolbar>
        <List>
            {files.map((file, i) => {
                return <React.Fragment key={file.name}>
                    <ListItem selected={selected === file.name} secondaryAction={<React.Fragment>
                        {connectionId === 'webspace' &&
                            <IconButton onClick={() => setDeletePath(file.path)}><Delete/></IconButton>}
                    </React.Fragment>}>
                        {typeof onSelect === 'function' && <ListItemIcon>
                            <Checkbox
                                onClick={() => {
                                    if (typeof onSelect === 'function') {
                                        onSelect(file.name)
                                    }
                                }}
                                checked={selected === file.name}
                            />
                        </ListItemIcon>}
                        {file.contentType?.indexOf('image/') === 0 && <ListItemAvatar sx={{mr: 2}}>
                            <Box sx={{width: '100px'}} textAlign='center'>
                                <Box component='img' sx={{maxWidth: '100px', maxHeight: '40px'}} alt={file.path}
                                     src={file.url}/>
                            </Box>
                        </ListItemAvatar>}
                        <ListItemText primary={file.name}
                                      secondary={file.contentType}/>
                    </ListItem>
                    {i < files.length - 1 && <Divider variant={'fullWidth'} component='li'/>}
                </React.Fragment>
            })}
        </List>
    </React.Fragment>

    if (files.length <= 0) {
        content = <Box sx={{textAlign: 'center', color: theme.palette.grey[400], pb: 1, pt: 1}}>
            <UploadFile sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                <Typography variant='body2'>{t('filePicker.noFiles')}</Typography>
                <Link onClick={() => uploadRef.current.click()} variant='body2'>{t('filePicker.uploadFile')}</Link>
            </Box>
        </Box>
    }

    return <Box component='div' sx={{pt: 1}}>
        <input
            multiple={true}
            onChange={onFileSelect}
            accept={accept}
            ref={uploadRef}
            style={{display: 'none'}}
            id="file-upload-input"
            type="file"
        />
        <div {...getRootProps()}>
            {content}
        </div>
        <DeleteDialog onDelete={() => deleteFile()} isDeleting={deleting} title={t('filePicker.deleteFile')}
                      handleClose={() => setDeletePath(null)}
                      description={t('filePicker.deleteFileDescription', {name: deletePath})}
                      open={Boolean(deletePath)}/>
    </Box>;
}
export default FileBrowser
