import {Alert, Grid, LinearProgress} from "@mui/material";
import Logo from '../../images/logo.png'
import {styled} from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    backgroundColor: theme.palette.primary.dark,
    height: 6
}))

const CompanyLogo = styled('img')(({theme}) => ({
    marginBottom: theme.spacing(2),
    maxWidth: '600px'
}))


const LoadingScreen = (props) => {

    let error = '';
    if (props.errorList && props.errorList.length > 1) {
        const errorContent = props.errorList.map((error, index) => {
            return <li key={index}>{error}</li>
        })

        error = <ul>
            {errorContent}
        </ul>
    } else if (props.errorList && props.errorList.length === 1) {
        error = props.errorList[0]
    }

    return <Grid container justifyContent='center' alignItems='center' sx={{
        height: '100%'
    }}>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
            <CompanyLogo alt='company logo' src={Logo}/>
            {props.loading && <BorderLinearProgress color='primary'/>}
            {error && <Alert severity="error">{error}</Alert>}
        </Grid>
    </Grid>;
}

export default LoadingScreen
