import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, Replay, Save } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import Row from "./Row";
import { LoadingButton } from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [componentTypes, setComponentTypes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchComponentTypes()
    }, []);

    const fetchComponentTypes = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'device-component-types'
        })
            .then((res) => {
                setComponentTypes(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        componentTypes.push({id: uuid(), name: '', description: '', isNew: true})
        setComponentTypes(componentTypes.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (componentTypes.some(componentType => componentType.hasErrors && !componentType.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = componentTypes.filter(g => g.isNew || g.isDeleted || g.hasChanges).map((c) => {
            const componentType = Object.assign({}, c);
            const isNew = componentType.isNew;
            const isDeleted = componentType.isDeleted;
            const hasChanges = componentType.hasChanges;

            delete componentType.isNew;
            delete componentType.isDeleted;
            delete componentType.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'device-component-types', method: 'POST', body: componentType})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'device-component-types/' + componentType.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({
                    endpoint: 'device-component-types/' + componentType.id,
                    method: 'PUT',
                    body: componentType
                })
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchComponentTypes();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = componentTypes.some((componentType) => componentType.hasChanges || componentType.isNew || componentType.isDeleted);
    return <React.Fragment>
        {hasError && <Alert onClose={() => setHasError(null)}
                            severity='error'>{t('settings.deviceComponentType.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['deviceComponentTypes.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.deviceComponentType.addComponentType')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchComponentTypes}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.deviceComponentType.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.deviceComponentType.description')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {componentTypes.filter((g) => !g.isDeleted).map((componentType) => (
                        <Row key={componentType.id} componentType={componentType}
                             onDelete={() => {
                                 setComponentTypes((componentTypes) => {
                                     const i = componentTypes.findIndex((c) => c.id === componentType.id);
                                     const deleteComponentType = componentTypes[i];

                                     if (deleteComponentType.isNew) {
                                         componentTypes.splice(i, 1)
                                     } else {
                                         deleteComponentType.isDeleted = true;
                                         componentTypes[i] = deleteComponentType;
                                     }
                                     return componentTypes.slice();
                                 })
                             }}
                             onChange={(changedComponentType) => {
                                 setComponentTypes((componentTypes) => {
                                     const i = componentTypes.findIndex((c) => c.id === changedComponentType.id);
                                     componentTypes[i] = changedComponentType;
                                     return componentTypes.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
            {componentTypes.filter((g) => !g.isDeleted).length === 0 &&
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('settings.deviceComponentType.noComponentTypesCreated')}</Typography>
                    <Link variant='body2' onClick={onAdd}>{t('settings.deviceComponentType.addComponentType')}</Link>
                </Box>
            </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
