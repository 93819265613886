import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, Replay, Save } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import Row from "./Row";
import { LoadingButton } from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [tasks, setTasks] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchTasks()
    }, []);

    const fetchTasks = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'tasks'
        })
            .then((res) => {
                setTasks(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        tasks.push({id: uuid(), name: '', description: '', isNew: true})
        setTasks(tasks.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (tasks.some(task => task.hasErrors && !task.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = tasks.filter(g => g.isNew || g.isDeleted || g.hasChanges).map((t) => {
            const task = Object.assign({}, t);

            const isNew = task.isNew;
            const isDeleted = task.isDeleted;
            const hasChanges = task.hasChanges;

            delete task.isNew;
            delete task.isDeleted;
            delete task.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'tasks', method: 'POST', body: task})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'tasks/' + task.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'tasks/' + task.id, method: 'PUT', body: task})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchTasks();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = tasks.some((task) => task.hasChanges || task.isNew || task.isDeleted);
    return <React.Fragment>
        {hasError && <Alert onClose={() => setHasError(null)} severity='error'>{t('settings.tasks.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['tasks.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.tasks.addTask')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchTasks}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.tasks.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.tasks.description')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.tasks.averageDuration')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tasks.filter((g) => !g.isDeleted).map((task) => (
                        <Row key={task.id} task={task}
                             onDelete={() => {
                                 setTasks((tasks) => {
                                     const i = tasks.findIndex((c) => c.id === task.id);
                                     const deleteTask = tasks[i];

                                     if (deleteTask.isNew) {
                                         tasks.splice(i, 1)
                                     } else {
                                         deleteTask.isDeleted = true;
                                         tasks[i] = deleteTask;
                                     }
                                     return tasks.slice();
                                 })
                             }}
                             onChange={(changedTask) => {
                                 setTasks((tasks) => {
                                     const i = tasks.findIndex((c) => c.id === changedTask.id);
                                     tasks[i] = changedTask;
                                     return tasks.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
            {tasks.filter((g) => !g.isDeleted).length === 0 &&
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('settings.tasks.noTasksCreated')}</Typography>
                    <Link variant='body2' onClick={onAdd}>{t('settings.tasks.addTask')}</Link>
                </Box>
            </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
