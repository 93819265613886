import {CALL_API} from '../middleware/api'

export const TASK_LIST_REQUEST = 'TASK_LIST_REQUEST';
export const TASK_LIST_SUCCESS = 'TASK_LIST_SUCCESS';
export const TASK_LIST_FAILURE = 'TASK_LIST_FAILURE';


export function fetchTaskList() {

    return {
        [CALL_API]: {
            endpoint: 'tasks',
            method: 'GET',
            types: [TASK_LIST_SUCCESS, TASK_LIST_FAILURE, TASK_LIST_REQUEST]
        }
    }
}

