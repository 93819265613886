import {
    NOTIFICATION_COUNT_FAILURE,
    NOTIFICATION_COUNT_REQUEST,
    NOTIFICATION_COUNT_SUCCESS
} from "../actions/notificationActions";

export function notificationReducer(state = {
    isFetching: false,
    error: null,
    total: 0,
}, action) {
    switch (action.type) {
        case NOTIFICATION_COUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case NOTIFICATION_COUNT_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case NOTIFICATION_COUNT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                total: parseInt(action.headers['x-total-count'] || "0", 10)
            })
        default:
            return state
    }
}

