import React from 'react';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import './styles/styles.css'
import 'leaflet/dist/leaflet.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {createRoot} from "react-dom/client";
import AwsRumSingleton from "./helper/awsRum";
AwsRumSingleton.initialize();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);
reportWebVitals();
