import { Box, CircularProgress, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { History as HistoryIcon } from "@mui/icons-material";
import React from "react";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import moment from "moment";

const History = ({loading, ticket, statusList, userList}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const statusHistory = ticket.statusHistory || [];
    const statusChangeList = statusHistory.slice().sort((a, b) => moment(a.timestamp).diff(b.timestamp)).map((statusChange, i) => {
        let userName = t('deleted');
        const user = userList.find((user) => user.id === statusChange.userId);
        if (user) {
            userName = user.givenName + ' ' + user.familyName;
        }
        const oldStatus = statusList.find((s) => s.id === statusChange.oldStatusId)?.name || t('deleted');
        const newStatus = statusList.find((s) => s.id === statusChange.newStatusId)?.name || t('deleted');

        return <>
            <ListItem>
            <ListItemText
                secondary={moment(statusChange.timestamp).format("DD.MM.YYYY HH:mm")}
                primary={<Box dangerouslySetInnerHTML={{
                    __html: t('tickets.history.statusChange', {
                        userName: userName,
                        oldStatus: oldStatus,
                        newStatus: newStatus
                    })
                }}/>}
            />
        </ListItem>
            {i < statusHistory.length - 1 && <Divider variant='middle'/>}
            </>
    })

    return <List disablePadding>
        {(!loading && statusHistory.length > 0) && <Box sx={{overflow: 'auto'}}>{statusChangeList}</Box>}
        {loading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
            <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        </Box>}
        {(!loading && statusHistory.length <= 0) &&
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
                <HistoryIcon sx={{fontSize: 60}}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('tickets.history.noHistory')}</Typography>
                </Box>
            </Box>}
    </List>
}

export default History;
