import React from 'react'
import {Grid, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {Map, Tour} from "@mui/icons-material";
import {Route, Routes} from "react-router";
import {Link, Navigate} from "react-router-dom";
import MapSettings from './Map/Settings'
import {useSelector} from "react-redux";
import ToursRouter from "./Tours/Router";
import {useMatchPaths} from "../../Utils/useMatchPaths";

const Router = () => {
    const {t} = useTranslation();
    const paths = ['/settings/customers/tours', '/settings/customers/map'];
    let currentTab = useMatchPaths(paths);

    let redirect = null;
    const user = useSelector((state) => state.user)
    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

    const routes = []
    const items = [];
    if (hasPermission(['tours.read'])) {
        redirect = '/settings/customers/tours'
        items.push(<ListItemButton component={Link} to={'/settings/customers/tours'}
                                   selected={currentTab === '/settings/customers/tours'}>
            <ListItemIcon><Tour/></ListItemIcon>
            <ListItemText primary={t('settings.tours.label')}/>
        </ListItemButton>)
        routes.push(<Route path='tours/*' element={<ToursRouter/>}/>)
    }
    if (hasPermission(['customerMap.read'])) {
        redirect = redirect || '/settings/customers/map'
        items.push(<ListItemButton component={Link} to={'/settings/customers/map'}
                                   selected={currentTab === '/settings/customers/map'}>
            <ListItemIcon><Map/></ListItemIcon>
            <ListItemText primary={t('settings.customerMap.label')}/>
        </ListItemButton>)
        routes.push(<Route path='map' element={<MapSettings/>}/>)
    }

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'}
                           element={<Navigate to={redirect}/>}/>)
    }

    return <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
            <List component={Paper}>
                {items}
            </List>
        </Grid>
        <Grid item xs={12} md={9}>
            <Routes>
                {routes}
            </Routes>
        </Grid>
    </Grid>;

}

export default Router
