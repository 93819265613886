import React from 'react'
import {Grid, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {Group, Person} from "@mui/icons-material";
import {Route, Routes} from "react-router";
import {Link, Navigate} from "react-router-dom";
import UserList from './User/List'
import GroupList from './Groups/List'
import RoleRouter from './Roles/Router'
import {useSelector} from "react-redux";
import {useMatchPaths} from "../../Utils/useMatchPaths";

const Router = () => {
    const {t} = useTranslation();
    const paths = ['/settings/user/user', '/settings/user/groups', '/settings/user/roles'];
    let currentTab = useMatchPaths(paths);

    let redirect = null;
    const user = useSelector((state) => state.user)
    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

    const routes = []
    const items = [];
    if (hasPermission(['user.list'])) {
        redirect = '/settings/user/user'
        items.push(<ListItemButton key='user' component={Link} to={'/settings/user/user'}
                                   selected={currentTab === '/settings/user/user'}>
            <ListItemIcon><Person/></ListItemIcon>
            <ListItemText primary={t('settings.user.label')}/>
        </ListItemButton>)
        routes.push(<Route key='user' path='user' element={<UserList/>}/>)
    }
    if (hasPermission(['groups.read'])) {
        redirect = redirect || '/settings/user/groups'
        items.push(<ListItemButton key='groups' component={Link} to={'/settings/user/groups'}
                                   selected={currentTab === '/settings/user/groups'}>
            <ListItemIcon><Group/></ListItemIcon>
            <ListItemText primary={t('settings.groups.label')}/>
        </ListItemButton>)
        routes.push(<Route key='groups' path='groups' element={<GroupList/>}/>)
    }

    if (hasPermission(['roles.read'])) {
        redirect = redirect || '/settings/user/roles'
        items.push(<ListItemButton key='roles' component={Link} to={'/settings/user/roles'}
                                   selected={currentTab === '/settings/user/roles'}>
            <ListItemIcon><Group/></ListItemIcon>
            <ListItemText primary={t('settings.roles.label')}/>
        </ListItemButton>)
        routes.push(<Route key='roles' path='roles/*' element={<RoleRouter/>}/>)
    }

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'}
                           element={<Navigate to={redirect}/>}/>)
    }


    return <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <List component={Paper}>
                {items}
            </List>
        </Grid>
        <Grid item xs={12} sm={9}>
            <Routes>
                {routes}
            </Routes>
        </Grid>
    </Grid>;

}

export default Router
