import {CALL_API} from '../middleware/api'

export const PROPERTY_LIST_REQUEST = 'PROPERTY_LIST_REQUEST';
export const PROPERTY_LIST_SUCCESS = 'PROPERTY_LIST_SUCCESS';
export const PROPERTY_LIST_FAILURE = 'PROPERTY_LIST_FAILURE';


export function fetchPropertyList() {

    return {
        [CALL_API]: {
            endpoint: 'properties',
            method: 'GET',
            types: [PROPERTY_LIST_SUCCESS, PROPERTY_LIST_FAILURE, PROPERTY_LIST_REQUEST]
        }
    }
}

