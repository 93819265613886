import React, { useCallback, useEffect, useState } from 'react'
import qs from 'query-string';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ChevronLeft, Replay, Save } from "@mui/icons-material";
import {Link as RouterLink, useLocation} from "react-router-dom";
import { fetchTagList } from "../../../actions/tagActions";
import { fetchPropertyList } from "../../../actions/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeviceTypes } from "../../../actions/deviceTypeActions";
import Api from "../../../core/Api";
import { cloneDeep } from "lodash";
import TagSelect from "../../Utils/TagSelect";
import PropertyInput from "../../Utils/PropertyInput";
import Schema from "validate";
import { push } from "@lagunovsky/redux-react-router";
import { formatAddress } from "../../../helper/formatAddress";
import CustomerSelect from "../../Utils/CustomerSelect";

const getValidationSchema = (t, numberRequired) => {
    return new Schema({
        customerId: {
            required: true,
            type: String,
            message: t('devices.validation.customerId')
        },
        number: {
            required: numberRequired,
            type: String,
            message: t('devices.validation.number')
        },
        brand: {
            required: true,
            type: String,
            message: t('devices.validation.brand'),
        },
        model: {
            required: true,
            type: String,
            message: t('devices.validation.brand'),
        }
    })
}

const Create = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [customerId, setCustomerId] = useState(null);
    const [customer, setCustomer] = useState({});
    const [addressId, setAddressId] = useState(null);
    const [saving, setSaving] = useState(false);
    const [errorList, setErrorList] = useState({});

    const tagList = useSelector((state) => state.tags.list)
    const propertyList = useSelector((state) => state.properties.list)
    const deviceTypeList = useSelector((state) => state.deviceTypes.list)
    const loading = useSelector((state) => state.tags.isFetching || state.properties.isFetching || state.deviceTypes.isFetching || state.numberRange.isFetching)

    const location = useLocation();
    const parsedQuery = qs.parse(location.search);
    const queryCustomerId = parsedQuery.customerId;
    const queryAddressId = parsedQuery.addressId;

    const [device, setDevice] = useState({
        generateNumber: true,
        number: '',
        brand: '',
        model: '',
        image: '',
        components: [],
        tags: [],
        properties: [],
        typeId: null,
        addressId: null,
        customerId: null,
        locationId: null,
    });

    const handleChange = (key, value) => {
        setDevice((d) => ({
            ...d,
            [key]: value
        }))
    }


    const handlePropertyChange = (id, value) => {
        let internalValue = value ? value.toString() : value;
        if (value === '') {
            internalValue = null;
        }

        const properties = device.properties
        const index = properties.findIndex((property) => property.id === id)
        if (index === -1) {
            properties.push({
                id: id,
                value: internalValue
            })
        } else {
            properties[index]['value'] = internalValue
        }

        setDevice({
            ...device,
            properties: properties.slice()
        })
    }

    useEffect(() => {
        if (device.typeId === null && deviceTypeList.length) {
            setDevice((d) => {
                d.typeId = deviceTypeList[0].id;
                return cloneDeep(d);
            })
        }
    }, [deviceTypeList, device.typeId])

    useEffect(() => {
        if (addressId) {
            setDevice((d) => {
                d.addressId = addressId;
                const address = customer.addresses?.find((address) => address.id === addressId);
                if (!address) {
                    return cloneDeep(d);
                }

                const locationIds = address.locations.map((location) => location.id);
                if (d.locationId === null || !locationIds.includes(d.locationId)) {
                    d.locationId = locationIds[0];
                }

                return cloneDeep(d);
            })
        }
    }, [addressId, customer.addresses])

    useEffect(() => {
        setCustomerId(queryCustomerId || null);
        setAddressId(queryAddressId || null);
    }, [queryCustomerId, queryAddressId]);

    const onSave = () => {
        setSaving(true)
        setErrorList({})

        const v = getValidationSchema(t, !device.generateNumber)
        const saveDevice = cloneDeep(device);
        saveDevice.customerId = customerId;
        const errors = v.validate(cloneDeep(saveDevice))
        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })


            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'devices',
            body: saveDevice,
            method: 'POST'
        }).then((res) => {
            dispatch(push('/customers/show/' + res.response.customerId + '/addresses/' + res.response.addressId + '/devices/' + res.response.id))
        }, () => {
        }).then(() => setSaving(false))
    }

    const fetchData = useCallback(() => {
        dispatch(fetchTagList())
        dispatch(fetchPropertyList())
        dispatch(fetchDeviceTypes())
    }, [dispatch])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const onCustomerChange = (customer) => {
        setCustomer(customer);
        const addressIds = customer.addresses.map((address) => address.id);
        if (!addressIds.includes(addressId)) {
            setAddressId(addressIds[0]);
        }

        setCustomerId(customer.id);
    }

    let backlink = '/devices';
    if (queryCustomerId) {
        backlink = '/customers/show/' + queryCustomerId + '/addresses/all/devices';
    }
    if (queryCustomerId && queryAddressId) {
        backlink = '/customers/show/' + queryCustomerId + '/addresses/' + queryAddressId + '/devices';
    }

    const properties = propertyList.filter((property) => property.areas.includes('device')).map((property) => {
        const value = device.properties.find((propertyValue) => propertyValue.id === property.id)?.value
        return <TableRow key={property.id}>
            <TableCell>{property.name}</TableCell>
            <TableCell>{property.description}</TableCell>
            <TableCell>
                <PropertyInput property={property} value={value} onChange={handlePropertyChange}/>
            </TableCell>
        </TableRow>
    })

    const locations = customer?.addresses?.find((address) => address.id === addressId)?.locations || [];

    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true} sx={{mb: 1}}>
            <LoadingButton
                loadingPosition="start"
                disabled={loading}
                loading={saving}
                onClick={onSave}
                startIcon={<Save/>}
                variant='contained'
                color='primary'>{t('devices.create.saveNew')}</LoadingButton>
            <Button sx={{ml: 2}} component={RouterLink} to={backlink}
                    startIcon={<ChevronLeft/>}
                    color='primary'>{t('back')}</Button>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchData}><Replay/></IconButton>
        </Toolbar>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper sx={{p: 2}}>
                    <Typography variant='h5' sx={{mb: 2}}
                                component='h1'>{t('devices.create.customer')}</Typography>
                    <CustomerSelect onChange={onCustomerChange} externalCustomerId={customerId}
                        error={errorList.customerId}
                    />
                    <FormControl fullWidth margin='dense'>
                        <InputLabel shrink={Boolean(customerId)}
                                    id='address-label'>{t('devices.fields.address')}</InputLabel>
                        <Select
                            notched={Boolean(customerId)}
                            label={t('devices.fields.address')}
                            labelId='address-label'
                            value={device.addressId}
                            disabled={!customerId}
                            fullWidth
                            labelWidth={100}
                            onChange={(e) => setAddressId(e.target.value)}
                        >
                            {customer.addresses?.map((a) => (<MenuItem key={a.id} value={a.id}>
                                <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                                    <Chip size='small' sx={{ml: 2}}
                                          label={t('customers.address.main')}/> : null}</React.Fragment>}
                                              secondary={formatAddress(a)}/>
                            </MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel shrink={Boolean(customerId)}>{t('devices.fields.location')}</InputLabel>
                        <Select
                            notched={Boolean(customerId)}
                            disabled={!customerId}
                            label={('devices.fields.location')}
                            value={device.locationId}
                            fullWidth
                            onChange={(e) => handleChange('locationId', e.target.value)}
                        >
                            {locations.map((t) => (<MenuItem key={t.id} value={t.id}>
                                {t.name}
                            </MenuItem>))}
                        </Select>
                    </FormControl>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                {customerId == null && <Alert severity='info'>{t('devices.create.selectCustomerToProceed')}</Alert>}
                {Boolean(customerId) && <React.Fragment>
                    <Card>
                        <CardHeader title={t('devices.create.information')}/>
                        <CardContent sx={{pt: 0}}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={device.generateNumber}
                                    onChange={(e) => handleChange('generateNumber', e.target.checked)}
                                />}
                                label={t('devices.create.generateNumber')}
                            />
                            <Collapse in={!device.generateNumber}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    required
                                    error={errorList.hasOwnProperty('number')}
                                    helperText={errorList.number}
                                    margin='dense'
                                    label={t('devices.fields.number')}
                                    placeholder={t('devices.fields.number')}
                                    value={device.number}
                                    onChange={(e) => handleChange('number', e.target.value)}
                                />
                            </Collapse>
                            <TextField
                                fullWidth
                                margin='dense'
                                required
                                error={errorList.hasOwnProperty('brand')}
                                helperText={errorList.brand}
                                label={t('devices.fields.brand')}
                                placeholder={t('devices.fields.brand')}
                                value={device.brand}
                                onChange={(e) => handleChange('brand', e.target.value)}
                            />
                            <TextField
                                fullWidth
                                margin='dense'
                                required
                                error={errorList.hasOwnProperty('model')}
                                helperText={errorList.model}
                                label={t('devices.fields.model')}
                                placeholder={t('devices.fields.model')}
                                value={device.model}
                                onChange={(e) => handleChange('model', e.target.value)}
                            />
                            <FormControl fullWidth margin='dense'>
                                <InputLabel shrink>{t('devices.fields.type')}</InputLabel>
                                <Select
                                    notched
                                    label={('devices.fields.type')}
                                    value={device.typeId}
                                    fullWidth
                                    onChange={(e) => handleChange('typeId', e.target.value)}
                                >
                                    {deviceTypeList.map((t) => (<MenuItem key={t.id} value={t.id}>
                                        {t.name}
                                    </MenuItem>))}
                                </Select>
                            </FormControl>
                            <TagSelect
                                multiple
                                margin='dense'
                                sx={{mt: 1}}
                                tags={tagList}
                                label={t('devices.fields.tags')} value={device.tags}
                                onChange={(v) => handleChange('tags', v || null)}/>
                        </CardContent>
                    </Card>
                    {properties.length > 0 && <Card sx={{mt: 2}}>
                        <CardHeader title={t('devices.properties')}/>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('devices.fields.property')}
                                        </TableCell>
                                        <TableCell>
                                            {t('devices.fields.description')}
                                        </TableCell>
                                        <TableCell>
                                            {t('devices.fields.value')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {properties}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>}
                </React.Fragment>}
            </Grid>
        </Grid>
    </React.Fragment>
}

export default Create;
