import { Box } from "@mui/material";
import React from "react";
import ShowDevice from "./Show";
import DeviceList from "../../../Devices/Utils/DeviceList";

const ListDevices = ({customer, addressId, deviceId}) => {
    const urlParams = new URLSearchParams();

    const fixedFilters = {
        customerIds: customer.id
    }

    urlParams.set('customerId', customer.id);
    if (addressId !== "all") {
        fixedFilters.addressIds = addressId;
        urlParams.set('addressId', addressId);
    }

    return <Box sx={{p: 2}}>
        {!Boolean(deviceId) && <DeviceList
            deviceLinkAddress={addressId}
            createParams={urlParams} hiddenColumns={['customer', 'address']} fixedFilters={fixedFilters}/>}
        {Boolean(deviceId) &&
            <ShowDevice deviceId={deviceId} customer={customer} addressId={addressId} />}
    </Box>
}

export default ListDevices;
