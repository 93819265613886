import { Button, Dialog, DialogActions, DialogTitle, IconButton, InputAdornment, TextField } from "@mui/material";
import { Close, FileOpen } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import ConnectionFileBrowser from "./ConnectionFileBrowser";
import { Box } from "@mui/system";

const ConnectionFilePicker = ({path, onSelect, connectionId, accept, sx, disabled}) => {
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();

    const onInternalSelect = (path) => {
        if (path) {
            setOpen(false);
        }
        onSelect(path);
    }

    return <Box sx={sx}>
        <TextField
            onClick={() => {
                if (!disabled) {
                    setOpen(true);
                }
            }}
            fullWidth
            disabled={disabled}
            value={path || ''}
            placeholder={t('filePicker.selectFile')}
            margin='normal'
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton edge="end" color="primary" disabled={disabled}>
                            <FileOpen/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
            <DialogTitle>
                {t('filePicker.selectFile')}
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>

            </DialogTitle>
            <ConnectionFileBrowser accept={accept} onSelect={onInternalSelect} connectionId={connectionId} selected={path}/>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    </Box>;
}
export default ConnectionFilePicker
