import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Toolbar
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Delete, Edit, Replay, Save, Send } from "@mui/icons-material";
import Api from "../../../../core/Api";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton
} from '@mui/x-data-grid';
import TextField from "@mui/material/TextField";
import Schema from "validate";
import { LoadingButton } from "@mui/lab";
import DeleteDialog from "../../../Utils/DeleteDialog";
import * as EmailValidator from "email-validator";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupList } from "../../../../actions/groupActions";
import CheckPermissions from "../../../Utils/CheckPermissions";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
        </GridToolbarContainer>
    );
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getUsers = async () => {
    let users = [];
    let paginationToken = '';

    while (true) {
        const options = {
            endpoint: 'user/user',
            parameter: {
                limit: 50
            }
        };
        if (paginationToken !== '') {
            options.parameter.paginationToken = paginationToken;
        }
        try {
            const response = await Api.fetch(options)
            users = [...users, ...response.response.users]

            if (response.response.paginationToken == null) {
                return users;
            }

            paginationToken = response.response.paginationToken
        } catch (e) {
            return [];
        }
    }

};

const email = (val) => EmailValidator.validate(val);
const getValidationSchema = (t) => {
    return new Schema({
        email: {
            required: true,
            type: String,
            use: {email},
            message: t('settings.user.emailRequired'),
        },
    })
}

const getEditValidationSchema = (t) => {
    return new Schema({})
}

const columns = (t, setDeleteUserId, openEditDialog, resend, roles, groups, permissions) => ([
    {
        field: 'id',
        editable: false,
        headerName: 'ID',
        width: 300,
        minWidth: 300,
        hide: true,
        flex: 1,
    },
    {
        field: 'email',
        editable: false,
        minWidth: 350,
        headerName: t('settings.user.email'),
        flex: 1,
    },
    {
        minWidth: 100,
        field: 'givenName',
        editable: false,
        headerName: t('settings.user.givenName'),
        flex: 1,
    },
    {
        minWidth: 100,
        field: 'familyName',
        editable: false,
        headerName: t('settings.user.familyName'),
        flex: 1,
    },
    {
        field: 'type',
        editable: false,
        headerName: t('settings.user.type'),
        minWidth: 150,
        renderCell: ({value}) => t('settings.user.types.' + value),
        flex: 1,
    },
    {
        field: 'enabled',
        editable: false,
        headerName: t('settings.user.enabled'),
        type: 'boolean',
        minWidth: 80,
        flex: 1,
    },
    {
        field: 'emailVerified',
        editable: false,
        headerName: t('settings.user.emailVerified'),
        type: 'boolean',
        minWidth: 150,
        flex: 1,
    },
    {
        field: 'roles',
        editable: false,
        headerName: t('settings.user.roles'),
        minWidth: 150,
        renderCell: ({value}) => value.filter((v) => roles.find(r => r.id === v)).map((v) => roles.find(r => r.id === v).name).join(', '),
        flex: 1,
    },
    {
        field: 'groups',
        editable: false,
        headerName: t('settings.user.groups'),
        minWidth: 150,
        renderCell: ({value}) => value.filter((v) => groups.find(r => r.id === v)).map((v) => groups.find(r => r.id === v).name).join(', '),
        flex: 1
    },
    {
        field: 'status',
        editable: false,
        headerName: t('settings.user.status.label'),
        minWidth: 120,
        renderCell: (params) => {
            const {value} = params;
            let color = 'warning';
            if (['CONFIRMED'].indexOf(value) >= 0) {
                color = 'primary';
            } else if (['ARCHIVED'].indexOf(value) >= 0) {
                color = 'default';
            }
            return <Chip color={color} label={t('settings.user.status.' + value)}/>;
        },
        flex: 1,
    },
    {
        field: 'createdAt',
        minWidth: 200,
        hide: true,
        editable: false,
        headerName: t('settings.user.createdAt'),
        type: 'dateTime',
        valueGetter: ({value}) => value && new Date(value),
        flex: 1,
    },
    {
        minWidth: 200,
        hide: true,
        field: 'updatedAt',
        editable: false,
        headerName: t('settings.user.updatedAt'),
        type: 'dateTime',
        valueGetter: ({value}) => value && new Date(value),
        flex: 1,
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: t('actions'),
        getActions: (params) => {
            const actions = [];

            if (permissions.indexOf('user.delete') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem icon={<Delete/>} onClick={() => setDeleteUserId(params.id)}
                                                  label={t('settings.user.deleteUser')} showInMenu/>);
            }

            if (permissions.indexOf('user.write') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem icon={<Edit/>} onClick={() => openEditDialog(params.id)}
                                                  label={t('settings.user.editUser')}/>);

                actions.push(<GridActionsCellItem icon={<Send/>} onClick={() => resend(params.id)}
                                                  label={t('settings.user.resendConfirmationMail')} showInMenu/>);
            }

            return actions;
        }
    }
]);

const List = () => {
    const dispatch = useDispatch();
    const [errorList, setErrorList] = useState({});
    const [users, setUsers] = React.useState([]);

    const [inviteEmail, setInviteEmail] = React.useState('');
    const [familyName, setFamilyName] = React.useState('');
    const [givenName, setGivenName] = React.useState('');
    const [userType, setUserType] = React.useState('full');
    const [roles, setRoles] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    const [roleDefinitions, setRoleDefinitions] = React.useState([]);

    const [deleteUserId, setDeleteUserId] = React.useState(null);
    const [inviteOpen, setInviteOpen] = React.useState(false);
    const [inviting, setInviting] = React.useState(false);
    const [userLoading, setUserLoading] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const [updating, setUpdating] = React.useState(false);
    const [editUserId, setEditUserId] = React.useState(null);
    const [editFamilyName, setEditFamilyName] = React.useState('');
    const [editGivenName, setEditGivenName] = React.useState('');
    const [editRoles, setEditRoles] = React.useState([]);
    const [editGroups, setEditGroups] = React.useState([]);
    const [editType, setEditType] = React.useState('');

    const [subscription, setSubscription] = useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = useState(false);

    useEffect(() => {
        setSubscriptionLoading(true);
        Api.fetch({
            endpoint: 'system/subscription',
            ignoreErrorCodes: [404]
        }).then(res => {
            setSubscription(res.response);
        }, () => {
        }).then(() => setSubscriptionLoading(false));
    }, []);

    const groupDefinitions = useSelector(state => state.groups) || {list: []}
    const user = useSelector((state) => state.user)


    const openEditDialog = (userId) => {
        const user = users.find(u => u.id === userId);
        setEditUserId(userId);
        setEditFamilyName(user.familyName);
        setEditGivenName(user.givenName);
        setEditType(user.type);
        setEditRoles(user.roles);
        setEditGroups(user.groups);
    };

    const closeEditDialog = () => {
        setEditUserId(null);
        setEditFamilyName('');
        setEditGivenName('');
        setEditRoles([]);
        setEditGroups([]);
    }

    const onEdit = (e) => {
        e.preventDefault();

        setUpdating(true);
        setErrorList({})

        const updateData = {
            givenName: editGivenName,
            familyName: editFamilyName,
            groups: editGroups,
            roles: editRoles,
            type: editType
        }

        const v = getEditValidationSchema(t)
        const errors = v.validate(Object.assign({}, updateData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setUpdating(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'user/user/' + editUserId,
            method: "PUT",
            body: updateData
        }).then((res) => {
            closeEditDialog()
            getUserList();
        }, (err) => {
        }).then(() => {
            setUpdating(false);
        });
    }

    const onInvite = (e) => {
        e.preventDefault();

        setInviting(true);
        setErrorList({})
        const inviteData = {
            email: inviteEmail,
            givenName: givenName,
            familyName: familyName,
            groups: groups,
            roles: roles,
            type: userType
        }

        const v = getValidationSchema(t)
        const errors = v.validate(Object.assign({}, inviteData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })
            setInviting(false);
            setErrorList(errorObject)
            return;
        }


        Api.fetch({
            endpoint: 'user/user',
            method: "POST",
            body: inviteData
        }).then((res) => {
            setInviteOpen(false)
            getUserList();
        }, (err) => {
        }).then(() => {
            setInviting(false);
        });
    }

    const onDelete = () => {
        setDeleting(true);

        Api.fetch({
            endpoint: 'user/user/' + deleteUserId,
            method: "DELETE",
        }).then((res) => {
            setDeleteUserId(null)
            getUserList();
        }, (err) => {
        }).then(() => {
            setDeleting(false);
        });
    }

    const resend = (id) => {
        Api.fetch({
            endpoint: 'user/user/' + id + '/resend-invite',
            method: "POST",
        }).then((res) => {
        }, (err) => {
        });
    }

    const getUserList = () => {
        setUserLoading(true)
        getUsers().then((users => setUsers(users))).then(() => setUserLoading(false));
    }

    const getRoleList = () => {
        Api.fetch({
            endpoint: 'roles',
            method: "GET",
        }).then((res) => {
            setRoleDefinitions(res.response)
        }, (err) => {
        });
    }


    useEffect(() => {
        getRoleList();
        getUserList();
        dispatch(fetchGroupList());
    }, [dispatch]);

    useEffect(() => {
        if(editType !== 'full' && editRoles.includes('administrator')) {
            setEditRoles((er) => er.filter(role => role !== 'administrator'));
        }
    }, [editType, setEditRoles, editRoles]);

    useEffect(() => {
        if(userType !== 'full' && roles.includes('administrator')) {
            setRoles((r) => r.filter(role => role !== 'administrator'));
        }
    }, [userType, roles]);

    let deleteUserEmail = '';
    if (deleteUserId) {
        const user = users.find(user => user.id === deleteUserId);
        if (user) {
            deleteUserEmail = user.email;
        }
    }

    const getPriceByType = (type) => {
        const fullPrice = subscription?.units.find((u) => u.identifier === 'users')?.price || 0;
        const ticketCreatorPrice = subscription?.units.find((u) => u.identifier === 'ticketCreators')?.price || 0;
        if (type === 'full') {
            return fullPrice.toFixed(2) + '€ ' + t('settings.user.perMonth')
        }

        if (type === 'ticket-creator') {
            return ticketCreatorPrice.toFixed(2) + '€ ' + t('settings.user.perMonth')
        }

        return '0.00€ ' + t('settings.user.perMonth')
    }


    const {t} = useTranslation();
    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['user.write']}>
                <Button size='small' disabled={subscriptionLoading} onClick={() => {
                    setInviteEmail('');
                    setRoles([]);
                    setGroups([]);
                    setGivenName('');
                    setFamilyName('');
                    setInviteOpen(true)
                }} startIcon={<Add/>} variant='outlined'
                        color='primary'>{t('settings.user.addUser')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={getUserList}><Replay/></IconButton>
        </Toolbar>
        <Paper>
            <DataGrid
                disableSelectionOnClick={true}
                loading={userLoading}
                autoHeight
                components={{
                    Toolbar: CustomToolbar,
                }}
                rows={users}
                columns={columns(t, setDeleteUserId, openEditDialog, resend, roleDefinitions, groupDefinitions.list, user.user.permissions)}
                pageSize={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
            />
        </Paper>
        <Dialog
            open={inviteOpen}
            onClose={() => setInviteOpen(false)}
        >
            <DialogTitle>
                {t('settings.user.addUser')}
            </DialogTitle>
            <form onSubmit={onInvite}>
                <DialogContent>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        disabled={inviting}
                        name="email"
                        label={t('settings.user.email')}
                        type="text"
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        error={errorList.hasOwnProperty('email')} helperText={errorList.email}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                disabled={inviting}
                                name="givenName"
                                label={t('settings.user.givenName')}
                                type="text"
                                value={givenName}
                                onChange={(e) => setGivenName(e.target.value)}
                                error={errorList.hasOwnProperty('givenName')} helperText={errorList.givenName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                disabled={inviting}
                                name="familyName"
                                label={t('settings.user.familyName')}
                                type="text"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                                error={errorList.hasOwnProperty('familyName')} helperText={errorList.familyName}
                            />
                        </Grid>
                    </Grid>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel>{t('settings.user.type')}</InputLabel>
                        <Select
                            value={userType}
                            onChange={(e) => {
                                setUserType(e.target.value)
                            }}
                            input={<OutlinedInput label={t('settings.user.type')}/>}
                            MenuProps={MenuProps}
                        >
                            {['full', 'ticket-creator', 'test'].map((r) => (
                                <MenuItem key={r} value={r}>
                                    <ListItemText
                                        primary={t('settings.user.types.' + r)}
                                        secondary={Boolean(subscription) ? getPriceByType(r) : null}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel>{t('settings.user.roles')}</InputLabel>
                        <Select
                            multiple
                            value={roles}
                            onChange={(e) => {
                                setRoles(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                            }}
                            input={<OutlinedInput label={t('settings.user.roles')}/>}
                            renderValue={
                                (selected) => selected.filter((v) => roleDefinitions.find(r => r.id === v)).map((v) => roleDefinitions.find(r => r.id === v).name).join(', ')
                            }
                            MenuProps={MenuProps}
                        >
                            {roleDefinitions.map((r) => (
                                <MenuItem key={r.id} value={r.id} disabled={userType !== 'full' && r.id === 'administrator'}>
                                    <Checkbox checked={roles.includes(r.id)}/>
                                    <ListItemText primary={r.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel>{t('settings.user.groups')}</InputLabel>
                        <Select
                            multiple
                            value={groups}
                            onChange={(e) => {
                                setGroups(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                            }}
                            input={<OutlinedInput label={t('settings.user.groups')}/>}
                            renderValue={
                                (selected) => selected.filter((v) => groupDefinitions.list.find(r => r.id === v)).map((v) => groupDefinitions.list.find(r => r.id === v).name).join(', ')
                            }
                            MenuProps={MenuProps}
                        >
                            {groupDefinitions.list.map((r) => (
                                <MenuItem key={r.id} value={r.id}>
                                    <Checkbox checked={groups.includes(r.id)}/>
                                    <ListItemText primary={r.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={inviting} onClick={() => setInviteOpen(false)}>{t('cancel')}</Button>
                    <LoadingButton
                        loadingPosition="start"
                        loading={inviting}
                        onClick={onInvite}
                        startIcon={<Save/>}
                        type="submit"
                        autoFocus variant='contained' color='primary'>
                        {t('settings.user.inviteUser')}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
        <Dialog
            open={Boolean(editUserId)}
            onClose={() => closeEditDialog()}
        >
            <DialogTitle>
                {t('settings.user.editUser')}
            </DialogTitle>
            <form onSubmit={onEdit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                disabled={updating}
                                name="givenName"
                                label={t('settings.user.givenName')}
                                type="text"
                                value={editGivenName}
                                onChange={(e) => setEditGivenName(e.target.value)}
                                error={errorList.hasOwnProperty('givenName')} helperText={errorList.givenName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                disabled={updating}
                                name="familyName"
                                label={t('settings.user.familyName')}
                                type="text"
                                value={editFamilyName}
                                onChange={(e) => setEditFamilyName(e.target.value)}
                                error={errorList.hasOwnProperty('familyName')} helperText={errorList.familyName}
                            />
                        </Grid>
                    </Grid>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel>{t('settings.user.type')}</InputLabel>
                        <Select
                            value={editType}
                            onChange={(e) => {
                                setEditType(e.target.value)
                            }}
                            input={<OutlinedInput label={t('settings.user.type')}/>}
                            MenuProps={MenuProps}
                        >
                            {['full', 'ticket-creator', 'test'].map((r) => (
                                <MenuItem key={r} value={r}>
                                    <ListItemText
                                        primary={t('settings.user.types.' + r)}
                                        secondary={Boolean(subscription) ? getPriceByType(r) : null}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel>{t('settings.user.roles')}</InputLabel>
                        <Select
                            multiple
                            value={editRoles}
                            onChange={(e) => {
                                setEditRoles(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                            }}
                            input={<OutlinedInput label={t('settings.user.roles')}/>}
                            renderValue={
                                (selected) => selected.filter((v) => roleDefinitions.find(r => r.id === v)).map((v) => roleDefinitions.find(r => r.id === v).name).join(', ')
                            }
                            MenuProps={MenuProps}
                        >
                            {roleDefinitions.map((r) => (
                                <MenuItem key={r.id} value={r.id} disabled={editType !== 'full' && r.id === 'administrator'}>
                                    <Checkbox checked={editRoles.includes(r.id)}/>
                                    <ListItemText primary={r.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel>{t('settings.user.groups')}</InputLabel>
                        <Select
                            multiple
                            value={editGroups}
                            onChange={(e) => {
                                setEditGroups(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                            }}
                            input={<OutlinedInput label={t('settings.user.groups')}/>}
                            renderValue={
                                (selected) => selected.filter((v) => groupDefinitions.list.find(r => r.id === v)).map((v) => groupDefinitions.list.find(r => r.id === v).name).join(', ')
                            }
                            MenuProps={MenuProps}
                        >
                            {groupDefinitions.list.map((r) => (
                                <MenuItem key={r.id} value={r.id}>
                                    <Checkbox checked={editGroups.includes(r.id)}/>
                                    <ListItemText primary={r.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={updating} onClick={() => closeEditDialog()}>{t('cancel')}</Button>
                    <LoadingButton
                        loadingPosition="start"
                        loading={updating}
                        onClick={onEdit}
                        startIcon={<Save/>}
                        type="submit"
                        autoFocus variant='contained' color='primary'>
                        {t('save')}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
        <DeleteDialog onDelete={() => onDelete()} isDeleting={deleting} title={t('settings.user.deleteUser')}
                      handleClose={() => setDeleteUserId(null)}
                      description={t('settings.user.deleteUserDescription', {email: deleteUserEmail})}
                      open={Boolean(deleteUserId)}/>
    </React.Fragment>
}

export default List;
