import React, { useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import Api from "../../../../core/Api";
import { Box } from "@mui/system";

const url = "https://www.dropbox.com/oauth2/authorize?client_id=qgb5z9j4e4v8jqj&redirect_uri=https://login.vecodesk.com/dropbox-callback&response_type=code&token_access_type=offline&state="

const Dropbox = ({loading, setLoading, id, setData, data}) => {
    const [code, setCode] = React.useState(null)
    const location = useLocation();

    useEffect(() => {
        const query = qs.parse(location.search);
        if (query.code) {
            setCode(query.code)
        }
    }, [location.search])

    useEffect(() => {
        if (code) {
            setLoading(true)
            Api.fetch({
                endpoint: 'connections/' + id + '/link-dropbox',
                method: 'POST',
                body: {
                    code
                }
            }).then((res) => setData(res.response.data), () => {
            }).then(() => {
                setLoading(false)
            })
        }
    }, [code, setData, id, setLoading])

    const state = {
        redirect: window.location.origin + "/settings/integrations/connections/edit/" + id
    }

    const {t} = useTranslation()
    return <Box sx={{p: 2}}>
        <TextField
            InputLabelProps={{
                shrink: true
            }}
            label={t('settings.connections.dropbox.status')}
            fullWidth
            margin='normal'
            disabled
            value={data?.accountId ? t('settings.connections.dropbox.connected', {accountId: data?.accountId}) : t('settings.connections.dropbox.notConnected')}

        />
        <Button fullWidth href={url + window.btoa(JSON.stringify(state))}
                sx={{mt: 1}}
                disabled={loading}
                variant='contained'
                startIcon={<OpenInNew/>}
                color='primary'>{t('settings.connections.dropbox.link')}</Button>
    </Box>
}

export default Dropbox
