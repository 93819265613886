import React, { useEffect } from 'react';
import moment from "moment";
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DateRange } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DateRangePicker = ({labelFrom, labelTo, sx, onChange, value}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [fromValue, setFromValue] = React.useState(value?.from ? moment(value?.from) : null);
    const [toValue, setToValue] = React.useState(value?.to ? moment(value?.to) : null);

    const [qValue, setQValue] = React.useState('1');
    const [qUnit, setQUnit] = React.useState('days');


    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange({from: fromValue?.clone() || null, to: toValue?.clone()} || null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromValue, toValue]);

    useEffect(() => {
        if (fromValue?.unix() > toValue?.unix()) {
            setToValue(fromValue.clone());
        }
    }, [fromValue, toValue, setToValue]);

    const handleQuickSelect = () => {
        const now = new moment();
        setToValue(now);
        setFromValue(now.clone().subtract(qValue, qUnit));
        setAnchorEl(null);
    };

    return <Box sx={{mt: 2, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...sx}}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <DateRange/>
        </IconButton>
        <Menu
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
        >
            <Box sx={{m: 1}}>
                <Typography variant="h6">{t('dateRangePicker.quickSelect')}</Typography>
                <TextField
                    value={qValue} onChange={(e) => setQValue(e.target.value)}
                    fullWidth size='small' margin='dense' label={t('dateRangePicker.value')}/>
                <FormControl fullWidth size='small' margin='dense'>
                    <InputLabel>{t('dateRangePicker.unit')}</InputLabel>
                    <Select label={t('dateRangePicker.unit')}
                            value={qUnit} onChange={(e) => setQUnit(e.target.value)}>
                        >
                        <MenuItem value='days'>
                            {t('dateRangePicker.days')}
                        </MenuItem>
                        <MenuItem value='weeks'>
                            {t('dateRangePicker.weeks')}
                        </MenuItem>
                        <MenuItem value='months'>
                            {t('dateRangePicker.months')}
                        </MenuItem>
                        <MenuItem value='years'>
                            {t('dateRangePicker.years')}
                        </MenuItem>
                    </Select>
                </FormControl>
                <Button fullWidth color='primary' size='small' sx={{mt: 1}}
                        onClick={handleQuickSelect}
                        variant='contained'>{t('dateRangePicker.select')}</Button>
            </Box>
        </Menu>
        <DatePicker label={labelFrom}
                    mask="__.__.____"
                    value={value?.from || null}
                    onChange={(newValue) => {
                        setFromValue(newValue?.unix() > 0 ? newValue.startOf('day') : null);
                    }}
                    renderInput={(params) => <TextField {...params} />}
        />
        <Box>
            <ArrowForwardIcon/>
        </Box>
        <DatePicker label={labelTo}
                    mask="__.__.____"
                    value={value?.to || null}
                    minDate={fromValue}
                    onChange={(newValue) => {
                        setToValue(newValue?.unix() > 0 ? newValue.endOf('day') : null);
                    }}
        />
    </Box>
}

export default DateRangePicker
