import {Box, Button, IconButton, List, Paper, TextField, Toolbar, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {ChevronLeft, Replay, Save} from "@mui/icons-material";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import Api from "../../../../core/Api";
import PermissionSet from "./PermissionSet";
import Schema from "validate";
import {useDispatch} from "react-redux";
import {push} from "@lagunovsky/redux-react-router";

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String,
            message: t('settings.roles.nameRequired'),
        },
    })
}

const Edit = (props) => {
    const params = useParams();
    const existingId = params.id
    const dispatch = useDispatch();

    const {t} = useTranslation()

    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');

    const [errorList, setErrorList] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [permissions, setPermissions] = React.useState([]);

    const [selectedPermissions, setSelectedPermissions] = React.useState([]);

    const fetchRole = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: `roles/` + existingId,
        }).then((res) => {
            setName(res.response.name)
            setDescription(res.response.description)
            setSelectedPermissions(res.response.permissions || [])
        }).then(() => {
            setLoading(false);
        })
    }, [setLoading, setName, setDescription, setSelectedPermissions, existingId])

    useEffect(() => {
        if (!existingId) {
            return;
        }
        fetchRole()
    }, [existingId, fetchRole])


    const onSave = () => {
        setSaving(true)
        setErrorList({})

        const data = {
            name,
            description,
            permissions: selectedPermissions
        }

        const v = getValidationSchema(t)
        const errors = v.validate(Object.assign({}, data))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: existingId ? 'roles/' + existingId : 'roles',
            method: existingId ? 'PUT' : 'POST',
            body: data
        }).then(() => {
            dispatch(push('/settings/user/roles'))
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    const fetchPermissions = () => {
        setLoading(true);
        Api.fetch({
            endpoint: 'permissions'
        })
            .then((res) => {
                setPermissions(res.response)
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchPermissions();
    }, []);

    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <LoadingButton
                onClick={onSave}
                size='small'
                loadingPosition="start"
                disabled={loading}
                loading={saving}
                startIcon={<Save/>}
                variant='contained'
                color='primary'>{t('save')}</LoadingButton>
            <Button sx={{ml: 2}} size='small' component={Link} to={'/settings/user/roles'}
                    startIcon={<ChevronLeft/>}
                    color='primary'>{t('back')}</Button>
            <Box flexGrow={1}/>
            {existingId && <IconButton onClick={fetchRole}><Replay/></IconButton>}
        </Toolbar>
        <Paper sx={{
            px: 2,
            pb: 1,
        }}>
            <TextField
                placeholder={t('settings.roles.name')}
                margin='normal'
                fullWidth
                variant='outlined'
                label={t('settings.roles.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errorList.hasOwnProperty('name')} helperText={errorList.name}
            />
            <TextField
                placeholder={t('settings.roles.description')}
                margin='normal'
                fullWidth
                variant='outlined'
                label={t('settings.roles.description')}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
        </Paper>
        <Paper sx={{
            mt: 2,
            px: 2,
            py: 1
        }}>
            <Typography variant='h5'>{t('settings.roles.permissions')}</Typography>
            <List dense={true}>
                {permissions.map((permissionSet) => {
                    return <PermissionSet key={permissionSet.key} selectedPermissions={selectedPermissions}
                                          setSelectedPermissions={setSelectedPermissions}
                                          permissionSet={permissionSet}/>
                })}
            </List>
        </Paper>
    </React.Fragment>
}

export default Edit;
