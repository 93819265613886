import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import {
    Box,
    Card,
    CardHeader,
    CardMedia,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Replay, Save } from "@mui/icons-material";
import FilePicker from "../../../Utils/FilePicker";
import CheckPermissions from "../../../Utils/CheckPermissions";
import * as EmailValidator from "email-validator";
import Schema from "validate";
import { cloneDeep } from "lodash";

const email = (val) => val ? EmailValidator.validate(val) : true;

const getValidationSchema = (t) => {
    return new Schema({
        emailForCopies: {
            required: false,
            use: {email},
            message: t('settings.pdf.emailForCopiesRequired')
        }
    });
}

const Settings = () => {
    const {t} = useTranslation()
    const v = getValidationSchema(t)
    const [settings, setSettings] = useState({
        path: null,
        emailForCopies: null,
    })
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [file, setFile] = useState({})

    const [errors, setErrors] = useState({});

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/pdf'
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    const fetchFile = useCallback(() => {
        if (settings.path) {
            Api.fetch({
                endpoint: 'storage/pdf-logo/' + settings.path,
            }).then(res => {
                setFile(res.response)
            }, () => {
            })
        } else {
            setFile({})
        }
    }, [setFile, settings.path])


    useEffect(() => {
        fetchSettings()
    }, [fetchSettings])

    useEffect(() => {
        fetchFile()
    }, [fetchFile])

    const onSave = useCallback(() => {
        setSaving(true)
        setErrors({});

        const errors = v.validate(cloneDeep(settings))

        const errorObject = {};
        if (errors.length) {
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })
            setErrors(errorObject)
            setSaving(false)
            return;
        }

        Api.fetch({
            endpoint: 'settings/pdf',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving, v])

    return <Grid container>
        <Grid item xs={12} md={6}>
            <Toolbar variant='dense' disableGutters={true}>
                <CheckPermissions list={['pdf.write']}>
                    <LoadingButton
                        onClick={onSave}
                        size='small'
                        loadingPosition="start"
                        disabled={loading}
                        loading={saving}
                        startIcon={<Save/>}
                        variant='contained'
                        color='primary'>{t('save')}</LoadingButton>
                </CheckPermissions>
                <Box flexGrow={1}/>
                <IconButton onClick={() => {
                    fetchSettings();
                    fetchFile();
                }}><Replay/></IconButton>
            </Toolbar>
            <Paper sx={{p: 2}}>
                <Typography variant='h5'
                            component='div'>{t('settings.pdf.settings')}</Typography>
                <TextField
                    fullWidth
                    margin='normal'
                    error={errors.hasOwnProperty('emailForCopies')  }
                    helperText={errors.emailForCopies}
                    label={t('settings.pdf.emailForCopies')}
                    placeholder={t('settings.pdf.emailForCopies')}
                    value={settings.emailForCopies || null}
                    onChange={(e) => handleChange('emailForCopies', e.target.value.length ? e.target.value : null)}
                />
                <Typography variant='h5'
                            component='div'>{t('settings.pdf.logo')}</Typography>
                <FilePicker accept={'image/*'} type={'pdf-logo'} path={settings.path} removable={true}
                            onSelect={(path) => handleChange('path', path)}/>
            </Paper>
            <Card sx={{mt: 2, mb: 2}}>
                <CardHeader title={t('settings.pdf.preview')}/>
                {file?.url &&
                    <CardMedia component='img' width='100' image={file?.url} alt={t('settings.pdf.preview')}/>}
            </Card>
        </Grid>
    </Grid>;
}

export default Settings;
