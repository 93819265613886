import {
    Checkbox,
    FormControl,
    IconButton,
    ListItemText,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Schema from "validate";
import DeleteDialog from "../../../Utils/DeleteDialog";
import { Delete } from "@mui/icons-material";
import CheckPermissions from "../../../Utils/CheckPermissions";

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String
        },
        type: {
            required: true,
            type: String,
            enum: ['string', 'int', 'float', 'date', 'boolean']
        },
        areas: {
            required: true,
            type: Array,
            length: {min: 1},
            each: {type: String, enum: ['device', 'customer']}
        },
    })
}

const Row = ({property, onChange, onDelete}) => {
    const {t} = useTranslation();
    const [errorList, setErrorList] = React.useState({});
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const onDeleteClick = () => {
        setDeleteOpen(false);
        onDelete();
    }

    useEffect(() => {
        const v = getValidationSchema(t);
        const obj = {name: property.name, type: property.type, areas: property.areas}
        const errors = v.validate(obj)
        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setErrorList(errorObject)
            property.hasErrors = true;
            onChange(property)
        } else {
            property.hasErrors = false;
            setErrorList({})
            onChange(property)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property.name, property.type, property.areas, t]);

    return <React.Fragment>
        <TableRow>
            <TableCell width={300}>
                <TextField
                    fullWidth
                    variant='standard'
                    placeholder={t('settings.properties.name')}
                    value={property.name}
                    onChange={(e) => {
                        property.name = e.target.value
                        property.hasChanges = true
                        onChange(property)
                    }}
                    error={errorList.hasOwnProperty('name')}
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    variant='standard'
                    placeholder={t('settings.properties.description')}
                    value={property.description}
                    onChange={(e) => {
                        property.description = e.target.value
                        property.hasChanges = true
                        onChange(property)
                    }}
                />
            </TableCell>
            <TableCell>
                <FormControl variant="standard" fullWidth>
                    <Select
                        error={errorList.hasOwnProperty('type')}
                        value={property.type}
                        onChange={(e) => {
                            property.type = e.target.value
                            property.hasChanges = true
                            onChange(property)
                        }}
                    >
                        <MenuItem value={'string'}>{t('settings.properties.types.string')}</MenuItem>
                        <MenuItem value={'int'}>{t('settings.properties.types.int')}</MenuItem>
                        <MenuItem value={'float'}>{t('settings.properties.types.float')}</MenuItem>
                        <MenuItem value={'date'}>{t('settings.properties.types.date')}</MenuItem>
                        <MenuItem value={'boolean'}>{t('settings.properties.types.boolean')}</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl variant="standard" fullWidth>
                    <Select
                        error={errorList.hasOwnProperty('areas')}
                        value={property.areas}
                        multiple
                        renderValue={(selected) => selected.map((v) => t('settings.properties.areas.' + v)).join(', ')}
                        onChange={(e) => {
                            property.areas = e.target.value
                            property.hasChanges = true
                            onChange(property)
                        }}
                    >
                        {['device', 'customer'].map((key) => (<MenuItem key={key} value={key}>
                            <Checkbox checked={property.areas.indexOf(key) > -1}/>
                            <ListItemText primary={t('settings.properties.areas.' + key)}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <Checkbox checked={property.required} onChange={(e) => {
                    property.required = e.target.checked
                    property.hasChanges = true
                    onChange(property)
                }}/>
            </TableCell>
            <TableCell width={50}>
                <CheckPermissions list={['properties.delete']}>
                    <IconButton onClick={() => setDeleteOpen(true)} align="right"><Delete/></IconButton>
                </CheckPermissions>
            </TableCell>
        </TableRow>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('settings.properties.deleteProperty')}
                      handleClose={() => setDeleteOpen(false)}
                      description={t('settings.properties.deletePropertyDescription', {name: property.name || ''})}
                      open={deleteOpen}/>
    </React.Fragment>
}

export default Row
