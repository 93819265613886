import { useMap } from "react-leaflet";
import { useEffect } from "react";

const LeafletChangeView = ({center, zoom}) => {
    const map = useMap();
    useEffect(() => {
        map.setView(center, zoom);
    }, [map, center, zoom]);
    return null;
}

export default LeafletChangeView;
