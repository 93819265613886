import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import { Box, IconButton, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Replay, Save } from "@mui/icons-material";
import QrCode from "./QrCode";
import CheckPermissions from "../../../Utils/CheckPermissions";

const types = ['deviceSupportTicket']

const Settings = () => {
    const {t} = useTranslation()
    const [settings, setSettings] = useState({})
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/qr-code'
        }).then(res => {
            const loadedSettings = res.response;
            types.forEach((type) => {
                if (!loadedSettings.hasOwnProperty(type)) {
                    loadedSettings[type] = {
                        path: null,
                        size: 50,
                        positionX: 0,
                        positionY: 0,
                    }
                }
            })
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    useEffect(() => {
        fetchSettings()
    }, [fetchSettings])

    const settingList = Object.keys(settings).map((type) => {
        return <QrCode type={type} setting={settings[type]} key={type} onChange={(setting) => {
            handleChange(type, setting)
        }}/>
    })

    const onSave = useCallback(() => {
        setSaving(true)
        Api.fetch({
            endpoint: 'settings/qr-code',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving])

    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['qrCode.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={() => {
                fetchSettings();
            }}><Replay/></IconButton>
        </Toolbar>
        {settingList}
    </React.Fragment>;
}

export default Settings;
