import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../core/Api";
import { Delete, OpenInNew, Replay, UploadFile } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { useDropzone } from 'react-dropzone'
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import DeleteDialog from "./DeleteDialog";

const FileBrowser = ({selected, onSelect, type, accept, displayType}) => {
    const uploadRef = useRef();

    const {t} = useTranslation();
    const theme = useTheme();
    const [files, setFiles] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);

    const [deleting, setDeleting] = React.useState(false);
    const [deletePath, setDeletePath] = React.useState(null);

    const fetchFiles = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: 'storage/' + type
        }).then((response) => {
            setFiles(response.response);
        }, () => {
        }).then(() => {
            setLoading(false);
        });
    }, [type])

    const deleteFile = useCallback(() => {
        setDeleting(true);
        if (selected === deletePath) {
            if (typeof onSelect === 'function') {
                onSelect(null);
            }
        }
        Api.fetch({
            endpoint: 'storage/' + type + '/' + deletePath,
            method: 'DELETE'
        }).then(() => {
            setDeletePath(null);
            fetchFiles();
        }, () => {
        }).then(() => {
            setDeleting(false)
        })
    }, [deletePath, type, fetchFiles, onSelect, selected]);


    const onUpload = useCallback((files) => {
        setUploading(true);

        const promises = files.map((file) => {
            return new Promise((resolve, reject) => {
                Api.fetch({
                    endpoint: 'storage/' + type,
                    method: 'POST',
                    body: {
                        fileName: file.name
                    }
                }).then((response) => {
                    fetch(response.response.url, {method: 'PUT', body: file}).then(() => resolve(), () => reject());
                }, () => {
                    reject();
                });
            })
        })

        Promise.all(promises).then(() => {
            fetchFiles();
        }, () => {
        }).then(() => {
            setUploading(false)
        })
    }, [type, fetchFiles, setUploading])

    const onDrop = useCallback(acceptedFiles => {
        onUpload(acceptedFiles);
    }, [onUpload])

    const onFileSelect = useCallback(({target}) => {
        onUpload(Array.from(target.files));
    }, [onUpload])

    const {getRootProps} = useDropzone({onDrop, accept, multiple: true, noClick: true})

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    if (loading || uploading) {
        return <Box sx={{textAlign: 'center', py: 2}}>
            <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        </Box>
    }

    let list = <List>
        {files.map((file, i) => {
            return <React.Fragment key={file.path}>
                <ListItem selected={selected === file.path} secondaryAction={<React.Fragment>
                    <IconButton target={'_blank'} href={file.url}>
                        <OpenInNew/>
                    </IconButton>
                    <IconButton onClick={() => setDeletePath(file.path)}><Delete/></IconButton>
                </React.Fragment>}>
                    {typeof onSelect === 'function' && <ListItemIcon>
                        <Checkbox
                            onClick={() => {
                                if (typeof onSelect === 'function') {
                                    onSelect(file.path)
                                }
                            }}
                            checked={selected === file.path}
                        />
                    </ListItemIcon>}
                    {file.contentType.indexOf('image/') === 0 && <ListItemAvatar sx={{mr: 2}}>
                        <Box sx={{width: '100px'}} textAlign='center'>
                            <Box component='img' sx={{maxWidth: '100px', maxHeight: '40px'}} alt={file.path}
                                 src={file.url}/>
                        </Box>
                    </ListItemAvatar>}
                    <ListItemText primary={file.path} secondary={moment(file.lastModified).format('DD.MM.YY, HH:mm')}/>
                </ListItem>
                {i < files.length - 1 && <Divider variant={'fullWidth'} component='li'/>}
            </React.Fragment>
        })}
    </List>


    if (displayType === 'grid') {
        list = <ImageList sx={{px: 2}} variant='masonry' cols={3} gap={8}>
            {files.map((file) => {
                return <ImageListItem>
                    <img src={file.url} loading={'lazy'} alt={file.url}/>
                    <ImageListItemBar
                        title={file.path}
                        subtitle={moment(file.lastModified).format('DD.MM.YY, HH:mm')}
                        actionIcon={
                            <React.Fragment>
                                <IconButton target={'_blank'} href={file.url} sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                >
                                    <OpenInNew/>
                                </IconButton>
                                <IconButton onClick={() => setDeletePath(file.path)}
                                            sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                ><Delete/></IconButton>
                            </React.Fragment>
                        }
                    />
                </ImageListItem>
            })}
        </ImageList>
    }

    let content = <React.Fragment>
        <Toolbar variant='dense'>
            <LoadingButton
                onClick={() => uploadRef.current.click()}
                size='small'
                loadingPosition="start"
                startIcon={<UploadFile/>}
                variant='contained'
                color='primary'>{t('upload')}</LoadingButton>
            <Box flexGrow={1}/>
            <IconButton onClick={() => {
                fetchFiles();
            }}><Replay/></IconButton>
        </Toolbar>
        {list}
    </React.Fragment>

    if (files.length <= 0) {
        content = <Box sx={{textAlign: 'center', color: theme.palette.grey[400], pb: 1, pt: 1}}>
            <UploadFile sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                <Typography variant='body2'>{t('filePicker.noFiles')}</Typography>
                <Link onClick={() => uploadRef.current.click()} variant='body2'>{t('filePicker.uploadFile')}</Link>
            </Box>
        </Box>
    }

    return <Box component='div' sx={{pt: 1}}>
        <input
            multiple={true}
            onChange={onFileSelect}
            accept={accept}
            ref={uploadRef}
            style={{display: 'none'}}
            id="file-upload-input"
            type="file"
        />
        <div {...getRootProps()}>
            {content}
        </div>
        <DeleteDialog onDelete={() => deleteFile()} isDeleting={deleting} title={t('filePicker.deleteFile')}
                      handleClose={() => setDeletePath(null)}
                      description={t('filePicker.deleteFileDescription', {name: deletePath})}
                      open={Boolean(deletePath)}/>
    </Box>;
}
export default FileBrowser
