import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect } from "react";
import Api from "../../../core/Api";
import {Link, Link as RouterLink, useLocation, useParams} from "react-router-dom";
import qs from "query-string";
import { fetchConnectionList } from "../../../actions/connectionActions";
import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import { ChevronLeft, Close, Edit, ManageSearch, Notes, OpenInNew, Replay, Save, Send } from "@mui/icons-material";
import CheckPermissions from "../../Utils/CheckPermissions";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import Fields from "./Fields";
import Preview from "./Preview";

const Show = (props) => {
    const params = useParams();
    const exportId = params.id;
    const tab = params.tab;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [exportData, setExportData] = React.useState({});

    const [editExportData, setEditExportData] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    const [exportTypeLoading, setExportTypeLoading] = React.useState(false);
    const [exportType, setExportType] = React.useState({});

    const connections = useSelector((state) => state.connections.list)
    const [queueExport, setQueueExport] = React.useState(false);

    const [statusListPanelOpen, setStatusListPanelOpen] = React.useState(false);
    const [statusListLoading, setStatusListLoading] = React.useState(false);
    const [statusList, setStatusList] = React.useState([]);

    const handleEditDataChange = (key, value) => {
        setEditExportData((d) => ({
            ...d,
            [key]: value
        }))
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const importResponse = await Api.fetch({
                endpoint: 'exports/' + exportId,
            })

            const responseExport = importResponse.response
            setExportData((currentImportData) => ({
                ...responseExport,
                fields: responseExport.fields || currentImportData.fields || []
            }))
        } catch (e) {
        }
        setLoading(false);

    }, [setExportData, setLoading, exportId]);

    const saveExport = (internalExportData) => {
        setSaving(true);
        Api.fetch({
            endpoint: 'exports/' + exportId,
            method: 'PUT',
            body: internalExportData
        }).then((res) => {
            setExportData(res.response);
            setEditExportData(null)
            setErrors({})
        }, () => {
        }).then(() => setSaving(false));
    }

    const loadStatusList = useCallback(() => {
        setStatusListLoading(true);
        Api.fetch({
            endpoint: 'exports/' + exportId + '/status-list',
        }).then((res) => {
            setStatusList(res.response);
        }, () => {
        }).then(() => setStatusListLoading(false));
    }, [exportId])


    const location = useLocation();
    useEffect(() => {
        const parsedQuery = qs.parse(location.search);
        if (parsedQuery?.openHistory === null) {
            setStatusListPanelOpen(true);
        } else {
            setStatusListPanelOpen(false);
        }
    }, [location.search])

    useEffect(() => {
        if (statusListPanelOpen) {
            loadStatusList();
        }
    }, [loadStatusList, statusListPanelOpen])

    useEffect(() => {
        setExportTypeLoading(true);
        if (!exportData?.type) {
            return;
        }
        Api.fetch({
            endpoint: 'export-types/' + exportData?.type,
        }).then((res) => {
            setExportType(res.response);
        }, () => {
        }).then(() => setExportTypeLoading(false));
    }, [exportData?.type])


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        dispatch(fetchConnectionList())
    }, [dispatch])

    const editExport = () => {
        saveExport({
            ...exportData,
            ...editExportData
        })
    }

    const runExport = () => {
        setQueueExport(true)
        Api.fetch({
            endpoint: 'actions',
            method: 'POST',
            body: {
                action: 'export',
                payload: {
                    exportId: exportData.id
                }
            }
        }).then(() => {
            dispatch({
                type: 'ADD_ALERT',
                message: t('data.export.exportQueued'),
                style: 'success'
            })
        }, () => {
        }).then(() => setQueueExport(false))
    }

    const somethingIsLoading = loading || saving || exportTypeLoading || queueExport;

    const connection = connections.find((connection) => connection.id === exportData?.connectionId)

    return <>
        <Toolbar disableGutters={true} variant='dense'>
            <Button component={RouterLink} to={'/data/export'}
                    startIcon={<ChevronLeft/>}
                    color='primary'>{t('back')}</Button>
            <Box flexGrow={1}/>
            <IconButton onClick={() => {
                fetchData();
                dispatch(fetchConnectionList())
            }}><Replay/></IconButton>
        </Toolbar>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
                <Paper>
                    <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                        <Typography variant='h5'
                                    component='h1'>{exportData?.name}</Typography>
                        <Box flexGrow={1}/>
                        <CheckPermissions list={['export.write']}>
                            <IconButton
                                onClick={() => setEditExportData({
                                    name: exportData?.name,
                                    type: exportData?.type,
                                    limit: exportData?.limit,
                                    groupBy: exportData?.groupBy,
                                    connectionId: exportData?.connectionId,
                                })}
                                sx={{ml: 1}} disabled={loading || saving}><Edit/></IconButton>
                        </CheckPermissions>
                    </Toolbar>
                    <List disablePadding={true} sx={{pl: 2, pr: 2, pb: 2}}>
                        <ListItem disableGutters={true}
                                  secondaryAction={
                                      <IconButton component={Link}
                                                  to={connection?.editable ? '/settings/integrations/connections/edit/' + connection?.id : '/settings/integrations/connections'}
                                                  target={'_blank'} edge="end" aria-label="delete">
                                          <OpenInNew/>
                                      </IconButton>}
                                  disablePadding>
                            <ListItemText primary={t('data.export.connection')}
                                          secondary={connection?.name}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.export.type')}
                                          secondary={t('data.export.types.' + exportData?.type)}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.export.limit')}
                                          secondary={exportData?.limit || '-'}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.export.groupBy')}
                                          secondary={exportData?.groupBy ? t('data.export.groupBys.' + exportData?.groupBy) : '-'}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.export.createdAt')}
                                          secondary={moment(exportData.createdAt).format('DD.MM.YYYY HH:mm')}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.export.updatedAt')}
                                          secondary={moment(exportData.updatedAt).format('DD.MM.YYYY HH:mm')}/>
                        </ListItem>
                    </List>
                    <Divider variant='middle'/>
                    <Toolbar disableGutters={true} variant='dense'
                             sx={{px: 2, py: 1}}>
                        <Tooltip title={t('data.export.showHistory')}>
                            <IconButton onClick={() => setStatusListPanelOpen(true)}>
                                <ManageSearch/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </Paper>
                <Paper sx={{mt: 1}} variant='outlined'>
                    <List disablePadding={true}>
                        <ListItemButton component={RouterLink} selected={tab === 'fields'}
                                        to={'/data/export/show/' + exportId + '/fields'}>
                            <ListItemText primary={t('data.export.data')}/>
                        </ListItemButton>
                        <ListItemButton component={RouterLink} selected={tab === 'preview'}
                                        to={'/data/export/show/' + exportId + '/preview'}>
                            <ListItemText primary={t('data.export.preview')}/>
                        </ListItemButton>
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
                <Toolbar disableGutters={true} variant='dense'>
                    <LoadingButton size='small' startIcon={<Save/>}
                                   loadingPosition="start"
                                   disabled={somethingIsLoading}
                                   loading={saving}
                                   color='primary' variant='contained'
                                   onClick={() => saveExport(exportData)}>{t('save')}</LoadingButton>
                    <LoadingButton sx={{ml: 1}}
                                   loadingPosition="start"
                                   disabled={somethingIsLoading}
                                   loading={queueExport}
                                   size='small' startIcon={<Send/>} onClick={() => runExport()}
                                   color={'secondary'}
                                   variant='contained'>{t('data.export.run')}</LoadingButton>
                </Toolbar>
                {tab === 'fields' && <Fields
                    exportType={exportType}
                    setExportData={setExportData}
                    exportData={exportData}
                    somethingIsLoading={somethingIsLoading}/>}
                {tab === 'preview' && <Preview
                    fields={exportData?.fields ||[]}
                    importId={exportId}
                />}
            </Grid>
        </Grid>
        <Dialog maxWidth='md' fullWidth open={Boolean(editExportData)}>
            <DialogTitle>
                {t('data.export.editExport')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setEditExportData(null) && setErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {editExportData && <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    error={errors.hasOwnProperty('name')}
                    helperText={errors.name}
                    margin='normal'
                    label={t('data.export.name')}
                    placeholder={t('data.export.name')}
                    value={editExportData.name}
                    onChange={(e) => handleEditDataChange('name', e.target.value)}
                />
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.export.type')}</InputLabel>
                    <Select
                        notched
                        label={t('data.export.type')}
                        value={editExportData.type}
                        fullWidth
                        onChange={(e) => handleEditDataChange('type', e.target.value)}
                    >
                        {['customers', 'devices', 'tickets'].map((type) => (<MenuItem key={type} value={type}>
                            <ListItemText primary={t('data.export.types.' + type)}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.export.connection')}</InputLabel>
                    <Select
                        notched
                        label={t('data.export.connection')}
                        value={editExportData.connectionId}
                        fullWidth
                        onChange={(e) => handleEditDataChange('connectionId', e.target.value)}
                    >
                        {connections.map((t) => (<MenuItem key={t.id} value={t.id}>
                            <ListItemText primary={t.name} secondary={t.description}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.export.groupBy')}</InputLabel>
                    <Select
                        notched
                        label={t('data.export.groupBy')}
                        value={editExportData.groupBy || 'none'}
                        fullWidth
                        onChange={(e) => handleEditDataChange('groupBy', e.target.value !== 'none' ? e.target.value : null)}
                    >
                        {['none', ...(exportType?.groupByFields?.map(f => f.identifier) || [])].map((g) => (<MenuItem key={g} value={g}>
                            <ListItemText primary={t('data.export.groupBys.' + g)}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    error={errors.hasOwnProperty('limit')}
                    helperText={errors.limit}
                    margin='normal'
                    type='number'
                    label={t('data.export.limit')}
                    placeholder={t('data.export.limit')}
                    value={editExportData.limit ? editExportData.limit : ''}
                    onChange={(e) => handleEditDataChange('limit', e.target.value.length ? parseInt(e.target.value, 10) : null)}
                />
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditExportData(null) && setErrors({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={editExport}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            fullWidth
            maxWidth='md'
            open={statusListPanelOpen}
            onClose={() => setStatusListPanelOpen(false)}
        >
            <DialogTitle>
                {t('data.export.history')}
                <IconButton
                    aria-label="close"
                    onClick={() => setStatusListPanelOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <TableContainer>
                {<Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.export.startedAt')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.export.rows')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.export.errors')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.export.finishedAt')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.export.status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!statusListLoading && statusList.sort((a, b) => moment(b.startedAt).diff(moment(a.startedAt))).map((status) => (
                            <TableRow key={status.id}>
                                <TableCell>{moment(status.startedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                <TableCell>{status.rows}</TableCell>
                                <TableCell>{status.errors?.length || 0}</TableCell>
                                <TableCell>{status.finishedAt ? moment(status.finishedAt).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>
                                <TableCell>{t('data.export.exportStatus.' + status.status)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>}
                {statusListLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>}
                {(!statusListLoading && statusList.length <= 0) &&
                    <Box sx={{textAlign: 'center', color: (t) => t.palette.grey[400]}}>
                        <Notes sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                        <Box sx={{color: (t) => t.palette.text.secondary, mb: 2}} textAlign='center'>
                            <Typography variant='body2'>{t('data.export.noExportStatusFound')}</Typography>
                        </Box>
                    </Box>}
            </TableContainer>
        </Dialog>
    </>
}

export default Show
