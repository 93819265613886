import {CALL_API} from '../middleware/api'
import Api from "../core/Api";

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_CLEAR = 'USER_CLEAR';

const getUsers = async () => {
    let users = [];
    let paginationToken = '';

    while (true) {
        const options = {
            endpoint: 'user/user',
            parameter: {
                limit: 50
            }
        };
        if (paginationToken !== '') {
            options.parameter.paginationToken = paginationToken;
        }
        try {
            const response = await Api.fetch(options)
            users = [...users, ...response.response.users]

            if (response.response.paginationToken == null) {
                return users;
            }

            paginationToken = response.response.paginationToken
        } catch (e) {
            return [];
        }
    }

};

export function fetchUser() {
    return {
        [CALL_API]: {
            endpoint: 'user/me',
            method: 'GET',
            types: [USER_SUCCESS, USER_FAILURE, USER_REQUEST]
        }
    }
}

export function fetchUserList() {
    return (dispatch) => {
        dispatch({
            type: USER_LIST_REQUEST
        });

        return getUsers().then(users => {
            dispatch({
                type: USER_LIST_SUCCESS,
                response: users
            });
        }).catch(error => {
            dispatch({
                type: USER_LIST_FAILURE,
                error: error
            });
        });
    }
}

export function clearUser() {
    return {
        type: USER_CLEAR
    }
}