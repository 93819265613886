import {IconButton, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Delete, DragHandle} from "@mui/icons-material";
import {Draggable} from "react-smooth-dnd";
import React from "react";
import {useTranslation} from "react-i18next";
import DeleteDialog from "../../../Utils/DeleteDialog";

const Device = ({device, deviceData, onChange}) => {
    const {t} = useTranslation()
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    return <>
        <Draggable key={device.position}>
            <ListItem
                secondaryAction={<IconButton onClick={() => setDeleteDialogOpen(true)}><Delete/></IconButton>}
            >
                <ListItemIcon>
                    <IconButton><DragHandle className='drag-handle-3'/></IconButton>
                </ListItemIcon>
                <ListItemText primary={`${deviceData.number}: ${deviceData.brand} ${deviceData?.model}`}/>
            </ListItem>
        </Draggable>
        <DeleteDialog
            onDelete={() => {
                onChange(null)
                setDeleteDialogOpen(false)
            }} isDeleting={false}
            title={t('settings.tours.deleteTourDevice')}
            handleClose={() => setDeleteDialogOpen(false)}
            description={t('settings.tours.deleteTourDeviceDescription', {name: `${deviceData.number}: ${deviceData.brand} ${deviceData?.model}`})}
            open={deleteDialogOpen}/>
    </>
}

export default Device