import React, { useState } from "react";
import { Checkbox, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Permission from "./Permission";

const PermissionSet = (props) => {
    const {permissionSet, selectedPermissions, setSelectedPermissions} = props;
    const [expanded, setExpanded] = useState(false);
    const {t} = useTranslation()

    const subPermissionList = permissionSet.permissions.map((permission) => {
        return permission.subPermissions.map((subPermission) => subPermission.key)
    }).flat();

    const filteredPermissions = selectedPermissions.filter((permission) => {
        if (permissionSet.key === 'tickets' && !subPermissionList.includes(permission)) {
            console.log(permission)
        }
        return subPermissionList.includes(permission)
    });

    const onSelect = (e) => {
        e.stopPropagation();

        setSelectedPermissions((p) => {
            const newList = p.slice()
            subPermissionList.forEach((subPermission) => {
                if (e.target.checked && !newList.includes(subPermission)) {
                    newList.push(subPermission)
                } else if (!e.target.checked && newList.includes(subPermission)) {
                    newList.splice(newList.indexOf(subPermission), 1)
                }
            })
            return newList;
        })
    }

    if(permissionSet.key === 'tickets') {
        console.log(filteredPermissions.length < subPermissionList.length, filteredPermissions.length, subPermissionList.length)
    }

    return <React.Fragment>
        <ListItemButton onClick={() => setExpanded((v) => !v)}>
            <ListItemIcon>
                <Checkbox
                    onClick={onSelect}
                    checked={filteredPermissions.length === subPermissionList.length}
                    indeterminate={filteredPermissions.length > 0 && filteredPermissions.length < subPermissionList.length}/>
            </ListItemIcon>
            <ListItemText sx={{flexGrow: 0}}>{t(permissionSet.translationKey)}</ListItemText>
            {expanded ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense={true}>
                {permissionSet.permissions.map((permission) => {
                    return <Permission key={permission.key} selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} permission={permission}/>
                })}
            </List>
        </Collapse>
    </React.Fragment>
}

export default PermissionSet;
