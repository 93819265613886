import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {Link, Link as RouterLink, useLocation, useParams} from "react-router-dom";
import {
    Add,
    ChevronLeft,
    Close,
    Delete,
    Edit,
    ManageSearch, Notes,
    OpenInNew,
    RemoveCircle,
    Replay,
    Save,
    Send
} from "@mui/icons-material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../core/Api";
import moment from "moment/moment";
import ConnectionFilePicker from "../../Utils/ConnectionFilePicker";
import CheckPermissions from "../../Utils/CheckPermissions";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { fetchConnectionList } from "../../../actions/connectionActions";
import qs from "query-string";

const Show = () => {
    const params = useParams();
    const importId = params.id;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [importData, setImportData] = React.useState({});

    const [editImportData, setEditImportData] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    const [previewLoading, setPreviewLoading] = React.useState(false);
    const [preview, setPreview] = React.useState([]);

    const [importTypeLoading, setImportTypeLoading] = React.useState(false);
    const [importType, setImportType] = React.useState({});

    const connections = useSelector((state) => state.connections.list)
    const [queueImport, setQueueImport] = React.useState(false);

    const [statusListPanelOpen, setStatusListPanelOpen] = React.useState(false);
    const [statusListLoading, setStatusListLoading] = React.useState(false);
    const [statusList, setStatusList] = React.useState([]);

    const handleEditDataChange = (key, value) => {
        setEditImportData((d) => ({
            ...d,
            [key]: value
        }))
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const importResponse = await Api.fetch({
                endpoint: 'imports/' + importId,
            })

            const responseImport = importResponse.response
            setImportData((currentImportData) => ({
                ...responseImport,
                fields: responseImport.fields || currentImportData.fields || []
            }))
        } catch (e) {
        }
        setLoading(false);

    }, [setImportData, setLoading, importId]);

    const fetchPreview = useCallback(() => {
        if (!importData?.fileName || !importType.fields) {
            return;
        }
        setPreviewLoading(true);
        Api.fetch({
            endpoint: 'imports/' + importId + '/preview',
        }).then((res) => {
            setPreview(res.response);
            setImportData((currentImportData) => ({
                ...currentImportData,
                fields: !currentImportData.fields.length ? Object.keys(res.response.fields).map((field) => {
                    return {
                        source: [{value: field, type: 'field'}],
                        separator: ' ',
                        destination: importType.fields ? importType.fields[0].identifier : null
                    }
                }) : currentImportData.fields
            }))
        }, () => {
        }).then(() => setPreviewLoading(false));
    }, [importId, importData?.fileName, importType.fields])

    const saveImport = (internalImportData) => {
        setSaving(true);
        Api.fetch({
            endpoint: 'imports/' + importId,
            method: 'PUT',
            body: internalImportData
        }).then((res) => {
            setImportData(res.response);
            setEditImportData(null)
            setErrors({})
        }, () => {
        }).then(() => setSaving(false));
    }

    const loadStatusList = useCallback(() => {
        setStatusListLoading(true);
        Api.fetch({
            endpoint: 'imports/' + importId + '/status-list',
        }).then((res) => {
            setStatusList(res.response);
        }, () => {
        }).then(() => setStatusListLoading(false));
    }, [importId])


    const location = useLocation();
    useEffect(() => {
        const parsedQuery = qs.parse(location.search);
        if (parsedQuery?.openHistory === null) {
            setStatusListPanelOpen(true);
        } else {
            setStatusListPanelOpen(false);
        }
    }, [location.search])

    useEffect(() => {
        if (statusListPanelOpen) {
            loadStatusList();
        }
    }, [loadStatusList, statusListPanelOpen])

    useEffect(() => {
        setImportTypeLoading(true);
        if (!importData?.type) {
            return;
        }
        Api.fetch({
            endpoint: 'import-types/' + importData?.type,
        }).then((res) => {
            setImportType(res.response);
        }, () => {
        }).then(() => setImportTypeLoading(false));
    }, [importData?.type])


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        dispatch(fetchConnectionList())
    }, [dispatch])

    useEffect(() => {
        fetchPreview();
    }, [fetchPreview])

    const editImport = () => {
        saveImport({
            ...importData,
            ...editImportData
        })
    }

    const runImport = () => {
        setQueueImport(true)
        Api.fetch({
            endpoint: 'actions',
            method: 'POST',
            body: {
                action: 'import',
                payload: {
                    importId: importData.id
                }
            }
        }).then(() => {
            dispatch({
                type: 'ADD_ALERT',
                message: t('data.import.importQueued'),
                style: 'success'
            })
        }, () => {
        }).then(() => setQueueImport(false))
    }

    const somethingIsLoading = loading || saving || previewLoading || importTypeLoading || queueImport;

    const connection = connections.find((connection) => connection.id === importData?.connectionId)

    const rows = importData.fields?.map((field, i) => {

        const sources = field.source?.map((source, i2) => {
            return <ListItem disableGutters={true}
                             secondaryAction={<IconButton disabled={field.source?.length <= 1 || somethingIsLoading}
                                                          onClick={() => {
                                                              const newFields = [...importData.fields];
                                                              newFields[i].source.splice(i2, 1);
                                                              setImportData((currentImportData) => ({
                                                                  ...currentImportData,
                                                                  fields: newFields
                                                              }))
                                                          }}>
                                 <RemoveCircle/>
                             </IconButton>}>
                <Box sx={{width: '100%'}}>
                    {source.type === 'value' && <TextField
                        disabled={somethingIsLoading}
                        fullWidth
                        size='small'
                        margin='dense'
                        label={t('data.import.ownValue')}
                        placeholder={t('data.import.ownValue')}
                        value={source.value || ''}
                        onChange={(e) => {
                            setImportData((currentImportData) => {
                                currentImportData.fields[i].source[i2] = {type: 'value', value: e.target.value};
                                return {
                                    ...currentImportData,
                                    fields: currentImportData.fields
                                }
                            })
                        }}
                    />
                    }
                    {source.type === 'field' && <FormControl fullWidth margin='dense' size='small'>
                        <InputLabel shrink>{t('data.import.source')}</InputLabel>
                        <Select
                            disabled={somethingIsLoading}
                            notched
                            label={t('data.import.source')}
                            value={source.value || ''}
                            fullWidth
                            onChange={(e) => {
                                setImportData((currentImportData) => {
                                    currentImportData.fields[i].source[i2] = {type: 'field', value: e.target.value}
                                    return {
                                        ...currentImportData,
                                        fields: currentImportData.fields
                                    }
                                })
                            }}
                        >
                            {Object.keys(preview.fields || []).map((previewField) => {
                                return <MenuItem key={previewField} value={previewField}>
                                    <ListItemText primary={previewField}
                                                  secondary={(preview.fields[previewField] ?? []).slice(0, 4).join(', ')}/>
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>}
                    <Button disabled={somethingIsLoading} edge="end" color="primary"
                            onClick={() => setImportData((currentImportData) => {
                                currentImportData.fields[i].source[i2] = {
                                    type: source.type === 'value' ? 'field' : 'value',
                                    value: ''
                                }

                                return {
                                    ...currentImportData,
                                    fields: currentImportData.fields
                                }
                            })}>
                        {t(source.type === 'field' ? 'data.import.enterOwnValue' : 'data.import.selectFromSource')}
                    </Button>
                </Box>
            </ListItem>
        })

        return <TableRow>
            <TableCell>
                <List disablePadding={true} dense>
                    {sources}
                </List>
                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                    {field.source?.length > 1 && <TextField
                        disabled={somethingIsLoading}
                        sx={{mr: 1}}
                        required
                        size='small'
                        margin='dense'
                        label={t('data.import.separator')}
                        placeholder={t('data.import.separator')}
                        value={field.separator}
                        onChange={(e) => {
                            setImportData((currentImportData) => {
                                currentImportData.fields[i].separator = e.target.value;
                                return {
                                    ...currentImportData,
                                    fields: currentImportData.fields
                                }
                            })
                        }}
                    />}
                    <Button disabled={somethingIsLoading} size='small'
                            onClick={() => setImportData((currentImportData) => {
                                currentImportData.fields[i].source.push({type: 'field', value: null})
                                return {
                                    ...currentImportData,
                                    fields: currentImportData.fields
                                }
                            })} startIcon={<Add/>}>{t('data.import.addSource')}</Button>
                </Box>
            </TableCell>
            <TableCell>
                <FormControl fullWidth margin='dense' size='small'>
                    <InputLabel shrink>{t('data.import.destination')}</InputLabel>
                    <Select
                        disabled={somethingIsLoading}
                        notched
                        label={t('data.import.destination')}
                        value={field.target || ''}
                        fullWidth
                        onChange={(e) => {
                            setImportData((currentImportData) => {
                                currentImportData.fields[i].target = e.target.value;

                                const importTypeField = importType.fields?.find((importTypeField) => importTypeField.identifier === e.target.value);
                                if (importTypeField?.isRequiredMapping) {
                                    currentImportData.fields[i].isMapping = true;
                                } else if (importTypeField?.isOptionalMapping) {
                                    currentImportData.fields[i].isMapping = false;
                                }
                                return {
                                    ...currentImportData,
                                    fields: currentImportData.fields
                                }
                            })
                        }}
                    >
                        {importType.fields?.map((importTypeField) => (
                            <MenuItem key={importTypeField.identifier} value={importTypeField.identifier}>
                                <ListItemText primary={t('data.import.fields.' + importTypeField.identifier)}/>
                            </MenuItem>))}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                {importType.fields?.find((importTypeField) => importTypeField.identifier === field.target)?.isRequiredMapping &&
                    <Checkbox checked={true} disabled={true}/>}
                {importType.fields?.find((importTypeField) => importTypeField.identifier === field.target)?.isOptionalMapping &&
                    <Checkbox disabled={somethingIsLoading} checked={field.isMapping} onChange={(e) => {
                        setImportData((currentImportData) => {
                            currentImportData.fields[i].isMapping = e.target.checked;
                            return {
                                ...currentImportData,
                                fields: currentImportData.fields
                            }
                        })
                    }}/>}
            </TableCell>
            <TableCell>
                <IconButton disabled={somethingIsLoading} onClick={() => setImportData({
                    ...importData,
                    fields: importData.fields.filter((f, j) => i !== j)
                })}><Delete/></IconButton>
            </TableCell>
        </TableRow>

    })


    return <>
        <Toolbar disableGutters={true} variant='dense'>
            <Button component={RouterLink} to={'/data/import'}
                    startIcon={<ChevronLeft/>}
                    color='primary'>{t('back')}</Button>
            <Box flexGrow={1}/>
            <IconButton onClick={() => {
                fetchData();
                fetchPreview();
                dispatch(fetchConnectionList())
            }}><Replay/></IconButton>
        </Toolbar>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
                <Paper>
                    <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                        <Typography variant='h5'
                                    component='h1'>{importData?.name}</Typography>
                        <Box flexGrow={1}/>
                        <CheckPermissions list={['import.write']}>
                            <IconButton
                                onClick={() => setEditImportData({
                                    name: importData?.name,
                                    type: importData?.type,
                                    connectionId: importData?.connectionId,
                                    delimiter: importData?.delimiter
                                })}
                                sx={{ml: 1}} disabled={loading || saving}><Edit/></IconButton>
                        </CheckPermissions>
                    </Toolbar>
                    <List disablePadding={true} sx={{pl: 2, pr: 2, pb: 2}}>
                        <ListItem disableGutters={true}
                                  secondaryAction={
                                      <IconButton component={Link}
                                                  to={connection?.editable ? '/settings/integrations/connections/edit/' + connection?.id : '/settings/integrations/connections'}
                                                  target={'_blank'} edge="end" aria-label="delete">
                                          <OpenInNew/>
                                      </IconButton>}
                                  disablePadding>
                            <ListItemText primary={t('data.import.connection')}
                                          secondary={connection?.name}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.import.type')}
                                          secondary={t('data.import.types.' + importData?.type)}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.import.delimiter')}
                                          secondary={t('data.import.delimiters.' + importData?.delimiter)}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.import.createdAt')}
                                          secondary={moment(importData.createdAt).format('DD.MM.YYYY HH:mm')}/>
                        </ListItem>
                        <ListItem disableGutters={true} disablePadding>
                            <ListItemText primary={t('data.import.updatedAt')}
                                          secondary={moment(importData.updatedAt).format('DD.MM.YYYY HH:mm')}/>
                        </ListItem>
                    </List>
                    <Divider variant='middle'/>
                    <Toolbar disableGutters={true} variant='dense'
                             sx={{px: 2, py: 1}}>
                        <Tooltip title={t('data.import.showHistory')}>
                            <IconButton onClick={() => setStatusListPanelOpen(true)}>
                                <ManageSearch/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
                <Toolbar disableGutters={true} variant='dense'>
                    <LoadingButton size='small' startIcon={<Save/>}
                                   loadingPosition="start"
                                   disabled={somethingIsLoading}
                                   loading={saving}
                                   color='primary' variant='contained'
                                   onClick={() => saveImport(importData)}>{t('save')}</LoadingButton>
                    <LoadingButton sx={{ml: 1}}
                                   loadingPosition="start"
                                   disabled={somethingIsLoading}
                                   loading={queueImport}
                                   size='small' startIcon={<Send/>} onClick={() => runImport()}
                                   color={'secondary'}
                                   variant='contained'>{t('data.import.run')}</LoadingButton>
                </Toolbar>
                <Paper sx={{p: 2, mb: 2}}>
                    <Typography variant='h6'>{t('data.import.file')}</Typography>
                    <ConnectionFilePicker accept={'text/csv'} connectionId={importData?.connectionId}
                                          disabled={somethingIsLoading}
                                          path={importData?.fileName}
                                          onSelect={(path) => {
                                              const newImportData = {
                                                  ...importData,
                                                  fileName: path
                                              }
                                              saveImport(newImportData);
                                          }}/>
                </Paper>
                <Paper sx={{p: 2, mb: 2}}>
                    <Typography variant='h6'>{t('data.import.mapping')}</Typography>
                    <FormControl fullWidth margin='normal' size='small'>
                        <InputLabel shrink>{t('data.import.mappingField')}</InputLabel>
                        <Select
                            disabled={somethingIsLoading}
                            notched
                            label={t('data.import.mappingField')}
                            value={importData.mappingField || ''}
                            fullWidth
                            onChange={(e) => {
                                setImportData((currentImportData) => {
                                    return {
                                        ...currentImportData,
                                        mappingField: e.target.value
                                    }
                                })
                            }}
                        >
                            {importType.fields?.filter(f => f.isRequiredMapping).map((importTypeField) => (
                                <MenuItem key={importTypeField.identifier} value={importTypeField.identifier}>
                                    <ListItemText primary={t('data.import.fields.' + importTypeField.identifier)}/>
                                </MenuItem>))}
                        </Select>
                    </FormControl>
                    <Box sx={{width: '100%'}}>
                        {importData.mappingValue?.type === 'value' && <TextField
                            disabled={somethingIsLoading}
                            fullWidth
                            size='small'
                            margin='dense'
                            label={t('data.import.mappingValue')}
                            placeholder={t('data.import.mappingValue')}
                            value={importData.mappingValue?.value || ''}
                            onChange={(e) => {
                                setImportData((currentImportData) => {
                                    return {
                                        ...currentImportData,
                                        mappingValue: {type: 'value', value: e.target.value}
                                    }
                                })
                            }}
                        />
                        }
                        {importData.mappingValue?.type === 'field' && <FormControl fullWidth margin='dense' size='small'>
                            <InputLabel shrink>{t('data.import.mappingSource')}</InputLabel>
                            <Select
                                disabled={somethingIsLoading}
                                notched
                                label={t('data.import.mappingSource')}
                                value={importData.mappingValue?.value || ''}
                                fullWidth
                                onChange={(e) => {
                                    setImportData((currentImportData) => {
                                        return {
                                            ...currentImportData,
                                            mappingValue: {type: 'field', value: e.target.value}
                                        }
                                    })
                                }}
                            >
                                {Object.keys(preview.fields || []).map((previewField) => {
                                    return <MenuItem key={previewField} value={previewField}>
                                        <ListItemText primary={previewField}
                                                      secondary={(preview.fields[previewField] ?? []).slice(0, 4).join(', ')}/>
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>}
                        <Button disabled={somethingIsLoading} edge="end" color="primary"
                                onClick={() => setImportData((currentImportData) => {
                                    return {
                                        ...currentImportData,
                                        mappingValue: {
                                            type: currentImportData.mappingValue.type === 'value' ? 'field' : 'value',
                                            mappingValue: ''
                                        }
                                    }
                                })}>
                            {t(importData.mappingValue?.type === 'field' ? 'data.import.enterOwnValue' : 'data.import.selectFromSource')}
                        </Button>
                    </Box>
                </Paper>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('data.import.sources')}</TableCell>
                                    <TableCell>{t('data.import.destination')}</TableCell>
                                    <TableCell>{t('data.import.isMapping')}</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Toolbar sx={{px: 2}} variant='regular' disableGutters={true}>
                        <Button size='small' onClick={() => {
                            setImportData({
                                ...importData,
                                fields: [...importData.fields, {
                                    source: [{type: 'field', value: null}],
                                    separator: ' ',
                                    destination: null
                                }]
                            });
                        }}
                                startIcon={<Add/>}
                                variant='outlined'
                                color='primary'>{t('data.import.addField')}</Button>
                    </Toolbar>
                </Paper>
            </Grid>
        </Grid>
        <Dialog maxWidth='md' fullWidth open={Boolean(editImportData)}>
            <DialogTitle>
                {t('data.import.editImport')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => editImportData(null) && setErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {editImportData && <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    error={errors.hasOwnProperty('name')}
                    helperText={errors.name}
                    margin='normal'
                    label={t('data.import.name')}
                    placeholder={t('data.import.name')}
                    value={editImportData.name}
                    onChange={(e) => handleEditDataChange('name', e.target.value)}
                />
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.import.type')}</InputLabel>
                    <Select
                        notched
                        label={t('data.import.type')}
                        value={editImportData.type}
                        fullWidth
                        onChange={(e) => handleEditDataChange('type', e.target.value)}
                    >
                        {['customers', 'devices'].map((type) => (<MenuItem key={type} value={type}>
                            <ListItemText primary={t('data.import.types.' + type)}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.import.delimiter')}</InputLabel>
                    <Select
                        notched
                        label={t('data.import.delimiter')}
                        value={editImportData.delimiter}
                        fullWidth
                        onChange={(e) => handleEditDataChange('delimiter', e.target.value)}
                    >
                        {[';', ',', '|'].map((type) => (<MenuItem key={type} value={type}>
                            <ListItemText primary={t('data.import.delimiters.' + type)}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.import.connection')}</InputLabel>
                    <Select
                        notched
                        label={t('data.import.connection')}
                        value={editImportData.connectionId}
                        fullWidth
                        onChange={(e) => handleEditDataChange('connectionId', e.target.value)}
                    >
                        {connections.map((t) => (<MenuItem key={t.id} value={t.id}>
                            <ListItemText primary={t.name} secondary={t.description}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditImportData(null) && setErrors({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={editImport}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            fullWidth
            maxWidth='md'
            open={statusListPanelOpen}
            onClose={() => setStatusListPanelOpen(false)}
        >
            <DialogTitle>
                {t('data.import.history')}
                <IconButton
                    aria-label="close"
                    onClick={() => setStatusListPanelOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <TableContainer>
                {<Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.import.startedAt')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.import.processedRows')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.import.createdEntities')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.import.updatedEntities')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.import.errors')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.import.finishedAt')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('data.import.status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!statusListLoading && statusList.sort((a, b) => moment(b.startedAt).diff(moment(a.startedAt))).map((status) => (
                            <TableRow key={status.id}>
                                <TableCell>{moment(status.startedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                <TableCell>{status.processedRows}</TableCell>
                                <TableCell>{status.createdEntities}</TableCell>
                                <TableCell>{status.updatedEntities}</TableCell>
                                <TableCell>{status.errors?.length || 0}</TableCell>
                                <TableCell>{status.finishedAt ? moment(status.finishedAt).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>
                                <TableCell>{t('data.import.importStatus.' + status.status)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>}
                {statusListLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>}
                {(!statusListLoading && statusList.length <= 0) &&
                    <Box sx={{textAlign: 'center', color: (t) => t.palette.grey[400]}}>
                        <Notes sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                        <Box sx={{color: (t) => t.palette.text.secondary, mb: 2}} textAlign='center'>
                            <Typography variant='body2'>{t('data.import.noImportStatusFound')}</Typography>
                        </Box>
                    </Box>}
            </TableContainer>
        </Dialog>
    </>;
}

export default Show
