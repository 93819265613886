import {
    Box,
    Chip,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationNumber } from "@mui/icons-material";
import { darken, lighten, styled, useTheme } from "@mui/system";
import Api from "../../core/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { uniq } from "lodash";
import { hasPermission } from "../../helper/hasPermission";

const StyledLink = styled(RouterLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


const Tickets = ({settings, editable, tagList, statusList, categoryList}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const columns = settings?.columns || ['number', 'customer', 'category', 'title', 'status', 'createdAt'];
    const orderColumn = 'number';
    const order = 'desc';

    const [ticketsFetching, setTicketsFetching] = React.useState(false);
    const [tickets, setTickets] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [perPage, setPerPage] = React.useState(20);
    const [customers, setCustomers] = React.useState({});

    const user = useSelector((state) => state.user)

    useEffect(() => {
        const customerIds = uniq(tickets.map(ticket => ticket.customerId)).sort();
        if (customerIds.filter(c => !Object.keys(customers).includes(c)) <= 0) {
            return;
        }

        const loadedCustomers = {};
        Api.fetch({
            endpoint: "customers",
            parameter: {
                filter: {
                    ids: 'in<-->' + customerIds.join(",")
                }
            }
        }).then(response => {
            response.response.forEach(customer => {
                loadedCustomers[customer.id] = customer;
            });
            customerIds.forEach((customerId) => {
                if (!loadedCustomers[customerId]) {
                    loadedCustomers[customerId] = null
                }
            })
            setCustomers(loadedCustomers);
        });

    }, [customers, tickets])

    const fetchTickets = useCallback(() => {
        if (!hasPermission(user, ['tickets.read'])) {
            return null;
        }

        const filters = settings?.filter || {};
        setTicketsFetching(true);

        const filter = {}

        if (filters.tags?.length > 0) {
            filter.tags = 'in<-->' + filters.tags.join(',');
        }

        if (filters.categories?.length > 0) {
            filter.categories = 'in<-->' + filters.categories.join(',');
        }

        if (filters.customers?.length > 0) {
            filter.customers = 'in<-->' + filters.customers.join(',');
        }

        if (filters.status?.length > 0) {
            filter.status = 'in<-->' + filters.status.join(',');
        }

        if (filters.userLinks?.length > 0) {
            filter.acceptedUserLinks = 'in<-->' + filters.userLinks.map((u) => u === 'me' ? user.user.id : u).join(',');
        }

        if (filters.importance != null) {
            filter.importance = 'between<-->' + filters.importance.from + ',' + filters.importance.to;
        }

        if (filters.createdAt?.from && filters.createdAt?.to) {
            filter.createdAt = 'between<-->' + moment(filters.createdAt.from).toISOString() + ',' + moment(filters.createdAt.to).toISOString();
        }

        if (filters.completedAt?.from && filters.completedAt?.to) {
            filter.completedAt = 'between<-->' + moment(filters.completedAt.from).toISOString() + ',' + moment(filters.completedAt.to).toISOString();
        }

        if (filters.updatedAt?.from && filters.updatedAt?.to) {
            filter.updatedAt = 'between<-->' + moment(filters.updatedAt.from).toISOString() + ',' + moment(filters.updatedAt.to).toISOString();
        }

        Api.fetch({
            endpoint: 'tickets',
            parameter: {
                page: page + 1,
                size: perPage,
                sort: `${orderColumn}_${order}`,
                filter: filter
            }
        }).then((res) => {
            setTotal(parseInt(res.headers['x-total-count'] || "0", 10));
            setTickets(res.response)
        }, () => {
        }).then(() => {
            setTicketsFetching(false);
        })
    }, [page, perPage, order, orderColumn, setTicketsFetching, setTickets, settings?.filter, user]);

    const fetchData = useCallback(() => {
        fetchTickets();
    }, [fetchTickets]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const notFoundMessage = <Box sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        color: theme.palette.grey[400]
    }}>
        <ConfirmationNumber sx={{fontSize: 60}} viewBox={'0 0 20 26'}/>
        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('tickets.list.noTicketFound')}</Typography>
        </Box>
    </Box>

    const loading = ticketsFetching;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;

    const ticketList = tickets.map((ticket) => {
        const customer = customers[ticket.customerId];
        const category = categoryList.find(c => c.id === ticket.categoryId);
        const status = statusList.find(c => c.id === ticket.statusId);

        let color = 'inherit';
        let hoverColor = 'inherit';
        if (status?.type === 'open') {
            if (ticket.importanceId >= 8) {
                color = getBackgroundColor(theme.palette.error.light, 0.5)
                hoverColor = getBackgroundColor(theme.palette.error.light, 0.3)
            } else if (ticket.importanceId >= 5) {
                color = getBackgroundColor(theme.palette.warning.light, 0.5)
                hoverColor = getBackgroundColor(theme.palette.warning.light, 0.3)
            }
        }
        const filteredTags = ticket.tags?.filter((tag) => tagList.find((t) => t.id === tag));
        return (
            <TableRow component={StyledLink} key={ticket.id} hover
                      sx={{backgroundColor: color, "&:hover": {backgroundColor: hoverColor + ' !important'}}}
                      to={'/tickets/show/' + ticket.id}>
                {columns.includes('number') && <TableCell>#{ticket.number}</TableCell>}
                {columns.includes('customer') && <TableCell>{customer?.name || <i>{t('deleted')}</i>}</TableCell>}
                {columns.includes('category') && <TableCell>{category?.name || <i>{t('deleted')}</i>}</TableCell>}
                {columns.includes('title') && <TableCell>{ticket.title}</TableCell>}
                {columns.includes('status') && <TableCell>{status?.name || <i>{t('deleted')}</i>}</TableCell>}
                {columns.includes('importance') && <TableCell>{ticket.importanceId}</TableCell>}
                {columns.includes('tags') && <TableCell>
                    {filteredTags.length ? filteredTags.map((tag) => {
                        const tagDefinition = tagList.find((t) => t.id === tag);
                        return <Chip key={tag} label={tagDefinition.name}
                                     sx={{mr: 1, backgroundColor: tagDefinition.color, color: 'white'}}/>
                    }) : '-'}
                </TableCell>}
                {columns.includes('createdAt') &&
                    <TableCell>{moment(ticket.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>}
                {columns.includes('updatedAt') &&
                    <TableCell>{moment(ticket.updatedAt).format('DD.MM.YYYY HH:mm')}</TableCell>}
                {columns.includes('completedAt') &&
                    <TableCell>{ticket.completedAt ? moment(ticket.completedAt).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>}
            </TableRow>
        )
    });
    return <Box component={'div'} sx={{
        height: '100%', width: '100%',
        pointerEvents: editable ? 'none' : 'inherit',
        opacity: editable ? 0.5 : 1
    }}>
        <Typography sx={{p: 2, maxHeight: '64px'}} component='h2'
                    variant='h5'>{settings?.label || t('dashboard.yourTickets')}< /Typography>
        <TableContainer sx={{height: 'calc(100% - 116px)'}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.includes('number') &&
                            <TableCell sortDirection={orderColumn === 'number' ? order : false}
                                       sx={{fontWeight: 'bold'}}>
                                <TableSortLabel
                                    active={orderColumn === 'number'}
                                    direction={order}
                                >
                                    {t('tickets.fields.number')}
                                </TableSortLabel>
                            </TableCell>}
                        {columns.includes('customer') &&
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.customer')}</TableCell>}
                        {columns.includes('category') &&
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.category')}</TableCell>}
                        {columns.includes('title') &&
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.title')}</TableCell>}
                        {columns.includes('status') &&
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.status')}</TableCell>}
                        {columns.includes('importance') && <TableCell
                            sx={{fontWeight: 'bold'}}>{t('tickets.fields.importance')}</TableCell>}
                        {columns.includes('tags') &&
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.tags')}</TableCell>}
                        {columns.includes('createdAt') &&
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.createdAt')}</TableCell>}
                        {columns.includes('updatedAt') &&
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.updatedAt')}</TableCell>}
                        {columns.includes('completedAt') && <TableCell
                            sx={{fontWeight: 'bold'}}>{t('tickets.fields.completedAt')}</TableCell>}
                    </TableRow>
                </TableHead>
                {!loading && <TableBody>
                    {ticketList}
                </TableBody>}
            </Table>
            {loading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            </Box>}
            {total <= 0 && !loading && notFoundMessage}
        </TableContainer>
        {total > 0 && <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={perPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={(e) => {
                setPerPage(parseInt(e.target.value, 10))
                setPage(0)
            }}
        />}
    </Box>
}

export default Tickets;
