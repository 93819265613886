import { useTranslation } from "react-i18next";
import { Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const TagSelect = ({value, onChange, tags, label, sx, multiple = false}) => {
    const {t} = useTranslation();

    if(!value) {
        if(multiple) {
            value = [];
        } else {
            value = '';
        }
    }
    return <FormControl fullWidth sx={sx}>
        <InputLabel>{label}</InputLabel>
        <Select
            label={t(label)}
            value={value || ''}
            fullWidth
            renderValue={(selected) => {
                if(multiple) {
                    return selected.map(s => {
                        const tag = tags.find(t => t.id === s);
                        return <Chip key={s} label={tag?.name || s} sx={{mr: 1, backgroundColor: tag?.color || 'black', color: 'white'}}/>
                    });
                } else {
                    const tag = tags.find(t => t.id === selected);
                    return <Chip label={tag?.name || selected} sx={{backgroundColor: tag?.color || 'black', color: 'white'}}/>
                }
            }}
            multiple={multiple}
            onChange={(e) => onChange(e.target.value)}
        >
            {tags.map((tag) => (<MenuItem key={tag.id} value={tag.id}>
                <Chip label={tag.name} sx={{backgroundColor: tag.color, color: 'white'}}/>
            </MenuItem>))}
        </Select>
    </FormControl>
}

export default TagSelect;
