import Api from '../core/Api';

const CALL_API = Symbol('Call API');

const api = store => next => action => {
    const callAPI = action[CALL_API];

    // So the middleware doesn't get applied to every single action
    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    let {types, identifier = null} = callAPI;

    const [successType, errorType, requestType] = types;

    store.dispatch({
        type: requestType,
        identifier: identifier,
    });

    return Api.fetch(callAPI, store.dispatch).then(
        response => {
            return next({
                response: response.response,
                headers: response.headers,
                identifier: identifier,
                type: successType
            })
        },
        error => {

            let errorText = error.message;

            return next({
                error: errorText,
                code: error.code,
                identifier: identifier,
                type: errorType
            });
        }
    )
};

export { CALL_API, api }
