import React, {useCallback, useEffect} from 'react';
import {
    Alert,
    AppBar,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Switch as MuiSwitch,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {Link, Link as RouterLink, useParams} from "react-router-dom";
import {
    Add,
    Check,
    ChevronLeft,
    Close,
    Delete,
    Edit,
    MoreVert,
    OpenInNew,
    Print,
    Replay,
    Save
} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import CheckPermissions from "../../Utils/CheckPermissions";
import Api from "../../../core/Api";
import {fetchTagList} from "../../../actions/tagActions";
import {fetchCategoryList} from "../../../actions/categoryActions";
import {fetchStatusList} from "../../../actions/statusActions";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {formatAddress} from "../../../helper/formatAddress";
import {fetchUserList} from "../../../actions/userActions";
import {Route, Routes, useMatch} from "react-router";
import {push} from "react-router-redux";
import Actions from "./Actions";
import {fetchTaskList} from "../../../actions/taskActions";
import {fetchDeviceTypes} from "../../../actions/deviceTypeActions";
import History from "./History";
import Devices from "./Devices";
import {LoadingButton} from "@mui/lab";
import {cloneDeep, uniq} from "lodash";
import Schema from "validate";
import ImportanceSlider from "../../Utils/ImportanceSlider";
import TagSelect from "../../Utils/TagSelect";
import {fetchGroupList} from "../../../actions/groupActions";
import LinkedUsers from "./LinkedUsers";
import FileBrowser from "../../Utils/FileBrowser";
import SignatureCanvas from "react-signature-canvas";
import * as EmailValidator from "email-validator";
import DeleteDialog from "../../Utils/DeleteDialog";
import CheckVersion from "../../Utils/CheckVersion";


const getTicketValidationSchema = (t) => {
    return new Schema({
        title: {
            required: true,
            type: String,
            message: t('tickets.validation.title'),
        },
        categoryId: {
            required: true,
            type: String,
            message: t('tickets.validation.categoryId'),
        },
        importanceId: {
            required: true,
            type: Number,
            message: t('tickets.validation.importanceId'),
        },
        addressIds: {
            type: Array,
            required: false,
            length: {min: 1},
            each: {type: String},
            message: t('tickets.validation.addressIds'),
        }
    })
}

const email = (val) => EmailValidator.validate(val);
const getCreatePdfValidationSchema = (t) => {
    return new Schema({
        email: {
            required: true,
            type: String,
            use: {email},
            message: t('tickets.validation.pdfEmail')
        }
    });
}

const getCreatePdfAdditionalValidationSchema = (t) => {
    return new Schema({
        additionalMails: {
            type: Array,
            required: false,
            each: {type: String, use: {email}, message: t('tickets.validation.pdfEmail')},
        }
    });
}

const Show = () => {

    const customerSignRef = React.createRef();
    const employeeSignRef = React.createRef();

    const params = useParams();
    const ticketId = params.id;

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [actionsLoading, setActionsLoading] = React.useState(false);
    const [fetchingDevices, setFetchingDevices] = React.useState(false);
    const [ticket, setTicket] = React.useState({});
    const [customer, setCustomer] = React.useState({});
    const [devices, setDevices] = React.useState([]);
    const [saving, setSaving] = React.useState(false);
    const [menu, setMenu] = React.useState(null);
    const [answerRequest, setAnswerRequest] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const [pdfIsCreating, setPdfIsCreating] = React.useState(false);
    const [createPdfSettings, setCreatePdfSettings] = React.useState(null);

    const [pdfSettings, setPdfSettings] = React.useState(false);
    const [pdfSettingsLoading, setPdfSettingsLoading] = React.useState(false);

    const [editTicketData, setEditTicketData] = React.useState(null);
    const [errors, setErrors] = React.useState({});

    const handleTicketDataChange = useCallback((key, value) => {
        setEditTicketData((d) => ({
            ...d,
            [key]: value
        }))
    }, [setEditTicketData]);

    const handlePdfSettingsChange = useCallback((key, value) => {
        setCreatePdfSettings((d) => ({
            ...d,
            [key]: value
        }))
    }, [setCreatePdfSettings]);

    const routeMatch = useMatch('/tickets/show/:id/:tabId');
    const tabId = routeMatch?.params?.tabId;

    const isLoading = actionsLoading || loading;
    const [actions, setActions] = React.useState([]);

    useEffect(() => {
        if (!tabId || !['actions', 'devices', 'history', 'linked-users', 'files', 'images'].includes(tabId)) {
            dispatch(push(`/tickets/show/${ticketId}/actions`));
        }
    }, [tabId, dispatch, ticketId]);

    const fetchPdfSettings = useCallback(() => {
        setPdfSettingsLoading(true);
        Api.fetch({
            endpoint: 'settings/pdf'
        }).then(res => {
            setPdfSettings(res.response)
        }, () => {
        }).then(() => setPdfSettingsLoading(false))
    }, []);

    const fetchTicket = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: 'tickets/' + ticketId,
        }).then((res) => {
            setTicket(res.response);
        }, () => {
        }).then(() => setLoading(false))
    }, [ticketId]);

    const onDelete = useCallback(() => {
        setDeleting(true)
        Api.fetch({
            endpoint: 'tickets/' + ticketId,
            method: 'DELETE'
        }).then(() => {
            dispatch(push('/tickets'));
        }, () => {
        }).then(() => setDeleting(false));
    }, [dispatch, ticketId])

    const fetchActions = useCallback(() => {
        setActionsLoading(true);
        Api.fetch({
            endpoint: 'tickets/' + ticketId + '/actions',
        }).then((res) => {
            setActions(res.response.map((action) => {
                action.deviceIds = action.deviceIds || [];
                action.taskIds = action.taskIds || [];
                action.addressIds = action.addressIds || [];
                return action;
            }));
        }, () => {
        }).then(() => setActionsLoading(false))
    }, [ticketId]);

    const saveDevices = (devices) => {
        setSaving(true);
        const saveTicket = cloneDeep(ticket);
        saveTicket.deviceIds = devices.map((d) => d.id);

        return new Promise((resolve, reject) => {
            Api.fetch({
                endpoint: 'tickets/' + saveTicket.id,
                method: "PUT",
                body: saveTicket
            })
                .then(response => {
                    setTicket(response.response);
                    setEditTicketData(null);
                    resolve();
                }, () => {
                    reject();
                }).then(() => setSaving(false))
        });
    };

    const deleteUserLink = (userId) => {
        setSaving(true);
        const saveTicket = cloneDeep(ticket);
        saveTicket.userLinks = saveTicket.userLinks.filter((u) => u.userId !== userId);

        return new Promise((resolve, reject) => {
            Api.fetch({
                endpoint: 'tickets/' + saveTicket.id,
                method: "PUT",
                body: saveTicket
            })
                .then(response => {
                    setTicket(response.response);
                    setEditTicketData(null);
                    resolve();
                }, () => {
                    reject();
                }).then(() => setSaving(false))
        });
    }

    const saveUserLinks = (userLinks) => {
        setSaving(true);
        const saveTicket = cloneDeep(ticket);

        userLinks.forEach((userId) => {
            if (!saveTicket.userLinks.find((l) => l.userId === userId)) {
                saveTicket.userLinks.push({
                    status: 'pending',
                    userId: userId
                })
            }
        })


        return new Promise((resolve, reject) => {
            Api.fetch({
                endpoint: 'tickets/' + saveTicket.id,
                method: "PUT",
                body: saveTicket
            })
                .then(response => {
                    setTicket(response.response);
                    setEditTicketData(null);
                    resolve();
                }, () => {
                    reject();
                }).then(() => setSaving(false))
        });
    };

    const editData = () => {
        setErrors({});
        setSaving(true);

        const v = getTicketValidationSchema(t)
        const errors = v.validate(cloneDeep(editTicketData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrors(errorObject)
            return;
        }

        const saveTicket = cloneDeep(ticket);
        saveTicket.categoryId = editTicketData.categoryId;
        saveTicket.title = editTicketData.title;
        saveTicket.description = editTicketData.description;
        saveTicket.contact = editTicketData.contact;
        saveTicket.contactId = editTicketData.contactId;
        saveTicket.addressIds = editTicketData.addressIds;
        saveTicket.importanceId = editTicketData.importanceId;
        saveTicket.tags = editTicketData.tags;
        saveTicket.private = editTicketData.private;

        Api.fetch({
            endpoint: 'tickets/' + saveTicket.id,
            method: "PUT",
            body: saveTicket
        })
            .then(response => {
                setTicket(response.response);
                setEditTicketData(null);
            }, () => {
            }).then(() => setSaving(false))
    }

    const createPdf = () => {
        setErrors({});
        setPdfIsCreating(true);

        let contactEmail = '';
        const v = getCreatePdfValidationSchema(t)
        const va = getCreatePdfAdditionalValidationSchema(t)

        const errors = va.validate(cloneDeep({
            additionalMails: createPdfSettings.additionalMails || []
        }))

        const errorObject = {};
        if (errors.length) {
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })
        }

        if (createPdfSettings.sendMailToContact && createPdfSettings.contactEmail != null) {
            const errors = v.validate(cloneDeep({
                email: createPdfSettings.contactEmail,
                additionalMails: createPdfSettings.additionalMails || []
            }))

            if (errors.length) {
                errors.forEach((error) => {
                    errorObject[error.path] = error.message
                })
                setPdfIsCreating(false);
                setErrors(errorObject)
                return;
            }
            contactEmail = createPdfSettings.contactEmail;
        } else if (createPdfSettings.sendMailToContact && createPdfSettings.contactId) {
            (customer.addresses?.filter(a => ticket?.addressIds.includes(a.id)).forEach((a) => {
                a.contacts?.forEach((c) => {
                    if (c.id === createPdfSettings.contactId) {
                        contactEmail = c.email;
                    }
                });
            }));
        }

        if (Object.keys(errorObject).length) {
            setPdfIsCreating(false);
            setErrors(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'actions',
            method: 'POST',
            body: {
                action: 'ticket-pdf',
                payload: {
                    ticketId: ticket.id,
                    addressId: createPdfSettings.addressId,
                    sendMailToUser: createPdfSettings.sendMail,
                    additionalMailAddresses: createPdfSettings.additionalMails || [],
                    contactMail: contactEmail ? contactEmail : null,
                    customerSign: customerSignRef.current.getCanvas().toDataURL(),
                    employeeSign: employeeSignRef.current.getCanvas().toDataURL()
                }
            }
        }).then(() => {
            setCreatePdfSettings(null)
            dispatch({
                type: 'ADD_ALERT',
                message: t('tickets.show.pdfWillBeCreated'),
                style: 'success'
            })
        }, () => {
        }).then(() => setPdfIsCreating(false))
    }

    const handleRequest = (type) => {
        setAnswerRequest(true)
        Api.fetch({
            endpoint: "tickets/" + ticketId + "/request/" + type,
            method: 'POST'
        }).then(() => {
            setTicket(t => {
                const i = t.userLinks.findIndex(l => l.userId === user.user.id && l.status === 'pending');
                if (i > -1) {
                    t.userLinks[i].status = type;
                }

                return t;
            })
        }, () => {
        }).then(() => {
            setAnswerRequest(false)
        })
    }

    useEffect(() => {
        if (!ticket.customerId) {
            return;
        }
        Api.fetch({
            endpoint: 'customers/' + ticket.customerId,
        }).then((res) => {
            setCustomer(res.response);
        }, () => {
        })
    }, [ticket.customerId])

    useEffect(() => {
        if (!ticket.deviceIds?.length) {
            return;
        }
        setFetchingDevices(true);
        Api.fetch({
            endpoint: 'devices',
            parameter: {
                filter: {
                    ids: 'in<-->' + uniq(ticket.deviceIds).join(",")
                }
            }
        }).then((res) => {
            setDevices(res.response);
        }, () => {
        }).then(() => setFetchingDevices(false))
    }, [ticket.deviceIds])

    const fetchData = useCallback(() => {
        fetchTicket();
        fetchActions();
        fetchPdfSettings();
        dispatch(fetchTagList())
        dispatch(fetchTaskList())
        dispatch(fetchCategoryList())
        dispatch(fetchStatusList())
        dispatch(fetchUserList())
        dispatch(fetchGroupList())
        dispatch(fetchDeviceTypes())
    }, [dispatch, fetchTicket, fetchActions, fetchPdfSettings])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const userList = useSelector((state) => state.userList.list)
    const tagList = useSelector((state) => state.tags.list)
    const statusList = useSelector((state) => state.statusList.list)
    const groupList = useSelector((state) => state.groups.list)
    const categories = useSelector((state) => state.categories.list)
    const taskList = useSelector((state) => state.tasks.list)
    const deviceTypeList = useSelector((state) => state.deviceTypes.list)
    const user = useSelector((state) => state.user)

    //const globalIsFetching = useSelector((state) => state.tags.isFetching || state.statusList.isFetching || state.categories.isFetching);

    const category = categories.find(c => c.id === ticket.categoryId);
    const creator = userList.find(c => c.id === ticket.creatorId);

    let creatorNameRaw = t('deleted');
    let creatorName = <i>{t('deleted')}</i>
    if (ticket.creatorId && creator?.id) {
        creatorName = creatorNameRaw = `${creator?.givenName} ${creator?.familyName}`;
    } else if (!ticket.creatorId) {
        creatorName = <i>{t('systemUser')}</i>
        creatorNameRaw = t('systemUser');
    }

    let contactString = ticket.contact;
    if (!contactString && ticket.contactId) {
        customer.addresses?.forEach((address) => {
            address.contacts?.forEach((contact) => {
                if (contact.id === ticket.contactId) {
                    const contactOptions = [contact.email, contact.telephone].filter(k => Boolean(k)).join(', ')
                    contactString = contact.name;
                    if (contactOptions) {
                        contactString += ': ' + contactOptions;
                    }
                }
            })
        })
    }

    let addresses = ticket.addressIds?.filter(addressId => customer.addresses?.find(a => a.id === addressId)).map((addressId) => {
        const address = customer.addresses.find(a => a.id === addressId);
        return <React.Fragment key={address.id}>{formatAddress(address)}</React.Fragment>;
    }).reduce((prev, elem, i) => {
        return prev === null ? [elem] : [...prev, <br key={'br-' + i}/>, elem]
    }, null);

    if (!addresses) {
        addresses = '-'
    }

    let addressSelects = ticket.addressIds?.filter(addressId => customer.addresses?.find(a => a.id === addressId)).map((addressId) => {
        const a = customer.addresses.find(a => a.id === addressId);
        return <MenuItem key={a.id} value={a.id}>
            <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                <Chip size='small' sx={{ml: 2}}
                      label={t('customers.address.main')}/> : null}</React.Fragment>}
                          secondary={formatAddress(a)}/>
        </MenuItem>;
    });

    const contacts = (customer.addresses?.filter(a => editTicketData?.addressIds.includes(a.id)).map((a) => {
        return a.contacts?.map((c) => (<MenuItem key={c.id} value={c.id}>
            <ListItemText primary={<React.Fragment>{c.name} ({a.name})</React.Fragment>}
                          secondary={[c.email, c.telephone].filter(k => Boolean(k)).join(', ')}/>
        </MenuItem>)) || []
    }) || []).flat();

    const pdfContacts = (customer.addresses?.filter(a => ticket?.addressIds.includes(a.id)).map((a) => {
        return a.contacts?.map((c) => (<MenuItem disabled={!c.email} key={c.id} value={c.id}>
            <ListItemText primary={<React.Fragment>{c.name} ({a.name})</React.Fragment>}
                          secondary={[c.email, c.telephone].filter(k => Boolean(k)).join(', ')}/>
        </MenuItem>)) || []
    }) || []).flat();


    const additionalEmailInputs = (createPdfSettings?.additionalMails || []).map((email, i) => {

        return <ListItem disableGutters={true}
                         secondaryAction={<IconButton onClick={() => {
                             setCreatePdfSettings({
                                 ...createPdfSettings,
                                 additionalMails: createPdfSettings?.additionalMails.filter((e, j) => j !== i)
                             });
                         }}>
                             <Delete/>
                         </IconButton>}>
            <TextField
                fullWidth
                margin='none'
                size='small'
                required
                error={errors.hasOwnProperty('additionalMails.' + i)}
                helperText={errors['additionalMails.' + i]}
                label={t('tickets.show.additionalEmail')}
                placeholder={t('tickets.show.additionalEmail')}
                value={email}
                onChange={(e) => {
                    const newAdditionalMails = [...createPdfSettings.additionalMails];
                    newAdditionalMails[i] = e.target.value;
                    setCreatePdfSettings({...createPdfSettings, additionalMails: newAdditionalMails})
                }}
            />
        </ListItem>
    });

    console.log(tabId)

    return (
        <React.Fragment>
            <Box sx={{overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box', px: 2}}>
                <Toolbar disableGutters={true} variant='dense'>
                    <Button component={RouterLink} to={'/tickets'}
                            startIcon={<ChevronLeft/>}
                            color='primary'>{t('back')}</Button>
                    <Box flexGrow={1}/>
                    <IconButton onClick={fetchData}><Replay/></IconButton>
                </Toolbar>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Paper sx={{mb: 2}}>
                            <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1}}>
                                <Typography variant='h5'
                                            component='h1'>#{ticket.number} {ticket.title}
                                </Typography>
                                <Box flexGrow={1}/>
                                <CheckPermissions list={['tickets.write', 'tickets.delete']}>
                                    <IconButton sx={{ml: 1}} disabled={loading || saving}
                                                onClick={(e) => setMenu(e.currentTarget)}><MoreVert/></IconButton>
                                </CheckPermissions>
                            </Toolbar>
                            <List disablePadding={true} sx={{pl: 2, pr: 2, pb: 2}}>
                                <ListItem disableGutters={true}
                                          secondaryAction={
                                              <IconButton component={Link} to={'/customers/show/' + customer?.id}
                                                          target={'_blank'} edge="end" aria-label="delete">
                                                  <OpenInNew/>
                                              </IconButton>}
                                          disablePadding>
                                    <ListItemText primary={t('tickets.fields.customer')}
                                                  secondary={customer?.name}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.addresses')}
                                                  secondary={addresses}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.contact')}
                                                  secondary={contactString || '-'}/>
                                </ListItem>
                                {Boolean(ticket.recurringTicketId) && <ListItem disableGutters={true} disablePadding>
                                    <Button component={Link}
                                            to={'/tickets/edit-recurring/' + ticket.recurringTicketId}
                                            size='small'
                                            startIcon={<OpenInNew/>}>{t('tickets.show.openRecurringTicket')}</Button>
                                </ListItem>}
                            </List>
                            <Divider variant='middle'/>
                            <Toolbar disableGutters={true} variant='dense' sx={{px: 2, pt: 1}}>
                                <Tooltip title={t('tickets.show.createPdf')}>
                                    <IconButton disabled={pdfSettingsLoading} onClick={() => {
                                        let additionalMails = null
                                        if (pdfSettings.emailForCopies) {
                                            additionalMails = [pdfSettings.emailForCopies]
                                        }
                                        setCreatePdfSettings({
                                            addressId: ticket.addressIds?.filter(addressId => customer.addresses?.find(a => a.id === addressId))[0] || '',
                                            sendMail: false,
                                            sendMailToContact: false,
                                            additionalMails: additionalMails,
                                            contactId: null,
                                            contactEmail: null
                                        })
                                    }}>
                                        <Print/>
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                            <List sx={{px: 2, pt: 0}}>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.status')}
                                                  secondary={<Select
                                                      disabled={saving || isLoading}
                                                      size="small"
                                                      fullWidth
                                                      value={ticket.statusId || ''}
                                                      onChange={(e) => {
                                                          setSaving(true);
                                                          Api.fetch({
                                                              endpoint: 'tickets/' + ticketId,
                                                              method: 'PUT',
                                                              body: {
                                                                  ...ticket,
                                                                  statusId: e.target.value
                                                              }
                                                          }).then((res) => {
                                                              setTicket(res.response);
                                                          }, () => {
                                                          }).then(() => setSaving(false))
                                                      }}
                                                      renderValue={(selected) => statusList.find(r => r.id === selected)?.name || ''}
                                                      input={<OutlinedInput label={t('tickets.fields.status')}/>}
                                                  >
                                                      {statusList.map((r) => (
                                                          <MenuItem key={r.id} value={r.id}>
                                                              <ListItemText primary={r.name} secondary={r.description}/>
                                                          </MenuItem>
                                                      ))}
                                                  </Select>}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.category')}
                                                  secondary={category?.name || <i>{t('deleted')}</i>}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.importance')}
                                                  secondary={ticket.importanceId}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.creator')}
                                                  secondary={creatorName}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.createdAt')}
                                                  secondary={moment(ticket.createdAt).format('DD.MM.YYYY HH:mm')}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.updatedAt')}
                                                  secondary={moment(ticket.updatedAt).format('DD.MM.YYYY HH:mm')}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.completedAt')}
                                                  secondary={ticket.completedAt ? moment(ticket.completedAt).format('DD.MM.YYYY HH:mm') : '-'}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('tickets.fields.private')}
                                                  secondary={ticket.private ? <Check/> : <Close/>}/>
                                </ListItem>
                                <ListItem disableGutters={true} disablePadding>
                                    <ListItemText primary={t('customers.fields.tags')}
                                                  secondaryTypographyProps={{
                                                      component: 'div'
                                                  }}
                                                  secondary={ticket.tags?.filter((tag) => tagList.find((t) => t.id === tag))?.length ? ticket.tags?.filter((tag) => tagList.find((t) => t.id === tag)).map((tag) => {
                                                      const tagDefinition = tagList.find((t) => t.id === tag);
                                                      return <Chip key={tag} label={tagDefinition.name} sx={{
                                                          mr: 1,
                                                          backgroundColor: tagDefinition.color,
                                                          color: 'white'
                                                      }}/>
                                                  }) : '-'}/>
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    <Menu
                        anchorEl={menu}
                        open={Boolean(menu)}
                        onClose={() => setMenu(null)}
                    >
                        <MenuItem onClick={() => {
                            setMenu(null);
                            setEditTicketData({
                                description: ticket.description,
                                categoryId: ticket.categoryId,
                                title: ticket.title,
                                contact: ticket.contact ? ticket.contact : null,
                                contactId: ticket.contactId ? ticket.contactId : null,
                                addressIds: ticket.addressIds,
                                importanceId: ticket.importanceId,
                                tags: ticket.tags,
                                private: ticket.private,
                            })
                        }}>
                            <ListItemIcon>
                                <Edit fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>{t('tickets.show.editTicket')}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setMenu(null);
                            setDeleteOpen(true);
                        }}>
                            <ListItemIcon>
                                <Delete fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>{t('tickets.delete.label')}</ListItemText>
                        </MenuItem>
                    </Menu>
                    <Grid item xs={12} sm={12} md={9}>
                        {!!ticket.userLinks?.find(l => l.userId === user.user.id && l.status === 'pending') &&
                            <Alert sx={{mb: 2, border: '1px solid'}} severity='info'
                                   action={<Box sx={{marginTop: '-4px'}}>
                                       <Button onClick={() => handleRequest('accepted')} disabled={answerRequest}
                                               sx={{mr: 1}} variant='outlined'>{t('tickets.show.acceptTicket')}</Button>
                                       <Button onClick={() => handleRequest('rejected')}
                                               disabled={answerRequest}>{t('tickets.show.rejectTicket')}</Button>
                                   </Box>}
                            >
                                {t('tickets.show.userLinkPending')}
                            </Alert>}
                        <AppBar position="relative" color='transparent'>
                            <Tabs value={tabId} variant="scrollable">
                                <Tab key='actions' label={t('tickets.actions.label')} value='actions'
                                     to={`/tickets/show/${ticketId}/actions`}
                                     component={RouterLink}/>
                                <Tab key='history' label={t('tickets.history.label')} value='history'
                                     to={`/tickets/show/${ticketId}/history`}
                                     component={RouterLink}/>
                                <Tab key={'devices'} label={t('tickets.show.devices')} value='devices'
                                     to={`/tickets/show/${ticketId}/devices`}
                                     component={RouterLink}/>
                                <Tab key={'linked-users'} label={t('tickets.userLinks.label')} value='linked-users'
                                     to={`/tickets/show/${ticketId}/linked-users`}
                                     component={RouterLink}/>
                                <Tab key={'files'} label={t('tickets.show.images')} value='images'
                                     to={`/tickets/show/${ticketId}/images`}
                                     component={RouterLink}/>
                                <Tab key={'images'} label={t('tickets.show.files')} value='files'
                                     to={`/tickets/show/${ticketId}/files`}
                                     component={RouterLink}/>
                            </Tabs>
                            <Paper>
                                <Routes>
                                    <Route path={`actions`}
                                           element={<Actions
                                               onChange={(withStatus) => {
                                                   fetchActions();
                                                   if (withStatus) {
                                                       fetchTicket();
                                                   }
                                               }}
                                               devices={devices}
                                               deviceTypeList={deviceTypeList}
                                               customer={customer}
                                               creatorNameRaw={creatorNameRaw}
                                               creatorName={creatorName} userList={userList}
                                               actions={actions} actionsLoading={actionsLoading}
                                               taskList={taskList} statusList={statusList}
                                               ticket={ticket}/>}/>
                                    <Route path={`history`}
                                           element={<History
                                               loading={loading}
                                               ticket={ticket}
                                               statusList={statusList}
                                               userList={userList}
                                           />}/>
                                    <Route path={`devices`}
                                           element={<Devices
                                               fetching={fetchingDevices}
                                               devices={devices}
                                               deviceTypeList={deviceTypeList}
                                               customer={customer}
                                               saveDevices={saveDevices}
                                           />}/>
                                    <Route path={`linked-users`}
                                           element={<LinkedUsers
                                               loading={loading}
                                               saving={saving}
                                               ticket={ticket}
                                               userList={userList}
                                               groupList={groupList}
                                               addUserLinks={saveUserLinks}
                                               deleteUserLink={deleteUserLink}
                                           />}/>
                                    <Route path={`files`}
                                           element={<FileBrowser type={'ticket-' + ticketId + '-files'}/>}/>
                                    <Route path={`images`}
                                           element={<FileBrowser accept={'image/*'} displayType='grid'
                                                                 type={'ticket-' + ticketId + '-images'}/>}/>
                                </Routes>
                            </Paper>
                        </AppBar>
                    </Grid>
                </Grid>
            </Box>
            <Dialog maxWidth='md' fullWidth open={Boolean(editTicketData)}>
                <DialogTitle>
                    {t('tickets.show.editTicket')}
                    <IconButton
                        aria-label="close"
                        disabled={saving}
                        onClick={() => setEditTicketData(null) && setErrors({})}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close/>
                    </IconButton>
                </DialogTitle>
                {editTicketData && <DialogContent>
                    <TextField
                        fullWidth
                        margin='dense'
                        required
                        error={errors.hasOwnProperty('title')} y
                        helperText={errors.title}
                        label={t('tickets.fields.title')}
                        placeholder={t('tickets.fields.title')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {Number(ticket.title?.length)}/50
                                </InputAdornment>
                            ),
                        }}
                        value={editTicketData.title}
                        onChange={(e) => {
                            handleTicketDataChange('title', e.target.value.slice(0, 50));
                        }}
                    />
                    <FormControl fullWidth margin='dense' error={errors.hasOwnProperty('addressIds')}>
                        <InputLabel shrink
                                    id='address-label'>{t('tickets.fields.addresses')}</InputLabel>
                        <Select
                            notched
                            label={t('tickets.fields.addresses')}
                            labelId='address-label'
                            multiple
                            error={errors.hasOwnProperty('addressIds')}
                            renderValue={(v) => v.filter(aId => customer.addresses?.find(a => a.id === aId)).map(aId => customer.addresses.find(a => a.id === aId).name).join(', ')}
                            value={editTicketData.addressIds}
                            fullWidth
                            labelWidth={100}
                            onChange={(e) => handleTicketDataChange('addressIds', e.target.value)}
                        >
                            {customer.addresses?.map((a) => (<MenuItem key={a.id} value={a.id}>
                                <Checkbox checked={editTicketData.addressIds.includes(a.id)}/>
                                <ListItemText primary={<React.Fragment>{a.name}{a.main ?
                                    <Chip size='small' sx={{ml: 2}}
                                          label={t('customers.address.main')}/> : null}</React.Fragment>}
                                              secondary={formatAddress(a)}/>
                            </MenuItem>))}
                        </Select>
                        {errors.hasOwnProperty('addressIds') &&
                            <FormHelperText>{errors.addressIds}</FormHelperText>}
                    </FormControl>
                    {editTicketData.contact === null && <Box>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel shrink={Boolean(editTicketData.contactId)}
                                        id='address-label'>{t('tickets.create.contact')}</InputLabel>
                            <Select
                                shrink={Boolean(editTicketData.contactId)}
                                label={t('tickets.create.contact')}
                                labelId='address-label'
                                value={editTicketData.contactId}
                                disabled={!contacts?.length}
                                fullWidth
                                labelWidth={200}
                                onChange={(e) => setEditTicketData((t) => {
                                    t.contactId = e.target.value;
                                    return cloneDeep(t);
                                })}>
                                {contacts}
                            </Select>
                        </FormControl>
                        <Button edge="end" color="primary" onClick={() => setEditTicketData((t) => ({
                            ...t,
                            contactId: null,
                            contact: '',
                        }))}>
                            {t('tickets.create.manually')}
                        </Button>
                    </Box>}
                    {editTicketData.contact !== null && <Box><TextField
                        fullWidth
                        margin='dense'
                        label={t('tickets.create.contact')}
                        placeholder={t('tickets.create.contact')}
                        value={editTicketData.contact}
                        onChange={(e) => {
                            handleTicketDataChange('contact', e.target.value);
                        }}
                    />
                        <Button edge="end" color="primary" onClick={() => setEditTicketData((t) => ({
                            ...t,
                            contactId: '',
                            contact: null,
                        }))}>
                            {t('tickets.create.selectContact')}
                        </Button>
                    </Box>}
                    <FormControl fullWidth margin='dense'>
                        <InputLabel>{t('tickets.fields.category')}</InputLabel>
                        <Select
                            value={editTicketData.categoryId}
                            onChange={(e) => handleTicketDataChange('categoryId', e.target.value)}
                            renderValue={
                                (selected) => categories.find(r => r.id === selected)?.name || ''
                            }
                            input={<OutlinedInput label={t('tickets.fields.category')}/>}
                        >
                            {categories.map((r) => (
                                <MenuItem key={r.id} value={r.id}>
                                    <ListItemText primary={r.name} secondary={r.description}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <ImportanceSlider
                        label={t('tickets.fields.importance')}
                        value={editTicketData.importanceId}
                        onChange={(e, v) => handleTicketDataChange('importanceId', v)}
                    />
                    <TagSelect
                        multiple
                        margin='dense'
                        sx={{mt: 2}}
                        tags={tagList}
                        label={t('tickets.fields.tags')} value={editTicketData.tags}
                        onChange={(v) => handleTicketDataChange('tags', v || null)}/>
                    <FormControlLabel
                        control={<MuiSwitch onChange={(e) => handleTicketDataChange('private', e.target.checked)}
                                            checked={editTicketData.private}/>}
                        label={t('tickets.fields.private')}/>
                </DialogContent>}
                <DialogActions>
                    <Button
                        disabled={saving}
                        onClick={() => setEditTicketData(null) && setErrors({})}
                    >
                        {t('close')}
                    </Button>
                    <LoadingButton
                        loadingPosition="start"
                        loading={saving}
                        onClick={editData}
                        startIcon={<Save/>}
                        variant='contained'
                        color='primary'
                    >
                        {t('save')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth='md' fullWidth open={Boolean(createPdfSettings)}>
                <DialogTitle>
                    {t('tickets.show.createPdf')}
                    <IconButton
                        aria-label="close"
                        disabled={pdfIsCreating}
                        onClick={() => setCreatePdfSettings(null) && setErrors({})}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close/>
                    </IconButton>
                </DialogTitle>
                {Boolean(createPdfSettings) && <DialogContent>
                    <CheckVersion minimumVersion='extended'>
                        <FormControl fullWidth disabled={!user.user.emailVerified}>
                            <FormControlLabel control={<MuiSwitch
                                onChange={(e) => handlePdfSettingsChange('sendMail', e.target.checked)}
                                checked={createPdfSettings.sendMail}/>} label={t('tickets.show.sendMail')}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormControlLabel control={<MuiSwitch
                                onChange={(e) => handlePdfSettingsChange('sendMailToContact', e.target.checked)}
                                checked={createPdfSettings.sendMailToContact}/>}
                                              label={t('tickets.show.sendMailToContact')}/>
                        </FormControl>
                        {createPdfSettings.sendMailToContact && <>
                            {createPdfSettings.contactEmail === null && <Box>
                                <FormControl fullWidth margin='dense' disabled={!pdfContacts?.length}>
                                    <InputLabel shrink={Boolean(createPdfSettings.contactId)}
                                                id='address-label'>{t('tickets.create.contact')}</InputLabel>
                                    <Select
                                        shrink={Boolean(createPdfSettings.contactId)}
                                        label={t('tickets.create.contact')}
                                        labelId='address-label'
                                        value={createPdfSettings.contactId}
                                        disabled={!pdfContacts?.length}
                                        fullWidth
                                        labelWidth={200}
                                        onChange={(e) => handlePdfSettingsChange('contactId', e.target.value)}>
                                        {pdfContacts}
                                    </Select>
                                </FormControl>
                                <Button edge="end" color="primary" onClick={() => setCreatePdfSettings((t) => ({
                                    ...t,
                                    contactId: null,
                                    contactEmail: '',
                                }))}>
                                    {t('tickets.create.manually')}
                                </Button>
                            </Box>}
                            {createPdfSettings.contactEmail !== null && <Box><TextField
                                fullWidth
                                margin='dense'
                                error={errors.hasOwnProperty('email')}
                                helperText={errors.email}
                                label={t('tickets.create.contactEmail')}
                                placeholder={t('tickets.create.contactEmail')}
                                value={createPdfSettings.contactEmail}
                                onChange={(e) => {
                                    handlePdfSettingsChange('contactEmail', e.target.value);
                                }}
                            />
                                <Button edge="end" color="primary" onClick={() => setCreatePdfSettings((t) => ({
                                    ...t,
                                    contactId: '',
                                    contactEmail: null,
                                }))}>
                                    {t('tickets.create.selectContact')}
                                </Button>
                            </Box>}
                        </>}
                        <FormControlLabel control={<MuiSwitch
                            onChange={(e) => handlePdfSettingsChange('additionalMails', e.target.checked ? [''] : null)}
                            checked={createPdfSettings.additionalMails != null}/>}
                                          label={t('tickets.show.sendAdditionalMails')}/>
                        {createPdfSettings.additionalMails != null && <>
                            <List disablePadding={true} dense>
                                {additionalEmailInputs}
                            </List>
                            <IconButton edge="end" color="primary" onClick={() => setCreatePdfSettings((t) => ({
                                ...t,
                                additionalMails: [
                                    ...t.additionalMails,
                                    ''
                                ]
                            }))}><Add/></IconButton>
                        </>}
                        <FormControl fullWidth margin='normal'>
                            <InputLabel shrink={Boolean(createPdfSettings.addressId)}
                                        id='address-label'>{t('tickets.fields.address')}</InputLabel>
                            <Select
                                notched={Boolean(createPdfSettings.addressId)}
                                label={t('tickets.fields.address')}
                                labelId='address-label'
                                value={createPdfSettings.addressId}
                                fullWidth
                                labelWidth={100}
                                onChange={(e) => setCreatePdfSettings((t) => ({
                                    ...t,
                                    addressId: e.target.value,
                                }))}>
                                {addressSelects}
                            </Select>
                        </FormControl>
                        <Typography variant='h5'
                                    component='h1'>{t('tickets.show.customerSign')}</Typography>
                        <SignatureCanvas canvasProps={{className: 'ticket-signature-canvas'}} ref={customerSignRef}/>
                        <Button startIcon={<Close/>} onClick={() => customerSignRef.current.clear()} size='small'>
                            {t('clear')}
                        </Button>
                        <Typography variant='h5'
                                    component='h1'>{t('tickets.show.employeeSign')}</Typography>
                        <SignatureCanvas canvasProps={{className: 'ticket-signature-canvas'}} ref={employeeSignRef}/>
                        <Button startIcon={<Close/>} onClick={() => employeeSignRef.current.clear()} size='small'>
                            {t('clear')}
                        </Button>
                    </CheckVersion>
                </DialogContent>}
                <DialogActions>
                    <Button
                        disabled={pdfIsCreating}
                        onClick={() => setCreatePdfSettings(null) && setErrors({})}
                    >
                        {t('close')}
                    </Button>
                    <CheckVersion disableFallback minimumVersion='extended'>
                        <LoadingButton
                            loadingPosition="start"
                            loading={pdfIsCreating}
                            onClick={createPdf}
                            startIcon={<Save/>}
                            variant='contained'
                            color='primary'
                        >
                            {t('save')}
                        </LoadingButton>
                    </CheckVersion>
                </DialogActions>
            </Dialog>
            <DeleteDialog onDelete={() => onDelete()} title={t('tickets.delete.label')}
                          isDeleting={deleting}
                          handleClose={() => setDeleteOpen(false)}
                          description={t('tickets.delete.description', {
                              title: ticket.title || '',
                              number: ticket.number || '0'
                          })}
                          open={deleteOpen}/>
        </React.Fragment>
    );
};

export default Show;
