import React, {useCallback, useEffect, useState} from 'react';
import Api from "../../../../core/Api";
import {
    Alert,
    Box,
    Button,
    Card,
    CardHeader,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {Add, Delete, DragHandle, Replay, Save} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {fetchGroupList} from "../../../../actions/groupActions";
import {fetchCategoryList} from "../../../../actions/categoryActions";
import {fetchUserList} from "../../../../actions/userActions";
import CheckPermissions from "../../../Utils/CheckPermissions";
import ImportanceSlider from "../../../Utils/ImportanceSlider";
import FilePicker from "../../../Utils/FilePicker";
import {Link} from "react-router-dom";
import {useTheme} from "@mui/system";
import Avatar from "@mui/material/Avatar";
import logo from "../../../../images/small-logo.png";
import getTextColor from "../../../../helper/getTextColor";
import TagSelect from "../../../Utils/TagSelect";
import {fetchStatusList} from "../../../../actions/statusActions";
import {fetchTagList} from "../../../../actions/tagActions";
import CheckVersion from "../../../Utils/CheckVersion";
import {Container, Draggable} from "react-smooth-dnd";
import {arrayMoveImmutable} from "array-move";
import {ColorPicker} from "mui-color";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Settings = () => {
    const {t} = useTranslation()
    const publicTranslation = useTranslation('frontend')
    const dispatch = useDispatch()
    const theme = useTheme();

    const [settings, setSettings] = useState({
        active: false,
        categoryId: null,
        groups: [],
        user: [],
        subjectType: 'freeText',
        subjectOptions: [],
        importance: 5,
    })

    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [tab, setTab] = useState(0)
    const [file, setFile] = useState({})

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/support-ticket'
        }).then(res => {
            const settings = res.response
            settings.subjectType = settings.subjectType || 'freeText'
            settings.subjectOptions = settings.subjectOptions || []
            setSettings(settings)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    const fetchFile = useCallback(() => {
        if (settings.logo) {
            Api.fetch({
                endpoint: 'storage/support-ticket-logo/' + settings.logo,
            }).then(res => {
                setFile(res.response)
            }, () => {
            })
        } else {
            setFile({})
        }
    }, [setFile, settings.logo])

    useEffect(() => {
        fetchFile()
    }, [fetchFile])

    useEffect(() => {
        if (settings.subjectType === 'subjectSelection' && settings.subjectOptions.length === 0) {
            handleChange('subjectOptions', [{position: 0, content: ''}])
        }
    }, [settings.subjectType, settings.subjectOptions.length])

    const fetchEntities = useCallback(() => {
        dispatch(fetchGroupList())
        dispatch(fetchUserList())
        dispatch(fetchCategoryList())
        dispatch(fetchStatusList())
        dispatch(fetchTagList())
    }, [dispatch])

    useEffect(() => {
        fetchSettings()
        fetchEntities()
    }, [fetchSettings, fetchEntities])


    const groupList = useSelector((state) => state.groups.list)
    const userList = useSelector((state) => state.userList.list)
    const categoryList = useSelector((state) => state.categories.list)
    const statusList = useSelector((state) => state.statusList.list)
    const tagList = useSelector((state) => state.tags.list)
    const isFetching = useSelector((state) => state.groups.isFetching || state.userList.isFetching || state.categories.isFetching || state.statusList.isFetching || state.tags.isFetching)

    const onSave = useCallback(() => {
        setSaving(true)
        Api.fetch({
            endpoint: 'settings/support-ticket',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving])

    return <Box sx={{mb: 2}}>
        <CheckVersion minimumVersion='pro'>
            <Toolbar variant='dense' disableGutters={true}>
                <CheckPermissions list={['supportTicket.write']}>
                    <LoadingButton
                        onClick={onSave}
                        size='small'
                        loadingPosition="start"
                        disabled={loading || isFetching}
                        loading={saving}
                        startIcon={<Save/>}
                        variant='contained'
                        color='primary'>{t('save')}</LoadingButton>
                </CheckPermissions>
                <Box flexGrow={1}/>
                <IconButton onClick={() => {
                    fetchSettings();
                    fetchEntities();
                    fetchFile();
                }}><Replay/></IconButton>
            </Toolbar>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper sx={{p: 2}}>
                        <Alert sx={{mb: 2}} severity={'info'}>{t('settings.supportTicket.description')}</Alert>

                        <FormControlLabel control={<Switch onChange={(e) => handleChange('active', e.target.checked)}
                                                           checked={settings.active}/>}
                                          label={t('settings.supportTicket.active')}/>
                        <Collapse in={settings.active}>
                            <Button variant='outlined'
                                    component={Link} to={'/support/new-ticket'} target={'_blank'}
                                    fullWidth sx={{mt: 1}}>{t('settings.supportTicket.open')}</Button>
                            <Tabs variant="scrollable"
                                  scrollButtons="auto"
                                  sx={{mt: 2}} value={tab} onChange={(_, value) => setTab(value)}>
                                <Tab label={t('settings.supportTicket.ticketSettings')}/>
                                <Tab label={t('settings.supportTicket.contentSettings')}/>
                                <Tab label={t('settings.supportTicket.designSettings')}/>
                            </Tabs>
                            <Box sx={{pt: 3}} hidden={tab !== 0}>
                                <Typography variant='h5'
                                            component='div'>{t('settings.supportTicket.ticketSettings')}</Typography>
                                <FormControl fullWidth margin='normal'>
                                    <InputLabel>{t('settings.supportTicket.category')}</InputLabel>
                                    <Select
                                        value={categoryList.map(c => c.id).indexOf(settings.categoryId) > -1 ? settings.categoryId : ''}
                                        onChange={(e) => handleChange('categoryId', e.target.value)}
                                        renderValue={
                                            (selected) => categoryList.find(r => r.id === selected).name
                                        }
                                        input={<OutlinedInput label={t('settings.supportTicket.category')}/>}
                                    >
                                        {categoryList.map((r) => (
                                            <MenuItem key={r.id} value={r.id}>
                                                <ListItemText primary={r.name} secondary={r.description}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth margin='normal'>
                                    <InputLabel>{t('settings.supportTicket.status')}</InputLabel>
                                    <Select
                                        value={settings.statusId || 0}
                                        onChange={(e) => handleChange('statusId', e.target.value ? e.target.value : null)}
                                        input={<OutlinedInput label={t('settings.supportTicket.status')}/>}
                                    >
                                        <MenuItem value={0}>
                                            <ListItemText
                                                primary={t('settings.supportTicket.automaticStatus')}
                                                secondary={t('settings.supportTicket.automaticStatusDescription')}
                                            />
                                        </MenuItem>
                                        {statusList.map((r) => (
                                            <MenuItem key={r.id} value={r.id}>
                                                <ListItemText primary={r.name} secondary={r.description}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TagSelect
                                    multiple
                                    margin='normal'
                                    sx={{mt: 2, mb: 1}}
                                    tags={tagList}
                                    label={t('settings.supportTicket.tags')} value={settings.tags}
                                    onChange={(v) => handleChange('tags', v || null)}/>
                                <FormControl fullWidth margin='normal'>
                                    <InputLabel>{t('settings.supportTicket.groups')}</InputLabel>
                                    <Select
                                        multiple
                                        value={settings.groups.filter(g => groupList.map(g => g.id).indexOf(g) > -1)}
                                        onChange={(e) => {
                                            handleChange('groups', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                                        }}
                                        input={<OutlinedInput label={t('settings.supportTicket.groups')}/>}
                                        renderValue={
                                            (selected) => selected.map((v) => groupList.find(r => r.id === v).name).join(', ')
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {groupList.map((r) => (
                                            <MenuItem key={r.id} value={r.id}>
                                                <Checkbox checked={settings.groups.includes(r.id)}/>
                                                <ListItemText primary={r.name} secondary={r.description}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth margin='normal'>
                                    <InputLabel>{t('settings.supportTicket.users')}</InputLabel>
                                    <Select
                                        multiple
                                        value={settings.user.filter(g => userList.map(g => g.id).indexOf(g) > -1)}
                                        onChange={(e) => {
                                            handleChange('user', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                                        }}
                                        input={<OutlinedInput label={t('settings.supportTicket.users')}/>}
                                        renderValue={
                                            (selected) => selected.map((v) => {
                                                const user = userList.find(r => r.id === v)
                                                if (!user.givenName.length && !user.familyName.length) {
                                                    return user.email
                                                }
                                                return user.givenName + ' ' + user.familyName
                                            }).join(', ')
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {userList.map((r) => (
                                            <MenuItem key={r.id} value={r.id}>
                                                <Checkbox checked={settings.user.includes(r.id)}/>
                                                <ListItemText primary={r.givenName + ' ' + r.familyName}
                                                              secondary={r.email}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <ImportanceSlider
                                    label={t('settings.supportTicket.importance')}
                                    value={settings.importance}
                                    onChange={(e, v) => handleChange('importance', v)}
                                />
                            </Box>
                            <Box sx={{pt: 3}} hidden={tab !== 1}>
                                <Typography variant='h5'
                                            component='div'>{t('settings.supportTicket.contentSettings')}</Typography>
                                <FormControl fullWidth margin='normal'>
                                    <InputLabel>{t('settings.supportTicket.subjectType')}</InputLabel>
                                    <Select
                                        value={settings.subjectType || 'freeText'}
                                        onChange={(e) => handleChange('subjectType', e.target.value)}
                                        input={<OutlinedInput label={t('settings.supportTicket.subjectType')}/>}
                                    >
                                        {['freeText', 'subjectSelection'].map((r) => (
                                            <MenuItem key={r} value={r}>
                                                <ListItemText primary={t('settings.supportTicket.subjectTypes.' + r)}
                                                              secondary={t('settings.supportTicket.subjectTypes.' + r + 'Description')}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {settings.subjectType === 'subjectSelection' && <>
                                    <Toolbar disableGutters={true} variant='dense'>
                                        <Typography
                                            variant='h6'>{t('settings.supportTicket.subjectOptions')}</Typography>
                                        <Box flexGrow={1}/>
                                        <IconButton onClick={() => {
                                            handleChange('subjectOptions', settings.subjectOptions.concat({
                                                content: '',
                                                position: settings.subjectOptions.length
                                            }))
                                        }}><Add/></IconButton>
                                    </Toolbar>
                                    <List>
                                        <Container dragHandleSelector=".drag-handle" lockAxis="y"
                                                   onDrop={({removedIndex, addedIndex}) => {
                                                       handleChange('subjectOptions', arrayMoveImmutable(settings.subjectOptions, removedIndex, addedIndex).map((value, index) => {
                                                           value.position = index;
                                                           return value
                                                       }));
                                                   }}>
                                            {settings.subjectOptions.sort((a, b) => {
                                                //sort by position
                                                if (a.position < b.position) {
                                                    return -1;
                                                }
                                                if (a.position > b.position) {
                                                    return 1;
                                                }
                                                return 0;
                                            }).map((subjectSelectionItem, i) => (
                                                <Draggable key={subjectSelectionItem.position}>
                                                    <ListItem disableGutters={true} secondaryAction={<IconButton
                                                        disabled={settings.subjectOptions.length <= 1} onClick={() => {
                                                        handleChange('subjectOptions', settings.subjectOptions.filter((value, index) => index !== i))
                                                    }
                                                    }>
                                                        <Delete/>
                                                    </IconButton>}>
                                                        <ListItemIcon>
                                                            <ListItemIcon className="drag-handle">
                                                                <DragHandle/>
                                                            </ListItemIcon>
                                                        </ListItemIcon>
                                                        <TextField
                                                            fullWidth
                                                            margin='none'
                                                            size='small'
                                                            required
                                                            label={t('settings.supportTicket.subject')}
                                                            placeholder={t('settings.supportTicket.subject')}
                                                            value={subjectSelectionItem.content}
                                                            onChange={(e) => {
                                                                handleChange('subjectOptions', settings.subjectOptions.map((value, index) => {
                                                                    if (index === i) {
                                                                        value.content = e.target.value;
                                                                    }
                                                                    return value
                                                                }))
                                                            }}
                                                        />
                                                    </ListItem>
                                                </Draggable>
                                            ))}
                                        </Container>
                                    </List>
                                </>}
                            </Box>
                            <Box sx={{pt: 3}} hidden={tab !== 2}>
                                <Typography variant='h5'
                                            component='div'>{t('settings.supportTicket.designSettings')}</Typography>
                                <FilePicker placeholder={t('settings.supportTicket.logo')} accept={'image/*'}
                                            type={'support-ticket-logo'} path={settings.logo}
                                            onSelect={(path) => handleChange('logo', path)}/>
                                <Typography variant='h6'
                                            component='div'>{t('settings.supportTicket.color')}:</Typography>
                                <ColorPicker hideTextfield={true} value={settings.color || theme.palette.primary.main}
                                             onChange={({hex}) => {
                                                 handleChange('color', '#' + hex)
                                             }}/>
                            </Box>
                        </Collapse>
                    </Paper>
                </Grid>
                {settings.active && <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title={t('settings.supportTicket.preview')}/>
                        <CardHeader
                            avatar={<Avatar variant="square" sx={{height: 100, width: 'auto'}}
                                            src={file?.url || logo}/>}
                            sx={{backgroundColor: settings.color || theme.palette.primary.main}}
                            titleTypographyProps={{
                                sx: {
                                    fontSize: '250%',
                                    color: getTextColor(settings.color || theme.palette.primary.main)
                                }
                            }}
                            title={publicTranslation.t('createTicket')}
                        />
                    </Card>
                </Grid>}
            </Grid>
        </CheckVersion>
    </Box>;
}

export default Settings;
