import {
    USER_NOTIFICATIONS_SUCCESS,
    USER_NOTIFICATIONS_FAILURE,
    USER_NOTIFICATIONS_REQUEST,
    USER_NOTIFICATIONS_CLEAR
} from "../actions/userNotificationsActions";

export function userNotificationsReducer(state = {
    isFetching: false,
    error: null,
    data: null
}, action) {
    switch (action.type) {
        case USER_NOTIFICATIONS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case USER_NOTIFICATIONS_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case USER_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.response
            });
        case USER_NOTIFICATIONS_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                data: null,
                error: null
            });
        default:
            return state
    }
}

