import {CALL_API} from '../middleware/api'

export const DEVICE_TYPE_LIST_REQUEST = 'DEVICE_TYPE_LIST_REQUEST';
export const DEVICE_TYPE_LIST_SUCCESS = 'DEVICE_TYPE_LIST_SUCCESS';
export const DEVICE_TYPE_LIST_FAILURE = 'DEVICE_TYPE_LIST_FAILURE';


export function fetchDeviceTypes() {

    return {
        [CALL_API]: {
            endpoint: 'device-types',
            method: 'GET',
            types: [DEVICE_TYPE_LIST_SUCCESS, DEVICE_TYPE_LIST_FAILURE, DEVICE_TYPE_LIST_REQUEST]
        }
    }
}

