import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar, Tooltip,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, InfoOutlined, Replay, Save } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import Row from "./Row";
import { LoadingButton } from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [categories, setCategories] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchCategories()
    }, []);

    const fetchCategories = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'categories'
        })
            .then((res) => {
                setCategories(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        categories.push({id: uuid(), name: '', description: '', preSelected: false, isNew: true})
        setCategories(categories.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (categories.some(category => category.hasErrors && !category.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = categories.filter(g => g.isNew || g.isDeleted || g.hasChanges).map((c) => {
            const category = Object.assign({}, c);
            const isNew = category.isNew;
            const isDeleted = category.isDeleted;
            const hasChanges = category.hasChanges;

            delete category.isNew;
            delete category.isDeleted;
            delete category.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'categories', method: 'POST', body: category})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'categories/' + category.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'categories/' + category.id, method: 'PUT', body: category})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchCategories();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = categories.some((category) => category.hasChanges || category.isNew || category.isDeleted);
    return <React.Fragment>
        {hasError &&
        <Alert onClose={() => setHasError(null)} severity='error'>{t('settings.categories.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['categories.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.categories.addCategory')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchCategories}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.categories.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.categories.description')}</TableCell>
                        <Tooltip title={t('settings.categories.preSelectedInfo')}>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Box>{t('settings.categories.preSelected')}</Box>
                                    <InfoOutlined sx={{color: (theme) => theme.palette.grey[600]}} fontSize='small'/>
                                </Stack>
                            </TableCell>
                        </Tooltip>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.filter((g) => !g.isDeleted).map((category) => (
                        <Row key={category.id} category={category}
                             onDelete={() => {
                                 setCategories((categories) => {
                                     const i = categories.findIndex((c) => c.id === category.id);
                                     const deleteCategory = categories[i];

                                     if (deleteCategory.isNew) {
                                         categories.splice(i, 1)
                                     } else {
                                         deleteCategory.isDeleted = true;
                                         categories[i] = deleteCategory;
                                     }
                                     return categories.slice();
                                 })
                             }}
                             onChange={(changedCategory) => {
                                 setCategories((categories) => {
                                     const i = categories.findIndex((c) => c.id === changedCategory.id);
                                     categories[i] = changedCategory;

                                     if(changedCategory.preSelected) {
                                            categories.forEach((c, i) => {
                                                if(c.id !== changedCategory.id && c.preSelected) {
                                                    c.preSelected = false;
                                                    c.hasChanges = true;
                                                    categories[i] = c;
                                                }
                                            })
                                     }

                                     return categories.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
            {categories.filter((g) => !g.isDeleted).length === 0 &&
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('settings.categories.noCategoriesCreated')}</Typography>
                    <Link variant='body2' onClick={onAdd}>{t('settings.categories.addCategory')}</Link>
                </Box>
            </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
