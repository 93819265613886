import {CALL_API} from '../middleware/api'

export const CUSTOMER_MAP_SETTING_REQUEST = 'CUSTOMER_MAP_SETTING_REQUEST';
export const CUSTOMER_MAP_SETTING_SUCCESS = 'CUSTOMER_MAP_SETTING_SUCCESS';
export const CUSTOMER_MAP_SETTING_FAILURE = 'CUSTOMER_MAP_SETTING_FAILURE';

export const NUMBER_RANGE_SETTING_REQUEST = 'NUMBER_RANGE_SETTING_REQUEST';
export const NUMBER_RANGE_SETTING_SUCCESS = 'NUMBER_RANGE_SETTING_SUCCESS';
export const NUMBER_RANGE_SETTING_FAILURE = 'NUMBER_RANGE_SETTING_FAILURE';

export function fetchCustomerMapSettings() {

    return {
        [CALL_API]: {
            endpoint: 'settings/customer-map',
            method: 'GET',
            types: [CUSTOMER_MAP_SETTING_SUCCESS, CUSTOMER_MAP_SETTING_FAILURE, CUSTOMER_MAP_SETTING_REQUEST]
        }
    }
}

export function fetchNumberRangeSettings() {

    return {
        [CALL_API]: {
            endpoint: 'settings/number-range',
            method: 'GET',
            types: [NUMBER_RANGE_SETTING_SUCCESS, NUMBER_RANGE_SETTING_FAILURE, NUMBER_RANGE_SETTING_REQUEST]
        }
    }
}

