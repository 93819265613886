import React, { useCallback, useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Collapse,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Paper,
    Switch,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight, Download, RestartAlt, Send } from "@mui/icons-material";
import Api from "../../core/Api";
import CheckPermissions from "../Utils/CheckPermissions";
import { LoadingButton } from "@mui/lab";

const MapSettings = ({telegramSettings, setTelegramSettings, setActiveStep}) => {
    const {t} = useTranslation();

    const [fetching, setFetching] = useState(false)
    const [testing, setTesting] = useState(false)

    const handleChange = (key, value) => {
        setTelegramSettings((s) => ({...s, [key]: value}))
    }

    const fetchSettings = useCallback(() => {
        if(!telegramSettings.validationCode) {
            Api.fetch({
                endpoint: 'settings/telegram'
            }).then(res => {
                setTelegramSettings(res.response)
            }, () => {
            })
        }
    }, [setTelegramSettings, telegramSettings.validationCode]);

    useEffect(() => {
        fetchSettings()
    }, [fetchSettings])


    const fetchChatId = () => {
        setFetching(true);
        Api.fetch({
            endpoint: 'integrations/telegram/chat-id',
        }).then((res) => {
            handleChange('chatId', res.response.chatId)
        }, () => {
        }).then(() => {
            setFetching(false)
        })
    }

    const testChat = () => {
        setTesting(true);
        Api.fetch({
            endpoint: 'integrations/telegram/test',
            method: 'POST',
            body: {
                chatId: telegramSettings.chatId,
            }
        }).then(() => {
        }, () => {
        }).then(() => {
            setTesting(false)
        })
    }

    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Button size='small' startIcon={<ChevronLeft/>} variant='contained'
                    onClick={() => setActiveStep(6)}>{t('back')}</Button>
            <Box flexGrow={1}/>
            <Button size='small' startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(8)}>{t('next')}</Button>
        </Toolbar>
        <Paper sx={{p: 2, mb: 2}}>
            <Alert sx={{mb: 2}} severity={'info'}>{t('settings.telegram.description')}</Alert>
            <FormControlLabel control={<Switch onChange={(e) => handleChange('active', e.target.checked)}
                                               checked={telegramSettings.active}/>}
                              label={t('settings.reminder.active')}/>
            <Collapse in={telegramSettings.active}>
                <Typography>
                    <TextField
                        fullWidth
                        value={telegramSettings.chatId || ''}
                        label={t('settings.telegram.chatId')}
                        placeholder={t('settings.telegram.chatId')}
                        margin='normal'
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" color="primary"
                                                onClick={() => handleChange('chatId', null)}>
                                        <RestartAlt/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{__html: t('settings.telegram.instruction', {validationCode: telegramSettings.validationCode})}}/>
                </Typography>
                <CheckPermissions list={['telegram.write']}>
                    <LoadingButton loadingPosition="start" startIcon={<Download/>} loading={fetching}
                                   onClick={fetchChatId} sx={{mt: 2}} fullWidth
                                   variant='contained'>{t('settings.telegram.request')}</LoadingButton>
                    <LoadingButton disabled={!telegramSettings.chatId} loadingPosition="start" startIcon={<Send/>}
                                   loading={testing}
                                   onClick={testChat} sx={{mt: 2}} fullWidth
                                   variant='outlined'>{t('settings.telegram.test')}</LoadingButton>
                </CheckPermissions>
            </Collapse>
        </Paper>
    </React.Fragment>
}

export default MapSettings;
