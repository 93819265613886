export const formatAddress = (address) => {
    if(!address?.id) {
        return '-';
    }
    return `${address.name} - ${formatAddressOnly(address)}`;
}

export const formatAddressOnly = (address) => {
    if(!address?.id) {
        return '-';
    }
    return (`${address.address}, ${address['zip']} ${address.city}`) + (address.additional ? ', ' + address.additional : '')
}
