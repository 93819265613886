import React, { useCallback, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    Paper,
    Slider,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight, LocationSearching } from "@mui/icons-material";
import Api from "../../core/Api";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import LeafletChangeView from "../Utils/LeafletChangeView";

const MapSettings = ({mapSettings, setMapSettings, setActiveStep}) => {
    const {t} = useTranslation();
    const [coordsLoading, setCoordsLoading] = useState(false)

    const handleChange = (key, value) => {
        setMapSettings((s) => ({...s, [key]: value}))
    };

    const fetchCoords = useCallback(async () => {
        setCoordsLoading(true);
        Api.fetch({
            endpoint: 'integrations/location/by-address',
            parameter: {
                address: mapSettings.address
            }
        }).then((res) => {
            const data = res.response;
            setMapSettings((s) => ({...s, address: data.label, lat: data.lat, lng: data.lng}))
        }, () => {
        }).then(() => {
            setCoordsLoading(false);
        })

    }, [mapSettings.address, setMapSettings])

    const position = [mapSettings.lat, mapSettings.lng]

    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Button size='small' startIcon={<ChevronLeft/>} variant='contained'
                    onClick={() => setActiveStep(4)}>{t('back')}</Button>
            <Box flexGrow={1}/>
            <Button size='small' startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(6)}>{t('next')}</Button>
        </Toolbar>
        <Paper sx={{p: 2, mb: 2}}>
            <TextField
                disabled={coordsLoading}
                fullWidth
                value={mapSettings.address}
                label={t('settings.customerMap.address')}
                placeholder={t('settings.customerMap.address')}
                margin='normal'
                onChange={(e) => handleChange('address', e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton edge="end" color="primary" onClick={fetchCoords}>
                                <LocationSearching/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <FormControl fullWidth margin='normal'>
                <Typography gutterBottom>{t('settings.customerMap.zoom')}</Typography>
                <Box sx={{pl: 2, pr: 2}}>
                    <Slider
                        value={mapSettings.zoom}
                        onChange={(e, v) => handleChange('zoom', v)}
                        step={1}
                        marks={[
                            {value: 5, label: '5'},
                            {value: 18, label: '18'},
                        ]}
                        min={5}
                        max={18}
                        valueLabelDisplay='auto'
                    />
                </Box>
            </FormControl>
            <MapContainer style={{height: '300px', width: '100%'}} center={position} zoom={mapSettings.zoom}
                          scrollWheelZoom={false}>
                <LeafletChangeView zoom={mapSettings.zoom} center={position}/>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}/>
            </MapContainer>
        </Paper>
    </React.Fragment>
}

export default MapSettings;
