import {CALL_API} from '../middleware/api'

export const STATUS_LIST_REQUEST = 'STATUS_LIST_REQUEST';
export const STATUS_LIST_SUCCESS = 'STATUS_LIST_SUCCESS';
export const STATUS_LIST_FAILURE = 'STATUS_LIST_FAILURE';


export function fetchStatusList() {

    return {
        [CALL_API]: {
            endpoint: 'status',
            method: 'GET',
            types: [STATUS_LIST_SUCCESS, STATUS_LIST_FAILURE, STATUS_LIST_REQUEST]
        }
    }
}

