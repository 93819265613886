import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, Replay, Save } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import Row from "./Row";
import { LoadingButton } from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [statusList, setStatusList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchStatusList()
    }, []);

    const fetchStatusList = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'status'
        })
            .then((res) => {
                setStatusList(res.response.map((status) => {
                    status.type = status.type || 'pending';
                    return status;
                }));
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        statusList.push({id: uuid(), name: '', description: '', type: 'pending', priority: 0, isNew: true})
        setStatusList(statusList.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (statusList.some(status => status.hasErrors && !status.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = statusList.slice().filter(g => g.isNew || g.isDeleted || g.hasChanges).map((s) => {
            const status = Object.assign({}, s);
            const isNew = status.isNew;
            const isDeleted = status.isDeleted;
            const hasChanges = status.hasChanges;

            delete status.isNew;
            delete status.isDeleted;
            delete status.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'status', method: 'POST', body: status})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'status/' + status.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'status/' + status.id, method: 'PUT', body: status})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchStatusList();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = statusList.some((status) => status.hasChanges || status.isNew || status.isDeleted);

    const displayList = statusList.sort((a, b) => a.priority - b.priority)
    return <React.Fragment>
        {hasError &&
        <Alert onClose={() => setHasError(null)} severity='error'>{t('settings.status.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['status.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.status.addStatus')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchStatusList}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.description')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.priority')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.status.type')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayList.filter((g) => !g.isDeleted).map((status) => (
                        <Row key={status.id} status={status}
                             onDelete={() => {
                                 setStatusList((statusList) => {
                                     const i = statusList.findIndex((c) => c.id === status.id);
                                     const deleteStatus = statusList[i];

                                     if (deleteStatus.isNew) {
                                         statusList.splice(i, 1)
                                     } else {
                                         deleteStatus.isDeleted = true;
                                         statusList[i] = deleteStatus;
                                     }
                                     return statusList.slice();
                                 })
                             }}
                             onChange={(changedStatus) => {
                                 setStatusList((statusList) => {
                                     const i = statusList.findIndex((c) => c.id === changedStatus.id);
                                     statusList[i] = changedStatus;
                                     return statusList.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
            {statusList.filter((g) => !g.isDeleted).length === 0 &&
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('settings.status.noStatusCreated')}</Typography>
                    <Link variant='body2' onClick={onAdd}>{t('settings.status.addStatus')}</Link>
                </Box>
            </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
