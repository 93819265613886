import { Checkbox, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const PropertyInput = ({property, value, onChange}) => {
    const {t} = useTranslation();
    if (property.type === 'string') {
        return <TextField
            size='small'
            fullWidth
            label={t('propertyInput.value')}
            placeholder={t('propertyInput.value')}
            value={value || ''}
            onChange={(e) => onChange(property.id, e.target.value)}
        />
    } else if(property.type === 'boolean') {
        return <Checkbox
            checked={!!value}
            onChange={(e) => onChange(property.id, e.target.checked ? '1' : null)}
        />
    } else if(property.type === 'int') {
        return  <TextField
            size='small'
            fullWidth
            type='number'
            label={t('propertyInput.value')}
            placeholder={t('propertyInput.value')}
            value={value ? parseInt(value) : ''}
            onChange={(e) => onChange(property.id, e.target.value ? parseInt(e.target.value, 10).toString() : '')}
        />
    } else if(property.type === 'float') {
        return <TextField
            size='small'
            fullWidth
            type='number'
            step={0.0001}
            label={t('propertyInput.value')}
            placeholder={t('propertyInput.value')}
            value={value ? parseFloat(value) : ''}
            onChange={(e) => onChange(property.id, e.target.value ? parseFloat(e.target.value).toString() : '')}
        />
    } else if(property.type === 'date') {
        return <TextField
            size='small'
            fullWidth
            type='datetime-local'
            label={t('propertyInput.value')}
            InputLabelProps={{shrink: true}}
            value={value || ''}
            onChange={(e) => onChange(property.id, e.target.value)}
        />
    }
};

export default PropertyInput;
