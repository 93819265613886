import {
    AppBar,
    Avatar,
    Box,
    Container,
    Grid,
    LinearProgress,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Toolbar,
    Typography
} from "@mui/material";
import logo from "../../images/small-logo.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DoNotDisturb } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import Introduction from "./Introduction";
import DeviceTypes from "./DeviceTypes";
import {v1 as uuid} from "uuid";
import Categories from "./Categories";
import MapSettings from "./MapSettings";
import NumberRangeSettings from "./NumberRangeSettings";
import TelegramSettings from "./TelegramSettings";
import Complete from "./Complete";
import Api from "../../core/Api";
import { fetchSystemData } from "../../actions/systemDataActions";
import Status from "./Status";
import BasicSettings from "./BasicSettings";
import {push} from "@lagunovsky/redux-react-router";

const Setup = (props) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [saving, setSaving] = React.useState(false);

    const dispatch = useDispatch();

    const [additionalFeatures, setAdditionalFeatures] = React.useState([]);

    const [basicSettings, setBasicSettings] = React.useState({
        companyName: '',
        address: '',
        zip: '',
        city: '',
    });

    const [deviceTypes, setDeviceTypes] = React.useState([
        {id: uuid(), name: 'Heißgetränke', description: ''},
        {id: uuid(), name: 'Kaltgetränke', description: ''},
        {id: uuid(), name: 'Snack', description: ''}
    ]);
    const [statusList, setStatusList] = React.useState([
        {id: uuid(), name: 'Offen', description: '', priority: 1, type: 'open'},
        {id: uuid(), name: 'In Bearbeitung', description: '', priority: 10, type: 'pending'},
        {id: uuid(), name: 'Abgeschlossen', description: '', priority: 100, type: 'completed'}
    ]);

    const [categories, setCategories] = React.useState([
        {id: uuid(), name: 'Reparatur', description: ''},
        {id: uuid(), name: 'Neuaufstellung', description: ''},
        {id: uuid(), name: 'Wartung', description: ''},
    ]);

    const [mapSettings, setMapSettings] = useState({
        zoom: 13,
        address: "Vellmar, Hessen, DEU",
        lat: 51.36225805000004,
        lng: 9.47249443100003
    })

    const [numberRangeSettings, setNumberRangeSettings] = useState({
        current: 10000,
        end: 0,
        endEnabled: false,
        places: 5,
        start: 10000,
    })

    const [telegramSettings, setTelegramSettings] = useState({
        active: false,
        chatId: null,
        validationCode: null
    })


    const theme = useTheme();
    const requestCount = useSelector(state => state.requestCount)
    const {t} = useTranslation();

    const user = useSelector(state => state.user);

    const onSave = () => {
        setSaving(true);

        const promises = [];

        promises.push(
            ...deviceTypes.map((type) => {
                return Api.fetch({endpoint: 'device-types', method: 'POST', body: type})
            })
        );

        promises.push(
            ...categories.map((cat) => {
                return Api.fetch({endpoint: 'categories', method: 'POST', body: cat})
            })
        );

        promises.push(
            ...statusList.map((status) => {
                return Api.fetch({endpoint: 'status', method: 'POST', body: status})
            })
        );

        promises.push(Api.fetch({
            endpoint: 'settings/customer-map',
            method: 'POST',
            body: mapSettings
        }))

        promises.push(Api.fetch({
            endpoint: 'settings/basics',
            method: 'POST',
            body: basicSettings
        }))

        if (additionalFeatures.includes('numberRange')) {
            promises.push(Api.fetch({
                endpoint: 'settings/number-range',
                method: 'POST',
                body: numberRangeSettings
            }))
        }

        if (additionalFeatures.includes('telegram')) {
            promises.push(Api.fetch({
                endpoint: 'settings/telegram',
                method: 'POST',
                body: telegramSettings
            }))
        }

        promises.push(Api.fetch({
            endpoint: 'settings/system',
            method: 'POST',
            body: {
                setupDone: true
            }
        }))


        Promise.all(promises).then(() => {
            dispatch(fetchSystemData())
            dispatch(push('/'));
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    let content = <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
        <DoNotDisturb sx={{fontSize: 120}} viewBox={'0 0 24 24'}/>
        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='h5'>{t('setup.waitForAdmin')}</Typography>
        </Box>
    </Box>

    useEffect(() => {
        if (activeStep === 6 && !additionalFeatures.includes('numberRange')) {
            setActiveStep(7)
        }
        if (activeStep === 7 && !additionalFeatures.includes('telegram')) {
            setActiveStep(8)
        }
    }, [activeStep, additionalFeatures])

    if (user.user.permissions.includes("*")) {
        content = <Container maxWidth='lg'>
            <Typography sx={{mb: 2}} variant='h4'>{t('setup.label')}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Stepper activeStep={activeStep} onChange={(c, v) => console.log(c, v)} orientation='vertical'>
                        <Step>
                            <StepButton color="inherit" onClick={() => setActiveStep(0)}>
                                <StepLabel>{t('setup.introduction')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step>
                            <StepButton color="inherit" onClick={() => setActiveStep(1)}>
                                <StepLabel>{t('setup.basicSettings')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step>
                            <StepButton color="inherit" onClick={() => setActiveStep(2)}>
                                <StepLabel>{t('setup.deviceTypes')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step>
                            <StepButton color="inherit" onClick={() => setActiveStep(3)}>
                                <StepLabel>{t('setup.ticketCategories')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step>
                            <StepButton color="inherit" onClick={() => setActiveStep(4)}>
                                <StepLabel>{t('setup.ticketStatus')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step>
                            <StepButton color="inherit" onClick={() => setActiveStep(5)}>
                                <StepLabel>{t('setup.customerMap')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step hidden={!additionalFeatures.includes('numberRange')}>
                            <StepButton color="inherit" onClick={() => setActiveStep(6)}>
                                <StepLabel>{t('setup.numberRange')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step hidden={!additionalFeatures.includes('telegram')}>
                            <StepButton color="inherit" onClick={() => setActiveStep(7)}>
                                <StepLabel>{t('setup.telegram')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step>
                            <StepLabel>{t('setup.complete')}</StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
                <Grid item xs={10}>
                    {activeStep === 0 && <Introduction additionalFeatures={additionalFeatures}
                                                       setAdditionalFeatures={setAdditionalFeatures}
                                                       setActiveStep={setActiveStep}/>}
                    {activeStep === 1 && <BasicSettings basicSettings={basicSettings}
                                                        setBasicSettings={setBasicSettings}
                                                        setActiveStep={setActiveStep}/>}
                    {activeStep === 2 && <DeviceTypes deviceTypes={deviceTypes} setDeviceTypes={setDeviceTypes}
                                                      setActiveStep={setActiveStep}/>}
                    {activeStep === 3 && <Categories categories={categories} setCategories={setCategories}
                                                     setActiveStep={setActiveStep}/>}
                    {activeStep === 4 && <Status statusList={statusList} setStatusList={setStatusList}
                                                 setActiveStep={setActiveStep}/>}
                    {activeStep === 5 && <MapSettings mapSettings={mapSettings} setMapSettings={setMapSettings}
                                                      setActiveStep={setActiveStep}/>}
                    {(additionalFeatures.includes('numberRange') && activeStep === 6) &&
                        <NumberRangeSettings numberRangeSettings={numberRangeSettings}
                                             setNumberRangeSettings={setNumberRangeSettings}
                                             setActiveStep={setActiveStep}/>}
                    {(additionalFeatures.includes('telegram') && activeStep === 7) &&
                        <TelegramSettings telegramSettings={telegramSettings}
                                          setTelegramSettings={setTelegramSettings}
                                          setActiveStep={setActiveStep}/>}
                    {activeStep === 8 &&
                        <Complete saving={saving} onSave={onSave}/>}
                </Grid>
            </Grid>
        </Container>
    }

    return <React.Fragment>
        <AppBar position="fixed">
            <Toolbar>
                <Avatar sx={{mr: 2}} src={logo} alt="logo" variant='square'/>
                <Typography variant="h6" noWrap component="div">
                    VeCoDesk
                </Typography>
                <Box flexGrow={1}/>
            </Toolbar>
            {Boolean(requestCount.count) && <LinearProgress sx={{height: '3px'}} color="secondary"/>}
        </AppBar>
        <Toolbar/>
        <Box sx={{mt: 2}} component='div'>
            {content}
        </Box>
    </React.Fragment>
}

export default Setup
