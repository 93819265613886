import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, Replay, Save } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import Row from "./Row";
import { LoadingButton } from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [types, setTypes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchTypes()
    }, []);

    const fetchTypes = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'device-types'
        })
            .then((res) => {
                setTypes(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        types.push({id: uuid(), name: '', description: '', isNew: true})
        setTypes(types.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (types.some(type => type.hasErrors && !type.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = types.filter(g => g.isNew || g.isDeleted || g.hasChanges).map((c) => {
            const type = Object.assign({}, c);
            const isNew = type.isNew;
            const isDeleted = type.isDeleted;
            const hasChanges = type.hasChanges;

            delete type.isNew;
            delete type.isDeleted;
            delete type.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'device-types', method: 'POST', body: type})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'device-types/' + type.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'device-types/' + type.id, method: 'PUT', body: type})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchTypes();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = types.some((type) => type.hasChanges || type.isNew || type.isDeleted);
    return <React.Fragment>
        {hasError &&
        <Alert onClose={() => setHasError(null)} severity='error'>{t('settings.deviceType.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['devicesType.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.deviceType.addType')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchTypes}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.deviceType.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.deviceType.description')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {types.filter((g) => !g.isDeleted).map((type) => (
                        <Row key={type.id} type={type}
                             disableDelete={types.length <= 1}
                             onDelete={() => {
                                 setTypes((types) => {
                                     const i = types.findIndex((c) => c.id === type.id);
                                     const deleteType = types[i];

                                     if (deleteType.isNew) {
                                         types.splice(i, 1)
                                     } else {
                                         deleteType.isDeleted = true;
                                         types[i] = deleteType;
                                     }
                                     return types.slice();
                                 })
                             }}
                             onChange={(changedType) => {
                                 setTypes((types) => {
                                     const i = types.findIndex((c) => c.id === changedType.id);
                                     types[i] = changedType;
                                     return types.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
            {types.filter((g) => !g.isDeleted).length === 0 &&
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('settings.deviceType.noTypesCreated')}</Typography>
                    <Link variant='body2' onClick={onAdd}>{t('settings.deviceType.addType')}</Link>
                </Box>
            </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
