import React from 'react'
import {Grid, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {ConfirmationNumber, Event, PictureAsPdf} from "@mui/icons-material";
import {Route, Routes} from "react-router";
import {Link, Navigate} from "react-router-dom";
import ListIcon from '@mui/icons-material/List';
import CategoryList from './Categories/List'
import StatusList from './Status/List'
import TaskList from './Tasks/List'
import SupportTicketSettings from './SupportTicket/Settings';
import ReminderSettings from './Reminder/Settings';
import PdfSettings from './Pdf/Settings';
import {useSelector} from "react-redux";
import {useMatchPaths} from "../../Utils/useMatchPaths";

const Router = () => {
    const {t} = useTranslation();
    const paths = [
        '/settings/tickets/categories',
        '/settings/tickets/tasks',
        '/settings/tickets/pdf',
        '/settings/tickets/status',
        '/settings/tickets/support-ticket',
        '/settings/tickets/reminder'
    ];
    let currentTab = useMatchPaths(paths);

    let redirect = null;
    const user = useSelector((state) => state.user)
    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

    const routes = []
    const items = [];
    if (hasPermission(['categories.read'])) {
        redirect = '/settings/tickets/categories'
        items.push(<ListItemButton key='categories' component={Link} to={'/settings/tickets/categories'}
                                   selected={currentTab === '/settings/tickets/categories'}>
            <ListItemIcon><ListIcon/></ListItemIcon>
            <ListItemText primary={t('settings.categories.label')}/>
        </ListItemButton>)
        routes.push(<Route key='categories' path='categories' element={<CategoryList/>}/>)
    }
    if (hasPermission(['tasks.read'])) {
        redirect = redirect || '/settings/tickets/tasks'
        items.push(<ListItemButton key='tasks' component={Link} to={'/settings/tickets/tasks'}
                                   selected={currentTab === '/settings/tickets/tasks'}>
            <ListItemIcon><ListIcon/></ListItemIcon>
            <ListItemText primary={t('settings.tasks.label')}/>
        </ListItemButton>)
        routes.push(<Route key='tasks' path='tasks' element={<TaskList/>}/>)
    }
    if (hasPermission(['status.read'])) {
        redirect = redirect || '/settings/tickets/status'
        items.push(<ListItemButton key='status' component={Link} to={'/settings/tickets/status'}
                                   selected={currentTab === '/settings/tickets/status'}>
            <ListItemIcon><ListIcon/></ListItemIcon>
            <ListItemText primary={t('settings.status.label')}/>
        </ListItemButton>)
        routes.push(<Route key='status' path='status' element={<StatusList/>}/>)
    }
    if (hasPermission(['pdf.read'])) {
        redirect = redirect || '/settings/tickets/pdf'
        items.push(<ListItemButton key='pdf' component={Link} to={'/settings/tickets/pdf'}
                                   selected={currentTab === '/settings/tickets/pdf'}>
            <ListItemIcon><PictureAsPdf/></ListItemIcon>
            <ListItemText primary={t('settings.pdf.label')}/>
        </ListItemButton>)
        routes.push(<Route key='pdf' path='pdf' element={<PdfSettings/>}/>)
    }
    if (hasPermission(['supportTicket.read'])) {
        redirect = redirect || '/settings/tickets/support-ticket'
        items.push(<ListItemButton key='support-ticket' component={Link} to={'/settings/tickets/support-ticket'}
                                   selected={currentTab === '/settings/tickets/support-ticket'}>
            <ListItemIcon><ConfirmationNumber/></ListItemIcon>
            <ListItemText primary={t('settings.supportTicket.label')}/>
        </ListItemButton>)
        routes.push(<Route key='support-ticket' path='support-ticket'
                           element={<SupportTicketSettings/>}/>)
    }
    if (hasPermission(['reminder.read'])) {
        redirect = redirect || '/settings/tickets/reminder'
        items.push(<ListItemButton key='reminder' component={Link} to={'/settings/tickets/reminder'}
                                   selected={currentTab === '/settings/tickets/reminder'}>
            <ListItemIcon><Event/></ListItemIcon>
            <ListItemText primary={t('settings.reminder.label')}/>
        </ListItemButton>)
        routes.push(<Route key='reminder' path='reminder' element={<ReminderSettings/>}/>)
    }

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'}
                           element={<Navigate to={redirect}/>}/>)
    }


    return <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <List component={Paper}>
                {items}
            </List>
        </Grid>
        <Grid item xs={12} sm={9}>
            <Routes>
                {routes}
            </Routes>
        </Grid>
    </Grid>;

}

export default Router