import React from 'react'
import {Grid, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {ImportExport, Link as LinkIcon, Send} from "@mui/icons-material";
import {Route, Routes} from "react-router";
import {Link, Navigate} from "react-router-dom";
import TelegramSettings from './Telegram/Settings'
import FourVendingSettings from './4vending/Settings'
import ConnectionRouter from './Connections/Router'
import {useSelector} from "react-redux";
import CheckVersion from "../../Utils/CheckVersion";
import {useMatchPaths} from "../../Utils/useMatchPaths";

const Router = () => {
    const {t} = useTranslation();
    const paths = ['/settings/integrations/telegram', '/settings/integrations/4vending', '/settings/integrations/connections'];
    let currentTab = useMatchPaths(paths);

    let redirect = null;
    const user = useSelector((state) => state.user)
    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

    const routes = []
    const items = [];
    if (hasPermission(['telegram.read'])) {
        redirect = '/settings/integrations/telegram'
        items.push(<ListItemButton component={Link} to={'/settings/integrations/telegram'}
                                   selected={currentTab === '/settings/integrations/telegram'}>
            <ListItemIcon><Send/></ListItemIcon>
            <ListItemText primary={t('settings.telegram.label')}/>
        </ListItemButton>)
        routes.push(<Route path='telegram' element={<TelegramSettings/>}/>)
    }
    if (hasPermission(['fourVending.read'])) {
        redirect = redirect || '/settings/integrations/4vending'
        items.push(<ListItemButton component={Link} to={'/settings/integrations/4vending'}
                                   selected={currentTab === '/settings/integrations/4vending'}>
            <ListItemIcon><ImportExport/></ListItemIcon>
            <ListItemText primary={t('settings.fourVending.label')}/>
        </ListItemButton>)
        routes.push(<Route path='4vending' element={<FourVendingSettings/>}/>)
    }

    if (hasPermission(['connections.read'])) {
        redirect = redirect || '/settings/integrations/connections'
        items.push(<ListItemButton component={Link} to={'/settings/integrations/connections'}
                                   selected={currentTab === '/settings/integrations/connections'}>
            <ListItemIcon><LinkIcon/></ListItemIcon>
            <ListItemText primary={t('settings.connections.label')}/>
        </ListItemButton>)
        routes.push(<Route path='connections/*' element={<ConnectionRouter/>}/>)
    }

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'}
                           element={<Navigate to={redirect}/>}/>)
    }

    return <CheckVersion minimumVersion='extended'>
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <List component={Paper}>
                    {items}
                </List>
            </Grid>
            <Grid item xs={12} md={9}>
                <Routes>
                    {routes}
                </Routes>
            </Grid>
        </Grid>
    </CheckVersion>;

}

export default Router
