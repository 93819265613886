import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../core/Api";
import moment from "moment/moment";
import { Box } from "@mui/system";
import { Notes } from "@mui/icons-material";

const TimersDialog = ({open, onClose, timerIds, setTimers}) => {

    const [selectedTimers, setSelectedTimers] = useState({});

    const [tempTimerIds, setTempTimerIds] = useState([]);

    const {t} = useTranslation();
    const [timerList, setTimerList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [selectedLoading, setSelectedLoading] = useState(false);
    const [timerLoading, setTimerLoading] = useState(false);
    const [size, setSize] = useState(10);


    useEffect(() => {
        if (open) {
            setTempTimerIds(timerIds.slice());
        }
    }, [timerIds, setTempTimerIds, open]);

    useEffect(() => {
        if (!open) {
            return
        }
        setSelectedLoading(true);
        const promises = tempTimerIds.map(timerId => {
            if (selectedTimers.hasOwnProperty(timerId)) {
                return new Promise(resolve => resolve());
            }
            return Api.fetch({
                endpoint: `timer/${timerId}`,
                ignoreErrorCodes: [404]
            }).then(res => setSelectedTimers((s) => {
                return {...s, [timerId]: res.response}
            }), (e) => {
                if(e.response?.status === 404) {
                    setSelectedTimers((s) => {
                        return {...s, [timerId]: {deleted: true}}
                    })
                }
            });
        });
        Promise.all(promises).then(() => setSelectedLoading(false));
    }, [open, tempTimerIds, selectedTimers]);

    useEffect(() => {
        if (!open) {
            return
        }

        setTimerLoading(true);
        Api.fetch({
            endpoint: `timer`,
            params: {
                page: page + 1,
                size: size,
            }
        }).then((res) => {
            setTotal(parseInt(res.headers['x-total-count'] || "0", 10));
            setTimerList(res.response)
        }, () => {
        }).then(() => {
            setTimerLoading(false);
        });
    }, [open, page, size]);

    useEffect(() => {
        if (!open) {
            return
        }
        setPage(0)
    }, [open, setPage])


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
            <DialogTitle>{t('tickets.show.selectTimer')}</DialogTitle>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('timer.startedAt')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('timer.stoppedAt')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('timer.startPoint')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('timer.stopPoint')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('timer.distance')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('timer.duration')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!timerLoading && tempTimerIds.filter((st) => selectedTimers.hasOwnProperty(st)).map((timerId) => {
                            const entry = selectedTimers[timerId];
                            if(entry.deleted) {
                                return (
                                    <TableRow key={timerId}>
                                        <TableCell><Checkbox checked={true}/></TableCell>
                                        <TableCell colSpan={6}>
                                            <Typography>{t('tickets.show.timerDeleted')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            const duration = moment(moment(entry.stoppedAt).diff(moment(entry.startedAt)));
                            const entryFormattedDuration = moment.utc(duration).format('HH:mm:ss');

                            return <TableRow key={entry.id} onClick={() => {
                                if(selectedLoading) {
                                    return;
                                }
                                setSelectedTimers((s) => {
                                    const newSelected = {...s};
                                    delete newSelected[timerId];
                                    return newSelected;
                                });
                                setTempTimerIds(tempTimerIds.filter(tempTimerId => tempTimerId !== timerId));
                            }}>
                                <TableCell><Checkbox disabled={selectedLoading} checked={true}/></TableCell>
                                <TableCell>{moment(entry.startedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                <TableCell>{moment(entry.stoppedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                <TableCell>{entry.startPointLabel || '-'}</TableCell>
                                <TableCell>{entry.stopPointLabel || '-'}</TableCell>
                                <TableCell>{entry.distance >= 0 ? `${entry.distance} km` : '-'}</TableCell>
                                <TableCell>{entryFormattedDuration}</TableCell>
                            </TableRow>
                        })}
                        {!timerLoading && timerList.filter(entry => entry.stoppedAt && !tempTimerIds.includes(entry.id)).map((entry) => {
                            const duration = moment(moment(entry.stoppedAt).diff(moment(entry.startedAt)));
                            const entryFormattedDuration = moment.utc(duration).format('HH:mm:ss');

                            return <TableRow key={entry.id} onClick={() => {
                                if(selectedLoading) {
                                    return;
                                }
                                setTempTimerIds((t) => [...t, entry.id])
                            }}>
                                <TableCell><Checkbox disabled={selectedLoading} checked={false}/></TableCell>
                                <TableCell>{moment(entry.startedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                <TableCell>{moment(entry.stoppedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                <TableCell>{entry.startPointLabel || '-'}</TableCell>
                                <TableCell>{entry.stopPointLabel || '-'}</TableCell>
                                <TableCell>{entry.distance >= 0 ? `${entry.distance} km` : '-'}</TableCell>
                                <TableCell>{entryFormattedDuration}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                {timerLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>}
                {(!timerLoading && total <= 0) && (
                    <Box sx={{textAlign: 'center', color: (t) => t.palette.grey[400]}}>
                        <Notes sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                        <Box sx={{color: (t) => t.palette.text.secondary, mb: 2}} textAlign='center'>
                            <Typography variant='body2'>{t('timer.noHistoryFound')}</Typography>
                        </Box>
                    </Box>
                )}
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={size}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e) => {
                    setSize(parseInt(e.target.value, 10))
                    setPage(0)
                }}
            />
            <DialogActions>
                <Button onClick={onClose}>{t('cancel')}</Button>
                <Button onClick={() => {
                    setTimers(Object.values(selectedTimers).filter((t) => tempTimerIds.includes(t.id)))
                    onClose();
                }}>{t('save')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TimersDialog;
