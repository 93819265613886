import {CALL_API} from '../middleware/api'

export const TOURS_LIST_REQUEST = 'TOURS_LIST_REQUEST';
export const TOURS_LIST_SUCCESS = 'TOURS_LIST_SUCCESS';
export const TOURS_LIST_FAILURE = 'TOURS_LIST_FAILURE';


export function fetchToursList() {

    return {
        [CALL_API]: {
            endpoint: 'tours',
            method: 'GET',
            types: [TOURS_LIST_SUCCESS, TOURS_LIST_FAILURE, TOURS_LIST_REQUEST]
        }
    }
}

