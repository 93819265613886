import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import Api from "../../core/Api";
import { push } from "react-router-redux";
import { useDispatch } from "react-redux";
import CheckVersion from "../Utils/CheckVersion";

const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        colorschemes: {
            scheme: 'tableau.Tableau10'
        }
    }
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Statistic = ({editable, settings, statusList, categoryList}) => {
    const statisticType = settings?.type || 'tickets-by-status';
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);


    useEffect(() => {
        const settingsType = settings?.type || 'tickets-by-status';
        const filter = {}
        if (['tickets-by-category'].includes(settingsType) && settings?.filter?.categories?.length > 0) {
            filter.categories = 'in<-->' + settings.filter.categories.join(',');
        }


        if (['tickets-by-status', 'tickets-by-category'].includes(settingsType) && settings?.filter?.status?.length > 0) {
            filter.status = 'in<-->' + settings.filter.status.join(',');
        }

        Api.fetch({
            endpoint: 'statistics',
            parameter: {
                type: settingsType,
                filter: filter
            }
        }).then((res) => {
            setData(res.response)
        }, () => {
        });
    }, [settings?.filter?.categories, settings?.filter?.status, settings?.type])

    const labels = [];
    const barData = [];

    const ids = []

    if (statisticType === 'tickets-by-status') {
        data?.status?.terms.forEach(term => {
            labels.push(statusList.find(status => status.id === term.key)?.name || t('deleted'))
            barData.push(term.value);
            ids.push(term.key)
        })
    } else if (statisticType === 'tickets-by-category') {
        data?.categories?.terms.forEach(term => {
            labels.push(categoryList.find(category => category.id === term.key)?.name || t('deleted'))
            barData.push(term.value);
            ids.push(term.key)
        })
    }

    const chartData = {
        labels: labels,
        datasets: [
            {
                id: 1,
                data: barData,
                backgroundColor: theme.palette.secondary.light
            },
        ],
    };

    const chartRef = useRef(null);

    return <Box component={'div'} sx={{
        height: '100%', width: '100%',
        pointerEvents: editable ? 'none' : 'inherit',
        opacity: editable ? 0.5 : 1
    }}>
        <Typography sx={{p: 2, maxHeight: '64px'}} component='h2'
                    variant='h5'>{settings?.label || t('dashboard.statistics.types.' + statisticType)}< /Typography>
        <CheckVersion minimumVersion='extended'>
            <Box component='div' sx={{height: 'calc(100% - 116px)', px: 2}}>
                <Bar
                    ref={chartRef}
                    type='bar' data={chartData} options={chartOptions}
                    onClick={(event) => {
                        const {current: chart} = chartRef;

                        if (!chart) {
                            return;
                        }
                        if (statisticType === 'tickets-by-status') {
                            dispatch(push('tickets?filter[status]=' + ids[getElementAtEvent(chart, event)[0].index]));
                        } else if (statisticType === 'tickets-by-category') {
                            dispatch(push('tickets?filter[categories]=' + ids[getElementAtEvent(chart, event)[0].index]));
                        }

                        console.log(getElementAtEvent(chart, event));
                    }}
                />
            </Box>
        </CheckVersion>
    </Box>;
}

export default Statistic;
