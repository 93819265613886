import {Button, Grid} from "@mui/material";
import Logo from '../../images/logo.png'
import {styled} from "@mui/material/styles";
import {Alert} from "@mui/material";
import {useTranslation} from "react-i18next";

const CompanyLogo = styled('img')(({theme}) => ({
    marginBottom: theme.spacing(2),
    maxWidth: '600px'
}))


const LoadingScreen = (props) => {
    const {t} = useTranslation();

    return <Grid container justifyContent='center' alignItems='center' sx={{
        height: '100%'
    }}>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
            <CompanyLogo alt='company logo' src={Logo}/>
            <Alert severity="warning"
                action={<Button color="inherit" size="small" onClick={() => window.location.reload()}>{t('reload')}</Button>}
            ><b>{t('maintenance.title')}</b>: {t('maintenance.description')}</Alert>
        </Grid>
    </Grid>;
}

export default LoadingScreen
