import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import Api from "../../core/Api";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../actions/userActions";
import { LoadingButton } from "@mui/lab";
import { Delete, OpenInNew, Save, Smartphone } from "@mui/icons-material";
import QRCode from "react-qr-code";
import Schema from "validate";
import { fetchUserNotifications } from "../../actions/userNotificationsActions";
import { cloneDeep } from "lodash";
import qs from 'query-string';
import { useLocation } from "react-router";
import moment from "moment";
import { browserNotificationsSupported } from "../../helper/browserNotificationsSupported";

const url = "https://login.vecodesk.com/telegram-callback?state="

const get2FactorValidationSchema = (t) => {
    return new Schema({
        code: {
            required: true,
            type: String,
            message: t('profile.tokenRequired'),
        }
    })
}

const getChangePasswordValidationSchema = (t) => {
    return new Schema({
        oldPassword: {
            required: true,
            type: String,
            message: t('profile.passwordRequired'),
        },
        newPassword: {
            required: true,
            type: String,
            message: t('profile.oldPasswordRequired'),
        },
        confirmPassword: {
            required: true,
            type: String,
            message: t('profile.passwordRequired'),
        }
    })
}

const Profile = () => {
    const dispatch = useDispatch();
    const [savingBasicDetails, setSavingBasicDetails] = React.useState(false);
    const [givenName, setGivenName] = React.useState("");
    const [familyName, setFamilyName] = React.useState("");

    const [savingMfaStatus, setSavingMfaStatus] = React.useState(false);
    const [disableMfaOpen, setDisableMfaOpen] = React.useState(false);

    const [mfaSoftwareTokenLoading, setMfaSoftwareTokenLoading] = React.useState(false);
    const [mfaSoftwareToken, setMfaSoftwareToken] = React.useState('');
    const [token, setToken] = React.useState('');
    const [enableMfaOpen, setEnableMfaOpen] = React.useState(false);
    const [errorList, setErrorList] = useState({});

    const [changePasswordData, setChangePasswordData] = useState(null);
    const [changePasswordLoading, setChangePasswordLoading] = useState(false);

    const [savingSettings, setSavingSettings] = React.useState(false);
    const [loadingSettings, setLoadingSettings] = React.useState(true);
    const [settings, setSettings] = React.useState({});

    const [savingTelegram, setSavingTelegram] = React.useState(false);

    const [browserDenied, setBrowserDenied] = React.useState(!browserNotificationsSupported() || Notification.permission === 'denied');

    const [devicesLoading, setDevicesLoading] = React.useState(true);
    const [deviceDeleting, setDeviceDeleting] = React.useState(false);
    const [devices, setDevices] = React.useState([]);

    const theme = useTheme();
    const {t} = useTranslation();
    const location = useLocation();

    const user = useSelector((state) => state.user)
    useEffect(() => {
        setFamilyName(user.user.familyName);
        setGivenName(user.user.givenName);
    }, [user.user.familyName, user.user.givenName])

    const openMfaDialog = () => {
        setMfaSoftwareTokenLoading(true)
        Api.fetch({
            endpoint: 'user/associate-software-token',
            method: 'POST',
            tokenType: 'access_token'
        }).then((res) => {
            const params = new URLSearchParams({
                secret: res.response.code,
                issuer: "VeCoDesk",
            });

            setMfaSoftwareToken(`otpauth://totp/${user.user.email}?${params.toString()}`)
        }, () => {
        }).then(() => setMfaSoftwareTokenLoading(false))

        setEnableMfaOpen(true);
    }
    const closeMfaDialog = () => {
        setMfaSoftwareToken('')
        setEnableMfaOpen(false);
        setToken('');
    }

    const changePassword = () => {
        setChangePasswordLoading(true)
        setErrorList({})
        const v = getChangePasswordValidationSchema(t)
        const errors = v.validate(cloneDeep(changePasswordData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setChangePasswordLoading(false);
            setErrorList(errorObject)
            return;
        }

        if (changePasswordData.confirmPassword !== changePasswordData.newPassword) {
            const errorObject = {
                confirmPassword: t('profile.passwordMismatch')
            }
            setChangePasswordLoading(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'user/change-password',
            method: 'POST',
            body: changePasswordData,
            tokenType: 'access_token'
        }).then(() => {
            setChangePasswordData(null);
        }, () => {
        }).then(() => setChangePasswordLoading(false))
    }

    const verifyAndSave = () => {
        setSavingMfaStatus(true)
        setErrorList({})
        const v = get2FactorValidationSchema(t)
        const errors = v.validate({
            code: token
        })

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSavingMfaStatus(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'user/verify-software-token',
            method: 'POST',
            body: {
                code: token
            },
            tokenType: 'access_token'
        }).then(() => {
            Api.fetch({
                endpoint: 'user/set-mfa',
                method: 'POST',
                body: {
                    enabled: true
                },
                tokenType: 'access_token'
            }).then(() => {
                dispatch(fetchUser())
                closeMfaDialog()
            }, () => {
            }).then(() => {
                setSavingMfaStatus(false);
            })
        }, () => {
            setSavingMfaStatus(false);
        })
    }

    const disableMfa = () => {
        setSavingMfaStatus(true);
        Api.fetch({
            endpoint: 'user/set-mfa',
            method: 'POST',
            body: {
                enabled: false
            },
            tokenType: 'access_token'
        }).then(() => {
            dispatch(fetchUser())
            setDisableMfaOpen(false)
        }, () => {
        }).then(() => {
            setSavingMfaStatus(false);
        })
    }

    const onSavingBasicDetails = () => {
        setSavingBasicDetails(true);
        Api.fetch({
            endpoint: 'user/me',
            method: 'PUT',
            body: {
                givenName,
                familyName,
                telegramId: user.user.telegramId || ''
            }
        }).then(() => {
            dispatch(fetchUser())
        }, () => {
        }).then(() => {
            setSavingBasicDetails(false);
        })
    }

    const fetchUserDevices = useCallback(() => {
        setDevicesLoading(true)
        Api.fetch({
            endpoint: 'user/me/devices',
            ignoreErrorCodes: [404],
            method: 'GET'
        }).then((res) => {
            setDevices((res.response || []).sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))));
        }, () => {
        }).then(() => setDevicesLoading(false))
    }, [setDevicesLoading, setDevices]);

    const deleteUserDevice = (id) => {
        setDeviceDeleting(true)
        Api.fetch({
            endpoint: `user/me/devices/${id}`,
            method: 'DELETE'
        }).then(() => {
            fetchUserDevices();
        }, () => {
        }).then(() => setDeviceDeleting(false));
    }

    useEffect(() => {
        fetchUserDevices();
    }, [fetchUserDevices])

    const fetchSettings = useCallback(() => {
        setLoadingSettings(true)
        Api.fetch({
            endpoint: 'settings/user-notifications'
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoadingSettings(false))
    }, [setLoadingSettings, setSettings]);

    const onSaveNotifications = () => {
        setSavingSettings(true);
        Api.fetch({
            method: "POST",
            body: settings,
            endpoint: 'settings/user-notifications'
        }).then(res => {
            setSettings(res.response)
            dispatch(fetchUserNotifications())
        }, () => {
        }).then(() => setSavingSettings(false))
    }

    useEffect(() => {
        fetchSettings()
    }, [fetchSettings])

    useEffect(() => {
        const query = qs.parse(location.search);
        console.log(query)
        if (query['telegram-redirect'] !== 'true') {
            return;
        }

        if (!query.success || !query.id) {
            dispatch({
                type: 'ADD_ALERT',
                message: t('profile.telegramFailedMessage'),
                style: 'error'
            })
            return;
        }

        setSavingTelegram(true);
        Api.fetch({
            endpoint: 'user/me',
            method: 'PUT',
            body: {
                givenName: user.user.givenName,
                familyName: user.user.familyName,
                telegramId: query.id || "",
            }
        }).then(() => {
            dispatch(fetchUser())
            dispatch({
                type: 'ADD_ALERT',
                message: t('profile.telegramLinkedMessage'),
                style: 'success'
            })
        }, () => {
        }).then(() => {
            setSavingTelegram(false);
        })
    }, [location.search, user.user.familyName, user.user.givenName, t, dispatch])

    const unlinkTelegram = () => {
        setSavingTelegram(true);
        Api.fetch({
            endpoint: 'user/me',
            method: 'PUT',
            body: {
                givenName: user.user.givenName,
                familyName: user.user.familyName,
                telegramId: '',
            }
        }).then(() => {
            dispatch(fetchUser())
            dispatch({
                type: 'ADD_ALERT',
                message: t('profile.telegramUnlinkedMessage'),
                style: 'success'
            })
        }, () => {
        }).then(() => {
            setSavingTelegram(false);
        })
    }

    const onNotificationChange = (key, type, value) => {
        if (type === "browser") {
            Notification.requestPermission().then((n) => {
                if (Notification.permission === "granted") {
                    setSettings({
                        ...settings,
                        [key]: {
                            ...settings[key],
                            browser: value
                        }
                    })
                } else {
                    setBrowserDenied(true)
                }
            })
        } else {
            setSettings({
                ...settings,
                [key]: {
                    ...settings[key],
                    [type]: value
                }
            })
        }
    }

    const state = {
        redirect: window.location.origin + "/profile?telegram-redirect=true",
    }

    return <React.Fragment>
        <Box component='div' sx={{p: 2, overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card sx={{mb: 2}}>
                        <CardHeader title={t('profile.basicDetails')}
                                    action={<LoadingButton
                                        loadingPosition="start"
                                        disabled={user.isFetching}
                                        loading={savingBasicDetails}
                                        startIcon={<Save/>}
                                        variant='contained' color="primary" onClick={onSavingBasicDetails}>
                                        {t('save')}
                                    </LoadingButton>}/>
                        <CardContent>
                            <TextField
                                margin="normal"
                                fullWidth
                                disabled
                                name="email"
                                label={t('profile.email')}
                                id="email"
                                value={user.user.email}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                disabled={savingBasicDetails || user.isFetching}
                                name="givenName"
                                label={t('profile.givenName')}
                                id="givenName"
                                value={givenName}
                                onChange={(e) => setGivenName(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                disabled={savingBasicDetails || user.isFetching}
                                name="familyName"
                                label={t('profile.familyName')}
                                id="familyName"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                            />
                        </CardContent>
                        <List>
                            <ListItem
                                secondaryAction={
                                    <Button
                                        target='_blank'
                                        component={'a'}
                                        href={'https://www.gravatar.com/emails/'}
                                    >{t('profile.changePicture')}</Button>
                                }>
                                <ListItemText primary={t('profile.picture')}/>
                            </ListItem>
                        </List>
                    </Card>
                    <Card sx={{mb: 2}}>
                        <CardHeader title={t('profile.securityDetails')}/>
                        <List>
                            <ListItem>
                                <ListItemText primary={t('profile.2factor')}/>
                                <Switch edge='end' checked={user.user.mfaActive}
                                        onClick={() => user.user.mfaActive ? setDisableMfaOpen(true) : openMfaDialog()}/>
                            </ListItem>
                            <ListItem
                                secondaryAction={
                                    <Button onClick={() => setChangePasswordData({
                                        oldPassword: '',
                                        confirmPassword: '',
                                        newPassword: '',
                                    })}>{t('profile.changePassword')}</Button>
                                }>
                                <ListItemText primary={t('profile.password')}/>
                            </ListItem>
                        </List>
                    </Card>
                    <Card sx={{mb: 2}}>
                        <CardHeader title={t('profile.links')}/>
                        <List>
                            <ListItem
                                secondaryAction={
                                    !user.user.telegramId ?
                                        <Button href={url + window.btoa(JSON.stringify(state))}
                                                sx={{mt: 1}}
                                                disabled={savingTelegram}
                                                variant='contained'
                                                startIcon={<OpenInNew/>}
                                                color='primary'>{t('profile.link')}</Button> :
                                        <Button
                                            onClick={unlinkTelegram}
                                            sx={{mt: 1}}
                                            disabled={savingTelegram}
                                            color='primary'>{t('profile.unlink')}</Button>
                                }>
                                <ListItemText primary={t('profile.telegram')}
                                              secondary={user.user.telegramId ? t('profile.linked') : '-'}/>
                            </ListItem>
                        </List>
                    </Card>
                    <Card>
                        <CardHeader title={t('profile.devices')}/>
                        <List>
                            {!devicesLoading && devices.map((device) => {
                                return <ListItem key={device.id} secondaryAction={
                                    <IconButton disabled={deviceDeleting}
                                                onClick={() => deleteUserDevice(device.id)}><Delete/></IconButton>
                                }>
                                    <ListItemText primary={device.name}
                                                  secondary={`${moment(device.createdAt).format('DD.MM.YYYY HH:mm')}`}/>
                                </ListItem>;
                            })}
                            {devicesLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                                <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                            </Box>}
                            {(!devicesLoading && devices.length <= 0) &&
                                <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
                                    <Smartphone sx={{fontSize: 60}}/>
                                    <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                                        <Typography variant='body2'>{t('profile.noDevices')}</Typography>
                                    </Box>
                                </Box>}
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title={t('profile.notificationSettings')}
                                    action={<LoadingButton
                                        loadingPosition="start"
                                        disabled={loadingSettings}
                                        loading={savingSettings}
                                        startIcon={<Save/>}
                                        variant='contained' color="primary" onClick={onSaveNotifications}>
                                        {t('save')}
                                    </LoadingButton>}
                        />
                        <TableContainer>
                            <Table sx={{minWidth: 650}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell align="left">{t('profile.notifications.email')}</TableCell>
                                        <TableCell align="left">{t('profile.notifications.app')}</TableCell>
                                        <TableCell align="left">{t('profile.notifications.browser')}</TableCell>
                                        <TableCell align="left">{t('profile.notifications.telegram')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[
                                        'userNotificationNewAssignedTicket',
                                        'userNotificationAssignedTicketUpdatesStatus',
                                        'userNotificationTicketReminder',
                                        'userNotificationTicketPdfGenerated',
                                        'userNotificationDeviceQrCodeGenerated',
                                        'userNotificationImportFinished',
                                        'userNotificationExportFinished'].map(
                                        (key) => <TableRow key={key}>
                                            <TableCell>{t('profile.notifications.' + key)}</TableCell>
                                            <TableCell><Switch
                                                disabled={loadingSettings || savingSettings}
                                                onChange={(e) => onNotificationChange(key, "email", e.target.checked)}
                                                checked={settings[key]?.email || false}/></TableCell>
                                            <TableCell><Switch
                                                disabled={loadingSettings || savingSettings || devices.length === 0}
                                                onChange={(e) => onNotificationChange(key, "app", e.target.checked)}
                                                checked={settings[key]?.app || false}/></TableCell>
                                            <TableCell><Switch
                                                disabled={browserDenied || loadingSettings || savingSettings}
                                                onChange={(e) => onNotificationChange(key, "browser", e.target.checked)}
                                                checked={!browserDenied ? settings[key]?.browser || false : false}/></TableCell>
                                            <TableCell><Switch
                                                disabled={!user.user.telegramId}
                                                onChange={(e) => onNotificationChange(key, "telegram", e.target.checked)}
                                                checked={user.user.telegramId ? settings[key]?.telegram || false : false}/></TableCell>
                                        </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        <Dialog
            open={disableMfaOpen}
            onClose={() => disableMfaOpen(false)}
        >
            <DialogTitle>
                {t('profile.disableMfa')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('profile.disableMfaText')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={savingMfaStatus} onClick={() => setDisableMfaOpen(false)}>{t('cancel')}</Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={savingMfaStatus}
                    onClick={() => disableMfa()}
                    startIcon={<Save/>}
                    type="submit"
                    autoFocus variant='contained' color='error'>
                    {t('profile.disableMfa')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={enableMfaOpen}
            onClose={() => closeMfaDialog()}
        >
            <DialogTitle>
                {t('profile.enableMfa')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Box textAlign='center'>
                        {mfaSoftwareTokenLoading &&
                            <Skeleton sx={{margin: 'auto'}} variant="rectangular" width={256} height={256}/>}
                        {!mfaSoftwareTokenLoading && <QRCode value={mfaSoftwareToken}/>}
                    </Box>
                    <TextField
                        margin="normal"
                        fullWidth
                        disabled={savingMfaStatus}
                        label={t('profile.token')}
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        error={errorList.hasOwnProperty('code')} helperText={errorList.code}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={savingMfaStatus} onClick={() => closeMfaDialog()}>{t('cancel')}</Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={savingMfaStatus}
                    onClick={() => verifyAndSave()}
                    startIcon={<Save/>}
                    type="submit"
                    autoFocus variant='contained' color='primary'>
                    {t('profile.enableMfa')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={Boolean(changePasswordData)}
            onClose={() => changePasswordLoading ? null : setChangePasswordData(null)}
        >
            <DialogTitle>
                {t('profile.changePassword')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        margin="normal"
                        fullWidth
                        type='password'
                        disabled={changePasswordLoading}
                        label={t('profile.oldPassword')}
                        value={changePasswordData?.oldPassword}
                        onChange={(e) => setChangePasswordData({...changePasswordData, oldPassword: e.target.value})}
                        error={errorList.hasOwnProperty('oldPassword')} helperText={errorList.oldPassword}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        type='password'
                        disabled={changePasswordLoading}
                        label={t('profile.newPassword')}
                        value={changePasswordData?.newPassword}
                        onChange={(e) => setChangePasswordData({...changePasswordData, newPassword: e.target.value})}
                        error={errorList.hasOwnProperty('newPassword')} helperText={errorList.newPassword}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        type='password'
                        disabled={changePasswordLoading}
                        label={t('profile.confirmPassword')}
                        value={changePasswordData?.confirmPassword}
                        onChange={(e) => setChangePasswordData({
                            ...changePasswordData,
                            confirmPassword: e.target.value
                        })}
                        error={errorList.hasOwnProperty('confirmPassword')} helperText={errorList.confirmPassword}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={changePasswordLoading}
                        onClick={() => setChangePasswordData(null)}>{t('cancel')}</Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={changePasswordLoading}
                    onClick={() => changePassword()}
                    startIcon={<Save/>}
                    type="submit"
                    autoFocus variant='contained' color='primary'>
                    {t('profile.changePassword')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
};

export default Profile;
