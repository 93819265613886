import { Box, Typography } from "@mui/material";
import React from "react";
import NotificationsList from "../Utils/Notifications";
import { useTranslation } from "react-i18next";

const Notifications = ({editable}) => {
    const {t} = useTranslation();
    return <Box component={'div'} sx={{
        height: '100%', width: '100%',
        pointerEvents: editable ? 'none' : 'inherit',
        opacity: editable ? 0.5 : 1,
        display: 'flex',
        flexDirection: 'column',
    }}>
        <Typography sx={{p: 2, maxHeight: '64px'}} component='h2'
                    variant='h5'>{t('dashboard.notifications')}< /Typography>
        <NotificationsList/>
    </Box>;
}

export default Notifications;
