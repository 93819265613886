import {
    Autocomplete,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Schema from "validate";
import { CopyAll, Delete, OpenInNew, Refresh } from "@mui/icons-material";
import CheckPermissions from "../../Utils/CheckPermissions";
import DeleteDialog from "../../Utils/DeleteDialog";
import { cloneDeep } from "lodash";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";

const getValidationSchema = (t) => {
    return new Schema({
        hash: {
            required: true,
            type: String
        },
        ipRanges: {
            required: true,
            type: Array,
            each: {
                type: String,
            }
        },
        presetId: {
            required: false,
            type: String
        },
        refreshInterval: {
            required: true,
            type: Number,
            enum: [30, 60, 300, 600, 900]
        }
    })
}

const LiveLinkRow = ({liveLink, onChange, onDelete, presets, generateHash}) => {
    const {t} = useTranslation();
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const dispatch = useDispatch();

    const onDeleteClick = () => {
        setDeleteOpen(false);
        onDelete();
    }

    useEffect(() => {
        const v = getValidationSchema(t);
        const errors = v.validate(cloneDeep(liveLink))
        if (errors.length) {
            liveLink.hasErrors = true;
            onChange(liveLink)
        } else {
            liveLink.hasErrors = false;
            onChange(liveLink)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveLink.hash, liveLink.ipRanges, liveLink.presetId, liveLink.refreshInterval, t]);

    return <React.Fragment>
        <TableRow>
            <TableCell>
                <TextField
                    fullWidth
                    variant='standard'
                    value={liveLink.hash}
                    label={t('tickets.liveLinks.hash')}
                    placeholder={t('tickets.liveLinks.hash')}
                    margin='none'
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" color="primary" onClick={(e) => {
                                    liveLink.hash = generateHash();
                                    liveLink.hasChanges = true
                                    onChange(liveLink)
                                }}>
                                    <Refresh/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </TableCell>
            <TableCell>
                <Autocomplete
                    options={[]}
                    multiple
                    freeSolo
                    value={liveLink.ipRanges}
                    onChange={(e, value) => {
                        liveLink.hasChanges = true
                        liveLink.ipRanges = value;
                        onChange(liveLink)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            pattern="^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$"
                            variant="standard"
                            label={t('tickets.liveLinks.ipRanges')}
                            placeholder="192.168.2.1/32"
                        />
                    )}
                />
            </TableCell>
            <TableCell>
                <FormControl fullWidth variant='standard'>
                    <InputLabel>{t('tickets.liveLinks.preset')}</InputLabel>
                    <Select
                        value={liveLink.presetId || ''}
                        onChange={(e) => {
                            liveLink.hasChanges = true
                            liveLink.presetId = e.target.value ? e.target.value : null;
                            onChange(liveLink)
                        }}
                    >
                        <MenuItem value={''}>
                            <ListItemText primary={t('tickets.liveLinks.noPreset')}/>
                        </MenuItem>
                        {presets.map((p) => (
                            <MenuItem key={p.id} value={p.id}>
                                {p.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl fullWidth variant='standard'>
                    <InputLabel>{t('tickets.liveLinks.interval')}</InputLabel>
                    <Select
                        value={liveLink.refreshInterval.toString()}
                        onChange={(e) => {
                            liveLink.hasChanges = true
                            liveLink.refreshInterval = parseInt(e.target.value, 10);
                            onChange(liveLink)
                        }}
                    >
                        {[30, 60, 300, 600, 900].map((v) => (
                            <MenuItem key={v} value={v}>
                                {v} {t('seconds')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell width={50}>
                <Box display='flex' flexDirection='row'>
                    <IconButton
                        target='_blank'
                        component={'a'}
                        href={window.location.origin + '/live/' + liveLink.id + '/' + liveLink.hash + '?refreshInterval=' + liveLink.refreshInterval}
                    >
                        <OpenInNew/>
                    </IconButton>
                    <IconButton onClick={() => {
                        navigator.clipboard.writeText(window.location.origin + '/live/' + liveLink.id + '/' + liveLink.hash + '?refreshInterval=' + liveLink.refreshInterval)
                        dispatch({
                            type: 'ADD_ALERT',
                            message: t('tickets.liveLinks.copiedLinkToClipboard'),
                            style: 'success'
                        })
                    }}
                                align="right"><CopyAll/></IconButton>
                    <CheckPermissions list={['liveLinks.delete']}>
                        <IconButton onClick={() => setDeleteOpen(true)}
                                    align="right"><Delete/></IconButton>
                    </CheckPermissions>
                </Box>
            </TableCell>
        </TableRow>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('tickets.liveLinks.delete')}
                      handleClose={() => setDeleteOpen(false)}
                      description={t('tickets.liveLinks.deleteDescription')}
                      open={deleteOpen}/>
    </React.Fragment>
}

export default LiveLinkRow
