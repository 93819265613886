import {Box, Button, IconButton, Paper, Toolbar} from "@mui/material";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Api from "../../../../core/Api";
import {Add, Delete, Edit, Replay} from "@mui/icons-material";
import {useTheme} from "@mui/system";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {Link as BrowserLink} from "react-router-dom";
import DeleteDialog from "../../../Utils/DeleteDialog";
import CheckPermissions from "../../../Utils/CheckPermissions";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserList} from "../../../../actions/userActions";

const columns = (theme, t, onDelete, permissions, userList) => ([
    {
        field: 'name',
        editable: false,
        minWidth: 100,
        headerName: t('settings.tours.name'),
        flex: 1,
        disableColumnMenu: true
    },
    {
        disableColumnMenu: true,
        minWidth: 200,
        field: 'description',
        editable: false,
        headerName: t('settings.tours.description'),
        flex: 1,
        renderCell: ({value}) => (
            <Box sx={{color: theme.palette.text.secondary}}>
                {value}
            </Box>
        )
    },
    {
        disableColumnMenu: true,
        minWidth: 200,
        field: 'userIds',
        editable: false,
        headerName: t('settings.tours.users'),
        flex: 1,
        renderCell: ({value}) => (
            <Box sx={{color: theme.palette.text.secondary}}>
                {value?.filter(
                    (id) => userList.find((user) => user.id === id)
                ).map(
                    (id) => {
                        const user = userList.find((user) => user.id === id)
                        if (!user.givenName.length && !user.familyName.length) {
                            return user.email
                        }
                        return `${user.givenName} ${user.familyName}`
                    }).join(', ')}
            </Box>
        )
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: t('actions'),
        getActions: (params) => {
            const actions = [];

            if (permissions.indexOf('tours.delete') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem icon={<Delete/>}
                                                  onClick={() => onDelete(params.id)}
                                                  label={t('settings.tours.deleteTour')} showInMenu/>);
            }

            if (permissions.indexOf('tours.write') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem icon={<Edit/>} component={BrowserLink}
                                                  to={'/settings/customers/tours/edit/' + params.row.id}
                                                  label={t('settings.tours.editTour')}/>);
            }

            return actions;
        }
    }
]);


const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();
    const dispatch = useDispatch();

    const [tours, setTours] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [deleteTourId, setDeleteTourId] = React.useState(null);
    const [deleting, setDeleting] = React.useState(false);

    const user = useSelector((state) => state.user)
    const userList = useSelector((state) => state.userList.list)

    const fetchData = useCallback(() => {
        dispatch(fetchUserList())
        fetchTours()
    }, [dispatch])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const fetchTours = () => {
        setLoading(true);
        Api.fetch({
            endpoint: 'tours'
        })
            .then((res) => {
                setTours(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onDelete = () => {
        setDeleting(true);

        Api.fetch({
            endpoint: 'tours/' + deleteTourId,
            method: "DELETE",
        }).then(() => {
            setDeleteTourId(null)
            fetchTours();
        }, () => {
        }).then(() => {
            setDeleting(false);
        });
    }

    let deleteTourName = '';
    if (deleteTourId) {
        const tour = tours.find(r => r.id === deleteTourId);
        if (tour) {
            deleteTourName = tour.name;
        }
    }


    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['tours.write']}>
                <Button disabled={loading} size='small' component={BrowserLink} to={'/settings/customers/tours/new'}
                        startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.tours.addTour')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchData}><Replay/></IconButton>
        </Toolbar>
        <Paper>
            <DataGrid
                disableSelectionOnClick={true}
                loading={loading}
                autoHeight
                rows={tours}
                columns={columns(theme, t, setDeleteTourId, user.user.permissions, userList)}
                pageSize={50}
                rowsPerPageOptions={[50]}
            />
        </Paper>
        <DeleteDialog onDelete={() => onDelete()} isDeleting={deleting} title={t('settings.tours.deleteTour')}
                      handleClose={() => setDeleteTourId(null)}
                      description={t('settings.tours.deleteTourDescription', {name: deleteTourName})}
                      open={Boolean(deleteTourId)}/>
    </React.Fragment>;
}

export default List
