import {Route, Routes} from "react-router";
import TourList from "./List";
import React from "react";
import Edit from "./Edit";

const Router = (props) => {
    return <Routes>
        <Route path='new' element={<Edit />}/>
        <Route path='edit/:id' element={<Edit />}/>
        <Route path='/' element={<TourList />}/>
    </Routes>
}

export default Router;
