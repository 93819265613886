import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import { Alert, Box, Divider, FormControl, Grid, Paper, Slider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilePicker from "../../../Utils/FilePicker";
import qrCodeLogo from '../../../../images/qrcode.png';

const Settings = ({type, setting, onChange}) => {
    const {t} = useTranslation()

    const [file, setFile] = useState(null)

    const fetchFile = useCallback(() => {
        if (setting.path) {
            Api.fetch({
                endpoint: 'storage/qr-code/' + setting.path,
            }).then(res => {
                setFile(res.response)
            }, () => {
            })
        } else {
            setFile(null)
        }
    }, [setFile, setting.path])

    useEffect(() => {
        fetchFile()
    }, [fetchFile])
    return <Paper sx={{mb: 2, p: 2}}>
        <Grid container spacing={2}>
            <Grid item xs>
                <Typography variant='h5'>{t('settings.qr.types.' + type)}</Typography>
                <FilePicker accept={'image/*'} type={'qr-code'} path={setting.path}
                            onSelect={(path) => {
                                onChange({...setting, path})
                            }}/>
                <FormControl fullWidth margin='normal'>
                    <Typography gutterBottom>{t('settings.qr.size')}</Typography>
                    <Box sx={{pl: 2, pr: 2}}>
                        <Slider
                            value={setting.size}
                            onChange={(e, v) => onChange({...setting, size: v})}
                            step={1}
                            marks={[
                                {value: 10, label: '10%'},
                                {value: 50, label: '50%'},
                                {value: 100, label: '100%'}
                            ]}
                            min={10}
                            max={100}
                            valueLabelDisplay='auto'
                        />
                    </Box>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <Typography gutterBottom>{t('settings.qr.positionX')}</Typography>
                    <Box sx={{pl: 2, pr: 2}}>
                        <Slider
                            value={setting.positionX}
                            onChange={(e, v) => onChange({...setting, positionX: v})}
                            step={1}
                            marks={[
                                {value: 0, label: '0%'},
                                {value: 50, label: '50%'},
                                {value: 100, label: '100%'}
                            ]}
                            min={0}
                            max={100}
                            valueLabelDisplay='auto'
                        />
                    </Box>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <Typography gutterBottom>{t('settings.qr.positionY')}</Typography>
                    <Box sx={{pl: 2, pr: 2}}>
                        <Slider
                            value={setting.positionY}
                            onChange={(e, v) => onChange({...setting, positionY: v})}
                            step={1}
                            marks={[
                                {value: 0, label: '0%'},
                                {value: 50, label: '50%'},
                                {value: 100, label: '100%'}
                            ]}
                            min={0}
                            max={100}
                            valueLabelDisplay='auto'
                        />
                    </Box>
                </FormControl>
            </Grid>
            <Divider sx={{ml: 2, mt: 2}} orientation="vertical" flexItem/>
            <Grid item xs>
                <Typography variant='h6'>{t('settings.qr.preview')}</Typography>
                {file && <Paper variant='outlined' square sx={{width: '100%', position: 'relative', display: 'grid'}}>
                    <Box component='img' src={file.url} alt={t('settings.qr.types.' + type)} sx={{width: '100%'}}/>
                    <Box position='absolute' component='img' sx={{
                        bottom: setting.positionY + '%',
                        left: setting.positionX + '%',
                        width: setting.size + '%'
                    }} src={qrCodeLogo}
                         alt='example-qr-code'/>
                </Paper>}
                {!file && <Alert severity='info'>{t('settings.qr.noFile')}</Alert>}
            </Grid>
        </Grid>
    </Paper>;
}

export default Settings;
