import React, { useCallback, useEffect } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { Add, Check, Close, FilterAlt, Replay, Save, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/system";
import Api from "../../../core/Api";
import { push } from "@lagunovsky/redux-react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchTagList } from "../../../actions/tagActions";
import CheckPermissions from "../../Utils/CheckPermissions";
import TagSelect from "../../Utils/TagSelect";
import { cloneDeep } from "lodash";
import { LoadingButton } from "@mui/lab";

const StyledLink = styled(RouterLink)`
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const CustomerList = ({
                          selectable = false,
                          buttonSelect = false,
                          loading = false,
                          multiple = false, //TODO: implement multiple
                          onSelectionChange = () => false,
                          selected = [],
                          linkToCustomer = true,
                          displayCreate = true,
                          containerStyle
                      }) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [total, setTotal] = React.useState(0);
    const [searchString, setSearchString] = React.useState('');
    const [tempSearchString, setTempSearchString] = React.useState('');
    const [customers, setCustomers] = React.useState([]);
    const [fetching, setFetching] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [perPage, setPerPage] = React.useState(20);
    const [order, setOrder] = React.useState('asc');
    const [orderColumn, setOrderColumn] = React.useState('number');
    const [filters, setFilters] = React.useState({
        tags: []
    });
    const [editFilterData, setEditFilterData] = React.useState(null);

    const applyFilter = () => {
        setFilters(cloneDeep(editFilterData));
        setEditFilterData(null);
    }

    const handleFilterChange = (key, value) => {
        setEditFilterData((d) => {
            d[key] = value;
            return cloneDeep(d);
        })
    }

    const changeSort = (column) => {
        if (orderColumn === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrder('asc');
            setOrderColumn(column);
        }
    };

    const fetchCustomers = useCallback(() => {
        setFetching(true);
        const filter = {
            query: searchString
        };
        if (filters.tags?.length > 0) {
            filter.tags = 'in<-->' + filters.tags.join(',');
        }
        Api.fetch({
            endpoint: 'customers',
            parameter: {
                page: page + 1,
                size: perPage,
                sort: `${orderColumn}_${order}`,
                filter: filter
            }
        }).then((res) => {
            setTotal(parseInt(res.headers['x-total-count'] || "0", 10));
            setCustomers(res.response)
        }, () => {
        }).then(() => {
            setFetching(false);
        })
    }, [page, perPage, order, orderColumn, setFetching, setCustomers, filters, searchString]);

    const fetchData = useCallback(() => {
        fetchCustomers();
        dispatch(fetchTagList())
    }, [fetchCustomers, dispatch]);


    useEffect(() => {
        fetchData()
    }, [fetchData])

    const tagList = useSelector((state) => state.tags.list)

    const customerList = customers.map((customer) => {

        const mainAddress = customer.addresses.find(address => address.main);
        return (
            <TableRow component={linkToCustomer ? StyledLink : 'tr'} key={customer.id} hover={linkToCustomer}
                      to={'/customers/show/' + customer.id}>
                {selectable && <TableCell>
                    {!buttonSelect && <Checkbox
                        checked={selected === customer.id}
                        onChange={() => onSelectionChange(customer.id)}
                    />}
                    {buttonSelect && <LoadingButton
                        loadingPosition="start"
                        disabled={(loading && selected !== customer.id) || (!loading && selected === customer.id)}
                        loading={loading && selected === customer.id}
                        startIcon={<Check/>}
                        variant="contained"
                        color="primary"
                        onClick={() => onSelectionChange(customer.id)}
                    >{t(selected === customer.id ? 'selected' : 'select')}</LoadingButton>}
                </TableCell>}
                <TableCell>{customer.number}</TableCell>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{mainAddress.address}<br/>{mainAddress['zip']} {mainAddress.city}</TableCell>
                <TableCell>
                    {customer.tags?.filter((tag) => tagList.find((t) => t.id === tag)).map((tag) => {
                        const tagDefinition = tagList.find((t) => t.id === tag);
                        return <Chip key={tag} label={tagDefinition.name}
                                     sx={{mr: 1, backgroundColor: tagDefinition.color, color: 'white'}}/>
                    })}
                </TableCell>
            </TableRow>
        )
    });

    const nothingCreatedMessage = <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
        <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('customers.list.noCustomerFound')}</Typography>
            <Link variant='body2'
                  onClick={() => dispatch(push('/customers/new'))}>{t('customers.list.createCustomer')}</Link>
        </Box>
    </Box>

    const showFilterToolbar = filters.tags?.length > 0
    return <Box sx={containerStyle || {height: '100%'}}>
        <Box component='div' sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Toolbar disableGutters sx={{mb: showFilterToolbar ? 0 : 2, flexGrow: 0}}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    setSearchString(tempSearchString);
                    setPage(0);
                }}>
                    <TextField
                        autoFocus
                        sx={{mr: 2}}
                        value={tempSearchString}
                        label={t('customers.list.search')}
                        placeholder={t('customers.list.search')}
                        margin='none'
                        onChange={(e) => setTempSearchString(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" color="primary"
                                                onClick={() => setSearchString(tempSearchString) && setPage(0)}>
                                        <Search/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
                <Button sx={{mr: 2}} startIcon={<FilterAlt/>}
                        onClick={() => setEditFilterData(cloneDeep(filters))}>{t('customers.list.filter')}</Button>
                {displayCreate && <CheckPermissions list={['customers.write']}>
                    <Button startIcon={<Add/>}
                            onClick={() => dispatch(push('/customers/new'))}>{t('customers.list.createCustomer')}</Button>
                </CheckPermissions>}
                <Box flexGrow={1}/>
                <IconButton onClick={fetchData}><Replay/></IconButton>
            </Toolbar>
            {showFilterToolbar && <Toolbar disableGutters variant='dense' sx={{mb: 1, flexGrow: 0}}>
                {filters.tags?.length > 0 &&
                    <Chip onClick={() => setEditFilterData(filters)} onDelete={() => setFilters((f) => {
                        f.tags = [];
                        return cloneDeep(f);
                    })} label={
                        <React.Fragment>{t('customers.fields.tags')}: {filters.tags?.filter(t => tagList.find(tag => tag.id === t)).map(t => tagList.find(t2 => t2.id === t)?.name).join(', ')}</React.Fragment>}/>}
            </Toolbar>}
            <Paper variant='outlined' sx={{flexGrow: 1, minHeight: 0}}>
                <TableContainer sx={{height: 'calc(100% - 52px)'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {selectable && <TableCell/>}
                                <TableCell sortDirection={orderColumn === 'number' ? order : false}
                                           sx={{fontWeight: 'bold'}}>
                                    <TableSortLabel
                                        active={orderColumn === 'number'}
                                        direction={order}
                                        onClick={() => changeSort('number')}
                                    >
                                        {t('customers.fields.number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={orderColumn === 'name' ? order : false}
                                    sx={{fontWeight: 'bold'}}>
                                    <TableSortLabel
                                        active={orderColumn === 'name'}
                                        direction={order}
                                        onClick={() => changeSort('name')}
                                    >
                                        {t('customers.fields.name')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>{t('customers.fields.address')}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>{t('customers.fields.tags')}</TableCell>
                            </TableRow>
                        </TableHead>
                        {!fetching && <TableBody>
                            {customerList}
                        </TableBody>}
                    </Table>
                    {fetching && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                        <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                    </Box>}
                    {customers.length === 0 && !fetching && nothingCreatedMessage}
                </TableContainer>
                {customers.length > 0 && <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={perPage}
                    page={page}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={(e) => {
                        setPerPage(parseInt(e.target.value, 10))
                        setPage(0)
                    }}
                />}
            </Paper>
            <Dialog maxWidth='sm' fullWidth open={Boolean(editFilterData)} onClose={() => setEditFilterData(null)}>
                <DialogTitle>
                    {t('customers.filters')}
                    <IconButton
                        aria-label="close"
                        onClick={() => setEditFilterData(null)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close/>
                    </IconButton>
                </DialogTitle>
                {editFilterData && <DialogContent>
                    <TagSelect
                        sx={{mt: 1}}
                        multiple
                        margin='dense'
                        tags={tagList}
                        label={t('customers.fields.tags')} value={editFilterData.tags}
                        onChange={(v) => handleFilterChange('tags', v || [])}/>
                </DialogContent>}
                <DialogActions>
                    <Button
                        onClick={() => setEditFilterData(null)}
                    >
                        {t('close')}
                    </Button>
                    <Button
                        loadingPosition="start"
                        onClick={applyFilter}
                        startIcon={<Save/>}
                        variant='contained'
                        color='primary'
                    >
                        {t('applyFilter')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    </Box>;
}

export default CustomerList;
