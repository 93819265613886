import {CALL_API} from '../middleware/api'
import { hasPermission } from "../helper/hasPermission";

export const REQUEST_LIST_REQUEST = 'REQUEST_LIST_REQUEST';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_FAILURE = 'REQUEST_LIST_FAILURE';


export function fetchRequestList(user, page, perPage) {
    const userId = user?.user?.id

    if(!hasPermission(user, ['tickets.read'])) {
        return {
            type: 'VOID'
        };
    }

    return {
        [CALL_API]: {
            endpoint: 'tickets',
            method: 'GET',
            parameter: {
                page: page,
                size: perPage,
                sort: `createdAt_desc`,
                filter: {
                    pendingUserLinks: 'in<-->' + userId
                }
            },
            types: [REQUEST_LIST_SUCCESS, REQUEST_LIST_FAILURE, REQUEST_LIST_REQUEST]
        }
    }
}

