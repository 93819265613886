import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Toolbar
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {Add, Close, Delete, Replay, Save, Search} from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckPermissions from "../../Utils/CheckPermissions";
import DeleteDialog from "../../Utils/DeleteDialog";
import { fetchConnectionList } from "../../../actions/connectionActions";
import Api from "../../../core/Api";
import { LoadingButton } from "@mui/lab";
import Schema from "validate";
import { cloneDeep } from "lodash";
import { push } from "react-router-redux";

const columns = (theme, t, onDelete, permissions, connections) => ([
    {
        field: 'name',
        editable: false,
        minWidth: 100,
        headerName: t('data.export.name'),
        flex: 1,
        disableColumnMenu: true
    },
    {
        disableColumnMenu: true,
        minWidth: 200,
        field: 'type',
        editable: false,
        headerName: t('data.export.type'),
        flex: 1,
        renderCell: ({value}) => (
            <Box sx={{color: theme.palette.text.secondary}}>
                {t('data.export.types.' + value)}
            </Box>
        )
    },
    {
        disableColumnMenu: true,
        minWidth: 200,
        field: 'connectionId',
        editable: false,
        headerName: t('data.export.connection'),
        flex: 1,
        renderCell: ({value}) => (
            <Box sx={{color: theme.palette.text.secondary}}>
                {connections.find(c => c.id === value)?.name || t('data.export.connectionNotFound')}
            </Box>
        )
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: t('actions'),
        getActions: (params) => {
            const actions = [];

            if (permissions.indexOf('export.delete') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem icon={<Delete/>}
                                                  onClick={() => onDelete(params.id)}
                                                  label={t('data.export.deleteExport')} showInMenu/>);
            }

            if (permissions.indexOf('export.write') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem icon={<Search/>} component={Link}
                                                  to={'/data/export/show/' + params.row.id}
                                                  label={t('data.export.editExport')}/>);
            }

            return actions;
        }
    }
]);

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String,
            message: t('data.export.nameRequired'),
        },
    })
}


const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();
    const dispatch = useDispatch();

    const [exports, setExports] = React.useState([]);
    const [addData, setAddData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    const [deleteId, setDeleteId] = React.useState(null);
    const [deleting, setDeleting] = React.useState(false);

    const user = useSelector((state) => state.user)
    const connections = useSelector((state) => state.connections.list)

    const handleAddDataChange = (key, value) => {
        setAddData((d) => ({
            ...d,
            [key]: value
        }))
    }

    const fetchExports = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: 'exports'
        })
            .then((res) => {
                setExports(res.response || []);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }, [])

    const onDelete = () => {
        setDeleting(true);

        Api.fetch({
            endpoint: 'exports/' + deleteId,
            method: "DELETE",
        }).then((res) => {
            setDeleteId(null)
            fetchExports();
        }, () => {
        }).then(() => {
            setDeleting(false);
        });
    }

    const createExport = () => {
        setSaving(true);
        setErrors({});

        const v = getValidationSchema(t);
        const errors = v.validate(cloneDeep(addData))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrors(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'exports',
            method: "POST",
            body: addData
        }).then((res) => {
            setAddData(null)
            fetchExports();
            dispatch(push('/data/export/show/' + res.response.id))
        }, (e) => {
        }).then(() => {
            setSaving(false);
        });
    }

    const fetchData = useCallback(() => {
        fetchExports()
        dispatch(fetchConnectionList())
    }, [fetchExports, dispatch])

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    let deleteImportName = '';
    if (deleteId) {
        const importObject = exports.find(r => r.id === deleteId);
        if (importObject) {
            deleteImportName = importObject.name;
        }
    }


    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['exports.write']}>
                <Button size='small' onClick={() => setAddData({
                    name: '',
                    type: 'customers',
                    delimiter: ';',
                    connectionId: connections.length > 0 ? connections[0].id : null
                })} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('data.export.addExport')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchData}><Replay/></IconButton>
        </Toolbar>
        <Paper>
            <DataGrid
                disableSelectionOnClick={true}
                loading={loading}
                autoHeight
                rows={exports}
                columns={columns(theme, t, setDeleteId, user.user.permissions, connections)}
                pageSize={50}
                rowsPerPageOptions={[50]}
            />
        </Paper>
        <Dialog maxWidth='md' fullWidth open={Boolean(addData)}>
            <DialogTitle>
                {t('data.export.addExport')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setAddData(null) && setErrors({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {Boolean(addData) && <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    error={errors.hasOwnProperty('name')}
                    helperText={errors.name}
                    margin='normal'
                    label={t('data.export.name')}
                    placeholder={t('data.export.name')}
                    value={addData.name}
                    onChange={(e) => handleAddDataChange('name', e.target.value)}
                />
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.export.type')}</InputLabel>
                    <Select
                        notched
                        label={t('data.export.type')}
                        value={addData.type}
                        fullWidth
                        onChange={(e) => handleAddDataChange('type', e.target.value)}
                    >
                        {['customers', 'devices', 'tickets'].map((type) => (<MenuItem key={type} value={type}>
                            <ListItemText primary={t('data.export.types.' + type)}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel shrink>{t('data.export.connection')}</InputLabel>
                    <Select
                        notched
                        label={t('data.export.connection')}
                        value={addData.connectionId}
                        fullWidth
                        onChange={(e) => handleAddDataChange('connectionId', e.target.value)}
                    >
                        {connections.map((t) => (<MenuItem key={t.id} value={t.id}>
                            <ListItemText primary={t.name} secondary={t.description}/>
                        </MenuItem>))}
                    </Select>
                </FormControl>
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setAddData(null) && setErrors({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={createExport}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <DeleteDialog onDelete={() => onDelete()} isDeleting={deleting}
                      title={t('data.export.deleteExport')}
                      handleClose={() => setDeleteId(null)}
                      description={t('data.export.deleteExportDescription', {name: deleteImportName})}
                      open={Boolean(deleteId)}/>
    </React.Fragment>;
}

export default List
