import React from 'react';
import { IconButton, ListItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";

const Location = ({location, onChange, onDelete, deleteDisabled, sx, errors}) => {
    const {t} = useTranslation();

    return <ListItem disableGutters={true} sx={{...sx, mt:1}}
                     secondaryAction={<IconButton disabled={deleteDisabled} onClick={onDelete}>
                         <Delete/>
                     </IconButton>}>
        <TextField
            fullWidth
            margin='none'
            size='small'
            required
            error={errors.hasOwnProperty('name')}
            helperText={errors.name}
            label={t('customers.address.name')}
            placeholder={t('customers.address.name')}
            value={location.name}
            onChange={(e) => {
                location.name = e.target.value
                onChange(location)
            }}
        />
    </ListItem>;
};

export default Location;
