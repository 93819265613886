import {
    Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Toolbar,
    Typography
} from "@mui/material";
import { v1 } from 'uuid'
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect } from "react";
import { useTheme } from "@mui/system";
import { Responsive, WidthProvider } from "react-grid-layout";
import Tickets from "./Tickets";
import { Add, Close, Delete, Edit, MoreVert, Save, Settings } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import Api from "../../core/Api";
import TicketsSettings from "./TicketsSettings";
import { useDebounce } from "../Utils/useDebounce";
import { LoadingButton } from "@mui/lab";
import Notifications from "./Notifications";
import StatisticSettings from "./StatisticSettings";
import Statistic from "./Statistic";
import { fetchTagList } from "../../actions/tagActions";
import { fetchCategoryList } from "../../actions/categoryActions";
import { fetchStatusList } from "../../actions/statusActions";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../helper/hasPermission";
import { hasVersion } from "../../helper/hasVersion";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
    const {t} = useTranslation()
    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user)

    const [loading, setLoading] = React.useState(false);
    const [layout, setLayout] = React.useState({elements: []});

    const [menuOpen, setMenuOpen] = React.useState(null);
    const [editLayout, setEditLayout] =  React.useState(null);
    const [debouncedEditLayout, setDebouncedEditLayout] = useDebounce(null, 300);
    const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(null);

    useEffect(() => {
        setDebouncedEditLayout(editLayout)
    }, [editLayout, setDebouncedEditLayout])
    const fetchData = useCallback(() => {
        Api.fetch({
            endpoint: 'settings/user-dashboard',
        }).then((response) => {
            setLayout(response.response);
        }, () => {
        });
        if (hasPermission(user, ['tags.read'])) {
            dispatch(fetchTagList())
        }
        if (hasPermission(user, ['categories.read'])) {
            dispatch(fetchCategoryList())
        }
        if (hasPermission(user, ['status.read'])) {
            dispatch(fetchStatusList())
        }
    }, [dispatch, setLayout, user]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const tagList = useSelector((state) => state.tags.list)
    const statusList = useSelector((state) => state.statusList.list)
    const categoryList = useSelector((state) => state.categories.list)

    const onLayoutChange = (l) => {
        if (!Boolean(editLayout)) {
            return;
        }
        const newLayout = cloneDeep(editLayout);
        l.forEach(e => {
            const key = e.i.split('.')[1];
            const index = newLayout.elements.findIndex(el => el.key === key);
            if (index === -1) {
                return;
            }
            newLayout.elements[index].x = e.x;
            newLayout.elements[index].y = e.y;
            newLayout.elements[index].w = e.w;
            newLayout.elements[index].h = e.h;
        })
        setEditLayout(newLayout);
    }

    const addElement = () => {
        setEditLayout({
            ...editLayout,
            elements: [...editLayout.elements, {
                key: v1(),
                settings: {},
                type: 'tickets',
                x: 0,
                y: Infinity,
                w: 2,
                h: 2
            }]
        })
    }

    const removeElement = (key) => {
        setEditLayout({
            ...editLayout,
            elements: editLayout.elements.filter(el => el.key !== key)
        })
    }

    const saveLayout = () => {
        setLoading(true);
        Api.fetch({
            endpoint: 'settings/user-dashboard',
            method: 'POST',
            body: editLayout
        }).then((response) => {
            setLayout(response.response);
            setEditLayout(null);
        }).then(() => setLoading(false));
    }

    const usedLayout = Boolean(debouncedEditLayout) ? debouncedEditLayout : layout;
    const elements = usedLayout.elements.map(e => {
        const type = e.type || 'tickets'
        return <Paper sx={{height: '100%', width: '100%'}}
                      key={Boolean(editLayout) ? 'edit.' + e.key : 'static.' + e.key}
                      data-grid={{
                          i: (Boolean(editLayout) ? 'edit.' + e.key : 'static.' + e.key),
                          x: e.x,
                          y: e.y,
                          w: e.w,
                          h: e.h,
                          static: !Boolean(editLayout)
                      }}>
            {Boolean(editLayout) && <IconButton sx={{position: 'fixed', top: theme.spacing(1), right: theme.spacing(1)}}
                                                onMouseDown={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                onClick={(event) => setMenuOpen({
                                                    anchor: event.currentTarget,
                                                    key: e.key
                                                })}>
                <MoreVert/>
            </IconButton>}
            {type === 'tickets' && <Tickets editable={Boolean(editLayout)} settings={e.settings || {}} tagList={tagList}
                                            statusList={statusList} categoryList={categoryList}/>}
            {type === 'notifications' && <Notifications editable={Boolean(editLayout)}/>}
            {type === 'statistics' &&
                <Statistic editable={Boolean(editLayout)} settings={e.settings || {}} statusList={statusList}
                           categoryList={categoryList}/>}
        </Paper>
    });

    let settings = '';
    let type = 'tickets';
    if (Boolean(settingsDialogOpen)) {
        const element = editLayout.elements.find(el => el.key === settingsDialogOpen);
        type = element.type || 'tickets';
        if (type === 'tickets') {
            settings = <TicketsSettings
                tagList={tagList}
                statusList={statusList}
                categoryList={categoryList}
                settings={element.settings || {}} onChange={(settings) => {
                setEditLayout({
                    ...editLayout,
                    elements: editLayout.elements.map(el => {
                        if (el.key === settingsDialogOpen) {
                            return {
                                ...el,
                                settings: settings
                            }
                        }
                        return el;
                    })
                })
            }}/>
        } else if (type === 'statistics') {
            settings = <StatisticSettings
                statusList={statusList} categoryList={categoryList}
                settings={element.settings || {}} onChange={(settings) => {
                setEditLayout({
                    ...editLayout,
                    elements: editLayout.elements.map(el => {
                        if (el.key === settingsDialogOpen) {
                            return {
                                ...el,
                                settings: settings
                            }
                        }
                        return el;
                    })
                })
            }}/>
        }
    }

    return <Box component='div' sx={{my: 2, px: 2, overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box'}}>
        <Toolbar disableGutters variant={'dense'}>
            {!Boolean(editLayout) && <><Typography variant='h4'>{t('dashboard.title')}</Typography>
                <Box flexGrow={1}/>
                <IconButton onClick={() => setEditLayout(cloneDeep(layout))}><Edit/></IconButton>
            </>}
            {Boolean(editLayout) && <>
                <Button onClick={addElement} startIcon={<Add/>} variant='outlined'
                        sx={{mr: 2}}>{t('dashboard.addElement')}</Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={loading}
                    onClick={saveLayout}
                    startIcon={<Save/>}
                    sx={{mr: 2}}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button onClick={() => setEditLayout(null)}>{t('cancel')}</Button>
            </>}
        </Toolbar>
        <ResponsiveGridLayout
            className='layout'
            breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
            cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
            containerPadding={[0, 10]}
            onLayoutChange={onLayoutChange}
        >
            {elements}
        </ResponsiveGridLayout>
        <Dialog open={Boolean(settingsDialogOpen)} onClose={() => setSettingsDialogOpen(null)} maxWidth={'md'}
                fullWidth>
            <DialogTitle>
                {t('dashboard.settings')}
                <IconButton
                    aria-label="close"
                    onClick={() => setSettingsDialogOpen(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin='dense' sx={{mb: 1}}>
                    <InputLabel>{t('dashboard.type')}</InputLabel>
                    <Select
                        value={type}
                        onChange={(e) => {
                            const newType = e.target.value;
                            const settings = {}
                            if (newType === "statistics") {
                                settings.filter = {
                                    status: statusList.filter(status => status.type !== 'completed').map(status => status.id)
                                };
                            }

                            setEditLayout({
                                ...editLayout,
                                elements: editLayout.elements.map(el => {
                                    if (el.key === settingsDialogOpen) {
                                        return {
                                            ...el,
                                            settings: settings,
                                            type: newType
                                        }
                                    }
                                    return el;
                                })
                            })
                        }}
                        input={<OutlinedInput label={t('dashboard.type')}/>}
                    >
                        {['tickets', 'notifications', 'statistics'].map((key) => (
                            <MenuItem key={key} value={key} disabled={key === 'statistics' && !hasVersion('extended')}>
                                {t('dashboard.types.' + key)}{(key === 'statistics' && !hasVersion('extended')) && <Chip sx={{ml: '1em'}} label={t('versions.extended')} />}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {settings}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSettingsDialogOpen(null)} color="primary" autoFocus>
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
        <Menu onClose={() => setMenuOpen(null)} open={Boolean(menuOpen)} anchorEl={menuOpen?.anchor}>
            <MenuItem onClick={() => {
                setSettingsDialogOpen(menuOpen?.key);
                setMenuOpen(false)
            }}>
                <ListItemIcon>
                    <Settings fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{t('dashboard.settings')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
                removeElement(menuOpen?.key);
                setMenuOpen(false)
            }}>
                <ListItemIcon>
                    <Delete fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{t('dashboard.removeElement')}</ListItemText>
            </MenuItem>
        </Menu>
    </Box>
};

export default Dashboard;
