import React from 'react';
import {Route, Routes} from 'react-router';
import {Box} from "@mui/material";
import List from "./List";
import Show from "./Show";

const Router = () => {
    return <Box component='div' sx={{m: 2, height: 'calc(100% - 80px)'}}>
        <Routes>
            <Route exact path='/' element={<List />}/>
            <Route path='show/:id' element={<Show />}/>
        </Routes>
    </Box>
};

export default Router;
