import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, InfoOutlined, Replay, Save } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import Row from "./Row";
import { LoadingButton } from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [tags, setTags] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchTags()
    }, []);

    const fetchTags = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'tags'
        })
            .then((res) => {
                setTags(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        tags.push({id: uuid(), name: '', description: '', isNew: true, displayInTickets: false, color: '#000000'})
        setTags(tags.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (tags.some(tag => tag.hasErrors && !tag.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = tags.filter(g => g.isNew || g.isDeleted || g.hasChanges).map((c) => {
            const tag = Object.assign({}, c);
            const isNew = tag.isNew;
            const isDeleted = tag.isDeleted;
            const hasChanges = tag.hasChanges;

            delete tag.isNew;
            delete tag.isDeleted;
            delete tag.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'tags', method: 'POST', body: tag})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'tags/' + tag.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'tags/' + tag.id, method: 'PUT', body: tag})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchTags();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = tags.some((tag) => tag.hasChanges || tag.isNew || tag.isDeleted);
    return <React.Fragment>
        {hasError && <Alert onClose={() => setHasError(null)} severity='error'>{t('settings.tags.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['tags.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.tags.addTag')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchTags}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.tags.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.tags.description')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.tags.color')}</TableCell>
                        <Tooltip title={t('settings.tags.displayInTicketsInfo')}>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Box>{t('settings.tags.displayInTickets')}</Box>
                                    <InfoOutlined sx={{color: (theme) => theme.palette.grey[600]}} fontSize='small'/>
                                </Stack>
                            </TableCell>
                        </Tooltip>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tags.filter((g) => !g.isDeleted).map((tag) => (
                        <Row key={tag.id} tag={tag}
                             onDelete={() => {
                                 setTags((tags) => {
                                     const i = tags.findIndex((c) => c.id === tag.id);
                                     const deleteTag = tags[i];

                                     if (deleteTag.isNew) {
                                         tags.splice(i, 1)
                                     } else {
                                         deleteTag.isDeleted = true;
                                         tags[i] = deleteTag;
                                     }
                                     return tags.slice();
                                 })
                             }}
                             onChange={(changedTag) => {
                                 setTags((tags) => {
                                     const i = tags.findIndex((c) => c.id === changedTag.id);
                                     tags[i] = changedTag;
                                     return tags.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
            {tags.filter((g) => !g.isDeleted).length === 0 &&
                <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                    <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                    <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                        <Typography variant='body2'>{t('settings.tags.noTagsCreated')}</Typography>
                        <Link variant='body2' onClick={onAdd}>{t('settings.tags.addTag')}</Link>
                    </Box>
                </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
