import React from 'react';
import {Route, Routes} from 'react-router';
import List from './List/List';
import {Box} from "@mui/material";
import Create from "./Create/Create";
import Show from "./Show/Show";
import {useSelector} from "react-redux";
import {hasPermission} from "../../helper/hasPermission";

const Router = () => {
    const user = useSelector((state) => state.user)
    return <Box component='div' sx={{px: 2, height: '100%'}}>
        <Routes>
            <Route exact path='/' element={<List />}/>
            {hasPermission(user, ['customers.write']) && <Route exact path='new' element={<Create />}/>}
            <Route path='show/:id/*' element={<Show />}/>
        </Routes>
    </Box>
};

export default Router;
