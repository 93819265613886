import React from 'react';
import {Route, Routes} from 'react-router';
import {Link, Navigate} from "react-router-dom";
import {AppBar, Tab, Tabs} from "@mui/material";
import {useTranslation} from "react-i18next";
import UserRouter from './User/Router';
import GeneralRouter from './General/Router';
import TicketsRouter from './Tickets/Router';
import DevicesRouter from './Devices/Router';
import CustomersRouter from './Customers/Router';
import IntegrationsRouter from './Integrations/Router';
import {Box, useTheme} from "@mui/system";
import {useSelector} from "react-redux";
import {useMatchPaths} from "../Utils/useMatchPaths";

const Router = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const paths = ['/settings/general', '/settings/user', '/settings/tickets', '/settings/devices', '/settings/customers', '/settings/integrations'];
    let currentTab = useMatchPaths(paths);

    let redirect = null;
    const user = useSelector((state) => state.user)
    const hasPermission = (list) => user.user.permissions.includes('*') || user.user.permissions.some((key) => list.some((neededKey) => key.indexOf(neededKey) === 0))

    const tabs = [];
    const routes = [];
    if (hasPermission(['tags', 'properties'])) {
        redirect = '/settings/general'
        tabs.push(<Tab key='general' label={t('settings.navigation.general')} value='/settings/general'
                       to='/settings/general'
                       component={Link}/>)
        routes.push(<Route key='general' path='/general/*' element={<GeneralRouter/>}/>)
    }
    if (hasPermission(['user', 'roles', 'groups'])) {
        redirect = redirect || '/settings/user'
        tabs.push(<Tab key='user' label={t('settings.navigation.user')} value='/settings/user' to='/settings/user'
                       component={Link}/>)
        routes.push(<Route key='user' path='/user/*' element={<UserRouter/>}/>)
    }
    if (hasPermission(['categories', 'tasks', 'status', 'pdf', 'supportTicket', 'reminder'])) {
        redirect = redirect || '/settings/tickets'
        tabs.push(<Tab key='tickets' label={t('settings.navigation.tickets')} value='/settings/tickets'
                       to='/settings/tickets'
                       component={Link}/>)
        routes.push(<Route key='tickets' path='/tickets/*' element={<TicketsRouter/>}/>)
    }
    if (hasPermission(['devicesType', 'deviceComponentTypes', 'numberRange', 'qrCode'])) {
        redirect = redirect || '/settings/devices'
        tabs.push(<Tab key='devices' label={t('settings.navigation.devices')} value='/settings/devices'
                       to='/settings/devices'
                       component={Link}/>)
        routes.push(<Route key='devices' path='/devices/*' element={<DevicesRouter/>}/>)
    }
    if (hasPermission(['customerMap.read', 'tours.read'])) {
        redirect = redirect || '/settings/customers'
        tabs.push(<Tab key='customers' label={t('settings.navigation.customers')} value='/settings/customers'
                       to='/settings/customers'
                       component={Link}/>)
        routes.push(<Route key='customers' path='/customers/*' element={<CustomersRouter/>}/>)
    }
    if (hasPermission(['fourVending', 'telegram'])) {
        redirect = redirect || '/settings/integrations'
        tabs.push(<Tab key='integrations' label={t('settings.navigation.integrations')} value='/settings/integrations'
                       to='/settings/integrations'
                       component={Link}/>)
        routes.push(<Route key='integrations' path='/integrations/*' element={<IntegrationsRouter/>}/>)
    }

    if (routes.length) {
        routes.push(<Route key='redirect' exact path={'*'} element={<Navigate to={redirect}/>}/>)
    }

    return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
        <AppBar position='relative' color='transparent'
                sx={{display: 'grid', backgroundColor: theme.palette.background.paper}}>
            {currentTab && <Tabs value={currentTab} variant="scrollable"
                                 scrollButtons="auto">
                {tabs}
            </Tabs>}
        </AppBar>
        <Box component='div' sx={{flexGrow: 1, overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box'}}>
            <Box component='div' sx={{margin: theme.spacing(2)}}>
                <Routes>
                    {routes}
                </Routes>
            </Box>
        </Box>
    </Box>
};

export default Router;
