import {Route, Routes} from "react-router";
import RoleList from "./List";
import Edit from "./Edit";
import React from "react";

const Router = (props) => {
    return <Routes>
        <Route path='/new' element={<Edit />}/>
        <Route path='/edit/:id' element={<Edit />}/>
        <Route path={'*'} element={<RoleList />}/>
    </Routes>
}

export default Router;
