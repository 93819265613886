import {WEBSOCKET_CONNECT} from "../actions/websocketActions";
import {fetchNotificationCount, notificationReceived} from "../actions/notificationActions";
import {fetchRequestList} from "../actions/requestActions";

export const websocketMiddleware = (socket) => (params) => (next) => (action) => {
    const {dispatch, getState} = params
    const {type} = action

    switch (type) {
        case WEBSOCKET_CONNECT:
            socket.connect()
            socket.on('open', () => {
                console.log('Websocket openned')
            })
            socket.on('message', (message) => {
                const messageData = JSON.parse(message.data)
                console.log('Websocket message received', messageData)
                switch (messageData.eventType) {
                    case 'notification':
                        const user = getState().user
                        dispatch(notificationReceived(messageData.data))
                        setTimeout(() => {
                            dispatch(fetchNotificationCount())
                            dispatch(fetchRequestList(user, 1, 10))
                        }, 11000)
                        break
                    default:
                        break
                }
            })
            socket.on('close', (event) => {
                console.log('Websocket closed', event.code)
            })
            break

        case 'WEBSOCKET_DISCONNECT':
            socket.disconnect()
            break

        default:
            break
    }

    return next(action)
}