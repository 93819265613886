import {CONNECTION_LIST_REQUEST, CONNECTION_LIST_FAILURE, CONNECTION_LIST_SUCCESS} from "../actions/connectionActions";

export function connectionsReducer(state = {
    isFetching: false,
    error: null,
    list: []
}, action) {
    switch (action.type) {
        case CONNECTION_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case CONNECTION_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case CONNECTION_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.response
            });
        default:
            return state
    }
}

