import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { Check, Close, Delete, Edit, MoreVert, Save } from "@mui/icons-material";
import { formatAddress } from "../../../helper/formatAddress";
import { Box, useTheme } from "@mui/system";
import DeleteDialog from "../../Utils/DeleteDialog";
import Api from "../../../core/Api";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { LoadingButton } from "@mui/lab";
import EditAction from "./EditAction";
import { stripHtml } from "string-strip-html";
import Schema from "validate";

const htmlString = (val) => stripHtml(val || '').result.trim().length > 0;

const getValidationSchema = (t) => {
    return new Schema({
        message: {
            required: true,
            type: String,
            use: {htmlString},
            message: t('tickets.validation.message'),
        }
    })
}

const Action = ({action, userList, tasks, devices, addresses, onChange, customer, deviceTypeList}) => {
    const loggedInUser = useSelector((state) => state.user.user)

    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const [editData, setEditData] = React.useState(null);
    const [errorList, setErrorList] = React.useState({});
    const [saving, setSaving] = React.useState(false);

    const handleEditData = (key, value) => {
        setEditData((n) => ({
            ...n,
            [key]: value
        }))
    }

    const user = userList.find(c => c.id === action.userId);
    let userName = <i>{t('deleted')}</i>;
    let userNameRaw = t('deleted');
    if (user) {
        userName = `${user.givenName} ${user.familyName}`;
        userNameRaw = `${user.givenName} ${user.familyName}`;
    }
    const duration = moment.duration(action.duration, "minutes");

    const addressList = addresses.filter((a) => action.addressIds?.includes(a.id)).map((address) => {
        return <React.Fragment key={address.id}>{`${address.name} - ${formatAddress(address)}`}</React.Fragment>;
    }).reduce((prev, elem, i) => {
        return prev === null ? [elem] : [...prev, <br key={'br-' + i}/>, elem]
    }, null);

    const deviceList = devices.filter((d) => action.deviceIds?.includes(d.id)).map((d) => {
        return <React.Fragment key={d.id}>{d.number}: {d.brand} {d.model}</React.Fragment>;
    }).reduce((prev, elem, i) => {
        return prev === null ? [elem] : [...prev, <br key={'br-' + i}/>, elem]
    }, null);

    const taskList = <Grid container>
        {tasks.filter((t) => action.taskIds?.includes(t.id)).map((task) => {
            return <Grid item key={task.id} xs={6} sm={4}>
                <Box display='flex' sx={{alignItems: 'center', flexWrap: 'wrap'}}>
                    <Check sx={{color: theme.palette.text.secondary, mr: 0.5}} fontSize='small'/>
                    <Typography variant="body2"> {task.name}</Typography>
                </Box>
            </Grid>
        })}
    </Grid>


    const onSave = () => {
        setSaving(true);
        setErrorList({})

        const v = getValidationSchema(t)
        const data = cloneDeep(editData);
        data.distance = parseFloat(data.distance);
        data.duration = parseInt(data.duration);
        const errors = v.validate(cloneDeep(data))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: `tickets/${action.ticketId}/actions/${action.id}`,
            method: 'PUT',
            body: data
        }).then(() => {
            onChange();
            setEditData(null);
        }, () => {
        }).then(() => {
            setSaving(false);
        })
    }

    const onDeleteClick = () => {
        setDeleting(true);
        Api.fetch({
            endpoint: `tickets/${action.ticketId}/actions/${action.id}`,
            method: 'DELETE'
        }).then(() => {
            onChange();
            setDeleteOpen(false);
        }, () => {
        }).then(() => setDeleting(false));
    };

    return <React.Fragment>
        <Card variant='elevation' elevation={0}>
            <CardHeader
                avatar={
                    <Avatar alt={userNameRaw} src={'dummy.png'}/>
                }
                action={
                    user?.id === loggedInUser?.id ? <IconButton
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                        }}>
                        <MoreVert/>
                    </IconButton> : null
                }
                title={userName}
                subheader={moment(action.createdAt).fromNow() + (action.updatedAt !== action.createdAt ? ' (' + t('tickets.show.actionEdited') + ' ' + moment(action.updatedAt).fromNow() + ')' : '')}
            />
            <CardContent sx={{py: 0}}>
                <Grid container spacing={2}>
                    {(action.deviceIds?.length > 0 || action.taskIds?.length > 0 || action.addressIds?.length > 0 || action.duration > 0 || action.distance > 0) ?
                        <Grid item
                              xs={12} sm={6}
                        >
                            <Typography variant="body2"
                                        dangerouslySetInnerHTML={{__html: action.message}}/>
                        </Grid> : <Grid item xs={12} sm={12}>
                            <Typography variant="body2"
                                        dangerouslySetInnerHTML={{__html: action.message}}/>
                        </Grid>}
                    {(action.deviceIds?.length > 0 || action.taskIds?.length > 0 || action.addressIds?.length > 0 || action.duration > 0 || action.distance > 0) &&
                        <Grid item xs={12} sm={6}>
                            <List disablePadding={true} dense>
                                {action.addressIds?.length > 0 && <ListItem key={'addressIds'} disablePadding={true}>
                                    <ListItemText primary={t('tickets.fields.addresses')} secondary={addressList}/>
                                </ListItem>}
                                {action.deviceIds?.length > 0 && <ListItem key={'deviceIds'} disablePadding={true}>
                                    <ListItemText primary={t('tickets.fields.devices')} secondary={deviceList}/>
                                </ListItem>}
                                {action.taskIds?.length > 0 && <ListItem key={'taskIds'} disablePadding={true}>
                                    <ListItemText primary={t('tickets.fields.tasks')} secondary={taskList}/>
                                </ListItem>}
                                {action.duration > 0 && <ListItem key={'duration'} disablePadding={true}>
                                    <ListItemText primary={t('tickets.fields.duration')}
                                                  secondary={`${duration.hours() < 10 ? '0' : ''}${duration.hours()}:${duration.minutes() < 10 ? '0' : ''}${duration.minutes()} ${t('hours')}`}/>
                                </ListItem>}
                                {action.distance > 0 && <ListItem key={'distance'} disablePadding={true}>
                                    <ListItemText primary={t('tickets.fields.distance')}
                                                  secondary={`${action.distance} km`}/>
                                </ListItem>}
                            </List>
                        </Grid>}
                </Grid>
            </CardContent>
        </Card>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
        >
            <MenuItem onClick={() => {
                setEditData(cloneDeep(action));
                setAnchorEl(null)
            }}>
                <ListItemIcon>
                    <Edit fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{t('tickets.show.editAction')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
                setDeleteOpen(true);
                setAnchorEl(null);
            }}>
                <ListItemIcon>
                    <Delete fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{t('tickets.show.deleteAction')}</ListItemText>
            </MenuItem>
        </Menu>
        <DeleteDialog onDelete={() => onDeleteClick()} title={t('tickets.show.deleteAction')}
                      handleClose={() => setDeleteOpen(false)}
                      isDeleting={deleting}
                      description={t('tickets.show.deleteActionDescription')}
                      open={deleteOpen}/>
        <Dialog open={Boolean(editData)} maxWidth='lg' fullWidth>
            <DialogTitle>
                {t('tickets.show.editAction')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setEditData(null) && setErrorList({})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {editData && <DialogContent>
                <EditAction
                    action={editData}
                    handleChange={handleEditData}
                    addresses={addresses}
                    errorList={errorList}
                    devices={devices}
                    deviceTypeList={deviceTypeList}
                    customer={customer}
                    tasks={tasks}
                />
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditData(null) && setErrorList({})}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    onClick={onSave}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
};

export default Action;
