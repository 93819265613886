import {CALL_API} from '../middleware/api'

export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAILURE = 'GROUP_LIST_FAILURE';


export function fetchGroupList() {

    return {
        [CALL_API]: {
            endpoint: 'groups',
            method: 'GET',
            types: [GROUP_LIST_SUCCESS, GROUP_LIST_FAILURE, GROUP_LIST_REQUEST]
        }
    }
}

