import {useLocation} from "react-router-dom";

export const useMatchPaths = (paths) => {
    const location = useLocation();
    const currentPath = location.pathname;
    for (let i = 0; i < paths.length; i++) {
        if (currentPath.includes(paths[i])) {
            return paths[i];
        }
    }
}