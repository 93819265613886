import {CALL_API} from '../middleware/api'

export const USER_NOTIFICATIONS_REQUEST = 'USER_NOTIFICATIONS_REQUEST';
export const USER_NOTIFICATIONS_SUCCESS = 'USER_NOTIFICATIONS_SUCCESS';
export const USER_NOTIFICATIONS_FAILURE = 'USER_NOTIFICATIONS_FAILURE';
export const USER_NOTIFICATIONS_CLEAR = 'USER_NOTIFICATIONS_CLEAR';


export function fetchUserNotifications() {

    return {
        [CALL_API]: {
            endpoint: 'settings/user-notifications',
            method: 'GET',
            types: [USER_NOTIFICATIONS_SUCCESS, USER_NOTIFICATIONS_FAILURE, USER_NOTIFICATIONS_REQUEST]
        }
    }
}

export function clearUserNotifications() {
    return {
        type: USER_NOTIFICATIONS_CLEAR
    }
}

