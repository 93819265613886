import { cloneDeep } from "lodash";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import ImportanceSlider from "../Utils/ImportanceSlider";
import TagSelect from "../Utils/TagSelect";
import DateRangePicker from "../Utils/DateRangePicker";
import { fetchUserList } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TicketsSettings = ({settings, onChange, tagList, statusList, categoryList}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleFilterChange = (key, value) => {
        const filter = settings.filter || {};
        filter[key] = value;
        settings.filter = filter;
        onChange(cloneDeep(settings));
    }

    const handleChange = (key, value) => {
        settings[key] = value;
        onChange(cloneDeep(settings));
    }

    useEffect(() => {
        dispatch(fetchUserList())
    }, [dispatch]);

    const userList = useSelector((state) => state.userList.list)

    return <>
        <Typography variant='h5' sx={{mt: 1, mb: 1}}
                    component='h1'>{t('dashboard.tickets.settings')}</Typography>
        <TextField
            fullWidth
            margin='dense'
            required
            label={t('dashboard.panelLabel')}
            placeholder={t('dashboard.panelLabel')}
            value={settings?.label}
            onChange={(e) => handleChange('label', e.target.value)}
        />
        <Typography variant='h5' sx={{mt: 1, mb: 1}}
                    component='h1'>{t('dashboard.tickets.filters')}</Typography>
        <FormControl fullWidth margin='dense'>
            <InputLabel>{t('tickets.fields.categories')}</InputLabel>
            <Select
                value={settings?.filter?.categories || []}
                onChange={(e) => handleFilterChange('categories', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                multiple
                renderValue={
                    (selected) => selected.map((c) => categoryList.find(r => r.id === c)?.name || 'deleted').join(', ')
                }
                input={<OutlinedInput label={t('tickets.fields.categories')}/>}
            >
                {categoryList.map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                        <Checkbox checked={(settings?.filter?.categories || []).includes(r.id)}/>
                        <ListItemText primary={r.name} secondary={r.description}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <FormControl fullWidth margin='dense'>
            <InputLabel>{t('tickets.fields.status')}</InputLabel>
            <Select
                value={settings?.filter?.status || []}
                onChange={(e) => handleFilterChange('status', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                multiple
                renderValue={
                    (selected) => selected.map((c) => statusList.find(r => r.id === c)?.name || 'deleted').join(', ')
                }
                input={<OutlinedInput label={t('tickets.fields.status')}/>}
            >
                {statusList.map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                        <Checkbox checked={(settings?.filter?.status || []).includes(r.id)}/>
                        <ListItemText primary={r.name} secondary={r.description}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <FormControl fullWidth margin='dense'>
            <InputLabel>{t('tickets.fields.userLinks')}</InputLabel>
            <Select
                value={settings?.filter?.userLinks || []}
                onChange={(e) => handleFilterChange('userLinks', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                multiple
                renderValue={
                    (selected) => selected.map((c) => {
                        if (c === 'me') {
                            return t('tickets.filters.me')
                        }
                        const user = userList.find(r => r.id === c);
                        return user ? `${user.givenName} ${user.familyName}` : 'deleted';
                    }).join(', ')
                }
                input={<OutlinedInput label={t('tickets.fields.userLinks')}/>}
            >
                <MenuItem key='me' value='me'>
                    <Checkbox checked={(settings?.filter?.userLinks || []).includes('me')}/>
                    <ListItemText primary={t('tickets.filters.me')}/>
                </MenuItem>
                {userList.map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                        <Checkbox checked={(settings?.filter?.userLinks || []).includes(r.id)}/>
                        <ListItemText primary={r.givenName + ' ' + r.familyName}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <ImportanceSlider
            label={t('tickets.fields.importance')}
            value={settings?.filter?.importance ? [settings?.filter?.importance.from, settings?.filter?.importance.to] : [0, 10]}
            onChange={(e, v) => handleFilterChange('importance', (v[0] === 0 && v[1] === 10) ? null : {
                from: v[0],
                to: v[1]
            })}
        />
        <TagSelect
            sx={{my: 1}}
            multiple
            margin='normal'
            tags={tagList}
            label={t('tickets.fields.tags')} value={settings?.filter?.tags || []}
            onChange={(v) => handleFilterChange('tags', v || [])}/>
        <DateRangePicker value={settings?.filter?.createdAt} onChange={(v => handleFilterChange('createdAt', v))}
                         labelFrom={t('tickets.fields.createdAtFrom')}
                         labelTo={t('tickets.fields.createdAtTo')}/>
        <DateRangePicker value={settings?.filter?.updatedAt} onChange={(v => handleFilterChange('updatedAt', v))}
                         labelFrom={t('tickets.fields.updatedAtFrom')}
                         labelTo={t('tickets.fields.updatedAtTo')}/>
        <DateRangePicker value={settings?.filter?.completedAt}
                         onChange={(v => handleFilterChange('completedAt', v))}
                         labelFrom={t('tickets.fields.completedAtFrom')}
                         labelTo={t('tickets.fields.completedAtTo')}/>
    </>
}

export default TicketsSettings;
