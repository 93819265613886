import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, TextField, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Schema from "validate";
import { cloneDeep } from "lodash";


const getValidationSchema = (t) => {
    return new Schema({
        companyName: {
            required: true,
            type: String,
            message: t('settings.basics.companyNameRequired'),
        },
        address: {
            required: true,
            type: String,
            message: t('settings.basics.addressRequired'),
        },
        zip: {
            required: true,
            type: String,
            message: t('settings.basics.zipRequired'),
        },
        city: {
            required: true,
            type: String,
            message: t('settings.basics.cityRequired'),
        }
    });
}

const BasicSettings = ({basicSettings, setBasicSettings, setActiveStep}) => {
    const {t} = useTranslation();

    const [errorList, setErrorList] = useState({})
    const [hasErrors, setHasErrors] = useState(false)
    const handleChange = (key, value) => {
        setBasicSettings((s) => ({...s, [key]: value}))
    }

    useEffect(() => {
        setErrorList({})
        const v = getValidationSchema(t)
        const errors = v.validate(cloneDeep(basicSettings))
        setHasErrors(errors.length > 0)
        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setErrorList(errorObject)
        }
    }, [basicSettings, t])


    return <React.Fragment>
        <Toolbar disableGutters={true}>
            <Button size='small' startIcon={<ChevronLeft/>} variant='contained'
                    onClick={() => setActiveStep(0)}>{t('back')}</Button>
            <Box flexGrow={1}/>
            <Button size='small' disabled={hasErrors} startIcon={<ChevronRight/>} variant='contained'
                    onClick={() => setActiveStep(2)}>{t('next')}</Button>
        </Toolbar>
        <Paper sx={{p: 2}}>
            <TextField
                autoFocus
                fullWidth
                required
                error={errorList.hasOwnProperty('companyName')}
                helperText={errorList.companyName}
                margin='dense'
                label={t('settings.basics.companyName')}
                placeholder={t('settings.basics.companyName')}
                value={basicSettings.companyName}
                onChange={(e) => handleChange('companyName', e.target.value)}
            />
            <TextField
                fullWidth
                margin='dense'
                required
                error={errorList.hasOwnProperty('address')}
                helperText={errorList.address}
                label={t('settings.basics.address')}
                placeholder={t('settings.basics.address')}
                value={basicSettings.address}
                onChange={(e) => handleChange('address', e.target.value)}
            />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        margin='dense'
                        required
                        error={errorList.hasOwnProperty('zip')}
                        helperText={errorList['zip']}
                        label={t('settings.basics.zip')}
                        placeholder={t('settings.basics.zip')}
                        value={basicSettings.zip}
                        onChange={(e) => handleChange('zip', e.target.value)}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        margin='dense'
                        required
                        error={errorList.hasOwnProperty('city')}
                        helperText={errorList.city}
                        label={t('settings.basics.city')}
                        placeholder={t('settings.basics.city')}
                        value={basicSettings.city}
                        onChange={(e) => handleChange('city', e.target.value)}

                    />
                </Grid>
            </Grid>
        </Paper>
    </React.Fragment>
}

export default BasicSettings;
