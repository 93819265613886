import { Box, Button, IconButton, Paper, Toolbar } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, Delete, Edit, Replay } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import DeleteDialog from "../../../Utils/DeleteDialog";
import CheckPermissions from "../../../Utils/CheckPermissions";
import { useSelector } from "react-redux";

const columns = (theme, t, onDelete, permissions) => ([
    {
        field: 'name',
        editable: false,
        minWidth: 100,
        headerName: t('settings.roles.name'),
        flex: 1,
        disableColumnMenu: true
    },
    {
        disableColumnMenu: true,
        minWidth: 200,
        field: 'description',
        editable: false,
        headerName: t('settings.roles.description'),
        flex: 1,
        renderCell: ({value}) => (
            <Box sx={{color: theme.palette.text.secondary}}>
                {value}
            </Box>
        )
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: t('actions'),
        getActions: (params) => {
            const actions = [];

            if (permissions.indexOf('roles.delete') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem disabled={!params.row.editable} icon={<Delete/>}
                                                  onClick={() => onDelete(params.id)}
                                                  label={t('settings.roles.deleteRole')} showInMenu/>);
            }

            if (permissions.indexOf('roles.write') !== -1 || permissions.indexOf('*') !== -1) {
                actions.push(<GridActionsCellItem disabled={!params.row.editable} icon={<Edit/>} component={Link}
                                                  to={'/settings/user/roles/edit/' + params.row.id}
                                                  label={t('settings.roles.editRole')}/>);
            }

            return actions;
        }
    }
]);


const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [roles, setRoles] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [deleteRoleId, setDeleteRoleId] = React.useState(null);
    const [deleting, setDeleting] = React.useState(false);

    const user = useSelector((state) => state.user)

    useEffect(() => {
        fetchRoles()
    }, []);

    const fetchRoles = () => {
        setLoading(true);
        Api.fetch({
            endpoint: 'roles'
        })
            .then((res) => {
                setRoles(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onDelete = () => {
        setDeleting(true);

        Api.fetch({
            endpoint: 'roles/' + deleteRoleId,
            method: "DELETE",
        }).then((res) => {
            setDeleteRoleId(null)
            fetchRoles();
        }, () => {
        }).then(() => {
            setDeleting(false);
        });
    }

    let deleteRoleName = '';
    if (deleteRoleId) {
        const role = roles.find(r => r.id === deleteRoleId);
        if (role) {
            deleteRoleName = role.name;
        }
    }


    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['roles.write']}>
                <Button disabled={loading} size='small' component={Link} to={'/settings/user/roles/new'} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.roles.addRole')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchRoles}><Replay/></IconButton>
        </Toolbar>
        <Paper>
            <DataGrid
                disableSelectionOnClick={true}
                loading={loading}
                autoHeight
                rows={roles}
                columns={columns(theme, t, setDeleteRoleId, user.user.permissions)}
                pageSize={50}
                rowsPerPageOptions={[50]}
            />
        </Paper>
        <DeleteDialog onDelete={() => onDelete()} isDeleting={deleting} title={t('settings.roles.deleteRole')}
                      handleClose={() => setDeleteRoleId(null)}
                      description={t('settings.roles.deleteRoleDescription', {name: deleteRoleName})}
                      open={Boolean(deleteRoleId)}/>
    </React.Fragment>;
}

export default List
