import React, {Fragment} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {LoadingButton} from "@mui/lab";

const DeleteDialog = (props) => {

    const onClick = () => {
        const promise = new Promise(((resolve, reject) => {
            props.onDelete(resolve, reject);
        }));

        promise.then(() => {
            props.handleClose();
        });
    };

    return <Fragment>
        <Dialog
            disableEscapeKeyDown
            open={props.open || false}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    props.handleClose(event, reason);
                }
            }}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText dangerouslySetInnerHTML={{__html: props.description}}/>
            </DialogContent>
            <DialogActions>
                <Button disabled={props.isDeleting} onClick={props.handleClose} autoFocus>
                    {props.t('cancel')}
                </Button>
                <LoadingButton
                    onClick={onClick}
                    loading={props.isDeleting}
                    loadingPosition="start"
                    startIcon={props.buttonIcon || <DeleteIcon/>}
                    variant="contained"
                    color='error'
                >
                    {props.buttonText || props.t('delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </Fragment>;
};

export default compose(withTranslation())(DeleteDialog);
