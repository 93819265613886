import {
    Box,
    Button,
    Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Toolbar,
    Typography,
    Link
} from "@mui/material";
import {Link as BrowserLink, useParams} from "react-router-dom";
import {Add, ChevronLeft, Close, Replay, Save} from "@mui/icons-material";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import Api from "../../../../core/Api";
import Schema from "validate";
import {useDispatch, useSelector} from "react-redux";
import {push} from "@lagunovsky/redux-react-router";
import {fetchUserList} from "../../../../actions/userActions";
import DeviceList from "../../../Devices/Utils/DeviceList";
import {arrayMoveImmutable} from "array-move";
import {Container} from "react-smooth-dnd";
import Address from "./Address";
import {uniq} from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String,
            message: t('settings.tours.nameRequired'),
        },
    })
}

const orderTourAddresses = (addresses) => {
    addresses = addresses.sort((a, b) => {
        return a.position - b.position;
    });
    addresses.forEach((address, addressIndex) => {
        address.position = addressIndex;
        address.locations = address.locations.sort((a, b) => {
            return a.position - b.position;
        });
        address.locations.forEach((location, locationIndex) => {
            location.position = locationIndex;
            location.devices = location.devices.sort((a, b) => {
                return a.position - b.position;
            });
            location.devices.forEach((device, deviceIndex) => {
                device.position = deviceIndex;
            })
        })
    });

    return addresses.slice()
}

const Edit = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const existingId = params.id;

    const {t} = useTranslation()

    const [deviceSelectOpen, setDeviceSelectOpen] = React.useState(false);
    const [temporarySelection, setTemporarySelection] = React.useState([]);
    useEffect(() => {
        setTemporarySelection([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceSelectOpen]);


    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [addresses, setAddresses] = React.useState([]);
    const [errorList, setErrorList] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const [fetchingDevices, setFetchingDevices] = React.useState(false);
    const [devicesData, setDevicesData] = React.useState([]);
    const [fetchingCustomers, setFetchingCustomers] = React.useState(false);
    const [customersData, setCustomersData] = React.useState([]);

    const userList = useSelector((state) => state.userList.list)

    useEffect(() => {
        const deviceIds = [];
        addresses.forEach((address) => {
            address.locations.forEach((location) => {
                location.devices.forEach((device) => {
                    deviceIds.push(device.deviceId)
                })
            });
        });

        const nonExisting = deviceIds.filter((deviceId) => {
            return !devicesData.find((device) => device.id === deviceId)
        })
        if (!deviceIds.length || nonExisting.length === 0) {
            return;
        }
        setFetchingDevices(true);
        Api.fetch({
            endpoint: 'devices',
            parameter: {
                filter: {
                    ids: 'in<-->' + uniq(deviceIds).join(",")
                }
            }
        }).then((res) => {
            setDevicesData(res.response);
        }, () => {
        }).then(() => setFetchingDevices(false))
    }, [addresses, devicesData]);

    useEffect(() => {
        const customerIds = [];
        addresses.forEach((customer) => {
            customerIds.push(customer.customerId)
        });
        if (!customerIds.length) {
            return;
        }

        const nonExisting = customerIds.filter((customerId) => {
            return !customersData.find((customer) => customer.id === customerId)
        })
        if (!customerIds.length || nonExisting.length === 0) {
            return;
        }

        setFetchingCustomers(true);
        Api.fetch({
            endpoint: 'customers',
            parameter: {
                filter: {
                    ids: 'in<-->' + customerIds.join(",")
                }
            }
        }).then((res) => {
            setCustomersData(res.response);
        }, () => {
        }).then(() => setFetchingCustomers(false))
    }, [addresses, customersData]);


    const fetchTour = useCallback(() => {
        setLoading(true);
        Api.fetch({
            endpoint: `tours/` + existingId,
        }).then((res) => {
            setName(res.response.name)
            setDescription(res.response.description)
            setUsers(res.response.userIds || [])
            setAddresses(res.response.addresses || [])
        }, () => {}).then(() => {
            setLoading(false);
        })
    }, [setLoading, setName, setDescription, existingId])

    useEffect(() => {
        if (!existingId) {
            return;
        }
        fetchTour()
    }, [existingId, fetchTour])

    const fetchData = useCallback(() => {
        dispatch(fetchUserList())
        if (!existingId) {
            return;
        }
        fetchTour()
    }, [dispatch, existingId, fetchTour])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const onSave = () => {
        setSaving(true)
        setErrorList({})

        const data = {
            name: name,
            description: description,
            userIds: users,
            addresses: addresses
        }

        const v = getValidationSchema(t)
        const errors = v.validate(Object.assign({}, data))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: existingId ? 'tours/' + existingId : 'tours',
            method: existingId ? 'PUT' : 'POST',
            body: data
        }).then((res) => {
            if (!existingId) {
                dispatch(push('/settings/customers/tours/edit/' + res.response.id))
            }
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    const addTemporaryDevicesToList = () => {
        const temporaryDevices = temporarySelection.slice();
        setDeviceSelectOpen(false)
        setAddresses((addresses) => {
            for (let i = 0; i < temporaryDevices.length; i++) {
                const device = temporaryDevices[i];
                const findMaximumPosition = addresses.reduce((max, customer) => {
                    if (customer.position > max) {
                        return customer.position
                    }
                    return max
                }, -1)

                let addressIndex = addresses.findIndex(c => c.customerId === device.customerId)
                if (addressIndex === -1) {
                    addresses = [...addresses, {
                        customerId: device.customerId,
                        addressId: device.addressId,
                        position: findMaximumPosition + 1,
                        locations: [],
                    }]
                    addressIndex = addresses.length - 1
                }

                const address = addresses[addressIndex]
                const findMaximumLocationPosition = address.locations.reduce((max, location) => {
                    if (location.position > max) {
                        return location.position
                    }
                    return max
                }, -1)

                let locationIndex = address.locations.findIndex(l => l.locationId === device.locationId)
                if (locationIndex === -1) {
                    address.locations = [...address.locations, {
                        locationId: device.locationId,
                        position: findMaximumLocationPosition + 1,
                        devices: [],
                    }]
                    locationIndex = address.locations.length - 1
                }

                const location = address.locations[locationIndex]
                const findMaximumDevicePosition = location.devices.reduce((max, device) => {
                    if (device.position > max) {
                        return device.position
                    }
                    return max
                }, -1)

                let deviceIndex = location.devices.findIndex(d => d.deviceId === device.id)
                if (deviceIndex === -1) {
                    location.devices = [...location.devices, {
                        deviceId: device.id,
                        position: findMaximumDevicePosition + 1,
                    }]
                    deviceIndex = location.devices.length - 1
                }

                address.locations[locationIndex] = location
                addresses[addressIndex] = address
            }

            return orderTourAddresses(addresses);
        });
    }

    const anythingLoading = loading || fetchingDevices || fetchingCustomers

    const nothingCreatedMessage = <Box sx={{textAlign: 'center', color: (t) => t.palette.grey[400]}}>
        <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        <Box sx={{color: (t) => t.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('settings.tours.noDeviceFound')}</Typography>
            <Link variant='body2'
                  onClick={!anythingLoading ? () => setDeviceSelectOpen(true): null}>
                {t('settings.tours.addDevices')}</Link>
        </Box>
    </Box>

    return <React.Fragment>
        <Toolbar variant='dense' disableGutters={true}>
            <LoadingButton
                onClick={onSave}
                size='small'
                loadingPosition="start"
                disabled={anythingLoading}
                loading={saving}
                startIcon={<Save/>}
                variant='contained'
                color='primary'>{t('save')}</LoadingButton>
            <Button sx={{ml: 2}} size='small' component={BrowserLink} to={'/settings/customers/tours'}
                    startIcon={<ChevronLeft/>}
                    color='primary'>{t('back')}</Button>
            <Box flexGrow={1}/>
            {existingId && <IconButton onClick={fetchTour}><Replay/></IconButton>}
        </Toolbar>
        <Paper sx={{
            px: 2,
            pt: 1,
            pb: 1.5,
            mb: 1,
        }}>
            <TextField
                placeholder={t('settings.tours.name')}
                margin='dense'
                fullWidth
                disabled={anythingLoading}
                variant='outlined'
                label={t('settings.tours.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errorList.hasOwnProperty('name')} helperText={errorList.name}
            />
            <TextField
                placeholder={t('settings.tours.description')}
                margin='dense'
                fullWidth
                disabled={anythingLoading}
                variant='outlined'
                label={t('settings.tours.description')}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <FormControl fullWidth margin='dense'>
                <InputLabel>{t('settings.tours.users')}</InputLabel>
                <Select
                    disabled={anythingLoading}
                    multiple
                    value={users}
                    onChange={(e) => {
                        setUsers(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                    }}
                    input={<OutlinedInput label={t('settings.tours.users')}/>}
                    renderValue={
                        (selected) => selected.filter(
                            (id) => userList.find((user) => user.id === id)
                        ).map((v) => {
                            const user = userList.find(r => r.id === v)
                            if (!user.givenName.length && !user.familyName.length) {
                                return user.email
                            }
                            return user.givenName + ' ' + user.familyName
                        }).join(', ')
                    }
                    MenuProps={MenuProps}
                >
                    {userList.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                            <Checkbox checked={users.includes(r.id)}/>
                            <ListItemText primary={r.givenName + ' ' + r.familyName} secondary={r.email}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
        <Paper sx={{
            px: 2,
            pb: 1,
            mb: 1
        }}>
            <Toolbar variant='dense' disableGutters={true}>
                <Typography variant='h6' sx={{mr: 1}}>{t('settings.tours.assignedDevices')}</Typography>
                <Button
                    onClick={() => setDeviceSelectOpen(true)}
                    size='small'
                    disabled={anythingLoading}
                    startIcon={<Add/>}
                    variant='outlined'
                    color='primary'>{t('settings.tours.addDevices')}</Button>
            </Toolbar>
            {(!anythingLoading && addresses?.length > 0) && <Container dragHandleSelector=".drag-handle-1" lockAxis="y" onDrop={({removedIndex, addedIndex}) => {
                setAddresses(addresses => arrayMoveImmutable(addresses, removedIndex, addedIndex).map((value, index) => {
                    value.position = index;
                    return value
                }));
            }}>
                {addresses.filter((c) => customersData.find(cD => cD.id === c.customerId)).map((address) => {
                    const customerData = customersData.find(c => c.id === address.customerId)
                    return <Address address={address} customerData={customerData} devices={devicesData}
                                    onChange={(updatedCustomer) => {
                                        setAddresses((addresses) => {
                                            const index = addresses.findIndex(r => r.customerId === address.customerId)
                                            if (updatedCustomer) {
                                                addresses[index] = updatedCustomer
                                            } else {
                                                addresses.splice(index, 1)
                                            }
                                            return orderTourAddresses(addresses);
                                        })
                                    }}/>
                })}
            </Container>}
            {(!anythingLoading && addresses?.length === 0) && nothingCreatedMessage}
            {anythingLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            </Box>}
        </Paper>
        <Dialog sx={{maxHeight: '100%'}}
                PaperProps={{sx: {maxHeight: 'calc(100% - 64px);'}}}
                open={deviceSelectOpen} fullWidth maxWidth='lg' onClose={() => setDeviceSelectOpen(false)}>
            <DialogTitle>
                {t('settings.tours.addDevices')}
                <IconButton
                    aria-label="close"
                    disabled={saving}
                    onClick={() => setDeviceSelectOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DeviceList
                    tableStyle={{maxHeight: 'calc(100vh - 344px)'}}
                    displayCreate={false} linkToDevice={false} containerStyle={{pt: 1}}
                    selectable selected={temporarySelection.map(d => d.id)}
                    onSelectionChange={(d) => {
                        setTemporarySelection((devices) => {
                            const found = devices.find(r => r.id === d.id)
                            if (!found) {
                                return [...devices, d];
                            }
                            return devices.filter(r => r.id !== d.id).slice()
                        })
                    }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setDeviceSelectOpen(false)}
                >
                    {t('close')}
                </Button>
                <Button
                    disabled={saving}
                    onClick={addTemporaryDevicesToList}
                    variant='contained'
                    color='primary'
                    startIcon={<Save/>}>
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export default Edit;
