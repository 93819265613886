import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect} from "react";
import Schema from "validate";
import Api from "../../../../core/Api";
import {push} from "@lagunovsky/redux-react-router";
import {cloneDeep} from "lodash";
import {
    Alert,
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Toolbar
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {ChevronLeft, Replay, Save} from "@mui/icons-material";
import {Link, useParams} from "react-router-dom";
import Dropbox from "./Dropbox";
import Ftp from "./Ftp";

const getValidationSchema = (t) => {
    return new Schema({
        name: {
            required: true,
            type: String,
            message: t('settings.connections.nameRequired'),
        },
    })
}

const Edit = () => {
    const params = useParams();
    const existingId = params.id
    const dispatch = useDispatch();

    const {t} = useTranslation()

    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [type, setType] = React.useState('web');

    const [data, setData] = React.useState(null);

    const [errorList, setErrorList] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [dataLoading, setDataLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const fetchConnection = useCallback(() => {
        if (!existingId) {
            return;
        }

        setLoading(true);
        Api.fetch({
            endpoint: `connections/` + existingId,
        }).then((res) => {
            setName(res.response.name)
            setDescription(res.response.description)
            setType(res.response.type)
            setData(res.response.data)
        }).then(() => {
            setLoading(false);
        })
    }, [setLoading, setName, setDescription, setType, existingId])

    useEffect(() => {
        fetchConnection()
    }, [fetchConnection])


    const onSave = () => {
        setSaving(true)
        setErrorList({})

        const data = {
            name,
            description,
            type
        }

        const v = getValidationSchema(t)
        const errors = v.validate(cloneDeep(data))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: existingId ? 'connections/' + existingId : 'connections',
            method: existingId ? 'PUT' : 'POST',
            body: data
        }).then((res) => {
            const path = '/settings/integrations/connections/edit/' + res.response.id
            dispatch(push(path));
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    return <>
        <Toolbar variant='dense' disableGutters={true}>
            <LoadingButton
                onClick={onSave}
                size='small'
                loadingPosition="start"
                disabled={loading || dataLoading}
                loading={saving}
                startIcon={<Save/>}
                variant='contained'
                color='primary'>{t('save')}</LoadingButton>
            <Button sx={{ml: 2}} size='small' component={Link} to={'/settings/integrations/connections'}
                    startIcon={<ChevronLeft/>}
                    color='primary'>{t('back')}</Button>
            <Box flexGrow={1}/>
            {existingId && <IconButton onClick={fetchConnection}><Replay/></IconButton>}
        </Toolbar>
        <Paper sx={{
            px: 2,
            pb: 1,
        }}>
            <TextField
                placeholder={t('settings.connections.name')}
                margin='normal'
                fullWidth
                variant='outlined'
                label={t('settings.connections.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errorList.hasOwnProperty('name')} helperText={errorList.name}
            />
            <TextField
                placeholder={t('settings.connections.description')}
                margin='normal'
                fullWidth
                variant='outlined'
                label={t('settings.connections.description')}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <FormControl fullWidth margin='normal' disabled={Boolean(existingId)}>
                <InputLabel shrink>{t('settings.connections.type')}</InputLabel>
                <Select
                    notched
                    label={t('settings.connections.type')}
                    value={type}
                    fullWidth
                    onChange={(e) => setType(e.target.value)}
                >
                    {['ftp', 'dropbox'].map((v) => (<MenuItem key={v} value={v}>
                        {t('settings.connections.types.' + v)}
                    </MenuItem>))}
                </Select>
            </FormControl>
        </Paper>
        {existingId && <Paper sx={{mt: 2}}>
            {type === 'dropbox' &&
                <Dropbox loading={dataLoading} setLoading={setDataLoading} data={data} setData={setData}
                         id={existingId}/>}
            {type === 'ftp' &&
                <Ftp loading={dataLoading} setLoading={setDataLoading} data={data || {}} setData={setData}
                     id={existingId}/>}
        </Paper>
        }
        {!existingId &&
            <Alert sx={{mt: 2}} severity='info'>{t('settings.connections.moreSettingsAvailableAfterSave')}</Alert>}
    </>
}
export default Edit
