import React, {useEffect} from 'react';
import qs from 'query-string';
import {push} from 'react-router-redux'
import {useDispatch} from 'react-redux';
import { jwtDecode } from "jwt-decode";
import {useLocation} from "react-router-dom";

const LoginCallback = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    useEffect(() => {
        const query = qs.parse(location.hash);
        const decoded = jwtDecode(query.id_token);
        localStorage.setItem('user_name', decoded.email)
        localStorage.setItem('access_token', query.access_token)
        localStorage.setItem('refresh_token', query.refresh_token)
        localStorage.setItem('id_token', query.id_token)

        let route = '/'
        if (query.state) {
            route = query.state
        }
        if(query.route) {
            route = query.route
        }
        dispatch(push(route))
    }, [dispatch, location.hash])

    return <div/>
}

export default LoginCallback

