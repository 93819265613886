import {
    Box, Chip, CircularProgress, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import {fetchRequestList} from "../../actions/requestActions";
import React, {useEffect} from "react";
import moment from "moment/moment";
import {Check, Close} from "@mui/icons-material";
import Api from "../../core/Api";
import {hasPermission} from "../../helper/hasPermission";
import {fetchCategoryList} from "../../actions/categoryActions";
import {uniq} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const TicketRequests = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [answerRequest, setAnswerRequest] = React.useState(false);

    const categories = useSelector((state) => state.categories.list)
    const user = useSelector((state) => state.user)
    const ticketRequestData = useSelector((state) => state.requests)
    const [customers, setCustomers] = React.useState({});

    useEffect(() => {
        if (hasPermission(user, ['categories.read'])) {
            dispatch(fetchCategoryList())
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (!user.user?.id) {
            return
        }
        dispatch(fetchRequestList(user, 1, 10))
    }, [user, dispatch])

    useEffect(() => {
        const customerIds = uniq((ticketRequestData.list || []).map(ticket => ticket.customerId)).sort();
        if (customerIds.filter(c => !Object.keys(customers).includes(c)) <= 0) {
            return;
        }

        const loadedCustomers = {};
        Api.fetch({
            endpoint: "customers",
            parameter: {
                filter: {
                    ids: 'in<-->' + customerIds.join(",")
                }
            }
        }).then(response => {
            response.response.forEach(customer => {
                loadedCustomers[customer.id] = customer;
            });
            customerIds.forEach((customerId) => {
                if (!loadedCustomers[customerId]) {
                    loadedCustomers[customerId] = null
                }
            })
            setCustomers(loadedCustomers);
        });

    }, [customers, ticketRequestData])


    useEffect(() => {
        let pending = false;
        (ticketRequestData.list || []).forEach((ticket) => {
            (ticket.userLinks || []).forEach((userLink) => {
                if (userLink.userId === user.user?.id && userLink.status !== 'pending') {
                    pending = true;
                }
            })
        });

        if (pending) {
            const timeoutID = setTimeout(() => {
                dispatch(fetchRequestList(user, ticketRequestData.page, ticketRequestData.size))
            }, 11000)

            return () => {
                clearTimeout(timeoutID)
            }
        }
    }, [ticketRequestData, dispatch, user])


    const handleRequest = (id, type) => {
        setAnswerRequest(true)
        Api.fetch({
            endpoint: "tickets/" + id + "/request/" + type,
            method: 'POST'
        }).then(response => {
            dispatch(fetchRequestList(user, ticketRequestData.page, ticketRequestData.size))
        }, () => {
        }).then(() => {
            setAnswerRequest(false)
        })
    }

    const ticketList = (ticketRequestData.list || []).map((ticket) => {
        const customer = customers[ticket.customerId];
        const category = categories.find(c => c.id === ticket.categoryId);

        let pending = false;
        (ticket.userLinks || []).forEach((userLink) => {
            if (userLink.userId === user.user?.id && userLink.status !== 'pending') {
                pending = true;
            }
        })

        return (
            <TableRow key={ticket.id} sx={{opacity: pending ? 0.8 : 1}}>
                <TableCell>#{ticket.number}</TableCell>
                <TableCell>{customer?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>{category?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>{ticket.title}</TableCell>
                <TableCell>{moment(ticket.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>
                    {pending && <Chip label={t('navigation.pendingRequest')}/>}
                    {!pending && <>
                        <IconButton onClick={() => handleRequest(ticket.id, 'accepted')} disabled={answerRequest}
                                    sx={{mr: 1}} color='success'><Check/></IconButton>
                        <IconButton onClick={() => handleRequest(ticket.id, 'rejected')} disabled={answerRequest}
                                    color='error'><Close/></IconButton>
                    </>}
                </TableCell>
            </TableRow>
        )
    });

    return <>
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>
                            {t('tickets.fields.number')}
                        </TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.customer')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.category')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.title')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.fields.createdAt')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ticketList}
                </TableBody>
            </Table>
            {(ticketRequestData.total <= 0 && !ticketRequestData.isFetching) &&
                <Box sx={{textAlign: 'center', color: (t) => t.palette.grey[400]}}>
                    <ConfirmationNumberIcon sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                    <Box sx={{color: (t) => t.palette.text.secondary, mb: 2}} textAlign='center'>
                        <Typography variant='body2'>{t('navigation.noRequestFound')}</Typography>
                    </Box>
                </Box>}
            {ticketRequestData.isFetching && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
            </Box>}
        </TableContainer>
        {ticketRequestData.total > 0 && <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={ticketRequestData.total}
            rowsPerPage={ticketRequestData.size}
            page={ticketRequestData.page - 1}
            onPageChange={(e, page) => {
                dispatch(fetchRequestList(user, page + 1, ticketRequestData.size))
            }}
            onRowsPerPageChange={(e) => {
                dispatch(fetchRequestList(user, 1, e.target.value))
            }}
        />}
    </>
}

export default TicketRequests;