import { orderBy, uniq } from "lodash";
import { useEffect, useState } from "react";
import Api from "../../../../core/Api";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";

const History = ({history, customer}) => {
    const {t} = useTranslation();
    const [customers, setCustomers] = useState({});

    useEffect(() => {
        const customerIds = uniq(history.map(history => history.customerId)).sort();
        if (customerIds.filter(c => !Object.keys(customers).includes(c)) <= 0) {
            return;
        }

        const loadedCustomers = {};
        if (customerIds.indexOf(customer.id) >= 0) {
            loadedCustomers[customer.id] = customer;
        }

        const customerIdsToLoad = customerIds.filter(c => c !== customer.id)
        if (customerIdsToLoad.length <= 0) {
            setCustomers(loadedCustomers);
            return;
        }

        Api.fetch({
            endpoint: "customers",
            parameter: {
                filter: {
                    ids: 'in<-->' + customerIdsToLoad.join(",")
                }
            }
        }).then(response => {
            customerIdsToLoad.forEach(id => {
                loadedCustomers[id] = response.response.find(c => c.id === id);
            })
            setCustomers(loadedCustomers);
        });

    }, [history, customers, customer]);


    const entries = orderBy(history, (h) => moment(h.timestamp), "desc").map((h) => {

        const c = customers[h.customerId] || {};

        const altText = <i>{t('deleted')}</i>;

        const a = c.addresses?.find(a => a.id === h.addressId) || {};
        const l = a.locations?.find(l => l.id === h.locationId) || {};
        return <TableRow key={h.timestamp}>
            <TableCell>{c.number ? <>{c.number}: {c.name}</> : altText}</TableCell>
            <TableCell>{a.name || altText}</TableCell>
            <TableCell>{l.name || altText}</TableCell>
            <TableCell>{moment(h.timestamp).format('DD.MM.YYYY HH:mm')}</TableCell>
        </TableRow>
    });

    return <Paper variant='outlined'>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('devices.history.customer')}</TableCell>
                        <TableCell>{t('devices.history.address')}</TableCell>
                        <TableCell>{t('devices.history.location')}</TableCell>
                        <TableCell>{t('devices.history.timestamp')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entries}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}

export default History;
