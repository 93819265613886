import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardHeader,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText
} from "@mui/material";
import Api from "../../../../core/Api";
import { useTranslation } from "react-i18next";
import { OpenInNew } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Alert } from "@mui/material";
import moment from "moment";

const Info = () => {

    const {t} = useTranslation();

    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Api.fetch({
            endpoint: 'system/subscription',
            ignoreErrorCodes: [404]
        }).then(res => {
            setSubscription(res.response);
        }, () => {
        }).then(() => setLoading(false));
    }, []);

    return <Grid container>
        <Grid item xs={12} md={6}>
            <Card>
                <CardHeader title={t('settings.systemInfo.label')}/>
                {loading && <Box sx={{p: 2, textAlign: 'center'}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>}
                {(!Boolean(subscription) && !loading) && <Alert severity={'info'}>
                    {t('settings.systemInfo.noContractFound')}
                </Alert>}
                {(Boolean(subscription) && !loading) && <List sx={{p: 2}}>
                    <ListItem disablePadisableGuttersdding disablePadding>
                        <ListItemText secondary={t('settings.systemInfo.plan')} primary={t('versions.' + subscription?.planId)}/>
                    </ListItem>
                    <ListItem disableGutters disablePadding>
                        <ListItemText secondary={t('settings.systemInfo.status')} primary={t('settings.systemInfo.statusList.' + subscription?.status)}/>
                    </ListItem>
                    <ListItem disableGutters  disablePadding>
                        <ListItemText secondary={t('settings.systemInfo.startDate')} primary={moment(subscription?.startDate).format('DD.MM.YYYY')}/>
                    </ListItem>
                    <ListItem disableGutters disablePadding>
                        <ListItemText secondary={t('settings.systemInfo.endDate')} primary={subscription?.endDate ? moment(subscription?.endDate).format('DD.MM.YYYY') : '-'}/>
                    </ListItem>
                </List>}
                {(Boolean(subscription) && !loading) && <CardActions>
                    <Button target='_blank' component='a' href={'https://account.malsch.solutions/products'}
                            startIcon={<OpenInNew/>}>
                        {t('settings.systemInfo.openDetails')}
                    </Button>
                </CardActions>}
            </Card>
        </Grid>
    </Grid>;
}

export default Info;
