import React, { useCallback, useEffect } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl,
    IconButton,
    InputAdornment, InputLabel,
    Link, ListItemText, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { Add, Close, FilterAlt, Replay, Save, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/system";
import Api from "../../../core/Api";
import { push } from "@lagunovsky/redux-react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchTagList } from "../../../actions/tagActions";
import CheckPermissions from "../../Utils/CheckPermissions";
import TagSelect from "../../Utils/TagSelect";
import { cloneDeep, uniq } from "lodash";
import { fetchDeviceTypes } from "../../../actions/deviceTypeActions";
import { formatAddress } from "../../../helper/formatAddress";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledLink = styled(RouterLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const DeviceList = ({
                        createParams = null,
                        hiddenColumns = [],
                        fixedFilters = {},
                        selectable = false,
                        onSelectionChange = () => false,
                        selected = [],
                        linkToDevice = true,
                        displayCreate = true,
                        containerStyle,
                        tableStyle,
                        deviceLinkAddress = null
                    }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [total, setTotal] = React.useState(0);
    const [searchString, setSearchString] = React.useState('');
    const [tempSearchString, setTempSearchString] = React.useState('');
    const [devices, setDevices] = React.useState([]);
    const [customers, setCustomers] = React.useState({});
    const [fetching, setFetching] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [perPage, setPerPage] = React.useState(20);
    const [orderColumn, setOrderColumn] = React.useState('number');
    const [locations, setLocations] = React.useState([]);
    const [filters, setFilters] = React.useState({
        tags: [],
        locationIds: [],
        typeIds: [],
        number: '',
    });

    useEffect(() => {
        if(fixedFilters.customerIds) {
            Api.fetch({
                endpoint: 'customers',
                parameter: {
                    filter: {
                        ids: 'in<-->' + fixedFilters.customerIds
                    }
                }
            }).then(response => {
                let locations = [];
                response.response.forEach((c) => {
                    c.addresses.forEach((a) => {
                        locations = locations.concat(a.locations);
                    })
                })
                setLocations(locations);
            }, () => {});
        }
    }, [fixedFilters.customerIds]);

    useEffect(() => {
        dispatch(fetchDeviceTypes())
    }, [dispatch])

    const deviceTypeList = useSelector((state) => state.deviceTypes.list)

    const [editFilterData, setEditFilterData] = React.useState(null);


    const applyFilter = () => {
        setFilters(cloneDeep(editFilterData));
        setEditFilterData(null);
    }

    const handleFilterChange = (key, value) => {
        setEditFilterData((d) => {
            d[key] = value;
            return cloneDeep(d);
        })
    }

    const changeSort = (column) => {
        if (orderColumn === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrder('asc');
            setOrderColumn(column);
        }
    };

    const fetchDevices = useCallback(() => {
        setFetching(true);
        const filter = {
            query: searchString,
        };

        if(fixedFilters.customerIds) {
            filter.customerIds = fixedFilters.customerIds;
        }

        if(fixedFilters.addressIds) {
            filter.addressIds = fixedFilters.addressIds;
        }

        if (filters.tags?.length > 0) {
            filter.tags = 'in<-->' + filters.tags.join(',');
        }
        if (filters.typeIds?.length > 0) {
            filter.typeIds = 'in<-->' + filters.typeIds.join(',');
        }
        if (filters.locationIds?.length > 0) {
            filter.locationIds = 'in<-->' + filters.locationIds.join(',');
        }
        if(filters.number?.length > 0) {
            filter.number =  'like<-->' +filters.number;
        }
        Api.fetch({
            endpoint: 'devices',
            parameter: {
                page: page + 1,
                size: perPage,
                sort: `${orderColumn}_${order}`,
                filter: filter
            }
        }).then((res) => {

            const customerIds = uniq(res.response.map(d => d.customerId));

            if (customerIds.length) {
                Api.fetch({
                    endpoint: "customers",
                    parameter: {
                        filter: {
                            ids: 'in<-->' + customerIds.join(",")
                        }
                    }
                }).then(response => {
                    const loadedCustomers = {};
                    response.response.forEach(customer => {
                        loadedCustomers[customer.id] = customer;
                    });
                    customerIds.forEach((customerId) => {
                        if (!loadedCustomers[customerId]) {
                            loadedCustomers[customerId] = null
                        }
                    })

                    setCustomers(loadedCustomers);
                }, () => {
                }).then(() => {
                    setFetching(false);
                });
            } else {
                setFetching(false);
            }

            setTotal(parseInt(res.headers['x-total-count'] || "0", 10));
            setDevices(res.response)
        }, () => {
            setFetching(false);
        });
    }, [page, perPage, order, orderColumn, setFetching, setDevices, filters, searchString, fixedFilters.customerIds, fixedFilters.addressIds]);

    const fetchData = useCallback(() => {
        fetchDevices();
        dispatch(fetchTagList())
    }, [fetchDevices, dispatch]);


    useEffect(() => {
        fetchData()
    }, [fetchData])

    const tagList = useSelector((state) => state.tags.list)

    const deviceList = devices.map((device) => {

        const customer = customers[device.customerId];
        const address = customer?.addresses?.find((a) => a.id === device.addressId);
        const location = address?.locations.find((l) => l.id === device.locationId);
        const type = deviceTypeList.find((t) => t.id === device.typeId);
        const linkAddress = deviceLinkAddress || device.addressId;

        return (
            <TableRow component={linkToDevice ? StyledLink : 'tr'} key={device.id} hover={linkToDevice}
                      to={`/customers/show/${device.customerId}/addresses/${linkAddress}/devices/${device.id}`}>
                {selectable && <TableCell>
                    <Checkbox
                        checked={selected.includes(device.id)}
                        onChange={() => onSelectionChange(device)}
                    />
                </TableCell>}
                {!hiddenColumns.includes('customer') &&
                    <TableCell>{customer?.name || <i>{t('deleted')}</i>}</TableCell>}
                <TableCell>{device.number}</TableCell>
                <TableCell>{device.model}</TableCell>
                <TableCell>{device.brand}</TableCell>
                <TableCell>{type?.name || <i>{t('deleted')}</i>}</TableCell>
                {!hiddenColumns.includes('address') &&
                    <TableCell>{address ? formatAddress(address) : <i>{t('deleted')}</i>}</TableCell>}
                <TableCell>{location?.name || <i>{t('deleted')}</i>}</TableCell>
                <TableCell>
                    {device.tags?.filter((tag) => tagList.find((t) => t.id === tag)).map((tag) => {
                        const tagDefinition = tagList.find((t) => t.id === tag);
                        return <Chip key={tag} label={tagDefinition.name}
                                     sx={{mr: 1, backgroundColor: tagDefinition.color, color: 'white'}}/>
                    })}
                </TableCell>
            </TableRow>
        )
    });

    const nothingCreatedMessage = <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
        <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('devices.list.noDeviceCreated')}</Typography>
            <Link variant='body2'
                  onClick={() => dispatch(push('/devices/new' + suffix))}>{t('devices.list.createDevice')}</Link>
        </Box>
    </Box>

    let suffix = '';
    if (createParams != null) {
        suffix = '?' + createParams.toString()
    }

    const showFilterToolbar = filters.tags?.length > 0 || filters.locationIds?.length > 0 || filters.typeIds?.length > 0 || filters.number?.length > 0;
    return <Box component='div' sx={containerStyle}>
        <Toolbar disableGutters sx={{mb: showFilterToolbar ? 1 : 2}}>
            <form onSubmit={(e) => {
                e.preventDefault();
                setSearchString(tempSearchString);
                setPage(0);
            }}>
                <TextField
                    autoFocus
                    sx={{mr: 2}}
                    value={tempSearchString}
                    label={t('devices.list.search')}
                    placeholder={t('devices.list.search')}
                    margin='none'
                    onChange={(e) => setTempSearchString(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" color="primary"
                                            onClick={() => setSearchString(tempSearchString) && setPage(0)}>
                                    <Search/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
            <Button sx={{mr: 2}} startIcon={<FilterAlt/>}
                    onClick={() => setEditFilterData(cloneDeep(filters))}>{t('devices.list.filter')}</Button>
            {displayCreate && <CheckPermissions list={['devices.write']}>
                <Button startIcon={<Add/>}
                        onClick={() => dispatch(push('/devices/new' + suffix))}>{t('devices.list.createDevice')}</Button>
            </CheckPermissions>}
            <Box flexGrow={1}/>
            <IconButton onClick={fetchData}><Replay/></IconButton>
        </Toolbar>
        {showFilterToolbar && <Toolbar disableGutters variant='dense'  sx={{mb: 0, display: 'block', flexGrow: 0}}>
            {filters.tags?.length > 0 &&
                <Chip sx={{mr: 1}}  onClick={() => setEditFilterData(cloneDeep(filters))} onDelete={() => setFilters((f) => {
                    f.tags = [];
                    return cloneDeep(f);
                })} label={
                    <React.Fragment>{t('devices.fields.tags')}: {filters.tags?.filter(t => tagList.find(tag => tag.id === t)).map(t => tagList.find(t2 => t2.id === t)?.name).join(', ')}</React.Fragment>}/>}

            {filters.typeIds?.length > 0 &&
                <Chip sx={{mr: 1}} onClick={() => setEditFilterData(cloneDeep(filters))}
                      onDelete={() => setFilters((f) => {
                          f.typeIds = [];
                          return cloneDeep(f);
                      })} label={
                    <React.Fragment>{t('devices.fields.types')}: {filters.typeIds?.filter(t => deviceTypeList.find(type => type.id === t)).map(t => deviceTypeList.find(t2 => t2.id === t)?.name).join(', ')}</React.Fragment>}/>}
            {filters.locationIds?.length > 0 &&
            <Chip sx={{mr: 1}} onClick={() => setEditFilterData(cloneDeep(filters))}
                  onDelete={() => setFilters((f) => {
                      f.locationIds = [];
                      return cloneDeep(f);
                  })} label={
                <React.Fragment>{t('devices.fields.locations')}: {filters.locationIds?.filter(l => locations.find(l2 => l2.id === l)).map(l => locations.find(l2 => l2.id === l)?.name).join(', ')}</React.Fragment>}/>}
            {filters.number?.length > 0 &&
                <Chip sx={{mr: 1}} onClick={() => setEditFilterData(cloneDeep(filters))}
                        onDelete={() => setFilters((f) => {
                            f.number = '';
                            return cloneDeep(f);
                        })} label={
                    <React.Fragment>{t('devices.fields.number')}: {filters.number}</React.Fragment>}/>}
        </Toolbar>}
        <Paper variant='outlined'>
            <TableContainer sx={tableStyle}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {selectable && <TableCell/>}
                            {!hiddenColumns.includes('customer') &&
                                <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.customer')}</TableCell>}
                            <TableCell
                                sortDirection={orderColumn === 'number' ? order : false}
                                sx={{fontWeight: 'bold'}}>
                                <TableSortLabel
                                    active={orderColumn === 'number'}
                                    direction={order}
                                    onClick={() => changeSort('number')}
                                >
                                    {t('devices.fields.number')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.model')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.brand')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.type')}</TableCell>
                            {!hiddenColumns.includes('address') &&
                                <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.address')}</TableCell>}
                            <TableCell sx={{fontWeight: 'bold'}}>{t('devices.fields.location')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('customers.fields.tags')}</TableCell></TableRow>
                    </TableHead>
                    {!fetching && <TableBody>
                        {deviceList}
                    </TableBody>}
                </Table>
                {fetching && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>}
                {page === 0 && devices.length === 0 && !fetching && nothingCreatedMessage}
                {devices.length > 0 && <Box sx={{position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1}}
                ><Divider variant='full'/><TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={perPage}
                    page={page}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={(e) => {
                        setPerPage(parseInt(e.target.value, 10))
                        setPage(0)
                    }}
                /></Box>}
            </TableContainer>
        </Paper>
        <Dialog maxWidth='sm' fullWidth open={Boolean(editFilterData)} onClose={() => setEditFilterData(null)}>
            <DialogTitle>
                {t('devices.filters')}
                <IconButton
                    aria-label="close"
                    onClick={() => setEditFilterData(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {editFilterData && <DialogContent>
                <TextField
                    fullWidth
                    margin='dense'
                    label={t('devices.fields.number')}
                    value={editFilterData.number}
                    onChange={(e) => handleFilterChange('number', e.target.value)}
                />
                <TagSelect
                    sx={{my: 1}}
                    multiple
                    margin='dense'
                    tags={tagList}
                    label={t('devices.fields.tags')} value={editFilterData.tags}
                    onChange={(v) => handleFilterChange('tags', v || [])}/>
                <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('devices.fields.types')}</InputLabel>
                    <Select
                        multiple
                        label={('devices.fields.types')}
                        value={editFilterData.typeIds}
                        fullWidth
                        renderValue={
                            (selected) => selected.map((v) => deviceTypeList.find(r => r.id === v).name).join(', ')
                        }
                        MenuProps={MenuProps}
                        onChange={(e) => handleFilterChange('typeIds', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                    >
                        {deviceTypeList.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <Checkbox checked={editFilterData.typeIds.includes(r.id)}/>
                                <ListItemText primary={r.name} secondary={r.description}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {!!fixedFilters.customerIds && <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('devices.fields.locations')}</InputLabel>
                    <Select
                        multiple
                        label={('devices.fields.locations')}
                        value={editFilterData.locationIds}
                        fullWidth
                        renderValue={
                            (selected) => selected.map((v) => locations.find(r => r.id === v).name).join(', ')
                        }
                        MenuProps={MenuProps}
                        onChange={(e) => handleFilterChange('locationIds', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                    >
                        {locations.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <Checkbox checked={editFilterData.locationIds.includes(r.id)}/>
                                <ListItemText primary={r.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </DialogContent>}
            <DialogActions>
                <Button
                    onClick={() => setEditFilterData(null)}
                >
                    {t('close')}
                </Button>
                <Button
                    loadingPosition="start"
                    onClick={applyFilter}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('applyFilter')}
                </Button>
            </DialogActions>
        </Dialog>
    </Box>;
}

export default DeviceList;
