import React, {useCallback, useEffect, useMemo} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import {
    Add,
    Close,
    Delete,
    DragHandle,
    EventRepeat,
    FilterAlt,
    Link as LinkIcon,
    Replay,
    RestoreFromTrash,
    Save,
    Search,
    Star,
    StarBorder,
    ViewColumn
} from "@mui/icons-material";
import {arrayMoveImmutable} from "array-move";
import {useTranslation} from "react-i18next";
import {darken, lighten, styled, useTheme} from "@mui/system";
import Api from "../../../core/Api";
import {push} from "@lagunovsky/redux-react-router";
import {useDispatch, useSelector} from "react-redux";
import {fetchTagList} from "../../../actions/tagActions";
import CheckPermissions from "../../Utils/CheckPermissions";
import {cloneDeep, uniq} from "lodash";
import {fetchCategoryList} from "../../../actions/categoryActions";
import {fetchStatusList} from "../../../actions/statusActions";
import moment from "moment";
import TagSelect from "../../Utils/TagSelect";
import ImportanceSlider from "../../Utils/ImportanceSlider";
import {LoadingButton} from "@mui/lab";
import {useDebounce} from "../../Utils/useDebounce";
import DateRangePicker from "../../Utils/DateRangePicker";
import DeleteDialog from "../../Utils/DeleteDialog";
import {fetchUserList} from "../../../actions/userActions";
import LiveLinkRow from "./LiveLinkRow";
import {v1 as uuid} from "uuid";
import {presets} from "react-grid-layout/.babelrc";
import RecurringTicketList from "./RecurringTicketList";
import {formatAddress} from "../../../helper/formatAddress";
import {Container, Draggable} from "react-smooth-dnd";
import {fetchDeviceTypes} from "../../../actions/deviceTypeActions";
import {fetchToursList} from "../../../actions/toursActions";
import {hasPermission} from "../../../helper/hasPermission";

const availableColumns = [
    'number',
    'customer',
    'addresses',
    'devices',
    'locations',
    'categories',
    'title',
    'status',
    'importance',
    'tours',
    'tags',
    'createdAt',
    'updatedAt',
    'completedAt'];

const StyledBookmarkList = styled('div')(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        height: '300px',
    },
    [theme.breakpoints.up('md')]: {
        height: '100%',
    }
}));

const ForcedStyledBookmarkList = styled('div')(({theme}) => ({
    height: '300px',
}));


const StyledLink = styled(RouterLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const generateHash = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;

}

const TicketList = ({
                        addressId = null,
                        customerId = null,
                        deviceId = null,
                        actionBar = true,
                        paperView,
                        variant,
                        tablePaperStyle,
                        horizontalDisplay = false
                    }) => {
    let createSuffix = ''
    if (addressId || customerId || addressId) {
        const urlParams = new URLSearchParams();
        if (addressId) {
            urlParams.append('addressId', addressId);
        }
        if (customerId) {
            urlParams.append('customerId', customerId);
        }
        if (deviceId) {
            urlParams.append('deviceId', deviceId);
        }
        createSuffix = `?${urlParams.toString()}`
    }

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [total, setTotal] = React.useState(0);
    const [tickets, setTickets] = React.useState([]);

    const [customers, setCustomers] = React.useState({});

    const [loadedDevices, setLoadedDevices] = React.useState({});

    const [customersOpen, setCustomersOpen] = React.useState(false);
    const [customersLoading, setCustomersLoading] = React.useState(false);
    const [customerSearchString, setCustomerSearchString] = useDebounce('', 300);
    const [filterCacheCustomers, setFilterCacheCustomers] = React.useState({});
    const [searchCustomers, setSearchCustomers] = React.useState([]);

    const [deleteFilterPresetId, setDeleteFilterPresetId] = React.useState(null);
    const [filterPresetDeleting, setFilterPresetDeleting] = React.useState(false);

    const [fetching, setFetching] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [perPage, setPerPage] = React.useState(20);
    const [order, setOrder] = React.useState('asc');
    const [orderColumn, setOrderColumn] = React.useState('statusPriority');

    const [hoveredPreset, setHoveredPreset] = React.useState(null);

    const [searchString, setSearchString] = React.useState('');
    const [tempSearchString, setTempSearchString] = React.useState('');

    const [filterPresets, setFilterPresets] = React.useState([]);
    const [presetsLoading, setPresetsLoading] = React.useState(false);
    const [presetsSaving, setPresetsSaving] = React.useState(false);
    const [newPresetData, setNewPresetData] = React.useState(null);

    const [currentPresetId, setCurrentPresetId] = React.useState(null);

    const [liveLinks, setLiveLinks] = React.useState([]);
    const [liveLinksLoading, setLiveLinksLoading] = React.useState(false);
    const [liveLinksSaving, setLiveLinksSaving] = React.useState(false);
    const [liveLinkPanelOpen, setLiveLinkPanelOpen] = React.useState(false);

    const [recurringTicketsPanelOpen, setRecurringTicketsPanelOpen] = React.useState(false);

    const [filters, setFilters] = React.useState({
        tags: [],
        customers: [],
        categories: [],
        status: [],
        userLinks: [],
        tours: [],
        importance: null,
        createdAt: null,
        updatedAt: null,
        completedAt: null
    });

    const [columns, setColumns] = React.useState(['number', 'customer', 'addresses', 'categories', 'title', 'status', 'importance', 'tags', 'createdAt', 'updatedAt', 'completedAt']);
    const [editColumnsData, setEditColumnsData] = React.useState(null);
    const [editFilterData, setEditFilterData] = React.useState(null);

    const user = useSelector((state) => state.user)
    const currentUserId = user.user.id
    const userList = useSelector((state) => state.userList.list)
    const tempToursList = JSON.stringify(useSelector(state => state.tours.list))
    const tagList = useSelector((state) => state.tags.list)
    const statusList = useSelector((state) => state.statusList.list)
    const categories = useSelector((state) => state.categories.list)
    const deviceTypes = useSelector((state) => state.deviceTypes.list)
    const tempDevicesByTour = JSON.stringify(useSelector((state) => state.tours.devicesByTour));

    const toursList = useMemo(() => {
        return JSON.parse(tempToursList || '[]');
    }, [tempToursList]);

    const devicesByTour = useMemo(() => {
        return JSON.parse(tempDevicesByTour || '{}');
    }, [tempDevicesByTour]);

    const hasDisplayInTicketsTag = tagList.filter(t => t.displayInTickets).length > 0

    const filteredAvailableColumns = useMemo(() => {
        const tempAvailableColumns = availableColumns.slice();
        if (customerId || deviceId || addressId) {
            tempAvailableColumns.splice(tempAvailableColumns.indexOf('customer'), 1);
        }
        if (addressId || deviceId) {
            tempAvailableColumns.splice(tempAvailableColumns.indexOf('addresses'), 1);
        }

        if (deviceId) {
            tempAvailableColumns.splice(tempAvailableColumns.indexOf('devices'), 1);
        }

        if (!hasPermission(user, ['tours.read'])) {
            tempAvailableColumns.splice(tempAvailableColumns.indexOf('tours'), 1);
        }

        return tempAvailableColumns;
    }, [customerId, deviceId, addressId, user])

    const activeColumns = useMemo(() => {
        return columns.filter((column) => {
            return filteredAvailableColumns.includes(column)
        });
    }, [columns, filteredAvailableColumns])


    const changeSort = (column) => {
        if (orderColumn === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrder('asc');
            setOrderColumn(column);
        }
    };

    const handleFilterChange = (key, value) => {
        setEditFilterData((d) => {
            d[key] = value;
            return cloneDeep(d);
        })
    }

    const applyFilter = () => {
        setFilters(cloneDeep(editFilterData));
        setCurrentPresetId(null)
        setEditFilterData(null);
    }

    const applyPreset = (preset) => {
        const p = cloneDeep(preset);
        if (!p.data.filters) {
            setFilters(p.data)
        } else {
            if (!p.data.filters.tours) {
                p.data.filters.tours = []
            }
            setFilters(p.data.filters)
            setColumns(p.data.columns)
            setOrder(p.data.order)
            setOrderColumn(p.data.orderColumn)
            setPerPage(p.data.perPage)
        }

        setCurrentPresetId(p.id)

    }

    const fetchLiveLinks = useCallback(() => {
        setLiveLinksLoading(true);
        Api.fetch({
            endpoint: 'live-links'
        }).then((res) => {
            setLiveLinks(res.response);
        }, () => {
        }).then(() => {
            setLiveLinksLoading(false);
        });
    }, [setLiveLinksLoading, setLiveLinks]);

    useEffect(() => {
        if (liveLinkPanelOpen) {
            fetchLiveLinks();
        } else {
            setLiveLinks([]);
        }
    }, [liveLinkPanelOpen, fetchLiveLinks]);

    const saveLiveLinks = useCallback(() => {
        setLiveLinksSaving(true);

        if (liveLinks.some(ll => ll.hasErrors && !ll.isDeleted)) {
            setLiveLinksSaving(false);
            return;
        }

        const promiseList = liveLinks.slice().filter(ll => ll.isNew || ll.isDeleted || ll.hasChanges).map((ll) => {
            const liveLink = cloneDeep(ll)
            const isNew = liveLink.isNew;
            const isDeleted = liveLink.isDeleted;
            const hasChanges = liveLink.hasChanges;

            delete liveLink.isNew;
            delete liveLink.isDeleted;
            delete liveLink.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'live-links', method: 'POST', body: liveLink})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'live-links/' + liveLink.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'live-links/' + liveLink.id, method: 'PUT', body: liveLink})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchLiveLinks();
            }, () => {
            }).then(() => {
            setLiveLinksSaving(false);
        })
    }, [fetchLiveLinks, liveLinks])

    const onLiveLinkAdd = () => {
        liveLinks.push({
            id: uuid(),
            hash: generateHash(),
            ipRanges: [],
            presetId: presets[0]?.id || null,
            refreshInterval: 300,
            isNew: true
        })
        setLiveLinks(liveLinks.slice())
    }

    const fetchPresets = useCallback((selectDefault = false) => {
        setPresetsLoading(true);
        Api.fetch({
            endpoint: 'presets'
        }).then((res) => {
            setFilterPresets(res.response.filter((preset) => preset.type === 'ticket'));
            if (selectDefault) {
                const ownDefault = res.response.find((preset) => preset.type === 'ticket' && preset.isDefault && !preset.isGlobal);
                if (ownDefault) {
                    applyPreset(ownDefault);
                    return
                }
                const globalDefault = res.response.find((preset) => preset.type === 'ticket' && preset.isDefault && preset.isGlobal);
                if (globalDefault) {
                    applyPreset(globalDefault);
                }
            }
        }, () => {
        }).then(() => {
            setPresetsLoading(false);
        });
    }, [setPresetsLoading, setFilterPresets]);

    useEffect(() => {
        fetchPresets(true);
    }, [fetchPresets]);

    const deletePreset = () => {
        setFilterPresetDeleting(true);
        Api.fetch({
            method: 'DELETE',
            endpoint: 'presets/' + deleteFilterPresetId,
        }).then(() => {
            setDeleteFilterPresetId(null);
            fetchPresets()
        }, (e) => console.log(e)).then(() => {
            setFilterPresetDeleting(false);
        });
    }

    const savePreset = useCallback(() => {
        setPresetsSaving(true);
        Api.fetch({
            endpoint: 'presets',
            method: 'POST',
            body: {
                name: newPresetData.name,
                type: 'ticket',
                data: {
                    filters: filters,
                    columns: columns,
                    perPage: perPage,
                    order: order,
                    orderColumn: orderColumn,
                },
                isDefault: newPresetData.isDefault,
                isGlobal: newPresetData.isGlobal
            }
        }).then(() => {
            setNewPresetData(null);
            fetchPresets();
        }, () => {
        }).then(() => setPresetsSaving(false));
    }, [setNewPresetData, filters, newPresetData, fetchPresets, columns, order, orderColumn, perPage])

    const fetchCustomers = useCallback((customerSearchString) => {
        setCustomersLoading(true);
        Api.fetch({
            endpoint: 'customers',
            parameter: {
                page: 1,
                size: 100,
                sort: `score_desc`,
                filter: {
                    query: customerSearchString
                }
            }
        }).then((res) => {
            const cList = {};
            res.response.forEach((c) => {
                cList[c.id] = c;
            });
            setSearchCustomers(cList);
        }, () => {
        }).then(() => {
            setCustomersLoading(false);
        });
    }, [setSearchCustomers, setCustomersLoading]);

    useEffect(() => {
        if (!customersOpen) {
            return undefined;
        }

        fetchCustomers('');
    }, [customersOpen, fetchCustomers]);

    useEffect(() => {
        fetchCustomers(customerSearchString);
    }, [fetchCustomers, customerSearchString]);

    const hasTourReadPermission = hasPermission(user, ['tours.read']);
    const fetchTickets = useCallback(() => {
        const fixedFilters = {};
        if (addressId) {
            fixedFilters.addresses = 'in<-->' + addressId;
        }
        if (customerId) {
            fixedFilters.customers = 'in<-->' + customerId;
        }
        if (deviceId) {
            fixedFilters.devices = 'in<-->' + deviceId;
        }

        setFetching(true);
        const filter = {
            query: searchString,
            ...fixedFilters
        };

        if (filters.tags?.length > 0) {
            filter.tags = 'in<-->' + filters.tags.join(',');
        }

        if (filters.categories?.length > 0) {
            filter.categories = 'in<-->' + filters.categories.join(',');
        }

        if (!fixedFilters.customers && filters.customers?.length > 0) {
            filter.customers = 'in<-->' + filters.customers.join(',');
        }

        if (filters.status?.length > 0) {
            filter.status = 'in<-->' + filters.status.join(',');
        }

        if (filters.userLinks?.length > 0) {
            filter.acceptedUserLinks = 'in<-->' + filters.userLinks.map((u) => u === 'me' ? currentUserId : u).join(',');
        }

        if (filters.importance != null) {
            filter.importance = 'between<-->' + filters.importance.from + ',' + filters.importance.to;
        }

        if (filters.createdAt?.from && filters.createdAt?.to) {
            filter.createdAt = 'between<-->' + moment(filters.createdAt.from).toISOString() + ',' + moment(filters.createdAt.to).toISOString();
        }

        if (filters.completedAt?.from && filters.completedAt?.to) {
            filter.completedAt = 'between<-->' + moment(filters.completedAt.from).toISOString() + ',' + moment(filters.completedAt.to).toISOString();
        }

        if (filters.updatedAt?.from && filters.updatedAt?.to) {
            filter.updatedAt = 'between<-->' + moment(filters.updatedAt.from).toISOString() + ',' + moment(filters.updatedAt.to).toISOString();
        }

        if (!fixedFilters.devices && filters.tours?.length > 0 && hasTourReadPermission) {
            const tourDeviceIds = filters.tours.map((tourId) => {
                if (tourId === 'me') {
                    const userTourIds = toursList.filter((tour) => tour.userIds.includes(currentUserId)).map((tour) => tour.id)
                    const userDeviceIds = [];
                    userTourIds.forEach((tourId) => {
                        userDeviceIds.push(...devicesByTour[tourId])
                    });
                    return userDeviceIds;
                }
                return devicesByTour[tourId] || []
            });

            const uniqTourDeviceIds = uniq(tourDeviceIds.flat());
            if (uniqTourDeviceIds.length <= 0) {
                setTotal(0);
                setTickets([]);
                setFetching(false);
                return;
            }

            filter.devices = 'in<-->' + uniqTourDeviceIds.join(',');
        }

        Api.fetch({
            endpoint: 'tickets',
            parameter: {
                page: page + 1,
                size: perPage,
                sort: `${orderColumn}_${order}`,
                filter: filter
            }
        }).then((res) => {
            setTotal(parseInt(res.headers['x-total-count'] || "0", 10));
            setTickets(res.response.filter((t) => !t.deletedAt))
        }, () => {
        }).then(() => {
            setFetching(false);
        })
    }, [customerId, addressId, deviceId, page, perPage, order, orderColumn, setFetching, setTickets, searchString, devicesByTour, toursList, filters, currentUserId, hasTourReadPermission]);

    const fetchData = useCallback(() => {
        fetchTickets();
        fetchPresets();
        dispatch(fetchUserList())
        dispatch(fetchTagList())
        dispatch(fetchCategoryList())
        dispatch(fetchStatusList())
        dispatch(fetchDeviceTypes())
        if (hasTourReadPermission) {
            dispatch(fetchToursList());
        }
    }, [fetchTickets, fetchPresets, dispatch, hasTourReadPermission]);

    useEffect(() => {
        if (!hasDisplayInTicketsTag && !activeColumns.includes('addresses') && !activeColumns.includes('customer')) {
            return;
        }

        const customerIds = uniq(tickets.map(ticket => ticket.customerId)).sort();
        if (customerIds.filter(c => !Object.keys(customers).includes(c)).length <= 0) {
            return;
        }

        const loadedCustomers = {};
        Api.fetch({
            endpoint: "customers",
            parameter: {
                filter: {
                    ids: 'in<-->' + customerIds.join(",")
                }
            }
        }).then(response => {
            response.response.forEach(customer => {
                loadedCustomers[customer.id] = customer;
            });
            customerIds.forEach((customerId) => {
                if (!loadedCustomers[customerId]) {
                    loadedCustomers[customerId] = null
                }
            })
            setCustomers(loadedCustomers);
        });
    }, [customers, tickets, activeColumns, hasDisplayInTicketsTag])

    useEffect(() => {
        if (!hasDisplayInTicketsTag && !activeColumns.includes('devices') && !activeColumns.includes('locations')) {
            return;
        }

        const deviceIds = uniq(tickets.map(ticket => ticket.deviceIds).flat()).sort();
        if (deviceIds.filter(d => !Object.keys(loadedDevices).includes(d)).length <= 0) {
            return;
        }

        const internalLoadedDevices = {};
        Api.fetch({
            endpoint: "devices",
            parameter: {
                filter: {
                    ids: 'in<-->' + deviceIds.join(",")
                }
            }
        }).then(response => {
            response.response.forEach(device => {
                internalLoadedDevices[device.id] = device;
            });
            deviceIds.forEach((deviceId) => {
                if (!internalLoadedDevices[deviceId]) {
                    internalLoadedDevices[deviceId] = null
                }
            })
            setLoadedDevices(internalLoadedDevices);
        });

    }, [loadedDevices, tickets, activeColumns, hasDisplayInTicketsTag])


    useEffect(() => {
        fetchData()
    }, [fetchData])

    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const globalIsFetching = useSelector((state) => state.tags.isFetching || state.statusList.isFetching || state.categories.isFetching || state.userList.isFetching || state.deviceTypes.isFetching || state.tours.isFetching)
    const loading = fetching || globalIsFetching
    const ticketList = tickets.map((ticket) => {
        const customer = customers[ticket.customerId];
        const category = categories.find(c => c.id === ticket.categoryId);
        const status = statusList.find(c => c.id === ticket.statusId);

        const ticketDevices = ticket.deviceIds.map((deviceId) => {
            return loadedDevices[deviceId] || null
        }).filter(Boolean);

        const ticketLocations = uniq(ticketDevices.map((device) => {
            return device.locationId
        })).map((l) => {
            return customer?.addresses.find((a) => a.locations.find((al) => al.id === l))?.locations.find((al) => al.id === l)
        }).filter(Boolean);


        let backgroundColor = 'inherit';
        let hoverColor = 'rgba(0, 0, 0, 0.04)';
        if (status?.type === 'open') {
            if (ticket.importanceId >= 8) {
                backgroundColor = getBackgroundColor(theme.palette.error.light, 0.5)
                hoverColor = getBackgroundColor(theme.palette.error.light, 0.3)
            } else if (ticket.importanceId >= 5) {
                backgroundColor = getBackgroundColor(theme.palette.warning.light, 0.5)
                hoverColor = getBackgroundColor(theme.palette.warning.light, 0.3)
            }
        }

        const addressesArray = ticket.addressIds.filter((addressId) => Boolean(customer?.addresses.find((a) => a.id === addressId))).map((addressId) => {
            return formatAddress(customer?.addresses.find((a) => a.id === addressId))
        })

        let addresses = '-'
        if (addressesArray.length === 1) {
            addresses = addressesArray[0]
        } else if (addressesArray.length > 1) {
            addresses = <Box component='ul' sx={{margin: 0, padding: 'inherit'}}>{addressesArray.map((a) => {
                return <li>{a}</li>
            })}</Box>
        }

        const filteredTags = ticket.tags?.filter((tag) => tagList.find((t) => t.id === tag)) || [];

        customer?.tags?.forEach((tagId) => {
            if (filteredTags.find((t) => t.id === tagId)) {
                return
            }

            const found = tagList.find((t) => t.id === tagId)
            if (found && found.displayInTickets) {
                filteredTags.push(found.id)
            }
        });

        ticketDevices.forEach((device) => {
            device.tags?.forEach((tagId) => {
                if (filteredTags.find((t) => t.id === tagId)) {
                    return
                }

                const found = tagList.find((t) => t.id === tagId)
                if (found && found.displayInTickets) {
                    filteredTags.push(found.id)
                }
            });
        });

        let devicesWithTypes = '-'
        if (ticketDevices.length === 1) {
            const type = deviceTypes.find((t) => t.id === ticketDevices[0].typeId)
            devicesWithTypes = ticketDevices[0].number;
            if (type) {
                devicesWithTypes += ' (' + type.name + ')'
            }
        } else if (ticketDevices.length > 1) {
            devicesWithTypes = <Box component='ul' sx={{margin: 0, padding: 'inherit'}}>{ticketDevices.map((d) => {
                const type = deviceTypes.find((t) => t.id === d.typeId)
                let deviceWithTypes = d.number;
                if (type) {
                    deviceWithTypes += ' (' + type.name + ')'
                }
                return <li>{deviceWithTypes}</li>
            })}</Box>
        }

        let locations = '-'
        if (ticketLocations.length === 1) {
            locations = ticketLocations[0].name
        } else if (ticketLocations.length > 1) {
            locations = <Box component='ul' sx={{margin: 0, padding: 'inherit'}}>{ticketLocations.map((l) => {
                return <li>{l.name}</li>
            })}</Box>
        }

        const tableColumns = activeColumns.map((column) => {
            switch (column) {
                case 'number':
                    return <TableCell key={column}>#{ticket.number}</TableCell>
                case 'customer':
                    return <TableCell key={column}>{customer?.name || <i>{t('deleted')}</i>}</TableCell>
                case 'addresses':
                    return <TableCell key={column}>{addresses}</TableCell>
                case 'devices':
                    return <TableCell key={column}>{devicesWithTypes}</TableCell>
                case 'locations':
                    return <TableCell key={column}>{locations}</TableCell>
                case 'categories':
                    return <TableCell key={column}>{category?.name || <i>{t('deleted')}</i>}</TableCell>
                case 'title':
                    return <TableCell key={column}>{ticket.title}</TableCell>
                case 'status':
                    return <TableCell key={column}>{status?.name || <i>{t('deleted')}</i>}</TableCell>
                case 'importance':
                    return <TableCell key={column}>{ticket.importanceId}</TableCell>
                case 'tours':
                    const existingTours = toursList.filter((tour) => {
                        return ticket.deviceIds.filter((deviceId) => {
                            return devicesByTour[tour.id]?.includes(deviceId)
                        }).length > 0
                    })
                    return <TableCell key={column}>{existingTours.length > 0 ? existingTours.map((tour) => {
                        return <Chip label={tour?.name} sx={{mr: 1}}/>
                    }) : '-'}</TableCell>
                case 'tags':
                    return <TableCell key={column}>
                        {filteredTags.length ? filteredTags.map((tag, i) => {
                            const tagDefinition = tagList.find((t) => t.id === tag);
                            return <Chip key={i} label={tagDefinition?.name}
                                         sx={{mr: 1, backgroundColor: tagDefinition?.color || '#000', color: 'white'}}/>
                        }) : '-'}
                    </TableCell>
                case 'createdAt':
                    return <TableCell key={column}>{moment(ticket.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                case 'updatedAt':
                    return <TableCell key={column}>{moment(ticket.updatedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                case 'completedAt':
                    return <TableCell
                        key={column}>{ticket.completedAt ? moment(ticket.completedAt).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>
                default:
                    return <TableCell key={column}/>
            }
        })

        return (
            <TableRow component={StyledLink} key={ticket.id} hover
                      sx={{backgroundColor: backgroundColor, "&:hover": {backgroundColor: hoverColor + ' !important'}}}
                      to={'/tickets/show/' + ticket.id}>
                {tableColumns}
            </TableRow>
        )
    });

    const notFoundMessage = <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
        <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('tickets.list.noTicketFound')}</Typography>
            <CheckPermissions list={['tickets.write']}>
                <Link variant='body2'
                      onClick={() => dispatch(push('/tickets/new' + createSuffix))}>{t('tickets.list.createTicket')}</Link>
            </CheckPermissions>
        </Box>
    </Box>

    const notViewFoundMessage = <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
        <Box sx={{color: theme.palette.text.secondary, mt: 1}} textAlign='center'>
            <Typography variant='body2'>{t('tickets.views.noViewFound')}</Typography>
            <Link variant='body2' onClick={() => setNewPresetData({
                name: '',
                type: 'ticket',
                isGlobal: false,
                isDefault: false
            })}>{t('tickets.views.create')}</Link>
        </Box>
    </Box>

    const showFilterToolbar = (
        filters.customers?.length > 0 ||
        filters.tags?.length > 0 ||
        filters.status?.length > 0 ||
        filters.categories?.length > 0 ||
        filters.userLinks?.length > 0 ||
        filters.importance != null ||
        filters.tours?.length > 0 ||
        (filters.createdAt?.from && filters.createdAt?.to) ||
        (filters.updatedAt?.from && filters.updatedAt?.to) ||
        (filters.completedAt?.from && filters.completedAt?.to)
    );

    const allFilterCustomers = Object.assign(searchCustomers, filterCacheCustomers)

    const renderFilterPresetRow = (p) => {
        return <ListItemButton
            key={p.id}
            selected={p.id === currentPresetId}
            onClick={() => {
                applyPreset(p)
            }}
            onMouseEnter={() => setHoveredPreset(p.id)}
            onMouseLeave={() => hoveredPreset === p.id && setHoveredPreset(null)}>
            <ListItemIcon>
                {(p.isDefault && p.id !== hoveredPreset) && <IconButton disabled><Star fontSize='small'/></IconButton>}
                {(!p.isDefault && p.id === hoveredPreset) && <IconButton
                    disabled={presetsSaving}
                    onClick={(e) => {
                        e.stopPropagation()
                        p.isDefault = true;
                        Api.fetch({
                            method: 'PUT',
                            endpoint: 'presets/' + p.id,
                            body: p
                        }).then(() => fetchPresets(), (e) => console.log(e));
                    }}
                ><StarBorder fontSize='small'/></IconButton>}
                {(p.isDefault && p.id === hoveredPreset) && <IconButton
                    disabled={presetsSaving}
                    onClick={(e) => {
                        e.stopPropagation()
                        p.isDefault = false;
                        Api.fetch({
                            method: 'PUT',
                            endpoint: 'presets/' + p.id,
                            body: p
                        }).then(() => fetchPresets(), (e) => console.log(e));
                    }}
                ><Star fontSize='small'/></IconButton>}
                {!p.isDefault && p.id !== hoveredPreset && <Box sx={{height: '36px'}}/>}
            </ListItemIcon>
            <ListItemText primary={p.name}/>
            {p.id === hoveredPreset && <Tooltip title={t('tickets.views.saveCurrentTooltip')}>
                <span>
                    <IconButton
                        disabled={presetsSaving}
                        sx={{mr: 1}}
                        onClick={(e) => {
                            e.stopPropagation()
                            setPresetsSaving(true);
                            p.data = {
                                filters: filters,
                                columns: columns,
                                perPage: perPage,
                                order: order,
                                orderColumn: orderColumn
                            };
                            Api.fetch({
                                method: 'PUT',
                                endpoint: 'presets/' + p.id,
                                body: p
                            }).then(() => fetchPresets(), (e) => console.log(e)).then(() => setPresetsSaving(false));
                        }}
                    ><Save fontSize='small'/></IconButton>
                </span>
            </Tooltip>}
            {p.id === hoveredPreset && <IconButton
                disabled={presetsSaving}
                onClick={(e) => {
                    e.stopPropagation()
                    setDeleteFilterPresetId(p.id)
                }}
            ><Delete fontSize='small'/></IconButton>}
        </ListItemButton>
    }

    const globalViews = filterPresets.filter(p => p.isGlobal).map(p => {
        return renderFilterPresetRow(p)
    })

    const userViews = filterPresets.filter(p => !p.isGlobal).map(p => {
        return renderFilterPresetRow(p)

    })

    const tableHeader = activeColumns.map((column) => {
        switch (column) {
            case 'number':
                return <TableCell key={column} sortDirection={orderColumn === 'number' ? order : false}
                                  sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'number'}
                        direction={order}
                        onClick={() => changeSort('number')}
                    >
                        {t('tickets.fields.number')}
                    </TableSortLabel>
                </TableCell>
            case 'customer':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.customer')}</TableCell>
            case 'addresses':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.addresses')}</TableCell>
            case 'devices':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.devices')}</TableCell>
            case 'locations':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.locations')}</TableCell>
            case 'categories':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.category')}</TableCell>
            case 'title':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.title')}</TableCell>
            case 'status':
                return <TableCell
                    key={column}
                    sortDirection={orderColumn === 'statusPriority' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'statusPriority'}
                        direction={order}
                        onClick={() => changeSort('statusPriority')}
                    >
                        {t('tickets.fields.status')}
                    </TableSortLabel>
                </TableCell>
            case 'importance':
                return <TableCell
                    key={column}
                    sortDirection={orderColumn === 'importanceId' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'importanceId'}
                        direction={order}
                        onClick={() => changeSort('importanceId')}
                    >
                        {t('tickets.fields.importance')}
                    </TableSortLabel>
                </TableCell>
            case 'tags':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.tags')}</TableCell>
            case 'tours':
                return <TableCell key={column} sx={{fontWeight: 'bold'}}>{t('tickets.fields.tours')}</TableCell>
            case 'createdAt':
                return <TableCell
                    key={column}
                    sortDirection={orderColumn === 'createdAt' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'createdAt'}
                        direction={order}
                        onClick={() => changeSort('createdAt')}
                    >
                        {t('tickets.fields.createdAt')}
                    </TableSortLabel>
                </TableCell>
            case 'updatedAt':
                return <TableCell
                    key={column}
                    sortDirection={orderColumn === 'updatedAt' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'updatedAt'}
                        direction={order}
                        onClick={() => changeSort('updatedAt')}
                    >
                        {t('tickets.fields.updatedAt')}
                    </TableSortLabel>
                </TableCell>
            case 'completedAt':
                return <TableCell
                    key={column}
                    sortDirection={orderColumn === 'completedAt' ? order : false}
                    sx={{fontWeight: 'bold'}}>
                    <TableSortLabel
                        active={orderColumn === 'completedAt'}
                        direction={order}
                        onClick={() => changeSort('completedAt')}
                    >
                        {t('tickets.fields.completedAt')}
                    </TableSortLabel>
                </TableCell>
            default:
                return <TableCell key={column}/>
        }
    })

    const bookmarkList = <Paper variant={paperView ? 'outlined' : 'elevation'}
                                sx={{
                                    maxHeight: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
        <Toolbar variant='dense' disableGutters={true} sx={{pl: 2, pr: 1, pt: 1, pb: 0.5}}>
            <Typography variant='h6'>
                {t('tickets.views.label')}
            </Typography>
            <Box flexGrow={1}/>
            <IconButton
                disabled={presetsSaving || presetsLoading}
                onClick={() => setNewPresetData({
                    name: '',
                    type: 'ticket',
                    isGlobal: false,
                    isDefault: false
                })}><Add/></IconButton>
        </Toolbar>
        <Box sx={{
            px: 2,
            overflowY: 'auto',
            flexGrow: 1
        }}>
            <List subheader={t('tickets.views.global')} dense>
                {!presetsLoading && <React.Fragment>
                    {globalViews.length ? globalViews : notViewFoundMessage}
                </React.Fragment>}
                {presetsLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>}
            </List>
            <List subheader={t('tickets.views.personal')} dense>
                {!presetsLoading && <React.Fragment>
                    {userViews.length ? userViews : notViewFoundMessage}
                </React.Fragment>}
                {presetsLoading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                </Box>}
            </List>
        </Box>
        {actionBar && <>
            <Divider variant='middle'/>
            <Toolbar disableGutters={true} variant='dense'
                     sx={{px: 2, py: 1}}>
                <Tooltip title={t('tickets.list.showTrash')}>
                                <span>
                                      <IconButton disabled>
                                    <RestoreFromTrash/>
                                </IconButton>
                                </span>
                </Tooltip>
                <CheckPermissions list={['liveLinks.read']}>
                    <Tooltip title={t('tickets.list.showLiveLinks')}>
                        <IconButton disabled={presetsLoading} onClick={() => setLiveLinkPanelOpen(true)}>
                            <LinkIcon/>
                        </IconButton>
                    </Tooltip>
                </CheckPermissions>
                <CheckPermissions list={['recurring.read']}>
                    <Tooltip title={t('tickets.recurring.label')}>
                        <IconButton onClick={() => setRecurringTicketsPanelOpen(true)}>
                            <EventRepeat/>
                        </IconButton>
                    </Tooltip>
                </CheckPermissions>
            </Toolbar>
        </>}
    </Paper>

    const content = <React.Fragment>
        <Box sx={{
            px: 2,
            overflowX: 'auto', width: '100%', height: '100%', boxSizing: 'border-box'
        }}>
            <Grid container spacing={2} sx={{height: '100%'}}>
                <Grid item xs={12} sm={12} md={horizontalDisplay ? 12 : 3} lg={horizontalDisplay ? 12 : 2}
                      sx={{height: {md: '100%', xs: 'unset'}}}>
                    {horizontalDisplay ? <ForcedStyledBookmarkList>
                        {bookmarkList}
                    </ForcedStyledBookmarkList> : <StyledBookmarkList>
                        {bookmarkList}
                    </StyledBookmarkList>}
                </Grid>
                <Grid item xs={12} sm={12} md={horizontalDisplay ? 12 : 9} lg={horizontalDisplay ? 12 : 10}
                      sx={{height: {md: '100%', xs: 'unset'}}}>
                    <Paper sx={{p: 2, height: {md: '100%', xs: 'unset'}, display: 'flex', flexDirection: 'column'}}
                           variant={paperView ? 'outlined' : 'elevation'}>
                        <Toolbar disableGutters sx={{mb: showFilterToolbar ? 1 : 2, flexGrow: 0}}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setSearchString(tempSearchString);
                                setPage(0);
                            }}>
                                <TextField
                                    autoFocus
                                    sx={{mr: 2}}
                                    value={tempSearchString}
                                    label={t('tickets.list.search')}
                                    placeholder={t('tickets.list.search')}
                                    margin='none'
                                    onChange={(e) => setTempSearchString(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" color="primary"
                                                            onClick={() => setSearchString(tempSearchString) && setPage(0)}>
                                                    <Search/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </form>
                            <Button sx={{mr: 2}} startIcon={<FilterAlt/>}
                                    onClick={() => {
                                        const clonedFilters = cloneDeep(filters)
                                        const dateFilters = ['createdAt', 'updatedAt', 'completedAt'];
                                        dateFilters.forEach((key) => {
                                            if (clonedFilters[key]?.from) {
                                                clonedFilters[key].from = moment(clonedFilters[key].from)
                                            }
                                            if (clonedFilters[key]?.to) {
                                                clonedFilters[key].to = moment(clonedFilters[key].to)
                                            }
                                        })
                                        setEditFilterData(clonedFilters)
                                    }}>{t('tickets.list.filter')}</Button>
                            <CheckPermissions list={['tickets.write']}>
                                <Button startIcon={<Add/>} component={RouterLink}
                                        to={'/tickets/new' + createSuffix}>{t('tickets.list.createTicket')}</Button>
                            </CheckPermissions>
                            <Box flexGrow={1}/>
                            <Tooltip title={t('tickets.list.editColumns')}>
                                <IconButton
                                    sx={{ml: 1}} size='small'
                                    onClick={() => setEditColumnsData(columns)}
                                ><ViewColumn/></IconButton>
                            </Tooltip>
                            <Tooltip title={t('reload')}>
                                <IconButton sx={{ml: 1}} size='small' onClick={fetchData}><Replay/></IconButton>
                            </Tooltip>
                        </Toolbar>
                        {showFilterToolbar &&
                            <Toolbar disableGutters variant='dense' sx={{mb: 0, display: 'block', flexGrow: 0}}>
                                {filters.customers?.length > 0 &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.customers = [];
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.customers')}: {filters.customers?.filter(cId => !!filterCacheCustomers[cId]).map(cId => filterCacheCustomers[cId].number + ': ' + filterCacheCustomers[cId].name).join(', ')}</React.Fragment>}/>}
                                {filters.tags?.length > 0 &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.tags = [];
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.tags')}: {filters.tags?.filter(t => tagList.find(tag => tag.id === t)).map(t => tagList.find(t2 => t2.id === t)?.name).join(', ')}</React.Fragment>}/>}
                                {filters.categories?.length > 0 &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.categories = [];
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.categories')}: {filters.categories?.filter(t => categories.find(tag => tag.id === t)).map(t => categories.find(t2 => t2.id === t)?.name).join(', ')}</React.Fragment>}/>}
                                {filters.userLinks?.length > 0 &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.userLinks = [];
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.userLinks')}: {filters.userLinks?.filter(user => user === 'me' || userList.find(u => u.id === user)).map(user => {
                                            if (user === 'me') return t('tickets.filters.me');
                                            const userObject = userList.find(u => u.id === user)
                                            return userObject?.givenName + ' ' + userObject?.familyName
                                        }).join(', ')}</React.Fragment>}/>}
                                {filters.tours?.length > 0 &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.tours = [];
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.tours')}: {filters.tours.filter((tourId) => tourId === 'me' || toursList.find(u => u.id === tourId)).map(tourId => {
                                            if (tourId === 'me') return t('tickets.filters.myTours');
                                            const tour = toursList.find(t => t.id === tourId)
                                            return tour.name
                                        }).join(', ')}</React.Fragment>}/>}
                                {filters.status?.length > 0 &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.status = [];
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.status')}: {filters.status?.filter(status => statusList.find(s => s.id === status)).map(status => statusList.find(s => s.id === status)?.name).join(', ')}</React.Fragment>}/>}
                                {filters.importance != null &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.importance = null;
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.importance')}: {t('tickets.filters.between', {
                                            from: filters.importance?.from,
                                            to: filters.importance?.to
                                        })} </React.Fragment>}/>}
                                {(filters.createdAt?.from && filters.createdAt?.to) != null &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.createdAt = null;
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.createdAt')}: {t('tickets.filters.between', {
                                            from: moment(filters.createdAt?.from).format('DD.MM.YYYY'),
                                            to: moment(filters.createdAt?.to).format('DD.MM.YYYY')
                                        })} </React.Fragment>}/>}
                                {(filters.updatedAt?.from && filters.updatedAt?.to) != null &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.updatedAt = null;
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.updatedAt')}: {t('tickets.filters.between', {
                                            from: moment(filters.updatedAt?.from).format('DD.MM.YYYY'),
                                            to: moment(filters.updatedAt?.to).format('DD.MM.YYYY')
                                        })} </React.Fragment>}/>}
                                {(filters.completedAt?.from && filters.completedAt?.to) &&
                                    <Chip sx={{mr: 1}} onClick={() => setEditFilterData(filters)}
                                          onDelete={() => {
                                              setCurrentPresetId(null)
                                              setFilters((f) => {
                                                  f.completedAt = null;
                                                  return cloneDeep(f);
                                              })
                                          }} label={
                                        <React.Fragment>{t('tickets.fields.completedAt')}: {t('tickets.filters.between', {
                                            from: moment(filters.completedAt?.from).format('DD.MM.YYYY'),
                                            to: moment(filters.completedAt?.to).format('DD.MM.YYYY')
                                        })} </React.Fragment>}/>}
                            </Toolbar>}
                        <Paper variant='outlined' sx={tablePaperStyle || {flexGrow: 1, minHeight: 0}}>
                            <TableContainer sx={{height: 'calc(100% - 52px)'}}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader}
                                        </TableRow>
                                    </TableHead>
                                    {!loading && <TableBody>
                                        {ticketList}
                                    </TableBody>}
                                </Table>
                                {loading && <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                                    <CircularProgress sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                                </Box>}
                                {total <= 0 && !loading && notFoundMessage}
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                component="div"
                                count={total}
                                rowsPerPage={perPage}
                                page={page}
                                onPageChange={(e, page) => setPage(page)}
                                onRowsPerPageChange={(e) => {
                                    setPerPage(parseInt(e.target.value, 10))
                                    setPage(0)
                                }}
                            />
                        </Paper>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Dialog maxWidth='sm' fullWidth open={Boolean(editFilterData)} onClose={() => setEditFilterData(null)}>
            <DialogTitle>
                {t('tickets.list.filter')}
                <IconButton
                    aria-label="close"
                    onClick={() => setEditFilterData(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {editFilterData && <DialogContent>
                {(!customerId && !deviceId && !addressId) && <Autocomplete
                    sx={{pt: 1, mb: 0.5}}
                    margin='dense'
                    multiple
                    options={Object.keys(allFilterCustomers)}
                    isOptionEqualToValue={(option, value) => option === value}
                    onInputChange={(e, value) => setCustomerSearchString(value)}
                    onOpen={() => setCustomersOpen(true)}
                    onClose={() => setCustomersOpen(false)}
                    getOptionLabel={(option) => allFilterCustomers[option].number + ': ' + allFilterCustomers[option].name}
                    filterOptions={(o) => o}
                    onChange={(e, value) => {
                        const cachedCustomers = cloneDeep(filterCacheCustomers);
                        value.forEach((c) => {
                            if (!cachedCustomers[c]) {
                                cachedCustomers[c] = allFilterCustomers[c];
                            }
                        })

                        Object.keys(cachedCustomers).forEach((k) => {
                            if (!value.includes(k)) {
                                delete cachedCustomers[k];
                            }
                        })
                        setFilterCacheCustomers(cachedCustomers)
                        handleFilterChange('customers', value)
                    }}
                    value={editFilterData.customers}
                    filterSelectedOptions
                    loading={customersLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('tickets.fields.customers')}
                            placeholder={t('tickets.fields.customers')}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {customersLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />}
                <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('tickets.fields.categories')}</InputLabel>
                    <Select
                        value={editFilterData.categories}
                        onChange={(e) => handleFilterChange('categories', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                        multiple
                        renderValue={
                            (selected) => selected.map((c) => categories.find(r => r.id === c)?.name || 'deleted').join(', ')
                        }
                        input={<OutlinedInput label={t('tickets.fields.categories')}/>}
                    >
                        {categories.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <Checkbox checked={editFilterData.categories.includes(r.id)}/>
                                <ListItemText primary={r.name} secondary={r.description}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('tickets.fields.status')}</InputLabel>
                    <Select
                        value={editFilterData.status}
                        onChange={(e) => handleFilterChange('status', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                        multiple
                        renderValue={
                            (selected) => selected.map((c) => statusList.find(r => r.id === c)?.name || 'deleted').join(', ')
                        }
                        input={<OutlinedInput label={t('tickets.fields.status')}/>}
                    >
                        {statusList.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <Checkbox checked={editFilterData.status.includes(r.id)}/>
                                <ListItemText primary={r.name} secondary={r.description}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('tickets.fields.userLinks')}</InputLabel>
                    <Select
                        value={editFilterData.userLinks}
                        onChange={(e) => handleFilterChange('userLinks', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                        multiple
                        renderValue={
                            (selected) => selected.map((c) => {
                                if (c === 'me') {
                                    return t('tickets.filters.me')
                                }
                                const user = userList.find(r => r.id === c);
                                return user ? `${user.givenName} ${user.familyName}` : 'deleted';
                            }).join(', ')
                        }
                        input={<OutlinedInput label={t('tickets.fields.userLinks')}/>}
                    >
                        <MenuItem key='me' value='me'>
                            <Checkbox checked={editFilterData.userLinks.includes('me')}/>
                            <ListItemText primary={t('tickets.filters.me')}/>
                        </MenuItem>
                        {userList.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <Checkbox checked={editFilterData.userLinks.includes(r.id)}/>
                                <ListItemText primary={r.givenName + ' ' + r.familyName}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {!deviceId && <FormControl fullWidth margin='dense'>
                    <InputLabel>{t('tickets.fields.tours')}</InputLabel>
                    <Select
                        value={editFilterData.tours}
                        onChange={(e) => handleFilterChange('tours', (typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value) || [])}
                        multiple
                        renderValue={
                            (selected) => selected.map((tourId) => {
                                if (tourId === 'me') {
                                    return t('tickets.filters.myTours')
                                }
                                const tour = toursList.find(t => t.id === tourId);
                                return tour ? tour.name : 'deleted';
                            }).join(', ')
                        }
                        input={<OutlinedInput label={t('tickets.fields.tours')}/>}
                    >
                        <MenuItem key='me' value='me'>
                            <Checkbox checked={editFilterData.tours.includes('me')}/>
                            <ListItemText primary={t('tickets.filters.myTours')}/>
                        </MenuItem>
                        {toursList.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                                <Checkbox checked={editFilterData.tours.includes(t.id)}/>
                                <ListItemText primary={t.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
                <ImportanceSlider
                    label={t('tickets.fields.importance')}
                    value={editFilterData.importance ? [editFilterData.importance.from, editFilterData.importance.to] : [0, 10]}
                    onChange={(e, v) => handleFilterChange('importance', (v[0] === 0 && v[1] === 10) ? null : {
                        from: v[0],
                        to: v[1]
                    })}
                />
                <TagSelect
                    sx={{my: 1}}
                    multiple
                    margin='normal'
                    tags={tagList}
                    label={t('tickets.fields.tags')} value={editFilterData.tags}
                    onChange={(v) => handleFilterChange('tags', v || [])}/>
                <DateRangePicker value={editFilterData.createdAt} onChange={(v => handleFilterChange('createdAt', v))}
                                 labelFrom={t('tickets.fields.createdAtFrom')}
                                 labelTo={t('tickets.fields.createdAtTo')}/>
                <DateRangePicker value={editFilterData.updatedAt} onChange={(v => handleFilterChange('updatedAt', v))}
                                 labelFrom={t('tickets.fields.updatedAtFrom')}
                                 labelTo={t('tickets.fields.updatedAtTo')}/>
                <DateRangePicker value={editFilterData.completedAt}
                                 onChange={(v => handleFilterChange('completedAt', v))}
                                 labelFrom={t('tickets.fields.completedAtFrom')}
                                 labelTo={t('tickets.fields.completedAtTo')}/>
            </DialogContent>}
            <DialogActions>
                <Button
                    onClick={() => setEditFilterData(null)}
                >
                    {t('close')}
                </Button>
                <Button
                    onClick={applyFilter}
                    startIcon={<FilterAlt/>}
                    variant='contained'
                    color='primary'
                >
                    {t('applyFilter')}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth='sm' fullWidth open={Boolean(newPresetData)} onClose={() => setNewPresetData(null)}>
            <DialogTitle>
                {t('tickets.views.new')}
                <IconButton
                    aria-label="close"
                    onClick={() => setNewPresetData(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {newPresetData && <React.Fragment>
                    <Alert severity='info'>{t('tickets.views.description')}</Alert>
                    <TextField margin='dense' fullWidth label={t('tickets.views.name')} value={newPresetData.name}
                               onChange={(e) => setNewPresetData({...newPresetData, name: e.target.value})}/>
                    <FormControlLabel label={t('tickets.views.isGlobal')} control={<Switch
                        onChange={(e) => setNewPresetData({...newPresetData, isGlobal: e.target.checked})}/>}
                                      checked={newPresetData.isGlobal}/>
                    <FormControlLabel label={t('tickets.views.isDefault')} control={<Switch
                        onChange={(e) => setNewPresetData({...newPresetData, isDefault: e.target.checked})}/>}
                                      checked={newPresetData.isDefault}/>
                </React.Fragment>}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setNewPresetData(null)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    onClick={savePreset}
                    loadingPosition="start"
                    loading={presetsSaving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('tickets.views.save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <DeleteDialog onDelete={() => deletePreset()} isDeleting={filterPresetDeleting}
                      title={t('tickets.views.delete')}
                      handleClose={() => setDeleteFilterPresetId(null)}
                      description={t('tickets.views.deleteDescription', {name: filterPresets.find(p => p.id === deleteFilterPresetId)?.name})}
                      open={Boolean(deleteFilterPresetId)}
        />
        <Dialog maxWidth='md' fullWidth open={liveLinkPanelOpen} onClose={() => setLiveLinkPanelOpen(false)}>
            <DialogTitle>
                {t('tickets.liveLinks.label')}
                <IconButton
                    aria-label="close"
                    onClick={() => setLiveLinkPanelOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <Toolbar variant='dense' disableGutters={true} sx={{mx: 1}}>
                <CheckPermissions list={['liveLinks.write']}>
                    <Button sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onLiveLinkAdd} startIcon={<Add/>}
                            variant='outlined'
                            color='primary'>{t('tickets.liveLinks.addLink')}</Button>
                </CheckPermissions>
                <Box flexGrow={1}/>
                <IconButton onClick={fetchLiveLinks}><Replay/></IconButton>
            </Toolbar>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.liveLinks.hash')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.liveLinks.ipRanges')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.liveLinks.preset')}</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>{t('tickets.liveLinks.interval')}</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {liveLinks.filter((ll) => !ll.isDeleted).map((liveLink) => (
                            <LiveLinkRow key={liveLink.id} liveLink={liveLink}
                                         presets={filterPresets.filter(p => p.isGlobal)}
                                         generateHash={generateHash}
                                         onDelete={() => {
                                             setLiveLinks((liveLinks) => {
                                                 const i = liveLinks.findIndex((ll) => ll.id === liveLink.id);
                                                 const deleteLiveLink = liveLinks[i];

                                                 if (deleteLiveLink.isNew) {
                                                     liveLinks.splice(i, 1)
                                                 } else {
                                                     deleteLiveLink.isDeleted = true;
                                                     liveLinks[i] = deleteLiveLink;
                                                 }
                                                 return liveLinks.slice();
                                             })
                                         }}
                                         onChange={(changedLiveLink) => {
                                             setLiveLinks((liveLinks) => {
                                                 const i = liveLinks.findIndex((c) => c.id === changedLiveLink.id);
                                                 liveLinks[i] = changedLiveLink;
                                                 return liveLinks.slice();
                                             })
                                         }}
                            />
                        ))}
                    </TableBody>
                </Table>
                {liveLinks.filter((g) => !g.isDeleted).length === 0 &&
                    <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                        <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                            <Typography variant='body2'>{t('tickets.liveLinks.noLinkCreated')}</Typography>
                            <Link variant='body2' onClick={onLiveLinkAdd}>{t('tickets.liveLinks.addLink')}</Link>
                        </Box>
                    </Box>
                }
            </TableContainer>
            <DialogActions>
                <Button
                    disabled={liveLinksLoading}
                    onClick={() => setLiveLinkPanelOpen(null)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    disabled={liveLinksLoading}
                    loading={liveLinksSaving}
                    onClick={saveLiveLinks}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('tickets.liveLinks.save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth='xl' fullWidth open={recurringTicketsPanelOpen}
                onClose={() => setRecurringTicketsPanelOpen(false)}>
            <DialogTitle>
                {t('tickets.recurring.label')}
                <IconButton
                    aria-label="close"
                    onClick={() => setRecurringTicketsPanelOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <RecurringTicketList/>
            <DialogActions>
                <Button
                    onClick={() => setRecurringTicketsPanelOpen(false)}
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={Boolean(editColumnsData)} onClose={() => setEditColumnsData(null)}>
            <DialogTitle>
                {t('tickets.list.editColumns')}
                <IconButton
                    aria-label="close"
                    onClick={() => setRecurringTicketsPanelOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {Boolean(editColumnsData) && <List>
                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={({removedIndex, addedIndex}) => {
                    setEditColumnsData(items => arrayMoveImmutable(items, removedIndex, addedIndex));
                }}>
                    {editColumnsData.map((columnName) => (
                        <Draggable key={columnName}>
                            <ListItem>
                                <ListItemIcon>
                                    <Switch
                                        edge="start"
                                        onChange={() => {
                                            setEditColumnsData((checked) => {
                                                return checked.filter((c) => c !== columnName);
                                            });
                                        }}
                                        checked={true}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t('tickets.fields.' + columnName)}/>
                                <ListItemSecondaryAction>
                                    <ListItemIcon className="drag-handle">
                                        <DragHandle/>
                                    </ListItemIcon>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Draggable>
                    ))}
                    {availableColumns.filter((c) => !editColumnsData.includes(c)).map((columnName) => (
                        <ListItem>
                            <ListItemIcon>
                                <Switch
                                    edge="start"
                                    onChange={() => {
                                        setEditColumnsData((checked) => {
                                            return [...checked, columnName];
                                        });
                                    }}
                                    checked={false}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('tickets.fields.' + columnName)}/>
                        </ListItem>
                    ))}
                </Container>
            </List>}
            <DialogActions>
                <Button
                    onClick={() => setEditColumnsData(null)}
                >
                    {t('close')}
                </Button>
                <Button
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        setColumns(editColumnsData);
                        setEditColumnsData(null);
                    }}
                >
                    {t('tickets.list.saveColumns')}
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>

    if (paperView) {
        return <Paper sx={{p: 2, height: '100%'}} variant={variant || 'elevation'}>
            {content}
        </Paper>
    }

    return content;
}

export default TicketList;
