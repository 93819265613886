import React, { useState } from "react";
import { Checkbox, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const PermissionSet = (props) => {
    const {permission, selectedPermissions, setSelectedPermissions} = props;
    const [expanded, setExpanded] = useState(false);
    const {t} = useTranslation()

    const subPermissionList = permission.subPermissions.map((p) => p.key).flat();
    const filteredPermissions = selectedPermissions.filter((permission) => subPermissionList.includes(permission));

    const onSelect = (e) => {
        e.stopPropagation();

        setSelectedPermissions((p) => {
            const newList = p.slice()

            subPermissionList.forEach((subPermission) => {
                if (e.target.checked && !newList.includes(subPermission)) {
                    newList.push(subPermission)
                } else if (!e.target.checked && newList.includes(subPermission)) {
                    newList.splice(newList.indexOf(subPermission), 1)
                }
            })

            return newList;
        })
    }

    const onSubSelect = (value, checked) => {
        setSelectedPermissions((p) => {
            const newList = p.slice()

            if (checked && !newList.includes(value)) {
                newList.push(value)
            } else if (!checked && newList.includes(value)) {
                newList.splice(newList.indexOf(value), 1)
            }

            return newList;
        })
    }


    return <React.Fragment>
        <ListItemButton sx={{pl: 4}} onClick={() => setExpanded((v) => !v)}>
            <ListItemIcon>
                <Checkbox
                    onClick={onSelect}
                    checked={filteredPermissions.length === subPermissionList.length}
                    indeterminate={filteredPermissions.length > 0 && filteredPermissions.length < subPermissionList.length}/>
            </ListItemIcon>
            <ListItemText  sx={{flexGrow: 0}}>{t(permission.translationKey)}</ListItemText>
            <IconButton>{expanded ? <ExpandLess/> : <ExpandMore/>}</IconButton>
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense={true}>
                {permission.subPermissions.map((subPermission) => {
                    const selected = selectedPermissions.includes(subPermission.key)
                    return <ListItemButton key={subPermission.key} sx={{pl: 8}} onClick={() => onSubSelect(subPermission.key, !selected)}>
                        <ListItemIcon>
                            <Checkbox checked={selected} onClick={(e) => onSubSelect(subPermission.key, e.target.value)}/>
                        </ListItemIcon>
                        <ListItemText>{t(subPermission.translationKey)}</ListItemText>
                    </ListItemButton>
                })}
            </List>
        </Collapse>
    </React.Fragment>
}

export default PermissionSet;
