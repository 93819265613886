import {
    Alert,
    Box,
    Button,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { Add, Replay, Save } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import Row from "./Row";
import { LoadingButton } from "@mui/lab";
import {v1 as uuid} from "uuid";
import CheckPermissions from "../../../Utils/CheckPermissions";

const List = () => {
    const {t} = useTranslation()
    const theme = useTheme();

    const [properties, setProperties] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        fetchProperties()
    }, []);

    const fetchProperties = () => {
        setHasError(false)
        setLoading(true);
        Api.fetch({
            endpoint: 'properties'
        })
            .then((res) => {
                setProperties(res.response);
            }, () => {
            })
            .then(() => {
                setLoading(false);
            })
    }

    const onAdd = () => {
        properties.push({
            id: uuid(),
            name: '',
            description: '',
            isNew: true,
            type: 'string',
            areas: ['customer', 'device']
        })
        setProperties(properties.slice())
    }

    const onSave = () => {
        setSaving(true);
        setHasError(false)

        if (properties.some(property => property.hasErrors && !property.isDeleted)) {
            setSaving(false);
            setHasError(true)
            return;
        }

        const promiseList = properties.filter(g => g.isNew || g.isDeleted || g.hasChanges).map((c) => {
            const property = Object.assign({}, c);
            const isNew = property.isNew;
            const isDeleted = property.isDeleted;
            const hasChanges = property.hasChanges;

            delete property.isNew;
            delete property.isDeleted;
            delete property.hasChanges;

            if (isNew) {
                return Api.fetch({endpoint: 'properties', method: 'POST', body: property})
            } else if (isDeleted) {
                return Api.fetch({endpoint: 'properties/' + property.id, method: 'DELETE'})
            } else if (hasChanges) {
                return Api.fetch({endpoint: 'properties/' + property.id, method: 'PUT', body: property})
            }

            return new Promise((resolve) => {
                resolve();
            })
        })
        Promise.all(promiseList)
            .then(() => {
                fetchProperties();
            }, () => {
            }).then(() => {
            setSaving(false);
        })
    }

    const hasChanges = properties.some((property) => property.hasChanges || property.isNew || property.isDeleted);
    return <React.Fragment>
        {hasError &&
        <Alert onClose={() => setHasError(null)} severity='error'>{t('settings.properties.errorFound')}</Alert>}
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['properties.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={!hasChanges || loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Button disabled={loading} sx={{marginLeft: theme.spacing(1)}} size='small' onClick={onAdd} startIcon={<Add/>}
                        variant='outlined'
                        color='primary'>{t('settings.properties.addProperty')}</Button>
            </CheckPermissions>
            <Box flexGrow={1}/>
            <IconButton onClick={fetchProperties}><Replay/></IconButton>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.properties.name')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.properties.description')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.properties.types.label')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.properties.areas.label')}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{t('settings.properties.required')}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {properties.filter((g) => !g.isDeleted).map((property) => (
                        <Row key={property.id} property={property}
                             onDelete={() => {
                                 setProperties((properties) => {
                                     const i = properties.findIndex((c) => c.id === property.id);
                                     const deleteProperty = properties[i];

                                     if (deleteProperty.isNew) {
                                         properties.splice(i, 1)
                                     } else {
                                         deleteProperty.isDeleted = true;
                                         properties[i] = deleteProperty;
                                     }
                                     return properties.slice();
                                 })
                             }}
                             onChange={(changedProperty) => {
                                 setProperties((properties) => {
                                     const i = properties.findIndex((c) => c.id === changedProperty.id);
                                     properties[i] = changedProperty;
                                     return properties.slice();
                                 })
                             }}
                        />
                    ))}
                </TableBody>
            </Table>
            {properties.filter((g) => !g.isDeleted).length === 0 &&
            <Box sx={{textAlign: 'center', color: theme.palette.grey[400]}}>
                <Add sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
                <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
                    <Typography variant='body2'>{t('settings.properties.noPropertiesCreated')}</Typography>
                    <Link variant='body2' onClick={onAdd}>{t('settings.properties.addProperty')}</Link>
                </Box>
            </Box>
            }
        </TableContainer>
    </React.Fragment>;
}

export default List
