import { TOURS_LIST_FAILURE, TOURS_LIST_REQUEST, TOURS_LIST_SUCCESS } from "../actions/toursActions";

export function toursReducer(state = {
    isFetching: false,
    error: null,
    list: []
}, action) {
    switch (action.type) {
        case TOURS_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case TOURS_LIST_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case TOURS_LIST_SUCCESS:
            const devicesByTour = {};
            action.response.forEach((tour) => {
                devicesByTour[tour.id] = [];
                tour.addresses?.forEach((address) => {
                   address.locations.forEach((location) => {
                       location.devices.forEach((device) => {
                            devicesByTour[tour.id].push(device.deviceId);
                       });
                   });
                });
            });

            return Object.assign({}, state, {
                isFetching: false,
                list: action.response,
                devicesByTour: devicesByTour
            });
        default:
            return state
    }
}

