import {
    SYSTEM_DATA_CLEAR,
    SYSTEM_DATA_FAILURE,
    SYSTEM_DATA_REQUEST,
    SYSTEM_DATA_SUCCESS
} from "../actions/systemDataActions";

export function systemDataReducer(state = {
    isFetching: false,
    error: null,
    data: null
}, action) {
    switch (action.type) {
        case SYSTEM_DATA_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SYSTEM_DATA_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case SYSTEM_DATA_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                data: action.response
            });
        case SYSTEM_DATA_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                data: null,
                error: null
            });
        default:
            return state
    }
}

