import { Button, Dialog, DialogActions, DialogTitle, IconButton, InputAdornment, TextField } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomerList from "../Customers/Utils/CustomerList";
import Api from "../../core/Api";

const CustomerSelect = ({externalCustomerId, onChange, error}) => {
    const [loading, setLoading] = React.useState(false);
    const [customer, setCustomer] = React.useState({});
    const [customerId, setCustomerId] = React.useState(externalCustomerId);
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setCustomerId(externalCustomerId);
    }, [externalCustomerId])

    useEffect(() => {
        if (customerId && customerId !== customer.id) {
            setLoading(true)
            Api.fetch({
                endpoint: 'customers/' + customerId,
            }).then((response) => {
                setCustomer(response.response);
                onChange(response.response)
                setOpen(false);
            }, () => {
            }).then(() => setLoading(false))
        }
    }, [customerId, onChange, customer.id])

    return <React.Fragment>
        <TextField
            onClick={() => setOpen(true)}
            fullWidth
            value={customer.id ? `${customer.number} - ${customer.name}` : ''}
            placeholder={t('customerSelect.placeholder')}
            label={t('customerSelect.customer')}
            margin='normal'
            error={!!error}
            helperText={error}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                            <Edit/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
        <Dialog sx={{maxHeight: '100%'}}
                PaperProps={{sx: {maxHeight: 'calc(100% - 64px);'}}}
                open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'
        >
            <DialogTitle>
                {t('customerSelect.selectCustomer')}
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>

            </DialogTitle>
            <CustomerList
                loading={loading}
                selectable
                buttonSelect
                selected={customerId}
                onSelectionChange={(cId) => setCustomerId(cId)}
                linkToCustomer={false} displayCreate={false}
                containerStyle={{height: 'calc(100vh - 200px)', px: 2}}
            />
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}
export default CustomerSelect
