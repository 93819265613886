import React, { useCallback, useEffect, useState } from 'react';
import Api from "../../../../core/Api";
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Switch,
    TextField,
    Toolbar
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Replay, RestartAlt, Save } from "@mui/icons-material";
import CheckPermissions from "../../../Utils/CheckPermissions";

const Settings = () => {
    const {t} = useTranslation()
    const [settings, setSettings] = useState({
        current: 0,
        end: 0,
        endEnabled: false,
        places: 0,
        start: 0,
    })
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [resetOpen, setResetOpen] = useState(false)

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    }

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/number-range'
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);


    useEffect(() => {
        fetchSettings()
    }, [fetchSettings])

    const onSave = useCallback(() => {
        setSaving(true)
        Api.fetch({
            endpoint: 'settings/number-range',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving])

    return <Grid container>
        <Grid item xs={12} md={6}>
            <Toolbar variant='dense' disableGutters={true}>
                <CheckPermissions list={['numberRange.write']}>
                    <LoadingButton
                        onClick={onSave}
                        size='small'
                        loadingPosition="start"
                        disabled={loading}
                        loading={saving}
                        startIcon={<Save/>}
                        variant='contained'
                        color='primary'>{t('save')}</LoadingButton>
                </CheckPermissions>
                <Box flexGrow={1}/>
                <IconButton onClick={() => {
                    fetchSettings();
                }}><Replay/></IconButton>
            </Toolbar>
            <Paper sx={{p: 2}}>
                <TextField
                    fullWidth
                    disabled
                    value={settings.current}
                    placeholder={t('settings.numberRange.current')}
                    label={t('settings.numberRange.current')}
                    margin='normal'
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" color="primary" onClick={() => setResetOpen(true)}>
                                    <RestartAlt/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    placeholder={t('settings.numberRange.places')}
                    margin='normal'
                    type='number'
                    fullWidth
                    variant='outlined'
                    label={t('settings.numberRange.places')}
                    value={settings.places}
                    onChange={(e) => handleChange('places', parseInt(e.target.value, 10))}
                />
                <TextField
                    placeholder={t('settings.numberRange.start')}
                    margin='normal'
                    type='number'
                    fullWidth
                    variant='outlined'
                    label={t('settings.numberRange.start')}
                    value={settings.start}
                    onChange={(e) => handleChange('start', parseInt(e.target.value, 10))}
                />
                <FormControlLabel control={<Switch
                    checked={settings.endEnabled}
                    onChange={(e) => handleChange('endEnabled', e.target.checked)}
                />} label={t('settings.numberRange.endEnabled')}/>
                <Collapse in={settings.endEnabled}>
                    <TextField
                        placeholder={t('settings.numberRange.end')}
                        margin='normal'
                        type='number'
                        fullWidth
                        variant='outlined'
                        label={t('settings.numberRange.end')}
                        value={settings.end}
                        onChange={(e) => handleChange('end', parseInt(e.target.value, 10))}
                    />
                </Collapse>
            </Paper>
            <Dialog
                disableEscapeKeyDown
                open={resetOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setResetOpen(false);
                    }
                }}
            >
                <DialogTitle>{t('settings.numberRange.reset')}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        dangerouslySetInnerHTML={{__html: t('settings.numberRange.resetDescription', {start: settings.start})}}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResetOpen(false)} autoFocus>
                        {t('cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            handleChange('current', settings.start)
                            setResetOpen(false);
                        }}
                        startIcon={<RestartAlt/>}
                        variant="contained"
                        color='primary'
                    >
                        {t('settings.numberRange.reset')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    </Grid>;
}

export default Settings;
