import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Select,
    Toolbar,
    Typography,
    Alert
} from "@mui/material";
import moment from "moment";
import { Box, useTheme } from "@mui/system";
import { Close, Edit, MoreVert, Notes, Save, Send } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { cloneDeep } from "lodash";
import Api from "../../../core/Api";
import Schema from "validate";
import { stripHtml } from "string-strip-html";
import Action from "./Action";
import EditAction from "./EditAction";
import TextEditor from "../../Utils/TextEditor";

const htmlString = (val) => stripHtml(val || '').result.trim().length > 0;

const getValidationSchema = (t) => {
    return new Schema({
        message: {
            required: true,
            type: String,
            use: {htmlString},
            message: t('tickets.validation.message'),
        }
    })
}

const Actions = ({
                     onChange,
                     customer,
                     ticket,
                     creatorName,
                     creatorNameRaw,
                     userList,
                     taskList,
                     statusList,
                     actions,
                     devices,
                     deviceTypeList
                 }) => {
    const [saving, setSaving] = React.useState(false);
    const [ticketSaving, setTicketSaving] = React.useState(false);
    const [editDescriptionData, setEditDescriptionData] = React.useState(null);
    const [errorList, setErrorList] = React.useState([]);
    const [newStatus, setNewStatus] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newData, setNewData] = React.useState({
        message: '',
        taskIds: [],
        deviceIds: [],
        addressIds: [],
        timerIds: [],
        distance: 0,
        duration: 0
    });

    const handleNewData = (key, value) => {
        setNewData((n) => ({
            ...n,
            [key]: value
        }))
    }

    const stats = statusList.filter((s) => s.type === 'completed').sort((a, b) => a.priority - b.priority);
    if (newStatus === null && stats.length) {
        setNewStatus(stats[0].id);
    }

    const addresses = customer.addresses?.filter(a => ticket.addressIds.includes(a.id)) || [];

    const {t} = useTranslation();
    const theme = useTheme();
    let innerActions = actions.slice().sort((a, b) => moment(a.createdAt).diff(b.createdAt)).map((a) => <Action
        deviceTypeList={deviceTypeList}
        customer={customer}
        tasks={taskList}
        devices={devices}
        addresses={addresses}
        key={a.id}
        onChange={() => onChange(false)}
        action={a}
        userList={userList}/>);

    const saveTicket = () => {
        setTicketSaving(true);

        Api.fetch({
            endpoint: 'tickets/' + ticket.id,
            method: 'PUT',
            body: {
                ...ticket,
                description: editDescriptionData.description,
            }
        }).then(() => {
            setEditDescriptionData(null);
            onChange(true);
        }, () => {
        }).then(() => setTicketSaving(false));
    }

    const onSave = (withStatus = false) => {
        setSaving(true)
        setErrorList({})

        const v = getValidationSchema(t)
        const data = cloneDeep(newData);
        data.distance = parseFloat(data.distance);
        data.duration = parseInt(data.duration);
        const errors = v.validate(cloneDeep(data))

        if (errors.length) {
            const errorObject = {};
            errors.forEach((error) => {
                errorObject[error.path] = error.message
            })

            setSaving(false);
            setErrorList(errorObject)
            return;
        }

        Api.fetch({
            endpoint: 'tickets/' + ticket.id + '/actions',
            method: 'POST',
            body: data
        }).then(() => {
            if (withStatus) {
                Api.fetch({
                    endpoint: 'tickets/' + ticket.id,
                    method: 'PUT',
                    body: {
                        ...ticket,
                        statusId: newStatus
                    }
                }).then(() => {
                    onChange(true)
                    setNewData({
                        message: '',
                        taskIds: [],
                        deviceIds: [],
                        addressIds: [],
                        distance: 0,
                        duration: 0
                    })
                    setNewStatus(stats[0].id);
                }, () => {
                }).then(() => setSaving(false))
            } else {
                setSaving(false);
                onChange(false)
                setNewData({
                    message: '',
                    taskIds: [],
                    deviceIds: [],
                    addressIds: [],
                    distance: 0,
                    duration: 0
                })
                setNewStatus(stats[0].id);
            }
        }, () => {
            setSaving(false);
        })
    }
    if (htmlString(ticket.description || '')) {
        innerActions = [
            <Card variant='elevation' elevation={0} key='initial'>
                <CardHeader
                    avatar={
                        <Avatar alt={creatorNameRaw} src={'dummy.png'}/>
                    }
                    action={
                        <IconButton
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                            }}>
                            <MoreVert/>
                        </IconButton>
                    }
                    title={creatorName}
                    subheader={moment(ticket.createdAt).fromNow()}
                />
                <CardContent sx={{py: 0}}>
                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: ticket.description}}/>
                </CardContent>
            </Card>, ...innerActions];
    }

    let actionsList = <Box sx={{textAlign: 'center', color: theme.palette.grey[400], py: 2}}>
        <Notes sx={{fontSize: 60}} viewBox={'0 0 20 20'}/>
        <Box sx={{color: theme.palette.text.secondary, mb: 2}} textAlign='center'>
            <Typography variant='body2'>{t('tickets.show.noActionsFound')}</Typography>
            <Link variant='body2' onClick={() => setEditDescriptionData({
                description: ticket.description
            })}>{t('tickets.show.addDescription')}</Link>
        </Box>
    </Box>;
    if (innerActions.length > 0) {
        actionsList = innerActions.reduce((prev, elem, i) => {
            return prev === null ? [elem] : [...prev, <Divider key={'divider-' + i} variant="middle"/>, elem]
        }, null);
    }

    return <React.Fragment>
        {(!htmlString(ticket.description) && !!innerActions.length) && <Alert severity='info' action={
            <Button size='small' color='inherit' onClick={() => setEditDescriptionData({
                description: ticket.description
            })}>{t('tickets.show.addDescription')}</Button>
        }>{t('tickets.show.noDescription')}</Alert>}
        {actionsList}
        <Divider variant="middle"/>
        <Box sx={{px: 2, pt: 2}}>
            <Typography sx={{mb: 1}} variant='h5'>{t('tickets.show.newAction')}</Typography>
            <EditAction
                action={newData}
                handleChange={handleNewData}
                addresses={addresses}
                errorList={errorList}
                devices={devices}
                deviceTypeList={deviceTypeList}
                customer={customer}
                tasks={taskList}
            />
            <Toolbar disableGutters>
                <LoadingButton
                    sx={{mr: 1}}
                    onClick={() => onSave(false)}
                    loadingPosition="start"
                    loading={saving}
                    startIcon={<Send/>}
                    variant='contained'
                    color='primary'>{t('tickets.show.saveNewAction')}</LoadingButton>
                <Box
                    sx={{mr: 1}}
                >{t('or')}</Box>
                <FormControl margin='dense' size='small' sx={{width: '200px', margin: 0, mr: 1}}>
                    <InputLabel>{t('tickets.fields.status')}</InputLabel>
                    <Select
                        value={newStatus || ''}
                        renderValue={
                            (selected) => statusList.find(r => r.id === selected)?.name || ''
                        }
                        onChange={(e) => setNewStatus(e.target.value)}
                        input={<OutlinedInput label={t('tickets.fields.status')}/>}
                    >
                        {statusList.map((r) => (
                            <MenuItem key={r.id} value={r.id}>
                                <ListItemText primary={r.name} secondary={r.description}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LoadingButton
                    loadingPosition="start"
                    loading={saving}
                    startIcon={<Send/>}
                    onClick={() => onSave(true)}
                    variant='contained'
                    color='primary'>{t('tickets.show.saveNewActionAndUpdateStatus')}</LoadingButton>
            </Toolbar>
        </Box>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
        >
            <MenuItem onClick={() => {
                setEditDescriptionData({description: ticket.description});
                setAnchorEl(null)
            }}>
                <ListItemIcon>
                    <Edit fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{t('tickets.show.editDescription')}</ListItemText>
            </MenuItem>
        </Menu>
        <Dialog maxWidth='md' fullWidth open={Boolean(editDescriptionData)}>
            <DialogTitle>
                {t('tickets.show.editDescription')}
                <IconButton
                    aria-label="close"
                    disabled={ticketSaving}
                    onClick={() => setEditDescriptionData(null)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            {Boolean(editDescriptionData) && <DialogContent>
                <TextEditor
                    style={{marginTop: '8px', marginBottom: '8px'}}
                    value={editDescriptionData.description}
                    onChange={(v) => setEditDescriptionData({description: v})}
                />
            </DialogContent>}
            <DialogActions>
                <Button
                    disabled={ticketSaving}
                    onClick={() => setEditDescriptionData(null)}
                >
                    {t('close')}
                </Button>
                <LoadingButton
                    loadingPosition="start"
                    loading={ticketSaving}
                    onClick={saveTicket}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'
                >
                    {t('save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export default Actions;
