import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Slider,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Api from "../../../../core/Api";
import { LoadingButton } from "@mui/lab";
import { LocationSearching, Replay, Save } from "@mui/icons-material";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import LeafletChangeView from "../../../Utils/LeafletChangeView";
import CheckPermissions from "../../../Utils/CheckPermissions";
import CheckVersion from "../../../Utils/CheckVersion";

const Settings = () => {
    const {t} = useTranslation()
    const [settings, setSettings] = useState({
        zoom: 13,
        address: "34246, Vellmar",
        lat: 51.36225805000004,
        lng: 9.47249443100003
    })

    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [coordsLoading, setCoordsLoading] = useState(false)

    const handleChange = (key, value) => {
        setSettings((s) => ({...s, [key]: value}))
    };

    const fetchCoords = useCallback(async () => {
        setCoordsLoading(true);
        Api.fetch({
            endpoint: 'integrations/location/by-address',
            parameter: {
                address: settings.address
            }
        }).then((res) => {
            const data = res.response;
            setSettings((s) => ({...s, address: data.label, lat: data.lat, lng: data.lng}))
        }, () => {
        }).then(() => {
            setCoordsLoading(false);
        })

    }, [settings.address])

    const fetchSettings = useCallback(() => {
        setLoading(true)
        Api.fetch({
            endpoint: 'settings/customer-map'
        }).then(res => {
            setSettings(res.response)
        }, () => {
        }).then(() => setLoading(false))
    }, [setLoading, setSettings]);

    useEffect(() => {
        fetchSettings()
    }, [fetchSettings]);

    const onSave = useCallback(() => {
        setSaving(true)
        Api.fetch({
            endpoint: 'settings/customer-map',
            method: 'POST',
            body: settings
        }).then(() => {
            fetchSettings()
        }, () => {
        }).then(() => setSaving(false))
    }, [fetchSettings, settings, setSaving]);

    const position = [settings.lat, settings.lng]

    return <CheckVersion minimumVersion='extended'>
        <Toolbar variant='dense' disableGutters={true}>
            <CheckPermissions list={['customerMap.write']}>
                <LoadingButton
                    onClick={onSave}
                    size='small'
                    loadingPosition="start"
                    disabled={loading}
                    loading={saving}
                    startIcon={<Save/>}
                    variant='contained'
                    color='primary'>{t('save')}</LoadingButton>
                <Box flexGrow={1}/>
                <IconButton onClick={() => {
                    fetchSettings();
                }}><Replay/></IconButton>
            </CheckPermissions>
        </Toolbar>
        <Paper sx={{p: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={coordsLoading}
                        fullWidth
                        value={settings.address}
                        label={t('settings.customerMap.address')}
                        placeholder={t('settings.customerMap.address')}
                        margin='normal'
                        onChange={(e) => handleChange('address', e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" color="primary" onClick={fetchCoords}>
                                        <LocationSearching/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl fullWidth margin='normal'>
                        <Typography gutterBottom>{t('settings.customerMap.zoom')}</Typography>
                        <Box sx={{pl: 2, pr: 2}}>
                            <Slider
                                value={settings.zoom}
                                onChange={(e, v) => handleChange('zoom', v)}
                                step={1}
                                marks={[
                                    {value: 5, label: '5'},
                                    {value: 18, label: '18'},
                                ]}
                                min={5}
                                max={18}
                                valueLabelDisplay='auto'
                            />
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MapContainer style={{height: '600px', width: '100%'}} center={position} zoom={settings.zoom}
                                  scrollWheelZoom={false}>
                        <LeafletChangeView zoom={settings.zoom} center={position}/>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position}/>
                    </MapContainer>
                </Grid>
            </Grid>
        </Paper>
    </CheckVersion>
}

export default Settings;
