import React from 'react';
import { Paper } from "@mui/material";
import CustomerList from '../Utils/CustomerList';
import {Box} from "@mui/system";

const List = () => {
    return <Box sx={{py: 2, height: '100%'}}>
        <Paper sx={{p: 2, height: '100%'}}>
            <CustomerList/>
        </Paper>
    </Box>;
}

export default List;
